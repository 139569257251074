<template>
  <div :id="'expandable-button' + title"
       :class="['option-holder', {'enabled' : !enabled}]" @click=itemClicked>
    <p class="expandable-button-title">{{ title }}: {{ selected }}</p>
    <div v-if="state === 'expanded'">
      <p v-for="option in options" :key="option" @click="itemSelected(title, option)"
         :class="['expandable-button-option', { 'selected': option === selected }]">
        {{ option }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpandableButton",
  props: [
    'title', 'options', 'expandedCallback', 'itemClickedCallback', 'enabled'
  ],
  data() {
    return {
      state: 'collapsed',
      selected: ''
    }
  },
  mounted() {
    // get selected from localStorage
    let selected = localStorage.getItem(this.title)
    if (selected && this.options.includes(selected)) {
      this.selected = selected
    } else {
      this.selected = this.options[0]
      localStorage.setItem(this.title, this.selected)
    }
  },
  methods: {
    collapse() {
      console.log('collapse')
      this.state = 'collapsed'
    },
    itemClicked() {
      if (!this.enabled) {
        return
      }
      console.log('itemClicked')
      if (this.state === 'expanded') {
        this.state = 'collapsed'
      } else {
        this.state = 'expanded'
        this.expandedCallback(this.title)
      }
    },
    itemSelected(title, option) {
      console.log('picked option', option)
      localStorage.setItem(title, option)
      this.selected = option
      this.itemClickedCallback(title, option)
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Jura';
  src: url('@/assets/fonts/Jura-VariableFont_wght.ttf') format('truetype'); /* Adjust the path accordingly */
  font-weight: normal;
  font-style: normal;
}


.option-holder {
  border-radius: 54px;
  border: 3px solid rgb(118, 104, 163);
  background: linear-gradient(180deg, #7D6ED9 0%, #B0A3FF 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  user-select: none;
  padding-bottom: 4px;
  padding-top: 4px;
  height: fit-content;
  flex-shrink: 0;
}

.option-holder:hover {
  background: linear-gradient(180deg, #5f49e0 0%, #B0A3FF 100%);
  cursor: pointer;
}

.expandable-button-title {
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expandable-button-option {
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.expandable-button-option:hover {
  font-weight: bold;
}

/*noinspection CssUnusedSymbol*/
.enabled {
  background: linear-gradient(180deg, #D2D2D2 0%, #9E9E9E 100%);
  border: 3px solid rgb(108, 108, 109);

}

/*noinspection CssUnusedSymbol*/
.enabled:hover {
  background: linear-gradient(180deg, #D2D2D2 0%, #9E9E9E 100%);
  border: 3px solid rgb(108, 108, 109);

}

</style>
