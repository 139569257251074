import {initializeApp} from 'firebase/app';
import {getAnalytics, logEvent} from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyD6mU8nHm_8EFNm2xfsolg3L20Qk9Shmm0",
    authDomain: "sillyvart.firebaseapp.com",
    projectId: "sillyvart",
    storageBucket: "sillyvart.appspot.com",
    messagingSenderId: "243655134021",
    appId: "1:243655134021:web:f0146903239581fd6f4c2c",
    measurementId: "G-CWYKHPHX2K"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export {analytics};

export function logAnalyticsEvent(eventName, eventParams) {
    logEvent(analytics, eventName, eventParams);
}
