<template>
  <div class="page">
    <SlicesHeaderView/>
    <div class="slices-home-content">
      <SlicesPictureButton
          :title="currentPrompt.title"
          :sub-title="currentPrompt.subtitle"
          number="3"
          total="5"
          :url="currentPrompt.url"
          @click="promptClick"
      />
      <div class="current-slice-container">
        <SlicesPictureButton
            :title="currentSlice.title"
            :sub-title="currentSlice.subtitle"
            :url="currentSlice.url"
        />
        <p v-if="promptState"
        >Time remaining</p>
        <div v-if="promptState" class="current-slice-timer">
          <p> {{ remainingTime }}</p>
        </div>
        <p v-if="promptState">request extension</p>
        <label class="toggle">
          <span class="toggle-label">NSFW</span>
          <input class="toggle-checkbox" type="checkbox" v-model="isChecked" @click="toggleSwitch"/>
          <div class="toggle-switch">
            <div class="toggle-switch-on-label" v-if="isChecked">on</div>
            <div class="toggle-switch-off-label" v-if="!isChecked">off</div>
          </div>
        </label>

      </div>
      <SlicesPictureButton
          title="Gallery"
          :subTitle="GallerySubtitle"
          :url="galleryImage"
      />
    </div>
    <SlicesAdsView/>
  </div>

</template>

<script>
import SlicesHeaderView from "@/views/slices/SlicesHeaderView.vue";
import SlicesPictureButton from "@/views/slices/SlicesPictureButton.vue";
import SlicesAdsView from "@/views/slices/SlicesAdsView.vue";

export default {
  components: {SlicesAdsView, SlicesPictureButton, SlicesHeaderView},
  data() {
    return {
      isChecked: false,
      daysUntilEvent: 7, // Replace with the actual number of days
      remainingTime: '24h 59m 59s',
      galleryImage: 'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169lll/bf/09/1b/bf091bbffd826fc5f570f93a56099501/bf091bbffd826fc5f570f93a56099501.13.jpg',
      GallerySubtitle: '4 / 69',
      currentPromptTitle: 'My Current Prompt',
      currentPromptSubtitle: '3 / 6',
      currentPromptUrl: 'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169lll/bf/09/1b/bf091bbffd826fc5f570f93a56099501/bf091bbffd826fc5f570f93a56099501.13.jpg',
      currentSliceTitle: 'My Current Slice',
      currentSliceSubtitle: '3 / 5',
      currentSliceUrl: 'https://cdn77-pic.xvideos-cdn.com/videos/thumbs169lll/bf/09/1b/bf091bbffd826fc5f570f93a56099501/bf091bbffd826fc5f570f93a56099501.13.jpg',
      promptState: false
    };
  },
  mounted() {
    let accessToken = new URLSearchParams(window.location.hash).get('#access_token');
    let state = new URLSearchParams(window.location.hash).get('state');
    if (accessToken && state === localStorage.getItem('twitchLoginState')) {
      localStorage.setItem('twitchToken', accessToken);
    }
    window.location.hash = '';
  },
  computed: {
    currentPrompt() {
      if (this.promptState) {
        return {
          title: this.currentPromptTitle,
          subtitle: this.currentPromptSubtitle,
          url: this.currentPromptUrl
        }
      } else {
        return {
          title: "New prompt",
          subtitle: "-1",
          url: this.currentSliceUrl
        }

      }
    },
    currentSlice() {
      if (this.promptState) {
        return {
          title: this.currentSliceTitle,
          subtitle: this.currentSliceSubtitle,
          url: this.currentSliceUrl
        }
      } else {
        return {
          title: "Join Round",
          subtitle: "7 available",
          url: this.currentSliceUrl
        }

      }
    }
  },
  methods: {
    promptClick() {
      if (!this.promptState) {
        //   navigate to slice
        window.location.href = 'http://localhost:8080/slices/upload';
      }
    },
    toggleSwitch() {
      this.isChecked = !this.isChecked;
    },
    getTwitchInfo() {
      //   https://api.twitch.tv/helix/users
      let url = `https://api.twitch.tv/helix/users`
      let token = localStorage.getItem('twitchToken');
      let headers = {
        'Authorization': `Bearer ${token}`,
        'Client-Id': 'mun73iujdvytp6ezl88wkl75d5cotp'
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!');
        }
      }).then(response => {
        console.log(response);
        localStorage.setItem('twitchUser', JSON.stringify(response));
        this.displayName = response.data[0].display_name;
        this.profileImageUrl = response.data[0].profile_image_url;
        localStorage.setItem('twitchDisplayName', this.displayName);
        localStorage.setItem('twitchProfileImageUrl', this.profileImageUrl);
      }).catch(error => {
        console.error(error);
      });
    },
    validateToken() {
      let token = localStorage.getItem('twitchToken');
      let url = `https://id.twitch.tv/oauth2/validate`
      let headers = {
        'Authorization': `OAuth ${token}`
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!');
        }
      }).then(response => {
        console.log(response);
        localStorage.setItem('twitchUser', JSON.stringify(response));
        this.getTwitchInfo()
      }).catch(error => {
        console.error(error);
        //   navigate to login
        window.location.href = 'http://localhost:8080/slices';
      });
    },
  },
};
</script>

<style scoped>
.page {
  background: linear-gradient(180deg, #FFF 0%, #999 100%);
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.slices-home-content {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-inline-end: 10%;
  margin-inline-start: 10%;
  width: 80%;
  flex: 3;
}

.content img {
  max-width: 200px;
}

.current-slice-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

/* Add more styles for the vertical banner ad here */
.current-slice-timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 34px;
  border: 3px solid #000;
  background: #92D582;
  width: 300px;
  height: 68px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Inter, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 148.023%; /* 53.288px */
}


.toggle {
  margin-top: 20px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
}

.toggle-switch {
  display: flex;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggle-switch:before, .toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggle-checkbox:checked + .toggle-switch {
  background: #000000;
}


.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
  background: #56c080;

}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
  color: #000;
  margin-inline-end: 20px;
  text-align: center;
  font-family: Irish Grover;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.toggle-switch-on-label {
  font-family: Inter, serif;
  width: 100%;
  height: 100%;
  text-align: start;
  user-select: none;
  color: whitesmoke;
  margin-top: 6px;
  margin-inline-start: 5px;
}

.toggle-switch-off-label {
  width: 100%;
  font-family: Inter, serif;
  height: 100%;
  text-align: end;
  margin-top: 6px;
  user-select: none;
  margin-inline-end: 5px;
}
</style>
