<template>
  <div class="odds-holder">
    <odds-component class="first"/>
    <odds-component class="second"/>
  </div>

</template>

<script>
import OddsComponent from "@/views/odds/oddsComponent.vue";

export default {
  name: "oddsView",
  components: {OddsComponent},
  mounted() {
    document.title = "Odds Calculator - Baldur's Gate 3"
    document.querySelector('.odds-holder').addEventListener('wheel', event => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }, true)

  }
}
</script>

<style scoped>

body {

}

.first {
  top: 11%;
  height: 78%;
  left: 20%;
  position: absolute;
}

.second {
  top: 11%;
  height: 78%;
  left: 60%;
  position: absolute;
}

.odds-holder {
  position: absolute;
  left: 0;
  top: 0;
  align-content: center;
  flex-direction: row;
  overflow: hidden;
  background-color: black;
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

#app {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;

}
</style>
