<template>
  <div class="storefront">
    <!-- Top Bar with Buttons -->
    <div class="top-bar">
      <!-- Your buttons go here -->
    </div>

    <!-- Main Grid of Items -->
    <div class="item-grid">
      <!-- Item Cards -->
      <div class="item-card" v-for="item in items" :key="item.id">
        <div class="item-info">
          <h3>{{ item.name }}</h3>
          <p>{{ item.description }}</p>
          <p class="price">{{ item.price }}</p>
        </div>
        <button class="add-to-cart">Add to Cart</button>
      </div>
    </div>

    <!-- Pagination Buttons -->
    <div class="pagination">
      <!-- Pagination buttons go here -->
    </div>

    <!-- Bottom Bar with Links -->
    <div class="bottom-bar">
      <!-- Your links and other content go here -->
    </div>
  </div>
</template>

<script>

export default {
  name: "StoreFront",
  data() {
    return {
      items: ['item1',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item2',
        'item3']
    }
  }
}
</script>

<style scoped>
.storefront {
  top: 0;
  left: 0;
  margin: 0;
  width: 96vw;
  overflow-x: hidden;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  background-color: #00ff00;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.top-bar {
  position: relative;
  width: auto;
  margin-inline-start: .1%;
  margin-inline-end: .1%;
  display: flex;
  justify-content: space-between;
  background-color: #8a72a0; /* Lilac header background color */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;

}

.item-grid {

  grid-auto-rows: minmax(100px, auto); /* Set the minimum row height and allow rows to grow as needed */

  display: grid;
  background-color: red;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width as needed */
  gap: 20px;


  grid-gap: 20px; /* Adjust the gap between items */
  min-height: 300px; /* Set your desired minimum height here */

}

.item-card {
  max-height: 250px;
  background-color: #fff; /* White item card background color */
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.item-card:hover {
  transform: translateY(-5px); /* Lift card on hover */
}

.item-info h3 {
  color: #333; /* Dark text color */
  font-size: 1.2rem;
  margin: 0;
}

.item-info p {
  color: #666; /* Gray text color */
}

.price {
  font-weight: bold;
  color: #8a72a0; /* Lilac text color for price */
  margin-top: 10px;
}

.add-to-cart {
  background-color: #8a72a0; /* Lilac button background color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #634a76; /* Darker lilac on hover */
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.bottom-bar {
  background-color: #8a72a0; /* Lilac footer background color */
  color: #fff; /* White text color */
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
</style>
