<template>
  <div class="download-page">
    <!-- Download All / Download Selected toggle -->

    <div class="checkbox-wrapper-8">
      <input id="cb3-8"  class="tgl tgl-skewed" type="checkbox"/>
      <label class="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3-8"></label>
    </div>
    <div class="checkbox-wrapper-8">
      <input id="cb3-8"  class="tgl tgl-skewed" type="checkbox"/>
      <label class="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3-8"></label>
    </div>
    <div class="checkbox-wrapper-8">
      <input id="cb3-8"  class="tgl tgl-skewed" type="checkbox"/>
      <label class="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3-8"></label>
    </div>
    <div class="checkbox-wrapper-8">
      <input id="cb3-8"  class="tgl tgl-skewed" type="checkbox"/>
      <label class="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3-8"></label>
    </div>

  </div>
</template>


<script>
export default {
  data() {
    return {
      downloadAll: false,
      twitchSizes: false,
      discordSizes: false,
      originalGrid: false,
    };
  },
  methods: {
    onToggleChange(section) {
      // Handle toggle changes for each section
      if (section === 'downloadAll') {
        // Update the state of the 'Download Selected' toggle
        this.downloadAll = !this.downloadAll;
        this.onToggleChange('downloadSelected');
      } else {
        // Handle other toggle changes
        console.log(`Toggle state for ${section}: ${this[section]}`);
      }
    },
  },
};
</script>
<style scoped>
.download-page {
  text-align: center;
  margin-inline-start: 48px;
  background: whitesmoke;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.checkbox-wrapper-8 .tgl {
  display: none;
}

.checkbox-wrapper-8 .tgl,
.checkbox-wrapper-8 .tgl:after,
.checkbox-wrapper-8 .tgl:before,
.checkbox-wrapper-8 .tgl *,
.checkbox-wrapper-8 .tgl *:after,
.checkbox-wrapper-8 .tgl *:before,
.checkbox-wrapper-8 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-8 .tgl::-moz-selection,
.checkbox-wrapper-8 .tgl:after::-moz-selection,
.checkbox-wrapper-8 .tgl:before::-moz-selection,
.checkbox-wrapper-8 .tgl *::-moz-selection,
.checkbox-wrapper-8 .tgl *:after::-moz-selection,
.checkbox-wrapper-8 .tgl *:before::-moz-selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-8 .tgl::selection,
.checkbox-wrapper-8 .tgl:after::selection,
.checkbox-wrapper-8 .tgl:before::selection,
.checkbox-wrapper-8 .tgl *::selection,
.checkbox-wrapper-8 .tgl *:after::selection,
.checkbox-wrapper-8 .tgl *:before::selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after,
.checkbox-wrapper-8 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-8 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: Inter;
  background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after,
.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active {
  background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn {
  background: rgba(90, 184, 6, 0.5);
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

.checkbox-wrapper-8 {
  margin-bottom: 10px;
}

</style>
