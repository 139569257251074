<template>
  <div class="RecapViewOuter">
    <div class="RecapContainer">
      <div class="RecapLogoContainer"><span class="recap-title">2023, SillyV Stream Recap</span></div>

      <div class="RecapTitleContainer">
        <div v-if="profilePicture2" class="profile-circle-gartic">
          <div class="circle"></div>
          <img :src="profilePicture2" alt="Profile Picture"/>
        </div>
        <div v-if="!profilePicture2" class="profile-circle-twitch">
          <img :src="profilePictureTwitch" alt="Profile Picture"/>
        </div>
        <div class="profile-welcome-text">
          <br>
          <br>
          <span>{{ name }}</span>
          <span>thank you for being</span>
          <span>a part of our community</span>
        </div>
      </div>
      <div class="arrow-down"></div>
      <div class="thank-you-first-text">
        Since I first started streaming on Twitch, our community has grown. Over the past two years, we have made more
        than 40,000 drawings together!<br><br>

        As a community, you have rekindled my love for art, which I am very grateful for. I’ve met wonderful friends on
        Twitch - people I want to stay connected to going into the future.<br><br>

        For everything that you do for me - everything that you mean to me, I want to give something back.<br><br>

        This recap of our last year together is a small part of that - a reminder of times we shared together.<br><br>

        I hope you have as fond memories of this as I do.<br><br>
      </div>
      <div class="disclaimer" @click="toggleDisclaimer">
        Disclaimer
      </div>
      <div v-if="disclaimer" class="disclaimerText">
        <div style="padding: 5%">
          Some images may be NSFW, but these are images that were either drawn by you, or with your consent.<br><br>
          A small amount of images might have been saved when you played Gartic Phone with me on other streams. These
          images will make the statistics slightly inaccurate. If you stream Gartic, the inaccuracy is probably
          bigger.<br><br>
        </div>
      </div>

      <div v-if="first.length > 0" class="RecapSectionContent first-redeem section-margin">
        <span>First of all - thank you for<br>redeeming first</span> <span class="bigger-font">{{
          first.length
        }} </span>
        <span v-if="first.length > 1"> times!</span>
        <span v-else-if="first.length === 1">time!</span>
      </div>
      <div v-if="myImages.length > 0" class="RecapNoSectionContent section-margin">
        <h2>you made</h2>
        <RandomImageView :urls="onlyImageUrls"/>
        <h2>{{ myImages.length }} Drawings</h2>
        <h2> Over {{ streamDates.length }} Streams</h2>

        <h4>This is the first drawing you made in 2023:</h4>
        <RandomImageView :urls="firstImageArray"/>
        <h4>And this is the last one so far:</h4>
        <RandomImageView :urls="lastImageArray"/>
        <h4>Your most active month was <span class="h4-but-bigger">{{ activeMonth }}</span></h4>
        <h4>With <span class="h4-but-bigger">{{ activeMonthDrawings.length }}</span> Drawings Over
          <span class="h4-but-bigger">{{ activeMonthStreams.length }}</span> Streams</h4>
        <RandomImageView :urls="activeMonthUrls"/>
        <h4 v-if="nsfwDrawings.length > 0">Your naughty self made <span class="h4-but-bigger">{{
            nsfwDrawings.length
          }}</span> drawings that were NSFW:
        </h4>
        <RandomImageView v-if="nsfwDrawings.length > 0" :urls="nsfwDrawings"/>
      </div>
      <div v-if="yourOcDrawings.length > 0 || ocSatYourOcDrawings.length > 0"
           class="RecapSectionContent section-margin">
        <div class="oc-header">
          <span class="oc-title">OC Saturday!</span><br><br><br><br><br>
          <span class="oc-description section-margin">
          OC Saturday is a weekly event where we draw original characters from the community. It's a chance to showcase your creativity and share your characters with the world.<br><br> We've drawn hundreds of OCs,<br>each one is a unique expression of the artist's imagination. <br>
        </span> <br> <br> <br><br><br><br>
          <span v-if="yourOcDrawingsUrls.length > 0" class="oc-item-title section-margin">You were chosen {{
              ocDates.length
            }} times</span> <br>
          <span v-if="yourOcDrawingsUrls.length > 0" class="oc-item-title">and were drawn {{ yourOcDrawings.length }} times</span>
          <RandomImageView v-if="yourOcDrawingsUrls.length > 0" :urls="yourOcDrawingsUrls" class="section-margin"/>
          <br><br><br><br>
          <span v-if="ocSatYourOcDrawingsUrls.length > 0" class="oc-item-title section-margin">You drew {{
              ocSatYourOcDrawings.length
            }} other people's OCs</span>
          <RandomImageView v-if="ocSatYourOcDrawingsUrls.length > 0" :urls="ocSatYourOcDrawingsUrls"
                           class="section-margin"/>
        </div>
      </div>
      <div v-if="sillyVDrawings.length > 0" class="RecapNoSectionContent section-margin">
        <h2>You drew SillyV {{ sillyVDrawings.length }} times:</h2>
        <RandomImageView :urls="sillyVDrawings"/>
      </div>
      <div v-if="bySillyVDrawings.length > 0" class="RecapNoSectionContent section-margin">
        <h2>SillyV drew you {{ bySillyVDrawings.length }} times:</h2>
        <RandomImageView :urls="bySillyVDrawings"/>

      </div>
      <div v-if="bySillyVDrawings.length > 0" class="oc-thank-you">
        Getting to draw all of your OCs has pushed me to
        explore different art styles and to work on anatomy.
        I have been enjoying the creativity and diversity
        that you bring to the table!

        Drawing your OC has been a pleasure,
        so thank you for sharing your character with me!
      </div>

      <div v-if="layoutAvatars.length > 0" class="RecapNoSectionContent section-margin">
        <h2>You received {{ layoutAvatars.length }} Layout Avatars</h2>
        <div class="layout-avatar-container">
          <div v-for="layoutAvatar in layoutAvatars" :key="layoutAvatar" class="layout-avatar">
            <img :alt="layoutAvatar.name" :src="layoutAvatar.url"/>
          </div>
        </div>
      </div>

      <div v-if="raids.length > 0" class="RecapNoSectionContent section-margin">
        <h2 class="section-title">You raided the stream {{ raids.length }} times</h2>
        <h2 class="section-title">With a total of {{ raidViewers }} viewers</h2>
      </div>

      <div class="RecapSectionContent section-margin last-thank-you">
        <div class="last-thank-you-inner">
          Thank you for being a part of this journey.<br><br>
          I'm grateful for the opportunity to share my passion for art with you.<br><br>
          I hope you've enjoyed the streams and look forward to seeing you in 2024.
        </div>
      </div>
      <img class="section-margin" src="./logo2.png">
      <div class="love section-margin">With Love,</div>
      <div class="love">SillyV</div>

      <div class="bottom-margin"></div>


      <!--      <RandomImageView :urls="onlyImageUrls"/>-->
      <!--      <h2>{{ myImages.length }} Drawings</h2>-->
      <!--      <h2> Over {{ streamDates.length }} Streams</h2>-->

      <!--      <h4>This is the first drawing you made in 2023:</h4>-->
      <!--      <RandomImageView :urls="firstImageArray"/>-->
      <!--      <h4>And this is the last one so far:</h4>-->
      <!--      <RandomImageView :urls="lastImageArray"/>-->
      <!--      <h4>Your most active month was <span class="h4-but-bigger">{{ activeMonth }}</span></h4>-->
      <!--      <h4>With <span class="h4-but-bigger">{{ activeMonthDrawings.length }}</span> Drawings Over-->
      <!--        <span class="h4-but-bigger">{{ activeMonthStreams.length }}</span> Streams</h4>-->
      <!--      <RandomImageView :urls="activeMonthUrls"/>-->
      <!--      <h4>Your naughty self made <span class="h4-but-bigger">{{ nsfwDrawings.length }}</span> drawings that were NSFW:-->
      <!--      </h4>-->
      <!--      <RandomImageView :urls="nsfwDrawings"/>-->

      <!--      <div v-if="sillyVDrawings.length > 0"-->
      <!--           class="RecapSectionContent section-margin"-->
      <!--      >-->
      <!--        <div class="image-container">-->
      <!--          <RandomImageView :urls="sillyVDrawings"/>-->
      <!--          <div class="image-text">-->
      <!--            <span class="section-title">You drew SillyV {{ sillyVDrawings.length }} times</span>-->
      <!--            <span class="section-text">I genuinely appreciate all the talented artists who've dedicated their time to drawing the purple cyclops.  it warms my heart to see the diverse interpretations of my character</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div v-if="bySillyVDrawings.length > 0"-->
      <!--           class="RecapSectionContent section-margin"-->
      <!--      >-->
      <!--        <div class="image-container">-->
      <!--          <RandomImageView :urls="bySillyVDrawings"/>-->
      <!--          <div class="image-text">-->
      <!--            <span class="section-title">SillyV drew you {{ bySillyVDrawings.length }} times</span>-->
      <!--            <span class="section-text">I deeply value all the skilled artists who've granted me the opportunity to portray them through my drawings.  Exploring the creation of your OC was an enlightening journey. </span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      &lt;!&ndash;      section for receiving layout avatars&ndash;&gt;-->
      <!--      <div v-if="layoutAvatars.length > 0"-->
      <!--           class="RecapSectionContent section-margin"-->
      <!--      >-->
      <!--        <span class="section-title">You received {{ layoutAvatars.length }} Layout Avatars-->
      <!--        {{ JSON.stringify(layoutAvatars) }}-->
      <!--        </span>-->
      <!--      </div>-->
      <!--      <div v-if="layoutAvatars.length > 0"-->
      <!--           class="RecapSectionContent"-->
      <!--      >-->

      <!--        <img alt="Logo" src="https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"/>-->
      <!--      </div>-->


      <!--      &lt;!&ndash;      section for raiding the stream&ndash;&gt;-->
      <!--      <div v-if="raids.length > 0"-->
      <!--           class="RecapSectionContent section-margin"-->
      <!--      >-->
      <!--        <span class="section-title">You raided the stream {{ raids.length }} times</span>-->
      <!--      </div>-->
      <!--      <div v-if="raids.length > 0"-->
      <!--           class="RecapSectionContent section-margin"-->
      <!--      >-->
      <!--        <span class="section-title">With a total of {{ raidViewers }} viewers</span>-->
      <!--      </div>-->

      <!--      &lt;!&ndash;      some closing words. and thankyous&ndash;&gt;-->
      <!--      <div class="RecapLogoContainer section-margin"><span class="recap-description">      Thank you for being a part of this journey. I'm grateful for the opportunity to share my passion for art with you. I hope you've enjoyed the streams and look forward to seeing you in 2024.</span>-->
      <!--      </div>-->
      <!--      <div class="RecapLogoContainer end-margin"><span class="recap-description">      -SillyV</span>-->
      <!--      </div>-->

      <!--      <div class="RecapLogoContainer"><span class="recap-description disclaimer">Disclaimer:-->
      <!--      a small amount of images may be NSFW.  but they are images that were drawn by you or with your consent. <br>-->
      <!--      a small amount of images were saved when you played Gartic Phone with me on other streams, and not on mine. so the numbers are slightly off, but the majority is on my stream. <br>-->
      <!--      for those of you who stream gartic, especially if i often join on your stream, this number will be higher. <br>-->
      <!--      not all the images are tagged, so you might have more NSFW, or OC images, that didn't count in here.  but most are. <br>-->
      <!--      </span>-->
      <!--      </div>-->

    </div>

  </div>
</template>

<script>
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import layoutSourceList from "@/assets/webImageSourceList.json";
import ocDirectorySourceList from "@/assets/ocDirectory.json";
import AOS from "aos";
import ocSatData from "@/assets/ocSatudayData.json";
import layoutData from "@/assets/layoutdata.json";
import raidData from "@/assets/raid.json";
import firstData from "@/assets/first.json";
import RandomImageView from "@/views/recap/RandomImageView.vue"


export default {
  name: "RecapView",
  components: {RandomImageView},
  data() {
    return {
      disclaimer: false,
      profilePictureTwitch: "",
      profilePicture2: "",
      firstImageArray: [],
      lastImageArray: [],
      yourOcDrawingsUrls: [],
      ocSatYourOcDrawingsUrls: [],
      onlyImageUrls: [],
      activeMonthUrls: [],
      ocSatSelectedDate: "",
      ocSatSelectedName: "",
      ocSatApplicableDates: [],
      ocSatOtherDates: [],
      ocSatApplicableNames: [],
      ocSatOtherNames: [],
      ocSatApplicableCategories: [],
      ocSatAllDates: {},
      ocSatAllNames: {},
      ocSatData: ocSatData,
      myImages: [],
      imageFiles: imageSourceList,
      layoutFiles: layoutSourceList,
      ocFiles: ocDirectorySourceList,
      layout2023: layoutData,
      ocDates: [],
      yourOcDrawings: [],
      ocSatYourOcDrawings: [],
      garticDrawings: [],
      streamDates: [],
      activeMonth: "September",
      activeMonthDrawings: [],
      activeMonthStreams: [],
      nsfwDrawings: [],
      blurNSFW: true,
      sillyVDrawings: [],
      bySillyVDrawings: [],
      raids: [],
      raidViewers: 0,
      layoutAvatars: [],
      first: [],
      allImages: [],
      pageImages: [],
      loadedPageImages: [],
      tagImages: [],
      loadedTagImages: [],
      page: "",
      name: "",


    }
  },
  created() {
    import('./fonts.css')
  },
  mounted() {

    // https://www.sillyv.art/recap/Fxw9NQIcDBc
    let page = this.$route.params.page;
    let key = "MySecretKey";
    page = this.decodeUrlSafe(page, key)
    this.name = page
    page = page.toLowerCase()
    this.page = page
    this.getTwitchPfp(page)
    this.getGarticAvatar(page)
    this.getGarticImages(page)
    this.getMyImages(page)
    this.obtainOCSatData(page)
    // raids have 3 stats, channel_name, viewer_count, date
    this.raids = raidData.filter((item) => item.channel_name.toLowerCase() === page.toLowerCase())
        .filter((item) => item.date.includes("2023"))

    this.raids.forEach((item) => {
      this.raidViewers += item.viewer_count
    })

    // find a key that is the same as page, but not case sensitive.  place the value into a var

    let firstDataKeys = Object.keys(firstData)
    firstDataKeys.forEach((item) => {
      if (item.toLowerCase() === page.toLowerCase()) {
        this.first.push(...firstData[item])
      }
    })


    if (page in this.layout2023) {
      this.layoutAvatars = this.layout2023[page]
    }
    // layoutFiles is an object,

    let myLayoutFiles = this.layoutFiles[page]
    this.layoutAvatars.forEach((item) => {
      console.log('layout' + JSON.stringify(item))
      let myLayoutFile = myLayoutFiles.find((layoutFile) => layoutFile.name === item.filename)
      console.log('myLayoutFile' + JSON.stringify(myLayoutFiles))
      console.log('myLayoutFile' + JSON.stringify(myLayoutFile))
      if (myLayoutFile) {
        item.url = myLayoutFile.url
      }
    })
  },
  methods: {
    toggleDisclaimer() {
      this.disclaimer = !this.disclaimer
    },
    decodeUrlSafe(encodedString, key) {
      let decodedString = this.base64UrlDecode(encodedString);
      let keyBytes = Array.from(key, c => c.charCodeAt(0));

      for (let i = 0; i < decodedString.length; i++) {
        decodedString = decodedString.substr(0, i) +
            String.fromCharCode(decodedString.charCodeAt(i) ^ keyBytes[i % keyBytes.length]) +
            decodedString.substr(i + 1);
      }

      return decodedString;
    },
    base64UrlDecode(encodedString) {
      encodedString = encodedString.replace(/-/g, '+').replace(/_/g, '/');
      while (encodedString.length % 4) {
        encodedString += '=';
      }
      return atob(encodedString);
    },
    getTwitchPfp(page) {
      let url = "https://decapi.me/twitch/avatar/" + page
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.profilePictureTwitch = request.responseText
        }
      };
      request.send();
    },
    getGarticAvatar(page) {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText)
          // eslint-disable-next-line
          if (json.hasOwnProperty(page))
            context.profilePicture2 = json[page]
        }
      };
      request.send();
    },
    obtainOCSatData(page) {
      let keys = Object.keys(this.ocSatData)
      for (let key of keys) {
        let value = this.ocSatData[key]
        for (let innerKey of Object.keys(value)) {
          if (innerKey === page) {
            this.ocDates.push({
              date: key,
              name: innerKey,
              images: value[innerKey]
            })
            this.yourOcDrawings.push(...value[innerKey])
          } else {
            let images = value[innerKey];
            for (let image of images) {
              if (image.name.toLowerCase() === page) {
                this.ocSatYourOcDrawings.push(image)
              }
            }
          }
        }
      }
      this.yourOcDrawingsUrls = this.yourOcDrawings.filter((item) => 'url' in item).map((item) => item.url)
      this.ocSatYourOcDrawingsUrls = this.ocSatYourOcDrawings.filter((item) => 'url' in item).map((item) => item.url)

    },
    ocCalculateApplicableAndOthers() {
      this.ocSatApplicableDates = []
      this.ocSatOtherDates = []
      this.ocSatApplicableNames = []
      this.ocSatOtherNames = []
      if (this.ocSatSelectedName && this.ocSatSelectedName !== "") {
        let keys = Object.keys(this.ocSatData)
        this.ocSatAllDates = keys
        for (let key of keys) {
          let value = this.ocSatData[key]
          if (this.ocSatSelectedName in value) {
            this.ocSatApplicableDates.push(key)
          } else {
            this.ocSatOtherDates.push(key)
          }
        }
      } else {
        this.ocSatApplicableDates = this.ocSatAllDates
        this.ocSatOtherDates = []
      }
      if (this.ocSatSelectedDate && this.ocSatSelectedDate !== "") {
        let ocSatForDate = this.ocSatData[this.ocSatSelectedDate];
        let keys = Object.keys(ocSatForDate)
        this.ocSatApplicableNames = keys
        this.ocSatOtherNames = this.ocSatAllNames.filter((name) => !keys.includes(name))
      } else {
        this.ocSatApplicableNames = this.ocSatAllNames
        this.ocSatOtherNames = []
      }
      if (this.ocSatSelectedName !== "" && this.ocSatSelectedDate !== "") {
        this.ocSatApplicableCategories = [{
          name: this.ocSatSelectedName + " - " + this.ocSatSelectedDate,
          images: this.ocSatData[this.ocSatSelectedDate][this.ocSatSelectedName]
        }]
      } else if (this.ocSatSelectedName !== "") {
        this.ocSatApplicableCategories = []
        for (let ocSatDataKey in this.ocSatData) {
          let ocSatDataValue = this.ocSatData[ocSatDataKey]
          if (this.ocSatSelectedName in ocSatDataValue) {
            this.ocSatApplicableCategories.push({
              name: this.ocSatSelectedName + " - " + ocSatDataKey,
              images: ocSatDataValue[this.ocSatSelectedName]
            })
          }
        }
      } else if (this.ocSatSelectedDate !== "") {
        this.ocSatApplicableCategories = []
        let ocSatDataValue = this.ocSatData[this.ocSatSelectedDate]
        for (let ocSatDataKey in ocSatDataValue) {
          this.ocSatApplicableCategories.push({
            name: ocSatDataKey + " - " + this.ocSatSelectedDate,
            images: ocSatDataValue[ocSatDataKey]
          })
        }
      } else {
        this.ocSatApplicableCategories = []
      }
      //   sort lists
      this.ocSatApplicableDates.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })
      this.ocSatOtherDates.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })
      this.ocSatApplicableNames.sort()
      this.ocSatOtherNames.sort()
    },
    toStreamDate(filename) {
      // if filename has only 1 space, find the first underscore, and replace it with a space
      if (filename.split(' ').length === 2) {
        filename = filename.replace('_', ' ')
      }
      const filenameParts = filename.split(' ');
      const datePart = filenameParts[1];
      const timePart = filenameParts[2];
      const parsedDate = new Date(datePart);
      const parsedTime = new Date(`1970-01-01T${timePart}Z`);
      if (parsedTime.getUTCHours() < 8) {
        parsedDate.setDate(parsedDate.getDate() - 1);
      }
      return parsedDate.toISOString().slice(0, 10).replace(/-/g, '');
    },
    getMyImages(page) {
      this.myImages = this.allImages
          .filter((item) => item.name.toLowerCase() === page.toLowerCase())
          .filter((item) => item.filename)

      this.myImages.forEach((item) => {
        item.actualStreamDate = this.toStreamDate(item.filename)
      })


      //   count unique actualStreamDate values
      this.streamDates = [...new Set(this.myImages.map(item => item.actualStreamDate))];

      // for each image in myImages.  count how many images are in each month

      let monthMap = new Map();
      this.myImages.forEach((item) => {
        const month = item.date.split('-')[1]
        if (monthMap.has(month)) {
          monthMap.set(month, monthMap.get(month) + 1)
        } else {
          monthMap.set(month, 1)
        }
      })

      // find most active month in map
      let max = 0;
      let maxMonth = "";
      for (let [key, value] of monthMap) {
        if (value > max) {
          max = value;
          maxMonth = key;
        }
      }
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November",
        "December"
      ];
      this.activeMonth = monthNames[maxMonth - 1]
      this.activeMonthDrawings = this.myImages
          .filter((item) => item.date.split('-')[1] === maxMonth)
      this.activeMonthStreams = [...new Set(this.activeMonthDrawings.map(item => item.actualStreamDate))];
      this.activeMonthUrls = this.activeMonthDrawings.filter((item) => 'url' in item).map((item) => item.url)
      this.nsfwDrawings = this.myImages
          .filter((item) => item.tags && item.tags.includes('nsfw'))
          .map((item) => {
            return item.url
          })


      this.onlyImageUrls = this.myImages.filter((item) => 'url' in item).map((item) => item.url)

      // a single item array with only the first image in onlyImageUrls
      this.firstImageArray = this.onlyImageUrls.slice(0, 1)
      this.lastImageArray = this.onlyImageUrls.slice(-1)
    },
    getGarticImages(page) {
      // noinspection JSUnresolvedFunction
      AOS.init()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }

      this.allImages = this.allImages
          .filter((item) => item.filename && item.filename.includes(' 2023-'))

      for (let allImage of this.allImages) {


        if (allImage.tags && Array.isArray(allImage.tags)) {
          for (let tag of allImage.tags) {
            if (allImage.name.toLowerCase() === "sillyv" &&
                tag.toLowerCase() === page.toLowerCase()) {
              this.bySillyVDrawings.push(allImage)
            }
          }
        }
        if (allImage.name.toLowerCase() === page.toLowerCase() && allImage.tags && Array.isArray(allImage.tags)) {
          for (let tag of allImage.tags) {
            if (tag.toLowerCase() === "sillyv" || tag.toLowerCase() === "silly") {
              this.sillyVDrawings.push(allImage)
            }
          }
        }
      }

      this.sillyVDrawings = this.sillyVDrawings.map((item) => {
        return item.url
      })
      this.bySillyVDrawings = this.bySillyVDrawings.map((item) => {
        return item.url
      })
      // noinspection SpellCheckingInspection
      const aliases = {
        silly: "sillyv",
        maryagneshiiitop: "maryagneshilltop",
        defnotpetri: "petrigor",
      };
      this.allImages.forEach((item) => {
        const alias = aliases[item.name];
        if (alias) {
          item.name = alias
        }
      });
      // eslint-disable-next-line
      this.allImages = this.allImages.filter((item) => 'url' in item)
      ;

      this.loadedPageImages = this.pageImages.slice(0, 20)

      this.loadedTagImages = this.tagImages.slice(0, 20)
    },
    toggleNSFW() {
      this.blurNSFW = !this.blurNSFW
    }
  },
}
</script>

<style scoped>


.RecapViewOuter {
  width: 100vw;
  height: 100vh;
  background-color: #1c1c24;
  position: absolute;
  overflow-x: hidden;

  top: 0;
  left: 0;
}

.RecapContainer {
  width: 100%;
  background-color: #1c1c24;
  height: fit-content;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RecapLogoContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.RecapTitleContainer {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}


.RecapLogoContainer img {
  align-self: start;
  margin-inline-start: 20%;
  object-fit: contain;
}

.recap-title {
  font-family: SuezOne, serif;
  color: white;
  font-size: 96px;
  align-self: center;
  object-fit: contain;
  margin-top: 5%;
}

.recap-description {
  color: white;
  font-size: 30px;
  width: 60%;
  text-align: start;
  align-self: start;
  margin-inline-start: 20%;
  object-fit: contain;
}

.image-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.image-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;

  bottom: 0;
  left: 0;
  padding: 20px; /* Adjust the padding as needed */
  color: white; /* Set text color */
}

.RecapSectionContent {
  border-radius: 50px;
  background: #837997;
  width: 60%;
  height: fit-content;
}

RecapNoSectionContent {
  width: 60%;
  height: fit-content;
}

.RecapSectionContent RandomImageView {
  width: 60%;
  height: fit-content;
  object-fit: contain;
  margin-inline-start: 0;
}

.section-margin {
  margin-top: 100px;
}

.section-title {
  color: white;
  font-size: 60px;
  object-fit: contain;
}

.section-text {
  color: white;
  font-size: 30px;
  width: 60%;
  text-align: start;
  object-fit: contain;
}

.GraphPlaceholder {
  height: 200px;
  display: flex;
  margin-inline-start: 20%;
  margin-inline-end: 20%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur {
  filter: blur(60px);
}

.click-text {
  color: white;
  font-size: 30px;
  width: 60%;
  text-align: start;
  object-fit: contain;
  margin-top: 20px;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 20%;
}

.nsfw-hover .hidden-child {
  visibility: hidden;
}

.nsfw-hover:hover .hidden-child {
  visibility: visible;
}


.end-margin {
  margin-bottom: 300px;
}

.disclaimer {
  display: flex;
  width: 281px;
  height: 114px;
  flex-direction: column;
  justify-content: center;
  color: #2A2732;
  text-align: center;
  font-family: ShareTech;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 50px;
  background: #4D475E;
}

.disclaimer:hover {
  background: #837997;
}

.profile-welcome-text {
  color: #2B2831;
  font-family: Roboto, serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: -169px;
  width: 1202px;
  height: 556px;
  border-radius: 52px;
  background: #837997;
}

.profile-welcome-text span {
  margin-inline-start: 10%;
}

.circle {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 338px;
  border: 17px solid #837997;
  background-color: #837997;
  position: absolute;
  bottom: 0;
  left: 0;
}

.profile-circle-gartic {
  width: 338px;
  height: 420px;
  display: block;
  position: relative;
  margin-inline-start: 5%;
}

.profile-circle-twitch {
  margin-top: 2.5%;
  width: 338px;
  height: 338px;
  display: block;
  position: relative;
  margin-inline-start: 5%;
  aspect-ratio: 1;
  border-radius: 338px;
  border: 17px solid #837997;
  background-color: #837997;
  bottom: 0;
  left: 0;
}

.profile-circle-twitch img {
  border-radius: 100%;
}

.profile-circle-gartic img {
  width: 100%;
  margin-inline-start: 5%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.thank-you-first-text {
  color: #837997;
  font-family: ShareTech, serif;
  font-size: 40px;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  margin-top: 5%;
  line-height: normal;
  width: 50%;
}

.first-redeem {
  color: #2B2831;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  font-family: Roboto;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bigger-font {
  font-family: RobotoBold, serif;
}

.RecapNoSectionContent {
  color: #837997;
  text-align: center;
  font-family: RobotoBold, serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.RecapNoSectionContent h2 {
  color: #837997;
  text-align: center;
  font-family: RobotoBold, serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.RecapNoSectionContent h4 {
  color: #837997;
  text-align: center;
  font-family: RobotoBold, serif;
  font-size: 40px;

  font-style: normal;
  font-weight: 900;
  line-height: normal;
}


.RecapNoSectionContent RandomImageView {
  width: 100%;
  height: fit-content;
  object-fit: contain;
  margin-inline-start: 0;
}

.h4-but-bigger {
  font-size: 64px;

}

.oc-title {
  margin: 5%;
  color: #2A2338;
  text-align: center;
  font-family: RobotoBold, serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: 117.51%; /* 112.809px */
}

.oc-description {
  margin-top: 5%;
  color: #2A2338;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 113.917%; /* 45.567px */
}

.oc-header {
  margin: 5%;
}

.oc-item-title {
  color: #2A2338;
  text-align: center;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 117.51%; /* 75.206px */
}

.oc-thank-you {
  width: 60%;
  margin-top: 5%;
  color: #837997;
  text-align: center;
  font-family: ShareTech;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.layout-avatar img {
  height: 200px;
}

.last-thank-you {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.last-thank-you-inner {
  margin: 5%;
}

.love {
  color: #837997;
  text-align: center;
  font-family: Roboto;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.bottom-margin {
  margin-bottom: 20%;
}

.disclaimerText {
  width: 60%;
  margin-top: 5%;
  border-radius: 50px;
  background: #4D475E;
  color: #FFF;
  text-align: center;
  font-family: ShareTech;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


</style>
