<!--suppress UnreachableCodeJS -->
<template>
  <div class="page">
    <SlicesHeaderView/>
    <div class="upload-prompt-content">
      <div class="slices-upload-tip slices-tip">
        <h1>Tip:</h1>
        <p>Make sure the background you create has clearly indicated spots for the other artists to draw in.</p>
      </div>
      <div class="slices-diagram">
        <img src="@/assets/slices_diagram.svg" alt="Logo"/>
      </div>
      <div class="slices-upload-file-explanation slices-tip">
        <h1>Why .psd?</h1>
        <p>Photoshop files can be opened in all of the most commonly used drawing applications, like Procreate,
          Clipstudio, Krita etc.</p>
        <p>It is a good way to preserve the layer structure between each submission.</p>
      </div>
      <p class="label-upload-psd">upload psd:</p>
      <span class="upload-controls-container">
        <div class="upload-input-container">
           <input class="upload-input" type="file" id="file"
                  accept=".psd"
                  @change="onFileChange"
           />
          <div>{{ displayedFileName }}</div>

        </div>
        <div v-if="displayedFileName"
             class="slices-upload-button button-selected" @click="clickClear">
          <div>clear</div>
        </div>
      </span>
      <p class="slices-number-label">number of slots:</p>
      <div class="slices-upload-form">
        <input class="number-input"
               type="number" id="number-of-slots" min="1" max="100" v-model="numberOfSlots"

        />
        <p class="slices-nsfw-label">nsfw ok?</p>
        <div :class="'slices-upload-button ' + (nsfwAllowed ? 'button-selected' : '')" @click="nsfwClicked(true)">
          <div>yes</div>
        </div>
        <div :class="'slices-upload-button ' + (!nsfwAllowed ? 'button-selected' : '')" @click="nsfwClicked(false)">
          <div>no</div>
        </div>
      </div>
      <slices-button class="slices-confirm-button"
                     text="send to next person <3"
                     buttonHeight=70
                     color="#96CAD1"
                     @click="uploadFile"
      />
    </div>
    <SlicesAdsView/>
  </div>

</template>

<script>
/* eslint-disable no-unreachable */
import SlicesHeaderView from "@/views/slices/SlicesHeaderView.vue";
import SlicesAdsView from "@/views/slices/SlicesAdsView.vue";
import SlicesButton from "@/views/slices/SlicesButton.vue";
import axios from "axios";

export default {
  components: {SlicesButton, SlicesAdsView, SlicesHeaderView},
  data() {
    return {
      displayedFileName: "",
      fileContent: "",
      nsfwAllowed: false,
      numberOfSlots: 5,
    };
  },
  methods: {
    async uploadFile() {
      let url = `https://pzc3pirkpdzzgx4b2uhso3a22a0ristq.lambda-url.eu-north-1.on.aws/`;
      // alert
      if (!this.fileContent) {
        alert("Please select a file to upload");
        return;
      }
      let userId = localStorage.getItem('twitchDisplayName')
      let fileContent = this.fileContent;
      let sliceCount = this.numberOfSlots;
      let allowAdultContent = this.nsfwAllowed;

      // Content-Type
      // Accept

      let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
      let body = {
        "file": fileContent,
        "userId": userId,
        "sliceCount": sliceCount,
        "allowAdultContent": allowAdultContent
      }

      console.log(body)
      try {
        const response = await axios.post(url, body, {
          headers: headers,
        });

        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          window.location.href = 'http://localhost:8080/slices/home';
        } else {
          console.error('Something went wrong on the API server!');
          console.log(response.data)
        }
      } catch (error) {
        console.error(error);
      }
    },
    nsfwClicked(value) {
      this.nsfwAllowed = value;
    },
    clickClear() {
      this.displayedFileName = "";
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      // make sure file is less than 3MB
      if (files[0].size > 3 * 1024 * 1024) {
        alert("File size limit is 3MB, please resize the file or merge layers and try again.");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(event.target.result)
        this.fileContent = event.target.result;
      };
      reader.readAsText(files[0]);
      this.displayedFileName = files[0].name;
      e.target.value = null;

    },
    loginWithTwitch() {
      // Add your Twitch login logic here
    },
  },
};
</script>

<style scoped>

p {
  margin: 0;
}

.page {
  background: linear-gradient(180deg, #FFF 0%, #999 100%);
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.content img {
  max-width: 200px;
}

/* Add more styles for the vertical banner ad here */

.choose-content img {
  max-width: 200px;
}

.upload-prompt-content {
  justify-content: start;
  padding-inline-start: 10%;
  padding-inline-end: 10%;
  grid-template-columns: 35%  29%  35%;
  grid-template-rows: 320px 80px 80px 60px;

  width: 73%;
  flex: 3;
  display: grid;
  grid-template-areas:
               "tip1 tip2 tip3"
               "label1 form1 form1"
               "label2 form2 form2"
               "...... button ......";

  flex-direction: column;
}

.slices-explanation {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  margin-inline-end: 10%;
  margin-inline-start: 10%;
}

.slices-upload-tip {
  grid-area: tip1;
  width: 83%;
  aspect-ratio: 363/250;
}

.slices-diagram {
  grid-area: tip2;
  width: 100%;
}

.slices-diagram img {
  width: 100%;

}

.slices-upload-file-explanation {

  grid-area: tip3;
  width: 83%;
  margin-inline-start: 17%;
  aspect-ratio: 363/250;

}

.slices-tip {
  border: 1px solid #000;
  background: rgba(0, 0, 0, 0.05);
}

.slices-tip h1 {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slices-tip h1 {
  font-family: Irish Grover, serif;
}

.slices-tip p {
  color: #000;
  text-align: center;
  font-family: Inter, serif;
  margin-inline-end: 5%;
  margin-inline-start: 5%;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 148.023%; /* 23.684px */
}

.upload-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-inline-end: 10%;
  margin-inline-start: 10%;
}

.upload-container p {
  color: #000;
  text-align: right;
  font-family: Irish Grover, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  width: 36.5%;
  line-height: normal;
}

.upload-input {
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.upload-controls-container {
  grid-area: form1;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: start;


}

.upload-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  border: 1px solid #000;
  background: #D9D9D9;
  width: 280px;
  height: 42px;
  flex-shrink: 0;
  cursor: pointer;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.50) inset;

}

.upload-input-container div {
  position: absolute;
  cursor: pointer;
  margin-inline-start: 20px;
  width: 250px;
  font-family: Inter, serif;
  pointer-events: none;
}

.upload-input-container input {
  background-color: #c0ffee;
  cursor: pointer;
}

.slices-upload-button {
  opacity: .2;
  margin-inline-start: 19px;
  width: 59px;
  height: 36px;
  flex-shrink: 0;
  color: #000;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  align-items: center;
  font-family: Irish Grover, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 4px solid #000;
  background: #D9D9D9;
  user-select: none;
  cursor: pointer;
}

.slices-upload-button div {
  width: 100%;
}

.slices-upload-form {
  grid-area: form2;
  display: flex;
  height: fit-content;
  flex-direction: row;
  align-items: start;
  width: 80%;
  margin-inline-end: 10%;
}

.slices-number-label {
  grid-area: label2;
  color: #000;
  text-align: right;
  font-family: Irish Grover, serif;
  font-size: 40px;
  font-style: normal;
  padding-inline-end: 50px;
  font-weight: 400;
  height: fit-content;
  line-height: normal;
}

.number-input {
  border: 1px solid #000;
  background: #D9D9D9;
  width: 74px;
  height: 42px;
  flex-shrink: 0;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.50) inset;
}

.slices-nsfw-label {
  color: #000;

  text-align: right;
  font-family: Irish Grover, serif;
  font-size: 40px;
  font-style: normal;
  margin-inline-start: 6%;
  margin-inline-end: 1%;
  font-weight: 400;
  line-height: normal;

}

.label-upload-psd {
  color: #000;
  text-align: right;
  padding-inline-end: 50px;
  font-family: Irish Grover;
  font-size: 40px;
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  grid-area: label1;
}

.slices-confirm-button {
  grid-area: button;
  width: 100%;
}

.button-selected {
  opacity: 1 !important;
}
</style>
