<template>
  <div class="main-menu-screen">

    <div class="chat-game-menu-grid">
      <ExpandableButton v-for="(option, i) in options" :key="option.title"
                        :title="option.title"
                        :options="option.items"
                        :ref="option.title"
                        :class="'expandable-button button' + (i + 1)"
                        :expandedCallback="expanded"
                        :itemClickedCallback="itemClicked"
                        :enabled="option.title !== 'Autocomplete' || selectedOptions['Answers'] === 'Typed Answers'"
      ></ExpandableButton>
    </div>

    <div class="main-screen-solver">

      <div class="chat-title"><h1 class="chat-title-text">Gartist Guesser</h1></div>
      <div class="bottom-buttons">
        <button class="start-button" @click="startGame">Start Game</button>
        <ExpandableInput
            class="chat-game-menu-twitch"
            title="Connect to Twitch"
            :usernameChanged="itemClicked"
            :item-clicked-callback="itemClicked"
        ></ExpandableInput>
      </div>
    </div>
  </div>
</template>


<script>
import ExpandableButton from "@/views/games/chat/ExpandableButton.vue";
import ExpandableInput from "@/views/games/chat/ExpandableInput.vue";

export default {
  components: {ExpandableButton, ExpandableInput},
  data() {
    return {
      options: [
        {
          title: "Artists", items:
              ['Stream Regulars',
                'Community Members',
                'Occasional Visitors',
                'Everyone']
        },
        {
          title: "Duration", items:

              ['5 Turns',
                '10 Turns',
                '20 Turns',
                'Infinite']
        },
        {
          title: "Timer", items:
              ['5s',
                '30s',
                '1m',
                'Host Decision'
              ]
        },
        {
          title: "Guess",
          items: ["Artist", "Artwork"]
        },
        {
          title: "Answers", items:
              ['2 Options',
                '4 Options',
                '6 Options']
        },
        {
          title: "NSFW", items:
              ['Off',
                'On',
                'Horny Jail']
        }
      ],
      selectedOptions: {
        Artists: 'Stream Regulars',
        Duration: '5 Rounds',
        Timer: '5s',
        NSFW: 'OFF',
        Answers: '2 Options',
        Guess: 'Artist'
      },
      nsfw: 'OFF',
      autoComplete: false,
      selectedDifficulty: 'easy',
      selectedGuessTime: '30s',
      gameDuration: 'infinite',
      twitchUsername: '',
    };
  },
  mounted() {
    document.title = "Gartist Guesser";
  },
  created() {
    import('./ChatGame.css')
  },

  methods: {
    expanded(event) {
      // log all refs
      this.options.forEach(option => {
        let button = this.$refs[option.title][0]
        if (option.title !== event) {
          button.collapse()
          //   set button z ine to 0
          button.$el.style.zIndex = 0
        } else {
          // set button z index to 1
          button.$el.style.zIndex = 1
        }
      })
    },
    itemClicked(title, option) {
      this.selectedOptions[title] = option
      console.log(title, option)
    },
    startGame() {
      // generate a random game id
      let gameId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      localStorage.setItem('gameId', gameId)
      // add gameID only as query param and push to page ChatGame
      this.$router.push({name: 'game', query: {gameId: gameId}})
    },
  },
};
</script>


<style scoped>
/*import local file ChatGame.css*/


label {
  font-size: 16px;
}

select, input {
  width: 150px;
  padding: 5px;
  font-size: 14px;
}

button {
  margin-top: 5%;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Add these rules for the label to make it clickable */

.chat-game-menu-grid {
  position: absolute;
  top: 23%;
  width: 50%;
  height: auto;
  background-color: red;
}

/*noinspection CssUnusedSymbol*/
.expandable-button {
  position: absolute;
  width: 47%;
  margin: 10px; /* Adjust the margins for spacing */
}

/*noinspection CssUnusedSymbol*/
.button1 {
  top: 0;
  left: 0;
}

/*noinspection CssUnusedSymbol*/
.button2 {
  top: 0;
  right: 0;
}

/*noinspection CssUnusedSymbol*/
.button3 {
  top: 100px;
  left: 0;
}

/*noinspection CssUnusedSymbol*/
.button4 {
  top: 100px;
  right: 0;
}

/*noinspection CssUnusedSymbol*/
.button5 {
  top: 200px;
  left: 0;
}

/*noinspection CssUnusedSymbol*/
.button6 {
  top: 200px;
  right: 0;
}

/*noinspection CssUnusedSymbol*/
.button7 {
  top: 300px;
  left: 0;
}

/*noinspection CssUnusedSymbol*/
.button8 {
  top: 300px;
  right: 0;
}


.start-button {
  font-family: Jura, serif;
  font-size: 30px;
  border-radius: 57px;
  background: linear-gradient(180deg, #EFBE90 0%, #C072D3 58.85%, #A57BFF 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 280px;
  height: 63px;
}

.start-button:hover {
  background: linear-gradient(180deg, #EFBE90 0%, #964aa9 58.85%, #A57BFF 100%);
  cursor: pointer;
}

.chat-game-menu-twitch {
  flex-shrink: 0;
  border-radius: 57px;
  margin-top: 32px;
  background: linear-gradient(180deg, #7D6ED9 0%, #B0A3FF 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.main-menu-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: start;
  justify-content: start;
  background-color: #432C61;
}

.bottom-buttons {
  align-items: center;
  position: relative;
  justify-content: start;
  display: flex;
  margin-bottom: 5px;
  height: 30%;
  flex-direction: column;
}

.main-screen-solver {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
