<template>
  <div class="page">
    <SlicesHeaderView/>
    <div class="download-content">

      <div class="download-image-container">
        <img
            src="https://cdn77-pic.xnxx-cdn.com/videos/thumbs169xnxxposter/23/47/bb/2347bbe03d050522c048a93f618d41d1/2347bbe03d050522c048a93f618d41d1.15.jpg">
        <div>
          <h1>Download PSD</h1>
        </div>
      </div>
      <div class="download-explanation-container">
        <h1>Why .psd?</h1>
        <h2>Photoshop files can be opened in all of the most commonly used drawing applications, like Procreate,
          Clipstudio, Krita etc. It is a good way to preserve the layer structure between each submission.</h2>
      </div>

    </div>
    <SlicesAdsView/>
  </div>

</template>

<script>
import SlicesHeaderView from "@/views/slices/SlicesHeaderView.vue";
import SlicesAdsView from "@/views/slices/SlicesAdsView.vue";

export default {
  components: {SlicesAdsView, SlicesHeaderView},
  data() {
    return {
      daysUntilEvent: 7, // Replace with the actual number of days
    };
  },
  methods: {
    loginWithTwitch() {
      // Add your Twitch login logic here
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #c0ffee;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.content img {
  max-width: 200px;
}

.choose-content img {
  max-width: 200px;
}


.download-content {
  justify-content: space-evenly;
  padding-inline-end: 100px;
  padding-inline-start: 100px;
  background-color: #DEFACE;
  width: 100%;
  flex: 3;
  display: flex;
  flex-direction: row;
}

.download-image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.download-explanation-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>
