<template>
  <div class="top-10-list">
    <h2 class="content-title-border" @click="toggleOpen" v-if="top10Drawers.length > 0">{{ title }}</h2>

    <div :class="[ 'gallery-cards', open ? '' : 'hidden' ]">
      <!-- Use v-for to loop through the top10Drawers array -->
      <div v-for="(drawer, index) in top10Drawers" :key="index" class="gallery-card" @click="navigate(drawer)">
        <div class="card-content">
          <span class="drawer-name">{{ drawer.name }}</span>
          <span class="drawer-count">{{ drawer.count }} images</span>
        </div>
      </div>
      <!-- End of cards -->
    </div>
  </div>
</template>
<script>

export default {
  name: 'SocialHomeTop10',
  props: {
    top10Drawers: {},
    title: {}
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
    navigate(drawer) {
      let url = `/social/${drawer.name}`
      this.$router.push(url)
    }
  }

}
</script>
<style scoped>


/* CSS for the top 10 list container */
/* Style for the container of the gallery cards */
.gallery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Style for each gallery card */
.gallery-card {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc(90% - 10px); /* Adjust the width as needed for your layout */
  text-align: center;
}

.gallery-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Style for the profile image within each card */

/* Style for the name and count within each card */
.card-content {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.drawer-name {
  color: #333333;
}

.drawer-count {
  color: #999999;
}

/* "See All" button */


button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}


@media (max-width: 768px) {
  .hidden {
    visibility: hidden;
    height: 0px;
  }

  .top-10-list {
    height: fit-content;
  }

  .top-10-list {
    margin-top: 20px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    width: 85vw;
  }
}


</style>
