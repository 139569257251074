<template>
  <div class="full_screen"    >
    <div class="wheel_home" style="height: 80vh;"  >
      <canvas ref="pieChart" class="actual_wheel" height="1000" width="1000" @click="spinSpoke"></canvas>
      <img ref="rotatingImg" :src="require('./spoke2.png')" alt="spoke" class="actual_spoke" height="589" width="93"
           @click="spinSpoke"/>
      <img :src="require('./silly.svg')" alt="button"
           class="actual_button"
           @click="spinSpoke"/>
      <img v-if="selected"
           :src="require('./Union.svg')" alt="button" class="actual_label"
           @click="spinSpoke"/>
      <h1 v-if="selected" :style="'font-size: ' + calculateFontSize(selected) + '; top: ' + calculateTop(selected)"
      > {{ this.selected.toUpperCase() }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PieChart',
  props: ['items', 'forceWinner'],
  data() {
    return {
      lastCurrent: "",
      audio: {},
      targetDegrees: 5000,
      selected: "",
    };
  },
  mounted() {
    console.log('this.items', this.items)
    this.drawPieChart();
  },
  computed: {
    totalWeight() {
      return this.items.reduce((acc, item) => acc + item.weight, 0);
    },
  },
  methods: {
    playAudio() {

      let audio = new Audio(require('../../assets/spin2.mp3'));
      audio.play();

    },
    calculateTop(text) {
      let length = text.length
      if (length > 20) {
        return '45vh'
      } else if (length > 15) {
        return '44vh'
      } else if (length > 10) {
        return '42.5vh'
      } else if (length > 5) {
        return '41vh'
      } else
        return '40vh'
    },
    calculateFontSize(text) {
      let length = text.length
      if (length > 20) {
        return '2.3vh'
      } else if (length > 15) {
        return '3vh'
      } else if (length > 10) {
        return '4vh'
      } else if (length > 5) {
        return '5vh'
      } else {
        return '6vh'
      }
    },
    drawPieChart() {
      const canvas = this.$refs.pieChart;
      const ctx = canvas.getContext('2d');
      // clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);


      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = Math.min(centerX, centerY);

      // -90 degress in radians
      let startAngle = -Math.PI / 2;
      let endAngle = startAngle;
      let i = 0

      ctx.strokeStyle = 'white';
      ctx.lineWidth = 10; // You can customize the border width
      ctx.stroke();


      this.items.forEach(item => {
        const sliceAngle = (item.weight / this.totalWeight) * (2 * Math.PI);
        endAngle += sliceAngle;

        // Calculate the position for the label
        const labelAngle = startAngle + sliceAngle / 2;
        const labelX = centerX + (radius / 1.5) * Math.cos(labelAngle);
        const labelY = centerY + (radius / 1.5) * Math.sin(labelAngle);

        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius - 10, startAngle, endAngle);

// Save the state before adding the border
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';

// Draw white border inside the pie slice
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 10; // You can customize the border width

// Ensure the stroke is drawn inside the circle
        ctx.lineJoin = 'round';
        ctx.stroke();

// Restore the saved state
        ctx.restore();
        if (i === this.items.length - 1 && i % 4 === 0) {
          ctx.fillStyle = this.getRandomColor(2)
        } else {
          ctx.fillStyle = this.getRandomColor(i % 4);
        }
        ctx.fill();
        console.log(i, i % 4, this.getRandomColor(i % 4))
        i++

        // Draw the label
        ctx.save();
        ctx.translate(labelX, labelY);
        if (endAngle + endAngle - sliceAngle > Math.PI) {
          ctx.rotate(labelAngle);
          ctx.scale(-1, -1);

        } else {

          // flip horizontally
          ctx.rotate(labelAngle);

        }


        let x = item.weight / this.totalWeight
        console.log('x', x)
        console.log('item.label', item.label)
        console.log('item.weight', item.weight)
        console.log('this.totalWeight', this.totalWeight)
        let y = 30

        if (x > .25) {
          y = 30
        } else if (x > .2) {
          y = 20
        } else {
          y = 20
        }


        ctx.fillStyle = 'white'; // You can customize the label color
        ctx.textAlign = 'center';
        ctx.font = y + 'px Arial'; // You can customize the font size and family
        ctx.fillText(item.label, 0, 0);
        ctx.restore();

        startAngle = endAngle;
      });
    },
    spinSpoke() {
      this.selected = ""
      // random number between 3000 and 10000
      let totalWeight = this.items.reduce((acc, item) => acc + item.weight, 0);
      let degIndex = 0

      console.log('this.items', this.items)
      let participants = this.items.map((item) => {
        let weightOutOf360 = item.weight / totalWeight * 360
        let newVar = {
          ...item,
          start: degIndex,
          end: degIndex + weightOutOf360
        };
        degIndex += weightOutOf360
        return newVar
      })


      // number between 5 and 10
      let randomSpinCount = Math.floor(Math.random() * 5) + 5;


      let randomSpinDegrees = Math.floor(Math.random() * 360);
      console.log('this.forceWinner', this.forceWinner)
      if (this.forceWinner) {
        let winner = participants.find(participant => participant.label.toLowerCase().includes(this.forceWinner.toLowerCase()))
        randomSpinDegrees = winner.start + ((winner.end - winner.start) * Math.random())
      }

      this.targetDegrees = randomSpinCount * 360 + randomSpinDegrees;
      // calculate winner and display it based on the targetDegrees

      let modulu360 = this.targetDegrees % 360


      // calculate start and end based on item.weight
      console.log('this.targetDegrees', this.targetDegrees)
      console.log('modulu360', modulu360)
      console.log('participants', participants)

      let winner = participants.find(participant => {
        return modulu360 >= participant.start && modulu360 < participant.end
      })

      console.log('winner', winner)


      // Clear previous interval
      clearInterval(this.spinningInterval);
      // Set spinning degrees and start spinning
      this.spinningDegrees = 0;
      let inter = 0
      this.spinningInterval = setInterval(() => {
        // play spin.mp3
        inter++


        console.log('play audio' + inter)


        let topSpeed = 10
        let baseSpeedEnd = .03
        let baseSpeed = 1

        let isFirstQuarter = this.spinningDegrees < this.targetDegrees / 4
        let isLastPortion = this.spinningDegrees > this.targetDegrees * 9 / 10
        let isLastQuarter = this.spinningDegrees > this.targetDegrees * 3 / 4

        let speed = 0
        if (isFirstQuarter) {
          speed = topSpeed
        } else if (isLastPortion) {
          speed = topSpeed - (topSpeed - baseSpeedEnd) * (this.spinningDegrees / this.targetDegrees)
        } else if (isLastQuarter) {
          speed = topSpeed - (topSpeed - baseSpeed) * (this.spinningDegrees / this.targetDegrees)
        } else {
          speed = topSpeed
        }
        this.spinningDegrees += speed;
        let modul = (this.spinningDegrees + 1) % 360
        let current = participants.find(participant => {
          return modul >= participant.start && modul < participant.end
        })

        console.log('current', current)
        console.log('this.lastCurrent', this.lastCurrent)
        if (current.label !== this.lastCurrent.label) {
          console.log('play audio')
          this.lastCurrent = current
          this.playAudio()
        }

        this.rotateSpoke();
        if (this.spinningDegrees >= this.targetDegrees) {
          clearInterval(this.spinningInterval);
          this.selected = winner.label
        }
      }, 1); // Adjust the interval as needed
    },
    rotateSpoke() {
      const img = this.$refs.rotatingImg;
      const pie = this.$refs.pieChart;
      console.log('pie', pie)
      const halfPieChartWidth = img.width / 2;
      const halfPieChartHeight = img.height / 2;

      const rotationDegrees = this.spinningDegrees % 360;
      const pivotX = halfPieChartWidth; // Set your desired X coordinate for the pivot point
      const pivotY = halfPieChartHeight; // Set your desired Y coordinate for the pivot point

      // Apply rotation and set the pivot point
      img.style.transform = `rotate(${rotationDegrees}deg)`;
      img.style.transformOrigin = `${pivotX}px ${pivotY}px`;
    },
    getRandomColor(i) {

      // generate random HSL
      const hue = 262;
      const saturation = Math.floor(Math.random() * 60) + 20;
      const lightness = Math.floor(Math.random() * 60) + 20;
      console.log(i)

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
  },
  watch: {
    items: {
      handler() {
        this.drawPieChart();
      },
      deep: true,
    },
  }

};
</script>

<style scoped>
.wheel_home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%) scale(.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 80vh;
  transform-origin: 50% 50%;
  animation: wheelHomeAnimation 2s ease-in-out forwards 0.5s;
}


.actual_spoke {
  z-index: 1;
  height: 60vh;
  aspect-ratio: 93/1085;
}


.actual_wheel {
  position: absolute;
  height: 80vh;
  top: 0;
  left: 0;
  z-index: 0;
}

.actual_button {
  position: absolute;
  z-index: 1;
  height: 20vh;
  width: 20vh;
  top: 30vh;
  left: 30vh;
}

.full_screen {
  position: absolute;
  height: 100vh;
  width: 73vw;
  opacity: 0;
  top: 0;
  left: 27vw;
  background-color: #000000BB;
  animation: fadeIn 0.5s ease-in-out forwards;

}

.actual_label {
  position: absolute;
  z-index: 1;
  height: 20vh;
  width: 50vh;
  top: 45vh;
  left: 15vh;
}

.wheel_home h1 {
  position: absolute;
  z-index: 1;
  height: 20vh;
  width: 50vh;
  top: 44vh;
  left: 15vh;
  color: #9876dd;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  text-align: center;
  line-height: 1;
  padding: 11% 4px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes wheelHomeAnimation {
  from {
    transform: translate(-50%, 50%) scale(.7);
  }
  to {
    transform: translate(-50%, -70%) scale(.7);
  }
}


</style>
