<template>
  <div class="social-page">
    <SocialHeader
        :page-images="imagesForDate"
        :search="false"
        :username="page"
        profile-picture1=""
        profile-picture2=""
        profile-picture3="https://media.discordapp.net/attachments/859602956247760907/1169079942282231829/logo.png"
    />
    <SocialImageGrid :imageClicked="imageClicked" :page-images="imagesForDate" :showNSFW="toggleState"/>
    <!--    absolute positioning fullscreen div for image-->
    <div v-if="fullScreenImage !== ''" class="fullscreen-image-container">
      <div class="fullscreen-image-button-container">
        <div class="full-screen-x-button webdings" @click="imageCleared">r</div>
        <div class="full-screen-x-button" @click="copyUrlToClipboard">
          <img alt="Copy to Clipboard" class="copy-to-clipboard-icon" src="./chain.png"/>
        </div>
      </div>
      <div class="fullscreen-image-border">
        <img :src="fullScreenImage" alt="Image" class="fullscreen-image"/>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import layoutSourceList from "@/assets/webImageSourceList.json";
import ocDirectorySourceList from "@/assets/ocDirectory.json";
import SocialHeader from "@/views/social/SocialHeader.vue";
import SocialImageGrid from "@/views/social/SocialImageGrid.vue";

import {logAnalyticsEvent} from "@/firebase/init";

// question: how do i subscribe to changes in the current url in vue3
// detailed answer: you can use the watch method to watch for changes in the $route object
// here is an example:
// watch: {

// }


// todo download button
// todo share buttons,  facebook, twitter, instagram, copy to clipboard


// todo reverse default sort order
// todo add sorting buttons.
// todo add click functionality to sort buttons
//

export default {
  components: {SocialImageGrid,  SocialHeader},
  watch: {
    '$route'() {
      // noinspection JSUnresolvedVariable
      let imageId = this.$route.params.imageId;
      if (!imageId) {
        this.fullScreenImage = ''
      }
    }
  },
  data() {
    return {
      fullScreenImage: '',
      page: '',
      imageFiles: imageSourceList,
      layoutFiles: layoutSourceList,
      ocFiles: ocDirectorySourceList,
      allImages: [],
      allStreams: [],
      imagesForDate: [],
      followers: 4000,
      followingSince: '2021-06-01',
      fanartImages: 100,
      username: '',
      profilePicture1: '',
      profilePicture2: '',
      shortDescription: 'Twitch Profile About me text',
      activeTab: 'gartic', // Default active tab
      toggleState: false,
      tab3Images: [
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/300',
        // Add more image URLs for Tab 3 as needed
      ],
    };
  },
  methods: {
    getCurrentStream(page) {
      this.allStreams.forEach((item) => {
        if (item.clickDate.toLowerCase() === page.toLowerCase()) {
          this.imagesForDate = item.items
        }
      })

    },
    defineAllStreams() {
      let images = this.allImages
          .filter((item) => !item.tags || !item.tags.includes('nsfw'))


      const groupedObjects = {}

      images
          .forEach((obj) => {
            const filenameParts = obj.filename.split(' ');
            if (filenameParts.length === 3) {
              const dateStr = filenameParts[1] + ' ' + filenameParts[2].replace('.png', '').replaceAll('_', ':');
              const date = new Date(dateStr);
              if (!isNaN(date)) {
                date.setHours(date.getHours() - 12);
                const key = date.toDateString();
                groupedObjects[key] = groupedObjects[key] || [];
                groupedObjects[key].push(obj);
              } else {
                const key = obj.date;
                groupedObjects[key] = groupedObjects[key] || [];
                groupedObjects[key].push(obj);
              }
            }
          })

      console.log(groupedObjects)

      let allStreams = []
      for (let key in groupedObjects) {
        let value = groupedObjects[key]
        allStreams.push({date: key, items: value})
      }
      allStreams.sort((a, b) => {
        let dateA = new Date(a.date)
        let dateB = new Date(b.date)
        return dateB - dateA
      })
      allStreams.forEach((stream) => {
        let spaces = stream.date.split(' ')
        if (spaces.length === 4) {
          let dateParts = stream.date.split(' ');
          let streamYear = parseInt(dateParts[3], 10);
          let streamMonthAbbreviation = dateParts[1];
          let streamDay = parseInt(dateParts[2], 10);
          // convert to yyyy-mm-dd format
          stream.clickDate = streamYear + '-' + streamMonthAbbreviation + '-' + streamDay
        } else {
          stream.clickDate = stream.date
        }
      })


      this.allStreams = allStreams

    },
    toggleCallback() {
      this.toggleState = !this.toggleState
    },
    handleBackButton(event) {
      event.preventDefault()

      if (this.fullScreenImage !== '') {
        this.fullScreenImage = ''
      } else {
        history.pushState(null, null, window.location.href);
      }
    },
    downloadImage() {
      logAnalyticsEvent('Gallery Image Downloaded', {image: this.fullScreenImage, type: 'gallery'})
      try {
        let link = document.createElement('a');
        link.href = this.fullScreenImage;
        link.target = '_blank'; // Open link in a new tab
        link.rel = 'noopener noreferrer'; // Security measure for opening in a new tab
        document.body.appendChild(link);

        // Use a HEAD request to check if Content-Disposition header is set
        fetch(this.fullScreenImage, {method: 'HEAD'})
            .then(response => {
              if (response.headers.get('Content-Disposition')) {
                // If Content-Disposition header is present, use it for download
                link.download = '';
              } else {
                // Otherwise, use the image filename for download
                link.download = this.fullScreenImage.split('/').pop();
              }

              // Initiating the download
              link.click();
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error during image download:', error);
              // Handle the error or provide user feedback as needed
            });
      } catch (error) {
        console.error('Error during image download:', error);
        // Handle the error or provide user feedback as needed
      }
    },
    copyUrlToClipboard() {
      logAnalyticsEvent('Gallery Image URL Copied', {image: this.fullScreenImage, type: 'gallery'})
      let url = window.location.href;
      navigator.clipboard.writeText(url)
    },
    imageCleared() {
      this.fullScreenImage = ''
      window.history.pushState({}, '', '/streams/' + this.page)
    },
    imageClicked(image) {
      logAnalyticsEvent('Gallery Image Clicked', {image: image, type: 'gallery'})
      this.fullScreenImage = image
      window.history.pushState({}, '', '/streams/' + this.page + '/' + image.split('/').pop())
    },
    checkScroll() {
      const scrollY = window.scrollY; // Current vertical scroll position
      const windowHeight = window.innerHeight; // Viewport height
      const documentHeight = document.documentElement.scrollHeight; // Total document height

      console.log("scrollY: " + scrollY)
      console.log("windowHeight: " + windowHeight)
      console.log("documentHeight: " + documentHeight)
      console.log("scrollY + windowHeight: " + (scrollY + windowHeight))


      // Check if you have reached the bottom
      if (scrollY + windowHeight >= documentHeight - 100) {
        //   check which tab is active


        if (this.activeTab === 'GARTIC') {
          this.loadedPageImages = this.pageImages.slice(0, this.loadedPageImages.length + 20)
        } else if (this.activeTab === 'FANART') {
          this.loadedTagImages = this.tagImages.slice(0, this.loadedTagImages.length + 20)
        }
      }
    },
    getGarticImages() {
      // noinspection JSUnresolvedFunction
      AOS.init()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }
    },
    getTwitchPfp(page) {
      let url = "https://decapi.me/twitch/avatar/" + page
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.profilePicture1 = request.responseText
        }
      };
      request.send();
    },
    getGarticAvatar(page) {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText)
          // eslint-disable-next-line no-prototype-builtins
          if (page === 'layout' || page === 'gartic') {
            context.garticAvatars = Object.values(json)
          }
          // eslint-disable-next-line
          if (json.hasOwnProperty(page))
            context.profilePicture2 = json[page]
        }
      };
      request.send();
    },
    selectTab(tab) {
      console.log(tab)
      logAnalyticsEvent('Gallery Tab Changed', {tab: tab, type: 'gallery'})
      this.activeTab = tab;
    },
    getLayoutAvatars(page) {

      if (this.page === 'layout' || this.page === 'gartic') {
        let keys = Object.keys(this.layoutFiles)
        let arrays = keys.map((key) => {
          return this.layoutFiles[key].map((item) => {
            return {name: key, url: item.url, "moderated": true}
          })
        })
        this.layoutImages = [].concat.apply([], arrays).map((item) => item.url)
        // eslint-disable-next-line
      } else if (this.layoutFiles.hasOwnProperty(page)) {
        this.layoutImages = this.layoutFiles[page].map((item) => item.url)
      } else {
        this.layoutImages = []
      }
    },
    getOcReferences(page) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.ocFiles.hasOwnProperty(page)) {
        this.ocImages = this.ocFiles[page]
      } else {
        this.ocImages = []
      }
    }
  },
  created() {
    console.log("created")
    window.addEventListener('scroll', this.checkScroll);
  },
  unmounted() {
    // Remove the scroll event listener when the component is destroyed to prevent memory leaks
    window.removeEventListener('scroll', this.checkScroll);
  },
  beforeUnmount() {
    // Remove the event listener when the component is about to be unmounted
    window.removeEventListener('handleBeforeUnload', this.handleBackButton);
  },
  mounted() {
    console.log("mounted")
    document.title = "Gallery"
    window.addEventListener('handleBeforeUnload', this.handleBackButton);
    // noinspection JSUnresolvedVariable
    const page = this.$route.params.date.toLowerCase();
    this.page = page
    // noinspection JSUnresolvedVariable
    console.log('sssss')
    logAnalyticsEvent('Gallery Opened', {page: page});
    this.getGarticImages()
    this.defineAllStreams()
    this.getCurrentStream(page)

  },
  computed: {


    tabs() {
      if (this.page === 'layout' || this.page === 'gartic') {
        return ['LAYOUT AVATARS', 'GARTIC AVATARS']
      }
      let tabs = []
      //   ['GARTIC', 'FANART', 'LAYOUT', 'OC']
      if (this.pageImages.length > 0) {
        tabs.push('GARTIC')
      }
      if (this.tagImages.length > 0) {
        tabs.push('FANART')
      }
      if (this.layoutImages.length > 0) {
        tabs.push('LAYOUT')
      }
      if (this.ocImages.length > 0) {
        tabs.push('OC')
      }

      return tabs


    }
  }

};
</script>


<style scoped>

.social-page {
  background-color: #fff;
  padding: 20px;
  position: absolute;
  right: 20%;
  left: 20%;
  width: 60vw;
}


/* Tab content */

/* Text color for tab content */
.tab-content p {
  color: #333;
}

/* List of tags */
.detail-item ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.detail-item ul li {
  margin-bottom: 5px;
}


/* Add more specific styles as needed */
.fullscreen-image {
  border: 15px solid #ffffffcc;
  border-radius: 30px;
  position: absolute;
  top: 5vh;
  left: 5vw;
  right: 5vw;
  bottom: 5vh;
  max-width: 85vw;
  width: 85vw;
  max-height: 90vh;
  object-fit: contain;
}

.fullscreen-image-container {
  background-color: #00000090;
  border: 1px solid black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}


@media (max-width: 768px) {

  .social-page {
    background-color: #fff;
    padding: 20px;
    position: absolute;
    right: 10%;
    left: 0;
    overflow: hidden;
    width: 90vw;
  }

  social-tabs {
    width: 35vw;
  }

}

.fullscreen-image-button-container {
  position: absolute;
  top: 0;
  right: 2%;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.full-screen-x-button {
  font-size: 30px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.full-screen-x-button img {
  width: 30px;
  height: 30px;
}

.webdings {
  font-family: "Webdings",serif;
}

</style>
