<template>
  <div v-if="visible" class="main-timer"
       :style="{ 'background-color': `${backgroundColor}`, color: `${foregroundColor}`, 'font-family': `${font}`, 'font-size': `${fontSize}` }">
    {{ displayedTime }}
  </div>
</template>
<script>
export default {
  name: "TimerView",
  data() {
    return {
      font: "",
      fontSize: "20vw",
      backgroundColor: "#7B587C",
      foregroundColor: "#412742",
      displayTime: '12:34',
      remaining: 30 * 60,
      total: 30 * 60,
      active: false,
      visible: false,
      lastChange: 0,
      lastTTs: 30 * 60,
      lastTTSString: "",
      sound: true
    }
  },
  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    isHex(hex) {
      return typeof hex === 'string'
          && hex.length >= 6
          && hex.length <= 8
          && !isNaN(Number('0x' + hex))
    },
    toDisplayTime() {
      let seconds = this.remaining
      if (seconds <= 0) {
        // wait for 10 seconds, and then hide the timer
        return 'Times Up!'
      }
      let hour = Math.floor(seconds / 3600);
      let minute = Math.floor((seconds % 3600) / 60);
      let second = seconds % 60;

      if (hour.toString().length === 1) {
        hour = `0${hour}:`;
      } else {
        hour = hour + ':'
      }
      if (hour === '00:') {
        hour = ''
      }
      if (minute.toString().length === 1) {
        minute = `0${minute}`;
      }
      if (second.toString().length === 1) {
        second = `0${second}`;
      }
      return `${hour}${minute}:${second}`;
    },
    speak: function (tts) {
      if (tts === -1) {
        return
      }
      let output = ''
      let minutes = Math.floor(tts / 60);
      if (minutes > 0) {
        if (minutes === 1) {
          output += minutes + " Minute"
        } else {
          output += minutes + " Minutes"
        }
        let seconds = tts - minutes * 60;
        if (seconds === 1) {
          output += " and one second"
        } else if (seconds > 1) {
          output += " and " + seconds + " seconds"
        }
      } else {
        let seconds = tts - minutes * 60;
        if (seconds > 3) {
          output += seconds + " seconds"
        } else if (seconds === 3) {
          output = "three"
        } else if (seconds === 2) {
          output = "two"
        } else if (seconds === 1) {
          output = "one"
        } else if (seconds === 0) {
          output = "Times Up"
        }
      }
      'speechSynthesis' in window ? console.log("Web Speech API supported!") : console.log("Web Speech API not supported :-(")
      let synth = window.speechSynthesis
      let ourText = output
      let utterThis = new SpeechSynthesisUtterance(ourText)
      console.log(ourText)
      if (ourText !== this.lastTTSString) {
        this.lastTTSString = ourText
        synth.speak(utterThis)
      }
    }, countDown() {
      if (this.active) {
        if (this.remaining > -1) {
          this.remaining = this.remaining - 1
        }
        if (this.sound) {
          let half = this.total / 2.0
          let quarter = this.total / 4.0
          let minute
          if (quarter > 4 * 60) {
            minute = 60
          } else {
            minute = -100
          }
          let arr = []
          arr.push(half)
          arr.push(quarter)
          arr.push(minute)
          arr.push(3)
          arr.push(2)
          arr.push(1)
          arr.push(0)
          for (let i = 0; i < arr.length; i++) {
            let arrElement = arr[i]
            if (this.remaining === arrElement) {
              this.speak(this.remaining)
              return
            }
          }
        }
      }
    },
    privilegeUser: function (tags) {
      try {
        return tags.badges.moderator === '1' || tags.badges.broadcaster === '1'
      } catch (e) {
        return false
      }
    }, handleMessage(name, message, tags) {
      let privilegedUser = this.privilegeUser(tags);
      console.log(privilegedUser, message.startsWith('!timer '), tags)
      if (privilegedUser && message.startsWith('!timer ')) {
        let split = message.split(' ');
        if (split.length > 1) {
          let command = split[1].toLowerCase()
          console.log(command, Number.isInteger(command))
          if (this.isNumeric(command)) {
            this.total = parseInt(command) * 60
            this.remaining = this.total
            this.lastTTs = this.total
            this.visible = true
            this.active = true
            return
          }
          switch (command) {
            case 'test':
              break
            case 'reset':
              this.active = false
              this.remaining = this.total
              this.lastTTs = this.total
              break
            case 'restart':
              this.remaining = this.total
              this.active = true
              this.lastTTs = this.total
              break
            case 'resume':
            case 'start':
              this.active = true
              break
            case 'stop':
            case 'pause':
              this.active = false
              break
            case 'set':
              if (split.length > 2) {
                let seconds = this.toSeconds(split[2].toLowerCase());
                if (seconds > 0) {
                  this.total = seconds
                  this.remaining = this.total
                  this.lastTTs = this.total
                }
              }
              break
            case 'add':
              if (split.length > 2) {
                let seconds = this.toSeconds(split[2].toLowerCase());
                if (seconds > 0) {
                  console.log('remaining', this.remaining, seconds)
                  this.remaining = this.remaining + seconds
                  console.log('after', this.remaining)
                }
              }
              break
            case 'remove':
              if (split.length > 2) {
                let seconds = this.toSeconds(split[2].toLowerCase());
                if (seconds > 0) {
                  this.remaining = this.remaining - seconds
                }
              }
              break
            case 'show':
              this.visible = true
              break
            case 'hide':
              this.visible = false
              break
            case 'background':
              if (split.length > 2) {
                let colorParam = split[2].replace("#", "");
                if (this.isHex(colorParam)) {
                  let color = "#" + colorParam;
                  localStorage.setItem('backgroundColor', color)
                  this.backgroundColor = color
                }
              }
              break
            case 'color':
              if (split.length > 2) {
                let colorParam = split[2].replace("#", "");
                if (this.isHex(colorParam)) {
                  let color = "#" + colorParam;

                  localStorage.setItem('foregroundColor', color)

                  this.foregroundColor = color
                }
              }
              break
            case 'font':
              if (split.length > 2) {
                let font = split[2];
                localStorage.setItem('font', font)
                this.font = font
              }
              break
            case 'size':
              if (split.length > 2) {
                let fontSize = split[2];
                if (isFinite(fontSize)) {
                  localStorage.setItem('fontSize', fontSize)
                  this.fontSize = fontSize + "vw"
                }
              }
              break
            case 'sound':
              if (split.length > 2) {
                if (split[2].toLowerCase() === 'on') {
                  this.sound = true
                } else if (split[2].toLowerCase() === 'off') {
                  this.sound = false
                }
              }
              break
          }
        }
      }
    },
    toSeconds(s) {
      let number
      if (this.isANumber(s)) {
        return s * 60
      } else {
        number = s.substring(0, s.length - 1);
        if (s.endsWith('s')) {
          return number * 1
        } else if (s.endsWith('m')) {
          return number * 60
        } else if (s.endsWith('h')) {
          return number * 60 * 60
        }
      }
      return 0;
    },
    isANumber(s) {
      return Number(s) === +s
    }
  },
  computed: {
    nameUrl() {
      return new URL(location.href).searchParams.get('name')
    },
    displayedTime() {
      return this.toDisplayTime()
    }
  },
  beforeUnmount() {
    this.active = false
  },
  mounted() {
    document.title = "Timer";

    let foreColor = localStorage.getItem('foregroundColor');
    let backColor = localStorage.getItem('backgroundColor');
    this.font = localStorage.getItem('font')
    let fontSize = localStorage.getItem('fontSize')

    if (!fontSize || !isFinite(fontSize)) {
      fontSize = 30
    }
    this.fontSize = fontSize + "vw"


    if (!foreColor || !this.isHex(foreColor.replace("#", ""))) {
      let color = "#412742";
      localStorage.setItem('foregroundColor', color)
      this.foregroundColor = color;
    } else {
      this.foregroundColor = foreColor
    }

    if (!backColor || !this.isHex(backColor.replace("#", ""))) {
      let color = "#7B587C";
      localStorage.setItem('backgroundColor', color)
      this.backgroundColor = color;
    } else {
      this.backgroundColor = backColor
    }

    setInterval(() => {
      this.countDown();
    }, 1000);
    const tmi = require('tmi.js')
    const client = new tmi.Client({
      channels: [this.nameUrl]
    })
    client.connect()
    client.on('message', (channel, tags, message) => {
          const name = tags['display-name']
          this.handleMessage(name, message, tags)
        }
    )

  }
}
</script>

<style scoped>

.main-timer {
  font-family: Impact, serif;
  text-align: center;
  width: 100vw;
  height: 100vh;
  font-size: 20vw;
  background-color: #2EA169;
  color: rebeccapurple;

}
</style>
