<!--suppress HtmlRequiredAltAttribute -->
<template>
  <main id="home">
    {{ twitchUsers["sillyv"] }}
  </main>
</template>

<script>

import tmi from "tmi.js";
//do they follow


let params = ['accountage', 'avatar', 'creation', 'followcount', 'game', 'subscriber_emotes', 'title']
export default {
  data() {
    return {
      msg: "",
      twitchUsers: {}
    }
  },
  mounted() {
    document.title = "Twitch Lurker Buster"
    const client = new tmi.Client({
      channels: ['sillyv']
    })
    client.connect()
    client.on('message', (channel, tags, message) => {
          const name = tags['display-name']
          this.handleMessage(name, message, tags)


        }
    )
  },
  methods: {
    handleMessage(name, message, tags) {
      let username = tags.username;
      this.msg = username
      if (!this.twitchUsers[username]) {
        this.twitchUsers[username] = {}
        this.getAccountAge(username)
      }
    },
    getAccountAge(username) {


      for (let param of params) {
        fetch('https://decapi.me/twitch/' + param + '/' + username, {method: 'GET'})
            .then(response => {
              if (response.ok) {
                return response.text().then(x => {
                  this.twitchUsers[username][param] = x
                });
              } else {
                this.twitchUsers[username][param] = param +' not found'
              }
            })

      }
    }

  },
  computed: {},
}
</script>


<style scoped>


</style>
