<template>
  <main id="home">

    <div class="container">
      <div class="button_row">
        <div class="zone">

          <div id="dropZ">
            <i class="fa fa-cloud-upload"></i>
            <div>Drag and drop your file here</div>
            <span>OR</span>
            <div class="selectFile">
              <input class="upload-field" type="file" name="files[]" id="file">
            </div>
            <p>File size limit : 10 MB</p>
          </div>

        </div>
        <button class="upload-button" @click="download">Convert</button>
      </div>
      <div id="result">
        <div v-for="row in images" v-bind:key="row" class="vRow">
          <div v-for="image in row" v-bind:key="image" class="column stack">
            <img class="image" :src="image[0]" alt="somealt">
            <div class="overlay"/>
            <div class="overlayText">
              <P class="names">{{ image[1] }}</P>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="app" v-cloak>

    </div>
  </main>
</template>


<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->
<!--suppress JSUnresolvedFunction -->
<script>
import AOS from "aos";
import domtoimage from 'dom-to-image';

export default {
  data() {
    return {
      images: [[['https://i.imgur.com/SP2xHL3.jpg', "Test"], ['https://i.imgur.com/KDO6aaH.png', 'White Test']], [['https://i.imgur.com/KDO6aaH.png', 'White Test'], ['https://i.imgur.com/KDO6aaH.png', 'White Test']]]
    }
  },
  methods: {

    download() {
      let elementById = document.getElementById('result');


      domtoimage.toPng(elementById, {quality: 0.95})
          .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            link.href = dataUrl;
            link.click();
          });


    },

    handleSelects(e) {
      this.images = [];
      const fileList = Array.prototype.slice.call(e.target.files);
      const columns = Math.ceil(Math.sqrt(fileList.length))
      const rows = Math.ceil(fileList.length / columns)
      console.log(rows, columns)
      let index = 0
      for (let i = 0; i < rows; i++) {
        this.images.push([])
        for (let j = 0; j < columns; j++) {
          const f = fileList[index]
          let splitElement = f.name.split(' ')[0];
          let name = splitElement.toUpperCase();
          console.log('f', f)
          index++
          if (!f.type.match("image.*")) {
            return;
          }
          const reader = new FileReader();
          const that = this
          reader.onload = function (e) {
            that.images[i].push([e.target.result, name]);
          }
          reader.readAsDataURL(f);
        }
      }
      console.log(this.images)
    },
    handleFilesValidated(result, files) {
      console.log('Validation result: ' + result, files);
    },
    handleFilesChanged(files) {
      console.log('Selected files: ', files);
      // console.table(files);

      // this.convertImages(files[0])
    },
    test() {

    },
    convertImage(file) {
      const imageType = /image.*/;
      if (!file.type.match(imageType)) {
        return;
      }
      const img = document.getElementById("thumbnail");
      img.file = file;
      const reader = new FileReader();
      reader.onload = (function (aImg) {
        return function (e) {
          aImg.src = e.target.result;
        };
      })(img);
      reader.readAsDataURL(file);
    }
  },
  mounted() {
    document.title = "Collage Maker"
    AOS.init()
  }
}
</script>


<style scoped>
.stack {
  display: grid;
}

.stack > * {
  grid-row: 1;
  grid-column: 1;
}

.upload-button {
  background-color: #c0ffee;
  width: 200px;
  height: 65px;
  font-size: 30px;
  margin-top: 30px;
  font-family: Impact, serif;
}

.image {
  width: 758px;
  height: 424px;
}

.vRow {
  display: flex;
}

.overlay {
  display: flex;
  position: relative;
  width: 100%;
  opacity: .3;
  height: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0) 100%);
}

.overlayText {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.names {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-family: Impact, serif;
  text-align: start;
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 40px;
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
}

.upload-field {
  display: none;
}


</style>
