<template>
  <div class="img-container" >
    <img :src="currentImageUrl"  @click="getRandomImageUrl" @error="onError"/>
  </div>
</template>

<script>
export default {
  props: {
    urls: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentImageUrl: '',
      drawingsNotYetShown: []
    };
  },
  mounted() {
    this.getRandomImageUrl();
  },
  methods: {
    getRandomImageUrl() {

      if (this.urls.length === 0) return '';
      if (this.urls.length === 1) return this.urls[0];

      if (this.drawingsNotYetShown.length === 0) {
        this.drawingsNotYetShown = [...this.urls];
      }

      let imageToChange = this.currentImageUrl
      let randomIndex = 0
      while (imageToChange === this.currentImageUrl) {
        randomIndex = Math.floor(Math.random() * this.drawingsNotYetShown.length);
        imageToChange = this.drawingsNotYetShown[randomIndex];
      }
      this.drawingsNotYetShown.splice(randomIndex, 1)
      this.currentImageUrl = imageToChange;
    },
    onError() {
      if (this.urls.length <= 1) {
        if (this.currentImageUrl !== this.urls[0]) {
          this.currentImageUrl = this.urls[0];
        }
        return;
      }
      this.getRandomImageUrl();
    }
  }
};
</script>

<style scoped>
/*gradient vertical 3 colors*/

.img-container {
  width: 100%;
  height: 100%;
}

.img-container img {
  width: 100%;
  border-radius: 25px;
  height: 100%;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.img-container img:hover {
  opacity: 0.8;
}

.img-container img:active {
  opacity: 0.5;
}
</style>
