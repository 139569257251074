<template>
  <div class="base-item">
    <label class="item-label">{{ title }}</label>
    <button v-if="itemType === 'die'" class="plus" @click="modifierClicked('+')">+</button>
    <button v-if="itemType === 'die'" class="minus" @click="modifierClicked('-')">-</button>
    <input>
  </div>
</template>


<script>
export default {
  props: ['title', 'itemType', 'modifierClicked', 'die'],
  name: "oddsItem",
}
</script>

<style scoped>

.base-item {
  font-family: 'Roboto Slab', serif;
  /*noinspection CssUnknownTarget*/
  background-image: url("../../../src/assets/items.png");
  background-size: 100%;
  background-repeat: no-repeat;
  aspect-ratio: 473 / 972;
  text-align: center;
  color: white;
  align-items: start;
}

.item-label {
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

}

button {
  position: absolute;
  top: 30%;

}

.plus {
  left: 20%;
}

.minus {
  left: 50%;
}

</style>
