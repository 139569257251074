<template>
  <div class="pong-game" @click="generate5PaddlesOnEachSide">
    <!-- Create paddles dynamically using v-for -->
    <div style="color: white ">{{ lastPlayerToTouchBall }}</div>


    <div v-for="paddle in leftPaddles" :key="paddle.id" class="paddle" :style="{ left: '20px', top: paddle.top }"
         :id=paddle.id>
      <div class="portrait-image">
        <img :src="userToProfilePictureMap.get(paddle.id)" alt="Paddle Image">
      </div>
      <div class="portrait-name">{{ paddle.name }}</div>
    </div>


    <div v-for="paddle in rightPaddles" :key="paddle.id" class="paddle" :style="{ right: '20px', top: paddle.top }"
         :id=paddle.id>
      <div class="paddle-name">{{ paddle.name }}</div>
    </div>

    <div class="ball"></div>
  </div>
</template>


<script>

export default {
  data() {
    return {
      ballSpeed: 1,
      ballDirectionX: 1,
      ballDirectionY: 1,
      userToProfilePictureMap: new Map(),
      lastPlayerToTouchBall: "",
      leftPaddles: [
        // {id: 1, name: "Player 1", left: "20px", top: "50%"},
        // {id: 2, name: "Player 3", left: "20px", top: "50%"},
        // {id: 3, name: "Player 3", left: "20px", top: "50%"},
        // {id: 4, name: "Player 3", left: "20px", top: "50%"},
        // {id: 5, name: "Player 3", left: "20px", top: "50%"},
        // Add more left paddles as needed
      ],
      rightPaddles: [
        // {id: 6, name: "Player 2", right: "20px", top: "50%"},
        // {id: 7, name: "Player 4", right: "20px", top: "50%"},
        // {id: 8, name: "Player 4", right: "20px", top: "50%"},
        // {id: 9, name: "Player 4", right: "20px", top: "50%"},
        // {id: 10, name: "Player 4", right: "20px", top: "50%"},
        // Add more right paddles as needed
      ],
    };
  },
  mounted() {
    document.title = "Twitch Pong"

    this.initTwitchIntegration();

    const ball = document.querySelector(".ball");
    ball.style.left = "50%";
    ball.style.top = "50%";


    //limit initial direction to not be too vertical.  so it can only be at a 45 degree angle going right or left.
    let initialDirectionDegrees = this.getRandomDirection();


    const initialDirectionRadians = (initialDirectionDegrees * Math.PI) / 180;
    this.ballDirectionX = Math.cos(initialDirectionRadians);
    this.ballDirectionY = Math.sin(initialDirectionRadians);
    this.ballSpeed = 2; // Adjust ball speed as needed

    const updateBallPosition = () => {
      initialDirectionDegrees = this.updateBallPosition(initialDirectionDegrees);
    };

    setInterval(updateBallPosition, 50); // Update ball position every 50ms
  },
  methods: {
    getRandomDirection: function () {
      return 0;
      // let randomDirection = Math.random(); // Generate a random number between 0 and 1
      // if (randomDirection < 0.5) {
      //   return Math.random() * 90 - 45;
      // } else {
      //   return Math.random() * 90 + 135;
      // }
    },
    initTwitchIntegration: function () {
      const channelQueryParam = this.$route.query.channel;
      let tmi = require('tmi.js');
      let client = new tmi.Client({
        connection: {
          secure: true,
          reconnect: true
        },
        channels: [channelQueryParam]
      });
      client.connect();
      client.on('message', (channel, tags, message) => {
            console.log(tags)
            //commands:  !left,  !right,  !move <number>, !restart
            const lowerCaseMessage = message.toLowerCase().trim();
            if (lowerCaseMessage === "!left" || lowerCaseMessage === "!right") {
              this.createPaddle(lowerCaseMessage, tags);
            } else if (lowerCaseMessage.startsWith('!move')) {
              this.movePaddle(tags, message);
            } else if (message.toLowerCase().trim() === '!restart') {
              // this.leftPaddles = [];
              // this.rightPaddles = [];
            }
          }
      )
    },
    movePaddle: function (tags, message) {
      const paddleElement = document.querySelector(`.paddle[id="${tags.username}"]`);
      let move = parseInt((message.match(/\d+/) || [])[0], 10); // Extract and cast 'move' to an integer
      if (!isNaN(move)) {
        move = Math.min(Math.max(move, 0), 100) * 0.9;
        if (paddleElement) {
          paddleElement.style.transition = "top 0.5s ease-in-out";
          paddleElement.style.top = `${move}%`
        }
      }
    },
    createPaddle: function (lowerCaseMessage, tags, top = "50%") {

      this.getImage(tags.username)

      const isLeft = lowerCaseMessage === "!left";

      this.rightPaddles = this.rightPaddles.filter(paddle => paddle.id !== tags.username);
      this.leftPaddles = this.leftPaddles.filter(paddle => paddle.id !== tags.username);

      const newPaddle = {id: tags.username, name: tags['display-name'], top: top};

      if (isLeft) {
        newPaddle.left = "20px";
        this.leftPaddles.push(newPaddle);
      } else {
        newPaddle.right = "20px";
        this.rightPaddles.push(newPaddle);
      }
    },
    getImage: function (username) {
      let context = this
      if (!this.userToProfilePictureMap.has(username)) {
        let request = new XMLHttpRequest();
        request.open('GET', 'https://decapi.me/twitch/avatar/' + username, true);
        request.onload = function () {
          if (request.status >= 200 && request.status < 400) {
            context.userToProfilePictureMap.set(username, request.responseText)
          }
        };
        request.send();
      }
    },
    generate5PaddlesOnEachSide() {
      for (let i = 0; i < 5; i++) {
        this.createPaddle("!left", {username: i + "left", 'display-name': i + "left"}, `${i * 20}%`)
        this.createPaddle("!right", {username: i, 'display-name': i}, `${i * 20}%`)
      }
    },
    isPointInRect(point, rect) {
      return point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;
    },
    updateBallPosition: function (initialDirectionDegrees) {

      const ball = document.querySelector(".ball");
      let ballY = parseFloat(ball.style.top);
      let ballX = parseFloat(ball.style.left);

      if (ballY <= 0) {
        this.ballDirectionY = -this.ballDirectionY; // Reverse the Y direction
      }
      if (ballY >= 100) {
        this.ballDirectionY = -this.ballDirectionY; // Reverse the Y direction
      }
      if (ballX <= 0) {
        ballX = 50;
        ballY = 50;
        initialDirectionDegrees = this.getRandomDirection(); // Random initial direction
        this.ballDirectionX = Math.cos((initialDirectionDegrees * Math.PI) / 180);
        this.ballDirectionY = Math.sin((initialDirectionDegrees * Math.PI) / 180);
      }
      if (ballX >= 100) {
        ballX = 50;
        ballY = 50;
        initialDirectionDegrees = this.getRandomDirection(); // Random initial direction
        this.ballDirectionX = Math.cos((initialDirectionDegrees * Math.PI) / 180);
        this.ballDirectionY = Math.sin((initialDirectionDegrees * Math.PI) / 180);
      }
      const paddleElements = document.querySelectorAll(".paddle");
      for (let paddleElement of paddleElements) {
        const paddleRect = paddleElement.getBoundingClientRect();
        const ballRect = ball.getBoundingClientRect();
        const ballBottomCenter = {x: ballRect.left + ballRect.width / 2, y: ballRect.bottom};
        const ballTopCenter = {x: ballRect.left + ballRect.width / 2, y: ballRect.top};
        const ballLeftCenter = {x: ballRect.left, y: ballRect.top + ballRect.height / 2};
        const ballRightCenter = {x: ballRect.right, y: ballRect.top + ballRect.height / 2};

        if (this.isPointInRect(ballLeftCenter, paddleRect)) {
          this.ballDirectionX = Math.abs(this.ballDirectionX); // Reverse the X direction
          console.log("left")
          break
        } else if (this.isPointInRect(ballRightCenter, paddleRect)) {
          console.log("right")
          this.ballDirectionX = -Math.abs(this.ballDirectionX); // Reverse the X direction
          break
        } else if (this.isPointInRect(ballBottomCenter, paddleRect)) {
          this.ballDirectionY = -Math.abs(this.ballDirectionY); // Reverse the Y direction
          console.log("bottom")
          break
        } else if (this.isPointInRect(ballTopCenter, paddleRect)) {
          this.ballDirectionY = Math.abs(this.ballDirectionY); // Reverse the Y direction
          console.log("top")
          break
        }


      }
      ballX += this.ballSpeed * this.ballDirectionX;
      ballY += this.ballSpeed * this.ballDirectionY;
      ball.style.left = `${ballX}%`;
      ball.style.top = `${ballY}%`;
      return initialDirectionDegrees;
    }
  }
};
</script>

<style>
.pong-game {
  position: relative;
  width: 1920px;
  height: 1080px;
  background-color: black;
  overflow: hidden;
}

.paddle {
  position: absolute;
  width: 90px;
  height: 120px;
  background-color: rgba(132, 210, 132, 0.43); /* Adjust the color to your preference */
  border: 2px solid #fff; /* Adjust the border color to your preference */
  border-radius: 10px; /* Adjust the border radius to your preference */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  color: #fff; /* Adjust the text color to your preference */
  font-size: 14px; /* Adjust the font size to your preference */
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.5); /* Adjust the shadow to your preference */
}

.portrait-image img {
  max-width: 100%;
  height: auto;
}

.portrait-name {
  margin-top: 5px; /* Adjust the margin to your preference */
}


/* Style for the paddle names */

.portrait-image img {
  max-width: 100%;
  height: auto;
}

.portrait-name {
  margin-top: 5px; /* Adjust the margin to your preference */
}


.ball {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}
</style>
