<template>
  <div class="sticky-tags">
    <div class="tag-input">
      <input type="text" v-model="tagInput" @keydown.enter.prevent="addTag">
    </div>
    <div class="tag-list">
      <div class="tag" v-for="(tag, index) in currentTags" :key="index" :class="{ 'selected': isSelected(tag) }"
           @click="toggleTag(tag)">
        {{ tag }} <span class="delete-tag" @click="deleteTag(index)">x</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StickyTags',
  props: ['dates', 'tags', 'selectedTags', 'tagsChanged'],
  data() {
    return {
      tagInput: '',
      currentTags: []
    }
  },
  methods: {
    addTag() {
      if (this.tagInput !== '' && !this.currentTags.includes(this.tagInput.toLowerCase())) {
        this.currentTags.push(this.tagInput.toLowerCase())
        // sort currentTags
        this.currentTags.sort()
        this.$emit('tagChanged', this.currentTags)
      }
      this.tagInput = ''
    },

    toggleTag(tag) {
      this.$emit('tagClick', tag)
    },

    isSelected(tag) {
      return this.selectedTags.includes(tag)
    },


    deleteTag(index) {
      const deletedTag = this.currentTags[index]
      if (this.selectedTags.includes(deletedTag)) {
        this.$emit('tagClick', deletedTag)
      }
      this.currentTags.splice(index, 1)
      this.currentTags.sort()
      this.$emit('tagChanged', this.currentTags)
    }
  },
  watch: {
    tags(newTags) {
      const predefinedTags = ['sillyv', 'best', 'cartoon', 'oc', 'delete', 'nsfw', 'maryagneshilltop', 'vixipixiplays', 'rossmode', 'slices', 'enhance', 'complement']; // Array of predefined tags
      this.currentTags = [...new Set([...newTags, ...predefinedTags.filter(tag => !newTags.includes(tag))])];
      this.currentTags.sort()
      this.$emit('tagChanged', this.currentTags)

    }
  }
}
</script>

<style scoped>
.sticky-tags {
  width: 90%;
}

.tag-input input {
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  padding: 0.25rem 0.5rem;
  background-color: #eee;
  border-radius: 0.25rem;
  cursor: pointer;
}

.tag.selected {
  background-color: #007aff;
  color: #fff;
}

.tag.selected:hover {
  background-color: #0069d9;
}

.tag:hover {
  background-color: #f0f0f0;
}

.tag:focus {
  outline: none;
}

.tag:active {
  background-color: #e0e0e0;
}

.tag:active:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  background-color: #f00;
  border-radius: 50%;
}
</style>
