<template>
  <div class="darts-container">
    <div ref="target" class="darts-target"></div>
    <div v-for="(dart,index) in darts" :key="dart.id"
         :ref="'dartRef' + index"
         :style="`top: ${ ((100 - targetBordersPercentage.top - targetBordersPercentage.bottom) * dart.y) / 100 + targetBordersPercentage.top   }%; left: ${((100 - targetBordersPercentage.left - targetBordersPercentage.right) * dart.x) / 100 + targetBordersPercentage.left}%; transform: translate(-50%,0); height:${100 - ((100 - targetBordersPercentage.top - targetBordersPercentage.bottom) * dart.y) / 100 - targetBordersPercentage.top  }%; width: 200px;`"
         class="darts-instance"
    >
      <div class="darts-reticule"></div>
    </div>
    <div class="darts-scoreboard">
      <h2>Top 10 Scorers</h2>
      <div v-for="score in scores" :key="score.id">
        <p>{{ score.name }}: {{ score.score }}</p>
      </div>
    </div>
    <div class="darts-controls">
      <input v-model="coordinates" class="darts-coordinates-input" placeholder="Enter coordinates" type="text"
      />
      <button class="darts-throw-button" @click="addDart">Place Dart</button>
      <button class="darts-throw-button">Throw Dart</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DartsView",
  data() {
    return {
      targetBordersPercentage: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      darts: [
        {
          id: "1",
          x: 50,
          y: 20,
        }
      ],
      scores: [],
      targetDirectionX: -1,
      targetDirectionY: -1,
      coordinates: "20,20",
    };
  },
  mounted() {
    this.setupTargetAnimation();
    this.animateReticules(0);
  },
  methods: {
    setupTargetAnimation() {
      const targetElement = this.$refs.target;
      const container = targetElement.parentElement;

      const updatePosition = () => {
        const containerRect = container.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();
        // take targetBordersPercentage into account

        const actualContainer = {
          top: containerRect.top + containerRect.height * (this.targetBordersPercentage.top / 100),
          right: containerRect.right - containerRect.width * (this.targetBordersPercentage.right / 100),
          bottom: containerRect.bottom - containerRect.height * (this.targetBordersPercentage.bottom / 100),
          left: containerRect.left + containerRect.width * (this.targetBordersPercentage.left / 100),
        };


        if (targetRect.left <= actualContainer.left) {
          this.targetDirectionX = 1; // Reverse X direction
        } else if (targetRect.right >= actualContainer.right) {
          this.targetDirectionX = -1; // Reverse X direction
        }
        if (targetRect.top <= actualContainer.top) {
          this.targetDirectionY = 1; // Reverse Y direction
        } else if (targetRect.bottom >= actualContainer.bottom) {
          this.targetDirectionY = -1; // Reverse Y direction
        }

        const newX = targetRect.left + this.targetDirectionX; // Adjust the step as needed
        const newY = targetRect.top + this.targetDirectionY; // Adjust the step as needed

        targetElement.style.transform = `translate(${newX}px, ${newY}px)`;
      };
      const animate = () => {
        updatePosition();
        requestAnimationFrame(animate);
      };

      // Start the animation loop
      animate();
    },
    addDart() {
      //   check if coordinates are valid, (x,y) should be 0 to 100, and separated by a comma,  remove all non numbers, and non commas
      const coordinates = this.coordinates.replace(/[^0-9,]/g, "");
      const [x, y] = coordinates.split(",");
      if (x < 0 || x > 100 || y < 0 || y > 100) {
        return;
      }
      const uuid = this.darts.length ? this.darts[this.darts.length - 1].id : 0;
      this.darts.push({id: uuid, x, y});
      this.coordinates = "";
      this.animateReticules(this.darts.length - 1);
    },
    animateReticules(index) {

      this.$nextTick(() => {
        console.log(this.$refs["dartRef" + index][0]);
        const dartRef = this.$refs["dartRef" + index][0];
        const reticule = dartRef.querySelector(".darts-reticule");
        let time = 0;
        let circle = 1;
        let oldTop = 0;

        const animate = () => {
          const top = 50 * Math.sin(time);
          const left = 50 * Math.cos(time);

          if (top > 0 && oldTop < 0) {
            circle *= -1;
          }
          oldTop = top;
          reticule.style.left = `${circle * left + (1 - circle) * 50 + 50}px`;

          if (circle === 1) {
            if (left < 0) {
              reticule.style.top = `${top + 50}px`;
            } else {
              reticule.style.top = `${top + 50}px`;
            }

          } else {
            reticule.style.top = `${top + 50}px`;
          }


          time += 0.05; // Adjust the speed of the animation
          requestAnimationFrame(animate);
        };
        animate();
      });
    },
  },
};
</script>

<style scoped>
.darts-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.darts-target {
  background-color: red; /* Placeholder color */
  border-radius: 50%;
  height: 100px;
  width: 100px;
  position: absolute;
}

.darts-instance {
  position: absolute;
}

.darts-scoreboard {
  background-color: #f0f0f0; /* Placeholder color */
  padding: 10px;
  margin-top: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.darts-controls {
  margin-top: 10px;
  position: absolute;
  bottom: 0;
}

.darts-coordinates-input {
  width: 150px; /* Adjust as needed */
}

.darts-throw-button {
  margin-left: 10px;
}

.darts-reticule {
  background-color: green; /* Placeholder color */
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  margin-top: 25%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>
