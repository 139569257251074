<template>
  <div class="home-container">
    <div class="social-page">
      <SocialHeader
          :page-images="[]"
          :query-changed="queryChanged"
          :search="true"
          :username="''"
          profile-picture1=""
          profile-picture2=""
          profile-picture3="https://media.discordapp.net/attachments/859602956247760907/1169079942282231829/logo.png"
      />
      <!--suppress JSValidateTypes -->
      <SocialTabs
          :active-tab="activeTab"
          :select-tab="selectTab"
          :tab-names="tabs"/>
    </div>
    <div class="content-row">
      <div class="top-10-column">
        <div v-if="activeTab==='OC Saturday'" class="oc-saturday-name-list">
          <div v-if="ocSatSelectedName" class="header-oc-sat">Selected Name:</div>
          <div v-if="ocSatSelectedName" class="item-oc-sat item-oc-sat-selected"
               @click="ocSatClickName(ocSatSelectedName)"
          ><p>{{ ocSatSelectedName }}</p>
          </div>
          <div v-if="ocSatSelectedDate && ocSatSelectedName" class="header-oc-sat">Other Names on Selected Date:</div>
          <div v-if="ocSatSelectedDate && !ocSatSelectedName" class="header-oc-sat">Names on Selected Date:</div>
          <div v-if="!ocSatSelectedDate" class="header-oc-sat">Names:</div>
          <div v-for="name in ocSatApplicableNames.filter(n => n !== ocSatSelectedName)"
               :key="name" class="item-oc-sat item-oc-sat-applicable"
               @click="ocSatClickName(name)"
          ><p>{{ name }}</p>
          </div>
          <div v-if="ocSatSelectedDate" class="header-oc-sat">Other Names:</div>
          <div v-for="name in ocSatOtherNames" :key="name" class="item-oc-sat item-oc-sat-other"
               @click="ocSatClickName(name)"><p>{{ name }}</p>
          </div>
        </div>
        <SocialHomeTop10 v-if="activeTab !=='OC Saturday'"
                         :title="(currentMonth) + ' Leaderboard'" :top10-drawers="currentMonthDrawers"/>
        <SocialHomeTop10 v-if="activeTab !=='OC Saturday'"
                         :top10-drawers="top10Drawers" title="All Time Leaderboard"/>
      </div>
      <div v-if="activeTab === 'Highlights'" class="mid-column-borderless highlights">
        <div class="mid-column ">
          <div class="column mid-column-left">
            <h2 class="content-title-border">Recent Uploads</h2>
            <a :href="'https://www.sillyv.art/social/' + latestStreamsCurrentName">
              <img :alt="latestStreamsCurrentUrl"
                   :src="latestStreamsCurrentUrl" class="latest-highlight">
            </a>
            <h3>Image by {{ this.latestStreamsCurrentName }}</h3>
          </div>
          <div class="column mid-column-left bump">
            <h2 class="content-title-border">Layout Avatars</h2>
            <a href="https://www.sillyv.art/social/layout">
              <img :alt="layoutAvatarsCurrentUrl"
                   :src="layoutAvatarsCurrentUrl" class="gartic-avatar-highlight">
            </a>
            <h3>{{ this.layoutAvatarsCurrentName }}</h3>
          </div>
        </div>
        <div class="mid-column">
          <div class="column mid-column-right">
            <h2 class="content-title-border">Featured Highlights</h2>
            <a :href="'https://www.sillyv.art/social/' + todayHighlightsCurrentName">
              <img :alt="todayHighlightsCurrentUrl"
                   :src="todayHighlightsCurrentUrl" class="gallery-highlight">
            </a>
            <h3>Image by {{ this.todayHighlightsCurrentName }}</h3>
          </div>
          <div class="column mid-column-right bump">
            <h2 class="content-title-border">Gartic Avatars</h2>
            <a href="https://www.sillyv.art/social/gartic">
              <img :alt="garticAvatarsCurrentUrl"
                   :src="garticAvatarsCurrentUrl" class="gartic-avatar-highlight">
            </a>
            <h3>{{ this.garticAvatarsCurrentName }}</h3>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'Artists'" class="column mid-column-big artists">
        <div class="artist-gallery mid-column-adjust">
          <div class="alphabet-navigation">
            <ul>
              <li v-for="letter in alphabet" :key="letter" :class="{ 'highlighted': letter === currentLetter }"
                  @click="letterClicked(letter)">
                {{ letter }}
              </li>
            </ul>
          </div>
          <div class="artist-grid">
            <div v-for="(artist, index) in currentLetterArtists" :key="index" class="artist-card">
              <a :href="'https://www.sillyv.art/social/' + artist.name">
                <img :src="artist.url" alt="Artist Image" class="artist-image" loading="lazy"/>
              </a>
              <h4>{{ artist.name }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'OC Saturday'" class="column mid-column-big oc-saturday">
        <div class="oc-saturday-gallery">
          <div v-for="(category, index) in ocSatApplicableCategories" :key="index" class="oc-sat-category">
            <div class="oc-sat-image-header">{{ category.name }}</div>
            <div class="artist-grid">
              <div v-for="(image, index) in category.images" :key="index" class="artist-card">
                <a>
                  <img :src="image.url" alt="Artist Image" class="artist-image" loading="lazy"/>
                </a>
                <h4>{{ image.name }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'Streams'" class="column mid-column-big streams">
        <div class="artist-gallery">
          <div class="just-some">
            <div>Year:</div>
            <select v-model="streamsYear">
              <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <!-- Add more years as needed -->
            </select>
            <div>Month:</div>
            <select v-model="streamsMonth">
              <option value="Jan">January</option>
              <option value="Feb">February</option>
              <option value="Mar">March</option>
              <option value="Apr">April</option>
              <option value="May">May</option>
              <option value="Jun">June</option>
              <option value="Jul">July</option>
              <option value="Aug">August</option>
              <option value="Sep">September</option>
              <option value="Oct">October</option>
              <option value="Nov">November</option>
              <option value="Dec">December</option>
            </select>
          </div>
          <div class="artist-grid">
            <div v-for="(stream, index) in filteredStreams" :key="index" class="artist-card">
              <a :href="'https://www.sillyv.art/streams/' + stream.clickDate">
                <img :src="stream.items[0].url" alt="Artist Image" class="artist-image" loading="lazy"/>
              </a>
              <div>
                <h4 style="margin-bottom: 0">{{ stream.date }}</h4>
                <p v-if="streamDateValidForOCSaturday(stream.date)"
                   style="margin-top: 0">OC Saturday </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'FAQ'" class="column mid-column-big faq">
        <h2 class="content-title-border">Frequently Asked Questions</h2>
        <div style="width: 95%">
          <div v-for="(item, index) in faqItems" :key="index" class="faq-item" @click="toggleAnswer(index)">
            <div class="question">{{ item.q }}</div>
            <div v-if="expanded.includes(index)" class="answer"
                 v-html="item.a"></div>

          </div>
        </div>
      </div>
      <div v-if="activeTab === 'Results'" class="column mid-column-big results">
        <div class="artist-gallery">
          <div class="artist-grid">
            <div v-for="(artist, index) in searchResults" :key="index" class="artist-card">
              <a :href="'https://www.sillyv.art/social/' + artist.name">
                <img :src="artist.url" alt="Artist Image" class="artist-image" loading="lazy"/>
              </a>
              <h4>{{ artist.name }}</h4>
            </div>
          </div>
        </div>
        <h1 v-if="searchResults.length === 0">No Results Found</h1>
      </div>
      <div class="column description-column">
        <div v-if="activeTab === 'OC Saturday'">

          <div v-if="ocSatSelectedDate" class="header-oc-sat">Selected Date:</div>
          <div v-if="ocSatSelectedDate" class="item-oc-sat item-oc-sat-selected"
               @click="ocSatClickDate(ocSatSelectedDate)"
          >{{ ocSatSelectedDate }}
          </div>
          <div
              v-if="ocSatSelectedName && ocSatSelectedDate && ocSatApplicableDates.filter(n => n !== ocSatSelectedDate).length > 0"
              class="header-oc-sat">Other Dates For Selected Name:
          </div>
          <div v-if="ocSatSelectedName && !ocSatSelectedDate" class="header-oc-sat">Dates For Selected Name:</div>
          <div v-if="!ocSatSelectedName" class="header-oc-sat">Dates:</div>
          <div v-for="date in ocSatApplicableDates.filter(n => n !== ocSatSelectedDate)"
               :key="date" class="item-oc-sat item-oc-sat-applicable"
               @click="ocSatClickDate(date)"
          >{{ date }}
          </div>
          <div v-if="ocSatSelectedName" class="header-oc-sat">Other Dates:</div>
          <div v-for="date in ocSatOtherDates" :key="date" class="item-oc-sat item-oc-sat-other"
               @click="ocSatClickDate(date)">{{ date }}
          </div>
        </div>


        <div v-if="activeTab !== 'OC Saturday'">
          <h2 class="content-title-border">Gartic Phone Art Gallery</h2>
          <div class="description-with-image">
            <!-- Text Description with Lorem Ipsum -->
            <div class="description-content">
              <p>
                Explore artwork created during two years of Gartic Phone sessions on Twitch.tv/sillyv. This
                digital
                archive captures our progress and creative journey.
              </p>
              <h4>Find Me:</h4>
              <div class="social-media-icons-row">
                <a href="https://www.twitch.tv/sillyv" target="_blank">
                  <!--                twitch logo-->
                  <img alt="Twitch"
                       class="social-media-icon"
                       src="https://www.freepnglogos.com/uploads/twitch-logo-symbol-25.png">
                </a>
                <a href="https://www.youtube.com/@sillyv" target="_blank">
                  <img alt="YouTube"
                       class="social-media-icon"
                       src="https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png">
                </a>
                <a href="https://twitter.com/SillyVOnline" target="_blank">
                  <img alt="Twitter"
                       class="social-media-icon"
                       src="https://www.freepnglogos.com/uploads/twitter-logo-png/twitter-logo-vector-png-clipart-1.png">
                </a>
                <a href="https://www.instagram.com/sillyvgartic/" target="_blank">
                  <img alt="Instagram"
                       class="social-media-icon"
                       src="https://www.freepnglogos.com/uploads/download-instagram-png-logo-20.png">
                </a>
              </div>

              <h4>Browser Extensions:</h4>
              <div class="extension-row">
                <div class="browser-extension-card">
                  <div class="extension-icon">
                    <!-- Replace 'your-icon.svg' with the actual path to your extension icon -->
                    <a
                        href="https://chrome.google.com/webstore/detail/streamer-tools-for-gartic/bgfkjfcafnnnffphjenlgoklahjiionh"
                        target="_blank">
                      <img
                          alt="Extension Icon"
                          src="https://lh3.googleusercontent.com/RHhvgqU6vS2Ct-DOdZrRL2heTRg7kfZu_103XWbXWKgrFQzDFRhaL8bIsoB6TZw7FJbzvQGaP3WG2pXsaZaVc9TZ4nU=w128-h128-e365-rj-sc0x00ffffff"/>
                    </a>
                  </div>
                  <div class="extension-name">Streamer Tools For Gartic Phone</div>
                </div>
                <div class="browser-extension-card">
                  <div class="extension-icon">
                    <!-- Replace 'your-icon.svg' with the actual path to your extension icon -->
                    <a href="https://chrome.google.com/webstore/detail/artist-tools-for-gartic-p/jimdddbdlngpncjdbfggmjieegcnjcbd"
                       target="_blank">
                      <img
                          alt="Extension Icon"
                          src="https://lh3.googleusercontent.com/aAzOHyz8zmhegHvzqH9ku3mnzGAfxunxj679OD-TpYsSVnB6CRDUiq2Vjo285u5B9Psfza9mnSrSG8j6QyMU1zH8uA=w128-h128-e365-rj-sc0x00ffffff"/>
                    </a>
                  </div>
                  <div class="extension-name">Artist Tools For Gartic Phone</div>
                </div>
              </div>
            </div>

            <!-- Image at the Bottom -->
            <div class="image-content">
              <a href="https://www.sillyv.art/social/sillyv">
                <img
                    alt="Silly Avatar"
                    src="https://media.discordapp.net/attachments/985315170609922048/1110751513791434804/Silly_avatar_circle.gif"
                    style="transform: scaleX(-1); height: 190px"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress JSUnresolvedFunction -->
<script>
import SocialHomeTop10 from "@/views/social/SocialHomeTop10.vue";
import SocialHeader from "@/views/social/SocialHeader.vue";
import SocialTabs from "@/views/social/SocialTabs.vue";
import AOS from "aos";
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import layoutSourceList from "@/assets/webImageSourceList.json";
import ocSatData from "@/assets/ocSatudayData.json";
import {logAnalyticsEvent} from "@/firebase/init";

export default {
  name: "SocialHomeView",
  components: {SocialTabs, SocialHeader, SocialHomeTop10},
  data() {
    // noinspection SpellCheckingInspection
    return {
      streamsYear: this.startCurrentYear(),
      streamsMonth: this.startCurrentMonth(),
      ocSatData: ocSatData,
      ocSatAllNames: {},
      ocSatApplicableNames: {},
      ocSatOtherNames: {},
      ocSatSelectedName: "",
      ocSatAllDates: {},
      ocSatApplicableDates: {},
      ocSatOtherDates: {},
      ocSatSelectedDate: "",
      ocSatApplicableCategories: [],


      currentMonth: this.getCurrentMonth(),
      layoutFiles: layoutSourceList,
      expanded: [],
      faqItems: [
        {
          q: 'How do I delete my pictures from this page?',
          a: 'First: <br>' +
              'Message SillyV by contacting them on Discord: https://discord.gg/tr6UJG2pcV <br>' +
              'Post a message in the channel #gallery-requests.<br>' +
              'If the request is urgent, or you don’t see a response in a few days, do not hesitate to message SillyVs user on discord, or using their email: sillyv@awesome.com<br>'
        },
        {
          q: 'Can I upload my own gartic drawings to my profile in the gallery',
          a: 'No. This page is for drawings made in SillyV’s stream.'
        },
        {
          q: 'How do I get a Gartic Phone avatar, like I see other people have?',
          a: 'The Gartic Phone avatars are part of a tool for Gartic Phone that SillyV has built. Global avatars can be redeemed in SillyVs stream on Twitch. They are rewards for community members who support the stream through regular participation, or for those who choose to support the stream with donations through Ko-fi.<br>' +
              'Individual streamers that use the tool can also upload avatars in their own games, tailored to their own viewers. You find the links to the Gartic Phone extensions on the right side of this page.<br>/sillyv. This archive captures our progress and creative journey.'
        },
        {
          q: 'What are Layout Avatars?',
          a: 'SillyV has built an extension for their stream, which displays custom images alongside chat messages. These are hybrids between SillyVs avatar and a character selected by the community member.<br>'
        },
        {
          q: 'How do I get Fanart to show up in the gallery?',
          a: 'To have your Fanart featured in the gallery, participate in our weekly event called OC-Saturday. During this event, random community members are selected to receive Gartic fanart. Once you\'ve been chosen, the resulting artwork will automatically appear in the gallery.'
        },
        {
          q: 'How do I get my OC references to show up in the gallery?',
          a: 'Post your OC reference in the dedicated OC-Saturday channel on our Discord server.<br>' +
              'Include your Twitch username alongside your OC reference.<br>' +
              'The gallery periodically updates itself by pulling images from the OC-Saturday channel. Your OC references will be displayed in the gallery once the update occurs.'
        }
      ],
      latestStreamsIndex: 0,
      allStreams: [],
      latestStreams: [],
      latestStreamsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      latestStreamsCurrentName: "",
      todayHighlightsIndex: 0,
      todayHighlights: [],
      todayHighlightsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      todayHighlightsCurrentName: "",
      garticAvatarsIndex: 0,
      garticAvatars: [],
      garticAvatarsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      garticAvatarsCurrentName: "",
      layoutAvatarsIndex: 0,
      layoutAvatars: [],
      layoutAvatarsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      layoutAvatarsCurrentName: "",
      artistsToImagesMap: {},
      currentMonthArtistsToImagesMap: {},
      datesToImagesMap: {},
      allImages: [],
      imageFiles: imageSourceList,
      currentLetter: 'A',
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      activeTab: 'Highlights', // Default active tab
      query: "",
      itemsPerPage: 4,
      currentPage: 1,
      top10Drawers: [],
      currentMonthDrawers: []
    };
  },
  computed: {

    filteredStreams() {
      console.log(this.streamsYear)
      console.log(this.streamsMonth)

      let filter = this.allStreams.filter((stream) => {
        let spaces = stream.date.split(' ')
        console.log(spaces)
        if (spaces.length === 4) {
          let streamYear = stream.date.split(' ')[3]
          let streamMonth = stream.date.split(' ')[1]
          return (streamYear.toString()) === (this.streamsYear.toString())
              && (streamMonth) === (this.streamsMonth)
        } else {
          // format is yyyy-mm-dd
          let streamYear = stream.date.split('-')[0]
          let streamMonth = stream.date.split('-')[1]
          // convert month to string 3 letter format
          let month = new Date(streamYear, streamMonth - 1, 1).toLocaleString('default', {month: 'short'})
          return (streamYear.toString()) === (this.streamsYear.toString())
              && (month) === (this.streamsMonth)
        }
      });
      filter.forEach(stream => {
        let spaces = stream.date.split(' ')
        if (spaces.length === 4) {
          let dateParts = stream.date.split(' ');
          let streamYear = parseInt(dateParts[3], 10);
          let streamMonthAbbreviation = dateParts[1];
          let streamDay = parseInt(dateParts[2], 10);
          // convert to yyyy-mm-dd format
          stream.clickDate = streamYear + '-' + streamMonthAbbreviation + '-' + streamDay
        } else {
          stream.clickDate = stream.date
        }
      })
      console.log(filter)
      return filter
    },
    searchResults() {
      // get all keys from artistsToImagesMap


      let keys = Object.keys(this.artistsToImagesMap)
          .filter((key) => key.toLowerCase().includes(this.query.toLowerCase()))
      // remove undefined from keys
      keys = keys.filter((key) => key !== undefined)

      // get all values from artistsToImagesMap for the keys

      let values = keys.map((key) => this.artistsToImagesMap[key])
      // sort values by key alphabetical order\

      // add sillyv to values,   sillyv is not in artistsToImagesMap, so just add name and a placeholder url

      if ('sillyv'.includes(this.query.toLowerCase())) {
        values.push([{
          name: 'sillyv',
          tags: ['best'],
          url: 'https://cdn.discordapp.com/attachments/1151525546505801859/1157504216596758579/sillyv_2023-9-30_1_51_56_728.png'
        }])
      }

      values.sort((a, b) => {
        let nameA = a[0].name.toLowerCase()
        let nameB = b[0].name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })


      let result = values.map((value) => {
        // find latest object, that has the tag best, and doesn't have the tag nsfw, otherwise, return latest object that doesn't have the tag nsfw
        // otherwise return https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg

        let objects = value.filter((obj) => obj.tags && obj.tags.includes('best') && !obj.tags.includes('nsfw'))
        if (objects.length === 0) {
          objects = value.filter((obj) => !obj.tags || !obj.tags.includes('nsfw'))
        }
        if (objects.length === 0) {
          objects = [{url: "'https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg'"}]
        }

        // return latest object in array.
        return objects[objects.length - 1]
      });

      // limit result to 20 items
      return result.slice(0, 20)


    },
    tabs() {
      let strings = ['Highlights', 'Artists', "Streams", "OC Saturday", "FAQ"];
      const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
      if (mobileMediaQuery.matches) {
        strings = ['Highlights', 'Artists', "FAQ"]
      }
      if (this.query !== "") {
        strings.push("Results")
      }
      return strings
    },
    currentLetterArtists() {
      // get all keys from artistsToImagesMap

      let keys = Object.keys(this.artistsToImagesMap)
          .filter((key) => key.toLowerCase().startsWith(this.currentLetter.toLowerCase()))


      // get all values from artistsToImagesMap for the keys

      let values = keys.map((key) => this.artistsToImagesMap[key])
      // sort values by key alphabetical order\
      values.sort((a, b) => {
        let nameA = a[0].name.toLowerCase()
        let nameB = b[0].name.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })

      return values.map((value) => {
        let bestObjects = value.filter((obj) => obj.tags && obj.tags.includes('best'))
        let randomBestObject
        if (bestObjects.length > 0) {
          randomBestObject = bestObjects[Math.floor(Math.random() * bestObjects.length)]
        } else {
          randomBestObject = value[Math.floor(Math.random() * value.length)]
        }
        return randomBestObject
      })


    }
  },
  methods: {
    streamDateValidForOCSaturday(date) {
      //   check both formats, same as in filteredStreams.
      //   a valid date, is one that is on a Saturday.
      //   and a date that is after 2022-12-10
      let spaces = date.split(' ')
      let valid
      if (spaces.length === 4) {
        let dateParts = date.split(' ');
        let streamYear = parseInt(dateParts[3], 10);
        let streamMonthAbbreviation = dateParts[1];
        let streamDay = parseInt(dateParts[2], 10);

// Convert month abbreviation to numeric representation
        const monthMap = {
          Jan: 0,
          Feb: 1,
          Mar: 2,
          Apr: 3,
          May: 4,
          Jun: 5,
          Jul: 6,
          Aug: 7,
          Sep: 8,
          Oct: 9,
          Nov: 10,
          Dec: 11
        };
        let streamMonth = monthMap[streamMonthAbbreviation];

        let streamDate = new Date(streamYear, streamMonth, streamDay);
        let dayOfWeek = streamDate.getDay();
        console.log(streamYear, streamMonth, streamDay, dayOfWeek);

        valid = (streamYear > 2022 || (streamYear === 2022 && streamMonth === 11 && streamDay >= 10)) && dayOfWeek === 6;

      } else {
        // format is yyyy-mm-dd
        let streamYear = date.split('-')[0]
        let streamMonth = date.split('-')[1]
        let streamDay = date.split('-')[2]
        let streamDate = new Date(streamYear, streamMonth - 1, streamDay)
        let dayOfWeek = streamDate.getDay()
        valid = (streamYear.toString() > '2022' ||
                streamYear.toString() === '2022' &&
                ((streamMonth) === ('12') && (streamDay) >= ('10')))
            && dayOfWeek === 6
      }
      console.log(date, valid)
      return valid
    },
    defineAllStreams() {
      let images = this.allImages
          .filter((item) => !item.tags || !item.tags.includes('nsfw'))
      const groupedObjects = {}
      console.log('1')
      images
          .forEach((obj) => {
            console.log(obj)
            const filenameParts = obj.filename.split(' ');

            if (filenameParts.length === 3) {
              const dateStr = filenameParts[1] + ' ' + filenameParts[2].replace('.png', '').replaceAll('_', ':');
              const date = new Date(dateStr);
              if (!isNaN(date)) {
                date.setHours(date.getHours() - 12);
                const key = date.toDateString();
                groupedObjects[key] = groupedObjects[key] || [];
                groupedObjects[key].push(obj);
              } else {
                const key = obj.date;
                groupedObjects[key] = groupedObjects[key] || [];
                groupedObjects[key].push(obj);
              }
            }
          })

      console.log(groupedObjects)
      console.log('2')
      let allStreams = []
      for (let key in groupedObjects) {
        let value = groupedObjects[key]
        value.sort((a, b) => {
          let tagsA = a.tags
          let tagsB = b.tags
          if (tagsA && tagsA.includes('best')) {
            return -1
          }
          if (tagsB && tagsB.includes('best')) {
            return 1
          }
          return 0
        })
        console.log('3')
        value.sort((a, b) => {
          let nameA = a.name.toLowerCase()
          let nameB = b.name.toLowerCase()
          if (nameA === 'sillyv') {
            return -1
          } else if (nameB === 'sillyv') {
            return 1
          } else if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })


        allStreams.push({date: key, items: value})
      }
      allStreams.sort((a, b) => {
        let dateA = new Date(a.date)
        let dateB = new Date(b.date)
        return dateB - dateA
      })
      this.allStreams = allStreams
    },
    obtainOCSatData() {
      //     get all keys from this.ocsatData
      let keys = Object.keys(this.ocSatData)
      this.ocSatAllDates = keys
      this.ocSatSelectedDate = this.ocSatAllDates[this.ocSatAllDates.length - 1]
      let innerKeys = new Set()
      for (let key of keys) {
        let value = this.ocSatData[key]
        for (let innerKey of Object.keys(value)) {
          innerKeys.add(innerKey)
        }
      }
      this.ocSatAllNames = Array.from(innerKeys)
      this.ocCalculateApplicableAndOthers()
    },
    ocCalculateApplicableAndOthers() {
      this.ocSatApplicableDates = []
      this.ocSatOtherDates = []
      this.ocSatApplicableNames = []
      this.ocSatOtherNames = []
      if (this.ocSatSelectedName && this.ocSatSelectedName !== "") {
        let keys = Object.keys(this.ocSatData)
        this.ocSatAllDates = keys
        for (let key of keys) {
          let value = this.ocSatData[key]
          if (this.ocSatSelectedName in value) {
            this.ocSatApplicableDates.push(key)
          } else {
            this.ocSatOtherDates.push(key)
          }
        }
      } else {
        this.ocSatApplicableDates = this.ocSatAllDates
        this.ocSatOtherDates = []
      }
      if (this.ocSatSelectedDate && this.ocSatSelectedDate !== "") {
        let ocSatForDate = this.ocSatData[this.ocSatSelectedDate];
        let keys = Object.keys(ocSatForDate)
        this.ocSatApplicableNames = keys
        this.ocSatOtherNames = this.ocSatAllNames.filter((name) => !keys.includes(name))
      } else {
        this.ocSatApplicableNames = this.ocSatAllNames
        this.ocSatOtherNames = []
      }
      if (this.ocSatSelectedName !== "" && this.ocSatSelectedDate !== "") {
        this.ocSatApplicableCategories = [{
          name: this.ocSatSelectedName + " - " + this.ocSatSelectedDate,
          images: this.ocSatData[this.ocSatSelectedDate][this.ocSatSelectedName]
        }]
      } else if (this.ocSatSelectedName !== "") {
        this.ocSatApplicableCategories = []
        for (let ocSatDataKey in this.ocSatData) {
          let ocSatDataValue = this.ocSatData[ocSatDataKey]
          if (this.ocSatSelectedName in ocSatDataValue) {
            this.ocSatApplicableCategories.push({
              name: this.ocSatSelectedName + " - " + ocSatDataKey,
              images: ocSatDataValue[this.ocSatSelectedName]
            })
          }
        }
      } else if (this.ocSatSelectedDate !== "") {
        this.ocSatApplicableCategories = []
        let ocSatDataValue = this.ocSatData[this.ocSatSelectedDate]
        for (let ocSatDataKey in ocSatDataValue) {
          this.ocSatApplicableCategories.push({
            name: ocSatDataKey + " - " + this.ocSatSelectedDate,
            images: ocSatDataValue[ocSatDataKey]
          })
        }
      } else {
        this.ocSatApplicableCategories = []
      }
      //   sort lists
      this.ocSatApplicableDates.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })
      this.ocSatOtherDates.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })
      this.ocSatApplicableNames.sort()
      this.ocSatOtherNames.sort()


    },
    ocSatClickName(name) {
      logAnalyticsEvent('OC Saturday Name Changed', {name: name, type: 'galleryHome'})
      if (this.ocSatSelectedName === name) {
        this.ocSatSelectedName = ""
      } else {
        this.ocSatSelectedName = name
      }
      this.ocCalculateApplicableAndOthers()
      if (!this.ocSatApplicableDates.includes(this.ocSatSelectedDate)) {
        this.ocSatSelectedDate = ""
      }
      this.ocCalculateApplicableAndOthers()
    },
    ocSatClickDate(date) {
      logAnalyticsEvent('OC Saturday Date Changed', {date: date, type: 'galleryHome'})
      if (this.ocSatSelectedDate === date) {
        this.ocSatSelectedDate = ""
      } else {
        this.ocSatSelectedDate = date
      }
      this.ocCalculateApplicableAndOthers()
      if (!this.ocSatApplicableNames.includes(this.ocSatSelectedName)) {
        this.ocSatSelectedName = ""
      }
      this.ocCalculateApplicableAndOthers()
    },
    getCurrentMonth() {
      let date = new Date()
      console.log(date)
      return date.toLocaleString('default', {month: 'long'})

    },
    queryChanged(query) {
      this.query = query
      this.activeTab = 'Results'
    },
    toggleAnswer(index) {
      if (this.expanded.includes(index)) {
        this.expanded = this.expanded.filter((item) => item !== index)
      } else {
        this.expanded.push(index)
      }
    },
    startCurrentYear() {

      const date = new Date();
      console.log(date)
      console.log(date.getFullYear())
      return date.getFullYear();

    },
    startCurrentMonth() {
      //    dont use currentDateYYYYMMDDInGMT2 method,
      //   just return the current calendar month, as a three letter string
      const date = new Date();
      return date.toLocaleString('default', {month: 'short'})
    },
    currentDateYYYYMMDDInGMT2() {
      const date = new Date();
      date.setUTCHours(date.getUTCHours() + 2);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    },
    letterClicked(letter) {
      logAnalyticsEvent('Gallery Letter Changed', {letter: letter, type: 'galleryHome'})
      this.currentLetter = letter

    },
    getGarticImages() {

      AOS.init()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }
      this.allImages.forEach((item) => {
        // don't add sillyv images
        if (item.name === 'sillyv') {
          return
        }
        // if no name, return
        if (!item.name) {
          return
        }
        if (this.artistsToImagesMap[item.name]) {
          this.artistsToImagesMap[item.name].push(item)
        } else {
          this.artistsToImagesMap[item.name] = [item]
        }
      });

      let arrayOFNameAndCount = []
      for (let key in this.artistsToImagesMap) {
        arrayOFNameAndCount.push({name: key, count: this.artistsToImagesMap[key].length})
      }

      this.top10Drawers = arrayOFNameAndCount.sort((a, b) => b.count - a.count).slice(0, 10)


      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // January is 0, so we add 1

      const filteredItems = this.allImages
          .filter(item => item.date)
          .filter(item => item.name.toLowerCase() !== "sillyv")
          .filter(item => {
            const [year, month] = item.date.split('-').map(Number);
            return year === currentYear && month === currentMonth;
          });

      const nameCount = {};
      filteredItems.forEach(item => {
        if (nameCount[item.name]) {
          nameCount[item.name]++;
        } else {
          nameCount[item.name] = 1;
        }
      });
      const nameCountArray = Object.entries(nameCount).map(([name, count]) => ({name, count}));
      nameCountArray.sort((a, b) => b.count - a.count);
      const topNames = nameCountArray.slice(0, 10);
      topNames.forEach(item => {
        console.log(`${item.name}: ${item.count}`);
      });
      this.currentMonthDrawers = topNames.map((item) => {
        return {name: item.name, count: item.count}
      })
    },
    selectTab(tab) {
      logAnalyticsEvent('Gallery Tab Changed', {tab: tab, type: 'galleryHome'})
      this.activeTab = tab;
      console.log(this.activeTab)
    },
    defineLatestStreams: function () {
      this.allImages
          .filter((item) => !item.tags || !item.tags.includes('nsfw'))
          .forEach((item) => {
            // don't add sillyv images

            // if no name, return
            if (!item.date) {
              return
            }
            if (this.datesToImagesMap[item.date]) {
              this.datesToImagesMap[item.date].push(item)
            } else {
              this.datesToImagesMap[item.date] = [item]
            }
            //   get all date keys.

          });
      let dateKeys = Object.keys(this.datesToImagesMap)

      dateKeys.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })


      //   get 3 latest dates
      let latestDates = dateKeys.slice(0, 3)
      this.latestStreams = []
      for (let date of latestDates) {
        this.latestStreams.push(...this.datesToImagesMap[date])
      }
      this.latestStreams.sort(() => Math.random() - 0.5);

      this.latestStreamsCurrentUrl = [this.latestStreams[0].url]
      this.latestStreamsCurrentName = this.latestStreams[0].name.split(' ')[0]

      //make latestStreamIndex a random number between 0 and latest Streams.length
      //   do an operation once every 60 seconds
      setInterval(() => {
        this.latestStreamsIndex += 1
        if (this.latestStreamsIndex >= this.latestStreams.length) {
          this.latestStreamsIndex = 0
        }
        this.latestStreamsCurrentUrl = [this.latestStreams[this.latestStreamsIndex].url]
        this.latestStreamsCurrentName = this.latestStreams[this.latestStreamsIndex].name.split(' ')[0]
      }, 9000)
    },
    shuffleArrayWithSeed(array, seed) {
      // Create a copy of the original array
      const shuffledArray = [...array];

      // Custom random number generator with a seed
      function seededRandom() {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
      }

      // Fisher-Yates shuffle algorithm
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(seededRandom() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }

      return shuffledArray;
    },
    getGarticAvatar() {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText);
          json = Object.keys(json)
              .map((key) => {
                return {name: key, url: json[key]}
              })
          context.garticAvatars = json
          context.garticAvatars.sort(() => Math.random() - 0.5);
          context.garticAvatarsCurrentUrl = [context.garticAvatars[0].url]
          context.garticAvatarsCurrentName = context.garticAvatars[0].name.split(' ')[0]
          setInterval(() => {
            context.garticAvatarsIndex += 1
            if (context.garticAvatarsIndex >= context.garticAvatars.length) {
              context.garticAvatarsIndex = 0
            }
            context.garticAvatarsCurrentUrl = [context.garticAvatars[context.garticAvatarsIndex].url]
            context.garticAvatarsCurrentName = context.garticAvatars[context.garticAvatarsIndex].name.split(' ')[0]
          }, 13000)

        }
      };
      request.send();
    },
    getLayoutAvatars() {

      let keys = Object.keys(this.layoutFiles)
      let arrays = keys.map((key) => {
        return this.layoutFiles[key].map((item) => {
          return {name: key, url: item.url}
        })
      })

      this.layoutAvatars = [].concat.apply([], arrays)
      // shuffle layout avatars
      this.layoutAvatars.sort(() => Math.random() - 0.5);
      this.layoutAvatarsCurrentUrl = [this.layoutAvatars[1].url]
      this.layoutAvatarsCurrentName = this.layoutAvatars[1].name.split(' ')[0]
      setInterval(() => {
        this.layoutAvatarsIndex += 1
        if (this.layoutAvatarsIndex >= this.layoutAvatars.length) {
          this.layoutAvatarsIndex = 0
        }
        this.layoutAvatarsCurrentUrl = [this.layoutAvatars[this.layoutAvatarsIndex].url]
        this.layoutAvatarsCurrentName = this.layoutAvatars[this.layoutAvatarsIndex].name.split(' ')[0]
      }, 17000)
    },
    defineDailyHighlights() {

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Note: Month is zero-based
      const startOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

      // Calculate the start date of the previous calendar month
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      const startOfPreviousMonth = `${previousYear}-${previousMonth.toString().padStart(2, '0')}`;

      let bestImages = this.allImages
          .filter((item) => item.moderated)
          .filter((item) => item.tags && item.tags.includes('best'))
          .filter((item) => !item.tags.includes('nsfw'))
          .filter((item) => item.date && !(item.date.startsWith(startOfMonth) || item.date.startsWith(startOfPreviousMonth)))
      // filter images so that it contains only one entry per item.name
      let seen = new Set();
      bestImages = bestImages.filter(el => {
        const duplicate = seen.has(el.name);
        seen.add(el.name);
        return !duplicate;
      });

      bestImages = this.shuffleArrayWithSeed(bestImages, this.currentDateYYYYMMDDInGMT2())

      //   shuffle bestImages, and use today as a random seed


      this.todayHighlights = bestImages.slice(0, 3)
      this.todayHighlightsCurrentUrl = [this.todayHighlights[0].url]
      this.todayHighlightsCurrentName = this.todayHighlights[0].name.split(' ')[0]
      setInterval(() => {
        this.todayHighlightsIndex += 1
        if (this.todayHighlightsIndex >= this.todayHighlights.length) {
          this.todayHighlightsIndex = 0
        }
        this.todayHighlightsCurrentUrl = [this.todayHighlights[this.todayHighlightsIndex].url]
        this.todayHighlightsCurrentName = this.todayHighlights[this.todayHighlightsIndex].name.split(' ')[0]
      }, 11000)

    }
  }
  ,
  mounted() {

    document.title = "Gartic Gallery"
    this.obtainOCSatData()
    this.getGarticImages()
    this.getGarticAvatar()
    this.getLayoutAvatars()
    this.defineLatestStreams();
    this.defineAllStreams();
    this.defineDailyHighlights()


  }

}
</script>

<style scoped>

.social-page {
  background-color: #fff;
  padding: 20px;
  position: absolute;
  right: 20%;
  left: 20%;
  width: 60vw;
}


.search-bar input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Adjust the font size as needed */
  border: none;
  border-radius: 5px;
  margin-right: 10px; /* Add some spacing between input and button */
}

/* CSS for the search button */
.search-bar button[type="submit"] {
  background-color: #0099FF; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
}

/* Hover effect for the search button */
.search-bar button[type="submit"]:hover {
  background-color: #0056b3; /* Adjust the hover background color */
}


.content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15%;
  align-items: start;
}

.top-10-column {
  flex: 2;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ddd; /* Add borders for clarity */
}

.description-column {
  flex: 2;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ddd; /* Add borders for clarity */
}


/* CSS for the top 10 list container */

/* Header styling */
.top-10-list h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* List item styling */
.top-10-list ul {
  list-style-type: none;
  padding: 0;
}

.top-10-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.mid-column-borderless {

  flex: 6.45;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline-start: 20px;
  margin-inline-end: 10px;
  justify-content: space-between;
}

.mid-column-left {
  border: 1px solid #ddd; /* Add borders for clarity */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline-end: 10px;
}

.mid-column-right {
  border: 1px solid #ddd; /* Add borders for clarity */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline-start: 10px;
}

.mid-column-big {
  flex: 6.45;
  padding: 0 0 30px;
  border: 1px solid #ddd; /* Add borders for clarity */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline-start: 20px;
  margin-inline-end: 10px;
}

* {
  font-family: 'Inter', sans-serif;
}

.image-card img {
  width: 100%;
  height: auto;
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.description-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.description-content {
  text-align: justify;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.image-content img {
  max-width: 100%;
  height: auto;
}

.home-container {
  display: flex;
  flex-direction: column;
}

.gallery-highlight {

  object-fit: cover;
  border: 1px solid #444;
  width: 90%;
  aspect-ratio: 758/424;
}

.gallery-highlight:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gartic-avatar-highlight {
  object-fit: contain;
  aspect-ratio: 758/424;
  width: 900px;

}

.gartic-avatar-highlight:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.latest-highlight {
  object-fit: cover;
  aspect-ratio: 758/424;
  border: 1px solid #444;
  width: 90%;
}

.latest-highlight:hover {
  min-height: 275.625px;
  min-width: 492.75px;
  /*shadow*/
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.social-media-icons-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.social-media-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-inline-start: 1px;
}

.browser-extension-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.extension-icon img {
  width: 64px; /* Adjust the width as needed */
  height: 64px; /* Adjust the height as needed */
}

.extension-name {
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.extension-row {
  display: flex;
  flex-direction: row;
}

.artist-gallery {
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.artist-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.artist-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5; /* Background color for artist cards */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  aspect-ratio: 241/200;
  width: 241px;
  max-height: 200px;
  height: 200px;
}

.artist-card img {
  max-width: 100%;
  aspect-ratio: 241/134;
  height: auto;
  border-radius: 8px;
}

.alphabet-navigation ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.alphabet-navigation li {
  cursor: pointer;
  font-size: 18px;
  color: #333; /* Color for alphabet letters */
}

.highlighted {
  font-weight: bold; /* Highlighted font-weight */
}

.faq-item {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;


  width: 100%;
  text-align: start;
  margin-bottom: 20px;
}

.faq-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.question {
  font-weight: bold;
}

.faq-item {
  width: 90%;
}

.answer {
  margin-top: 5px;
  margin-inline-end: 40px;
}

.mid-column {
  display: flex;
  flex-direction: column;
}

.bump {
  margin-top: 17px;
}


.artist-image {

}

.artist-image:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;

}


.header-oc-sat {
  color: #000;

  text-align: center;
  font-family: Inter, serif;
  margin-top: 19.92px;
  margin-bottom: 14px;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item-oc-sat {
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 46px;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.item-oc-sat:hover {
  background: #706e6e;
  cursor: pointer;
}

.item-oc-sat-selected {
  background: #ccffcc;
}

.item-oc-sat-applicable {
  background: #fff5cc;

}

.item-oc-sat-other {
}


.faq {
  width: 200px;
}

@media (max-width: 768px) {
  .content-row {
    width: 90vw;
    overflow: hidden;
  }

  .home-container {
    width: 100vw;
    overflow: hidden;
  }

  .social-page {
    background-color: #fff;
    padding: 20px;
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    width: 95vw;
  }

  .content-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15%;

    align-items: start;
  }

  .highlights {
    margin-inline-start: 0;
    overflow: hidden;
    margin-top: 150px;
    padding: 0;
    order: 2;
    width: 90vw;
  }

  .mid-column {
    flex: 1;
    width: 90%;
  }

  .top-10-column {
    order: 4;
  }

  .artists {
    order: 3;
  }

  .results {
    order: 1;
  }

  .faq {
    order: 1;
    width: 70vw;
    margin-inline-start: 0;
  }

  .faq-item {
    width: 75vw;
  }

  .question {
    width: 75vw;
  }

  .description-column {
    order: 4;
  }

  .mid-column {
    width: 50%;
    margin: 0;
  }

  .mid-column-right {
    margin: 0;
  }

  .gallery-highlight {

    object-fit: cover;
    border: 1px solid #444;
    width: 90%;
    aspect-ratio: 758/424;
  }

  .mid-column-left {
    height: 200px;
  }

  .mid-column-right {
    height: 200px;
  }

  .bump {
    margin-top: 10px;
  }

  .content-title-border {
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
  }

  .artist-card {
    width: 75vw;
    margin-inline-start: 0;
  }

  .artist-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mid-column-big {
    width: 90vw;
    margin-top: 150px;
    margin-inline-start: 0;
  }

  .artist-gallery {
    width: 90vw;
    margin-inline-start: 0;
  }

  .alphabet-navigation {
  }

  .alphabet-navigation ul {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    padding: 0;
  }

  .alphabet-navigation li {
    flex: 1;
    /* Add styles for the <li> elements */
  }


}

.just-some {
  display: flex;
  font-family: Inter, serif;
  font-size: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  width: 30%;
}

.oc-sat-image-header {
  cursor: pointer;
  text-align: center;
  color: black;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  height: 53px;
  font-family: Inter, serif;

  font-size: 16px;
  justify-content: center;
  width: 100%;

}


.just-some select {
  border: none;
  font-size: 16px;
}

.oc-sat-category {
  padding: 0;
}
</style>
