<template>
  <div class="container">
    <div class="image-container">
      <img ref="image" class="boopImage" src="https://i.imgur.com/Jjr0o7q.png"/>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    targetPosition() {
      const x = this.$route.query.x;
      const y = this.$route.query.y;

      if (x && y) {
        return `translate(${x}%, ${y}%)`;
      } else {
        return "translate(-50%, -50%)";
      }
    }
  },
  mounted() {
    document.title = "Boop";
    const image = this.$refs.image;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const imageWidth = image.offsetWidth;
    const imageHeight = image.offsetHeight;

    let y = 50;
    let x = 50;

// Check if the URL has x and y parameters
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('x') && urlParams.has('y')) {
      y = urlParams.get('y');
      x = urlParams.get('x');
    }

// Calculate the initial position of the image based on the viewport size and the x and y parameters or the center of the container
    const initialY = viewportHeight + imageHeight;
    const targetY = y / 100 * viewportHeight - imageHeight * .08
    const targetX = (x / 100 * viewportWidth) - (imageWidth * .28);
    const initialX = targetX


    image.style.transform = `translate(${initialX}px, ${initialY}px)`;

    //if y and x are -1 don't animate at all
    if (y !== -1 && x !== -1) {
      this.animate(initialX, initialY, targetX, targetY);
    }

  },
  methods: {
    animate(startX, startY, endX, endY) {
      const image = this.$refs.image;

      // Animate the image to the target position
      image.animate(
          [
            {transform: `translate(${startX}px, ${startY}px)`},
            {transform: `translate(${endX}px, ${endY}px)`},
          ],
          {
            duration: 1000,
            easing: "ease-in-out",
            fill: "forwards",
          }
      );

      // Wait for the first animation to finish
      setTimeout(() => {
        // Animate the image back to the initial position
        image.animate(
            [
              {transform: `translate(${endX}px, ${endY}px)`},
              {transform: `translate(${startX}px, ${startY}px)`},
            ],
            {
              duration: 1000,
              easing: "ease-in-out",
              fill: "forwards",
            }
        );
      }, 1000);
    },
  },
};
</script>

<style>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.image-container {
  position: absolute;
}

.boopImage {
  top: 100%;
  max-height: 200px;
  width: auto;
  object-fit: cover;
}
</style>
