<template>
  <div class="ads">
    <!-- Google Ads Vertical Banner Ad -->
    <!-- Insert your ad code here -->
  </div>
</template>
<script>
export default {
  name: 'SlicesAdsView'
}
</script>
<style scoped>

.content img {
  max-width: 200px;
}

.ads {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  width: 160px;
  height: 600px;
}

/* Add more styles for the vertical banner ad here */
</style>
