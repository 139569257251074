<template>
  <div class="grid-image">
    <img :src="avatar.url" alt="{{avatar.name}}"
         @error="handleImageError">
  </div>

  <div class="grid-name">{{ avatar.name }}</div>
  <div class="points-breakdown">
    <div class="points-breakdown-left">
      <div class="tokens-wins">
        <div :style="{ backgroundImage: 'url(' + require('./coin.svg') + ')' }" class="coin-holder">
          {{ calculateParticipationForDisplay }}
        </div>
        <div v-if="calculateWinsModifierForDisplay === 'X2'" class="modifier-times-two"
        >{{ calculateWinsModifierForDisplay.substring(0, 1) }}<span class="bigger">{{
            calculateWinsModifierForDisplay.substring(1, 2)
          }}</span>
        </div>
        <div v-if="calculateWinsModifierForDisplay !== 'X2'" class="modifier-minus">{{
            calculateWinsModifierForDisplay
          }}
        </div>
      </div>
    </div>
    <div class="points-breakdown-right">
      <div :style="{ backgroundImage: 'url(' + require('./score.svg') + ')' }" class="score-holder">

        {{ calculateScoreDisplay }}
      </div>
    </div>

  </div>
  <div class="days-display">{{ lastWinTime }}</div>
</template>
<script>
export default {
  name: 'OCSatCard',
  props: {
    avatar: {},

  },
  computed: {
    lastWinTime() {
      let wins = this.avatar.wins
      //returns a x days ago string based on the date of the latest win
      if (!wins || wins.length === 0) {
        return ""
      }
      let lastWin = wins[wins.length - 1]
      let lastWinDate = new Date(lastWin)
      // yyyy-mm-dd
      let readableDate = lastWinDate.getFullYear() + "-" + (lastWinDate.getMonth() + 1) + "-" + lastWinDate.getDate()
      let today = new Date()
      let diffTime = Math.abs(today - lastWinDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return readableDate + "(" + diffDays + ")"
    },
    calculateParticipationForDisplay() {
      let participationBonus = this.avatar.weight + 1
      if (participationBonus > 0) {
        return participationBonus
      } else {
        return "0"
      }
    },
    calculateWinsModifierForDisplay() {
      let wins = this.avatar.wins
      if (!wins || wins.length === 0) {
        return 'X2'
      }
      return "-" + (wins.length)
    },
    calculateScoreDisplay() {
      let wins = this.avatar.wins
      let weight = this.avatar.weight + 1
      return Math.max(this.calculateScoreNumber(wins, weight), 1)
    },

  },
  methods: {
    handleImageError(event) {
      // Switch to the fallback image URL when the main image fails to load
      event.target.src = require('./missing.png');
    },
    calculateScoreNumber(wins, weight) {
      //  double weight if no wins,  otherwise weight minus wins
      if (!wins || wins.length === 0) {
        return weight * 2
      }
      return weight - wins.length
    },
  },
  created() {
    import('@/views/slices/slices.css')
  }
}
</script>
<style scoped>


.card-image img {
  max-width: 100%;
  max-height: 100%;
}

.card-input label {
  font-weight: bold;
  font-size: 16px;
  color: #666;
}

.card-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  color: #333;
}


.grid-image {
  width: 90%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  aspect-ratio: 356/241;


}

.grid-image img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  aspect-ratio: 356/241;
  border-radius: 23px 23px 0px 0px;
  border: 4px solid #6a3f98;

  object-fit: cover;
  object-position: top;

}

.grid-name {
  font-size: 18px;
  background-color: #6a3f98;
  color: #fff;
  width: 90%;
  border: 4px solid #6a3f98;
  text-align: center;
  font-family: Inter, serif;
  overflow: hidden;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-bottom: 5px;
  border-radius: 0 0 23px 23px;

}


.winner-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.winner-text h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.winner-text p {
  font-size: 20px;
  color: #666;
}


.points-breakdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1
}

.points-breakdown-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex: 1;
}


.days-display {
  width: 100%;
  font-family: InterExtraBold, serif;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: start;
  font-size: 16px;
  font-family: InterExtraBold, serif;
  font-weight: 900;
  margin: 5px;
  background-clip: text;
  background: linear-gradient(180deg, #ffffff 100%, #e3e3e3 100%);
  /*white drop shadow*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.tokens-wins {
  height: 50px;
  align-content: center;
  align-items: center;
  border-radius: 11px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: rgba(255, 255, 255, 0.20);
  display: flex;
  flex-direction: row;
  margin-inline-start: 12px;
  justify-content: center;
}

.coin-holder {
  flex: 1;
  width: 46px;
  height: 46px;
  background-size: contain;
  background-repeat: no-repeat;

  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #A36200;
}

.modifier-times-two {
  flex: 1;
  width: 70%;
  height: 100%;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: InterExtraBold, serif;
  font-weight: 900;
  background: linear-gradient(180deg, #96F986 100%, #229B53 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;

}

.modifier-minus {
  flex: 1;
  width: 72px;
  height: 72px;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: InterExtraBold, serif;
  font-weight: 900;
  background-clip: text;
  background: linear-gradient(180deg, #b6b6b6 100%, #4b4b4b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.bigger {
  font-size: 22px;
}

.score-holder {
  height: 60px;
  flex: 1;

  aspect-ratio: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 5px;
}

.points-breakdown-right {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

</style>
