<template>
  <div class="flex-container">
    <div class="flex-item" v-for="index in sortedFMK.length" :key="index">
      <div class="inner-flex-container">
        <div class="inner-flex-item" v-for="item in sortedFMK[index - 1]" :key="item.color"
             :style="{ flex: item.value , backgroundColor: item.color        }">
          <div v-if="item.value > 0"

               :style="{ height: '100%', width: '100%', backgroundImage: 'url(' + item.url + ')'
              , backgroundSize: '10%'
              , backgroundPosition: 'center'
              , opacity: '0.4'
              , backgroundRepeat: 'repeat'
              , backgroundAttachment: 'fixed'}"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>

const FUCK_COLOR = "#fc54a8";
const MARRY_COLOR = "#f8b81b";
const KILL_COLOR = "rgba(6,3,72,0.89)";

const ELEMENT = [{
  color: FUCK_COLOR, value: 0,
  url: "https://i.imgur.com/RxKHrVQ.png"
}, {
  color: MARRY_COLOR, value: 0,
  url: "https://i.imgur.com/rDWQpC4.png"
}, {
  color: KILL_COLOR,
  value: 0,
  url: "https://i.imgur.com/9tsjZBb.png"
}];
const DEFAULT_FMK = [ELEMENT, ELEMENT, ELEMENT];
const DEFAULT_SOP = [ELEMENT, ELEMENT];
const DEFAULT_SP2 = [ELEMENT];

function defaultArray(size) {
  if (size === 3) {
    return JSON.parse(JSON.stringify(DEFAULT_FMK));
  } else if (size === 2) {
    return JSON.parse(JSON.stringify(DEFAULT_SOP));
  } else {
    return JSON.parse(JSON.stringify(DEFAULT_SP2));
  }
}

export default {
  data() {
    return {
      nofmk: true,
      fmk: defaultArray(3),
      userMap: {}
    }
  }, mounted() {
    document.title = "FMK"

    const urlParams = new URLSearchParams(window.location.search);
    //example url to load image http://localhost:8080/fmk?channel=sillyv
    let channel = 'sillyv';
    if (urlParams.has('channel')) {
      channel = urlParams.get('channel');
    }

    const tmi = require('tmi.js')
    const client = new tmi.Client({
      channels: [channel]
    })
    client.connect()

    client.on('message', (channel, tags, message) => {

          let username = tags.username
          // if moderator or broadcaster
          try {
            if (tags.mod || tags.badges.broadcaster) {
              if (message === '!fmk start') {
                //set this.fmk to be an array of 3 arrays, each array has the integer 0 3 times
                this.fmk = defaultArray(3)
                this.userMap = {}
                console.log('start')
                this.nofmk = false
              } else if (message === '!fmk2 start') {
                this.fmk = defaultArray(2)
                console.log('start')
                this.userMap = {}
                this.nofmk = false
              } else if (message === '!fmk1 start') {
                this.fmk = defaultArray(1)
                console.log('start')
                this.userMap = {}
                this.nofmk = false
              } else if (message === "!nofmk") {
                this.fmk = defaultArray(3)
                this.userMap = {}
                this.nofmk = true
              }

            }
          } catch (e) {
            console.log(e)
          }

          if (this.nofmk) {
            return
          }

          console.log(this.fmk.length)
          if (this.fmk.length === 3) {
            let isMessageValid = message.match(/^[fFmMkK]*$/g) && message.length === 3
            isMessageValid = isMessageValid && !message.match(/([fFmMkK]).*?\1/g)
            if (isMessageValid) {
              this.userMap[username] = message
              this.fmk = defaultArray(3)
              for (let user of Object.keys(this.userMap)) {

                let userMapElement = this.userMap[user];
                let messageArray = userMapElement.split('')
                messageArray.forEach((character, index) => {
                  if (character === 'f' || character === 'F') {
                    this.fmk[index][0].value++
                  }
                  if (character === 'm' || character === 'M') {
                    this.fmk[index][1].value++
                  }
                  if (character === 'k' || character === 'K') {
                    this.fmk[index][2].value++
                  }
                })
              }
            }
          } else if (this.fmk.length === 2) {

            let isMessageValid = message.match(/^[fFmMkK]*$/g) && message.length === 2
            console.log(message)
            console.log(isMessageValid)

            if (isMessageValid) {
              this.userMap[username] = message
              this.fmk = defaultArray(2)
              for (let user of Object.keys(this.userMap)) {
                let userMapElement = this.userMap[user];
                let messageArray = userMapElement.split('')
                console.log(messageArray)
                messageArray.forEach((character, index) => {
                  if (character === 'f' || character === 'F') {
                    this.fmk[index][0].value++
                  }
                  if (character === 'm' || character === 'M') {
                    this.fmk[index][1].value++
                  }
                  if (character === 'k' || character === 'K') {
                    this.fmk[index][2].value++
                  }
                })
              }
            }
          } else if (this.fmk.length === 1) {
            console.log(message)
            let toLowerCase = message.toLowerCase();
            let isMessageValid = toLowerCase === "smash" || toLowerCase === "pass" || toLowerCase === "m" || toLowerCase === "f" || toLowerCase === "k"
            if (isMessageValid) {
              this.userMap[username] = message.toLowerCase()
              this.fmk = defaultArray(1)
              for (let user of Object.keys(this.userMap)) {
                let userMapElement = this.userMap[user].toLowerCase();
                if (userMapElement === 'smash' || userMapElement === 'f') {
                  this.fmk[0][0].value++
                } else if (userMapElement === 'pass' || userMapElement === 'k') {
                  this.fmk[0][2].value++
                } else if (userMapElement === 'm') {
                  this.fmk[0][1].value++
                }
              }
            }
          }
        }
    )
  },
  computed:
      {
        sortedFMK() {
          if (this.fmk.length === 0) {
            return defaultArray(3)
          }

          let sortedFMK = JSON.parse(JSON.stringify(this.fmk))
          sortedFMK.forEach((array) => {
            array.sort((a, b) => {
              return a.value - b.value
            })
          })

          if (sortedFMK.length > 1)
            sortedFMK.forEach((array) => {
              let largestValue = array[array.length - 1].value
              array.forEach((element) => {
                if (element.value !== largestValue) {
                  element.color = 'transparent'
                  element.url = ''
                }
              })
            })


          return sortedFMK
        }
      }
}
</script>

<style>
.flex-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.flex-item {
  flex: 1;
  margin: 10px;
}

.inner-flex-container {
  display: flex;
  height: 95vh;
  flex-direction: column;

}

.inner-flex-item {
  background-color: lightblue;
  flex: 1;
}
</style>
