<template>
  <div class="page">
    <SlicesHeaderView/>
    <div class="download-confirmation-content">
      <h1>Thank you for participating!</h1>
      <h2>You now have 48 hours to complete and submit your slice :) If you realize you need more time, you can ask for
        a one time extension from the prompt owner or Silly, but try to stay within the allotted time frame. If you do
        not submit within the agreed upon time, the slice spot will be reassigned to someone else, and you will be put
        on a cooldown before being able to participate again.</h2>
      <h1>Next</h1>


    </div>
    <SlicesAdsView/>
  </div>

</template>

<script>
import SlicesHeaderView from "@/views/slices/SlicesHeaderView.vue";
import SlicesAdsView from "@/views/slices/SlicesAdsView.vue";

export default {
  components: {SlicesAdsView, SlicesHeaderView},
  data() {
    return {
      daysUntilEvent: 7, // Replace with the actual number of days
    };
  },
  methods: {
    loginWithTwitch() {
      // Add your Twitch login logic here
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #c0ffee;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.content img {
  max-width: 200px;
}

.choose-content img {
  max-width: 200px;
}


.download-image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.download-explanation-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.download-confirmation-content {
  justify-content: space-evenly;
  padding-inline-end: 100px;
  padding-inline-start: 100px;
  background-color: #DEFACE;
  width: 100%;
  flex: 3;
  display: flex;
  flex-direction: column;
}
</style>
