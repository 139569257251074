<template>
  <div class="gallery-screen-saver text">
    {{ logLine }}
    <div class="img-holder" id="dvd-holder"
         :style="{ transform: `translate(${position.x}px, ${position.y}px)`   } ">
      <img id="dvd-image" :src="currentUrl" alt="image"/>
      <div id="gradient" class="gradient">
        <div id="bottom-text" class="bottom-text">
          {{ currentCornerUsername }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

// this component will contain an image that moves around the screen
import imageSourceList from "@/assets/GarticDownloads/gartic.json";


export default {
  name: "DVDPfpView",
  data() {
    return {
      logLine: "",
      currentUrl: "",
      imageFiles: imageSourceList,
      position: {x: 0, y: 0},
      speed: 3,
      direction: {x: 1, y: 1},
      userToLastMessageTimeStampMap: new Map(),
      userToProfilePictureMap: new Map(),
      currentCornerUsername: ""
    }
  },
  methods: {
    log(message) {
      this.logLine = JSON.stringify(message);
    },
    startAnimation() {
      setInterval(() => {
        this.updatePosition();
      }, 16);
    },
    updatePosition() {
      var img = document.getElementById("dvd-image");
      var dvdWidth = img.clientWidth;
      var dvdHeight = img.clientHeight;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const width = screenW - dvdWidth;
      const height = screenH - dvdHeight;
      const {x, y} = this.position;
      const {speed, direction} = this;
      const newX = x + speed * direction.x;
      const newY = y + speed * direction.y;
      if (newX < 0) {
        this.getRandomImage()
        this.direction.x = 1;
      } else if (newX > width) {
        this.getRandomImage()
        this.direction.x = -1;
      } else if (newY < 0) {
        this.getRandomImage()
        this.direction.y = 1;
      } else if (newY > height) {
        this.getRandomImage()
        this.direction.y = -1;
      }
      this.position.x = newX;
      this.position.y = newY;
    },
    getRandomImage: function () {
      let listOfUsersWithImages = Array.from(this.userToProfilePictureMap.keys())
      if (this.userToLastMessageTimeStampMap.size > 0) {
        listOfUsersWithImages = listOfUsersWithImages.filter(user => this.userToLastMessageTimeStampMap.has(user.toLowerCase()))
      }
      let usersWithoutCurrentUser;
      if (listOfUsersWithImages.length === 1) {
        usersWithoutCurrentUser = listOfUsersWithImages
      } else {
        usersWithoutCurrentUser = listOfUsersWithImages.filter(value => {
          return value !== this.currentCornerUsername
        });
      }
      let randomUser = usersWithoutCurrentUser[Math.floor(Math.random() * usersWithoutCurrentUser.length)]
      this.currentUrl = this.userToProfilePictureMap.get(randomUser)
      this.currentCornerUsername = randomUser
      const img = document.getElementById("dvd-image")
      const imgWidth = img.clientWidth
      const text = document.getElementById("bottom-text")
      const gradiant = document.getElementById("gradient")


      //resize text size so 25 characters fit in the image
      text.style.fontSize = `${imgWidth  * 1.7 / (this.currentCornerUsername.length ) }px`
      text.style.display = "block"
      gradiant.style.display = "block"
      img.style.display = "block"



    },
    getImage: function (username) {
      let context = this
      if (!this.userToProfilePictureMap.has(username)) {
        let request = new XMLHttpRequest();
        request.open('GET', 'https://decapi.me/twitch/avatar/' + username, true);
        request.onload = function () {
          if (request.status >= 200 && request.status < 400) {
            context.userToProfilePictureMap.set(username, request.responseText)
          }
        };
        request.send();
      }
    }
  },
  mounted() {
    document.title = "DVD PFP";

    const urlParams = new URLSearchParams(window.location.search);
    let channel = 'sillyv';
    if (urlParams.has('channel')) {
      channel = urlParams.get('channel');
    }
    if (urlParams.has('height')) {
      const height = urlParams.get('height');
      const img = document.getElementById("dvd-image")
      const imgHolder = document.getElementById("dvd-image").parentElement
      img.style.height = `${height}vh`;
      img.style.aspectRatio = '1/1';
      imgHolder.style.height = `${height}vh`;
      imgHolder.style.aspectRatio = '1/1';

    }


    this.position.x = Math.floor(Math.random() * 1020);
    this.position.y = Math.floor(Math.random() * 780);
    this.direction.x = Math.floor(Math.random() * 2) === 0 ? 1 : -1;
    this.direction.y = Math.floor(Math.random() * 2) === 0 ? 1 : -1;
    this.startAnimation();
    let tmi = require('tmi.js');

    let client = new tmi.Client({
      connection: {
        secure: true,
        reconnect: true
      },
      channels: [channel]
    });
    this.getImage(channel)
    client.connect();
    client.on('message', (channel, tags) => {
      this.getImage(tags.username);
    });

  }
}
</script>

<style scoped>
.gallery-screen-saver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}


.img-holder img {
  object-fit: cover;
  display: none;
}

.text {
  font-weight: bold;
  color: white;
  text-shadow: 0 0 10px black;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 00) 50%);
}

.bottom-text {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: none;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 10px black;
}
</style>
