<template>
  <div class="poll-container" v-if="pollShown">
    <div>
      <div class="poll-title">{{ pollTitle }}</div>
      <div v-for="(option, index) in pollOptions" :key="index" class="option">
        <div class="option-number">{{ index + 1 }}.</div>
        <div class="option-name">{{ option.name }}</div>
        <div class="percentage-bar">
          <div class="percentage-fill" :style="{ width: option.percentage + '%' }"></div>
        </div>
      </div>
      <div v-if="pollStartTimestamp !== null"><strong>How to Vote:</strong> Type <code>!vote &lt;number&gt;</code> in the chat to cast your vote.</div>
      <div v-if="access.length > 0 || pollOptions.length === 0"><strong>How to Add Options:</strong> Type <code>!poll
        add &lt;option&gt;</code> in
        the
        chat to add an option to the poll.
      </div>
      <div class="info-section">
        <div v-if="access.length > 0"><strong>Allowed to add options to the poll:</strong> {{ access.join(', ') }}</div>
        <div v-if="limit.length > 0"><strong>Allowed to Vote:</strong> {{ limit.join(', ') }}</div>
        <div v-if="bitPerVote > 0"><strong>Bits Per Vote:</strong> {{ bitPerVote }}</div>
        <div v-if="pollStartTimestamp !== null"><strong>Poll Timer:</strong> {{ countdownString === null ? '' : countdownString }}</div>
        <div><strong>Poll Status:</strong> {{ pollStartTimestamp ? 'Active' : 'Inactive' }}</div>
      </div>
    </div>
    <div class="input-section" v-if="false">
      <input v-model="username" placeholder="Enter username" class="input-field"/>
      <input v-model="message" placeholder="Enter message" class="input-field"/>
      <button @click="simulateChat" class="add-button">Simulate Vote</button>
      <br>
      <br>
      <input v-model="command" placeholder="Enter message" class="input-field"/>
      <button @click="simulateChatPriv" class="add-button">Simulate Command</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PollView",
  methods: {
    vote(name, option) {
      if (isNaN(option)) {
        return;
      }
      if (option < 1 || option >= this.options.length + 1) {
        return;
      }
      this.votes[name] = option - 1;
    },
    simulateChat() {
      this.vote(this.username, this.message);
    },
    simulateChatPriv() {
      this.runCommand(this.command);
    },
    runCommand(command, name) {
      //command start with !poll second word indicates the command
      // if the command is vote it starts with !vote and you need to run the vote function
      if (command.toLowerCase().startsWith("!vote")) {
        let option = command.split(" ")[1];
        this.vote(name, option);
        return
      }
      if (command.toLowerCase().startsWith("!poll")) {
        let option = command.split(" ")[1];
        let commandOptions = command.split(" ").slice(2).join(" ");
        switch (option) {
          case "hide":
            this.pollShown = false;
            break
          case "new":
            //either an empty poll or a poll with a title and options delimited by |
            if (commandOptions.length > 0) {
              let options = commandOptions.split("|");
              this.pollTitle = options[0];
              this.options = options
                  .slice(1)
                  .map((option) => {
                    return {name: option}
                  });
            } else {
              this.pollTitle = "Poll Title"
              this.options = [];
            }
            this.votes = {};
            this.pollShown = true;
            break
          case
          "title"
          :
            //the title is everything after !poll title
            this.pollTitle = commandOptions;
            break
          case
          "start"
          :
            //start allows everyone to vote. and indicates that the poll is active visually.
            //if the command has a time attached to it, it will end after that time.
            //time can be 1s or 1m or 1h or 1d, also it can be 1m30s or 1h30m.
            // if no notation is given it is assumed to be seconds.
            // if no time is given, just open the poll with no timer.
            this.pollTimer = this.getTimeFromCommand(commandOptions)
            this.pollStartTimestamp = Date.now();
            break
          case
          "end"
          :
            this.pollStartTimestamp = null;
            break
          case
          "add"
          :
            this.options.push({name: commandOptions});
            break
          case
          "remove"
          :
            //the remove command uses the index (1 based) to remove the option.
            //if the index is not given, it does nothing
            this.options.splice(commandOptions - 1, 1);
            break
          case
          "access"
          :
            this.access = this.filterNonRoles(this.splitStringByNonLetters(commandOptions));
            if (this.access.includes("everyone")) {
              this.access = ["mods", "subs", "vips", "followers"];
            } else if (this.access.includes("none")) {
              this.access = [];
            }
            break
          case
          "only"
          :
            this.limit = this.filterNonRoles(this.splitStringByNonLetters(commandOptions));
            if (this.limit.includes("everyone")) {
              this.limit = [];
            } else if (this.limit.includes("none")) {
              this.limit = [];
            }
            break
          case
          "bits"
          :
            //command option can be a number, or a the word off.
            //if it is a number, it is the number of bits per vote.
            //if it is off, it turns off bits.
            if (commandOptions.toLowerCase() === "off") {
              this.bitPerVote = 0;
            } else {
              this.bitPerVote = parseInt(commandOptions, 10);
            }
            break
        }
      }
    },
    filterNonRoles(list) {
      let roles = ["mods", "subs", "vips", "followers", "everyone", "none"]
      let commonMispellings = {
        "mod": "mods",
        "vip": "vips",
        "sub": "subs",
        "subscriber": "subs",
        "subscribers": "subs",
        "follower": "followers",
        "follow": "followers",
        "all": "everyone",
        "noone": "none"
      }
      return list
          .filter((item) => roles.includes(item.toLowerCase()))
          .map((item) => item.toLowerCase())
          .map((item) => commonMispellings[item] || item);
    },
    splitStringByNonLetters(inputString) {
      return inputString.split(/[^a-zA-Z]+/).filter(Boolean);
    },
    getTimeFromCommand(options) {
      if (!isNaN(options)) {
        return options;
      }
      const timePattern = /^(\d+h)?(\d+m)?(\d+s)?$/;
      if (!options.match(timePattern)) {
        return null;
      }
      const hours = parseInt(options.match(/(\d+)h/)?.[1] || 0, 10);
      const minutes = parseInt(options.match(/(\d+)m/)?.[1] || 0, 10);
      const seconds = parseInt(options.match(/(\d+)s/)?.[1] || 0, 10);
      return hours * 60 * 60 + minutes * 60 + seconds;
    },
    formatTime(time) {
      let hours = Math.floor(time / 60 / 60);
      let minutes = Math.floor((time - hours * 60 * 60) / 60);
      let seconds = time - hours * 60 * 60 - minutes * 60;
      return `${hours}:${minutes}:${seconds}`;
    },
    isAllowedToVote(tags) {
      let isBroadcaster = tags.badges?.broadcaster === "1";
      let isMod = tags.mod || tags.badges?.moderator === "1";
      let isFollower = tags.badges?.follower === "1";
      let isSub = tags.badges?.subscriber === "1";
      let isVIP = tags.badges?.vip === "1";

      let isAllowedToVote = false;
      if (this.limit.includes("everyone")) {
        isAllowedToVote = true;
      } else if ((this.limit.includes("mods")) && isMod) {
        isAllowedToVote = true;
      } else if (this.limit.includes("subs") && isSub) {
        isAllowedToVote = true;
      } else if (this.limit.includes("vips") && isVIP) {
        isAllowedToVote = true;
      } else if (this.limit.includes("followers") && isFollower) {
        isAllowedToVote = true;
      } else {
        isAllowedToVote = isBroadcaster;
      }
      return isAllowedToVote;
    },
    isAllowedToAdd(tags, allowMods) {
      let isBroadcaster = tags.badges?.broadcaster === "1";
      let isMod = tags.mod || tags.badges?.moderator === "1";
      let isFollower = tags.badges?.follower === "1";
      let isSub = tags.badges?.subscriber === "1";
      let isVIP = tags.badges?.vip === "1";

      let isAllowedToAdd = false;
      if (this.access.includes("everyone")) {
        isAllowedToAdd = true;
      } else if ((this.access.includes("mods") || allowMods) && isMod) {
        isAllowedToAdd = true;
      } else if (this.access.includes("subs") && isSub) {
        isAllowedToAdd = true;
      } else if (this.access.includes("vips") && isVIP) {
        isAllowedToAdd = true;
      } else if (this.access.includes("followers") && isFollower) {
        isAllowedToAdd = true;
      } else isAllowedToAdd = isBroadcaster;
      return isAllowedToAdd;
    }


  },
  data() {
    return {
      pollShown: false,
      bitPerVote: 0,
      limit: [],
      access: [],
      username: "",
      message: "",
      command: "",
      pollTimer: -1,
      pollStartTimestamp: null,
      pollTitle: "Poll Title",
      //votes is a map from name to option
      votes: {},
      options: [
        {name: "Option 1"},
        {name: "Option 2"},
        {name: "Option 3"},
      ],
      selectedOption: 0,
      countdownString: ""
    };
  },
  mounted() {
    document.title = "Twitch Chat Poll";
    //run some code every half a second

    setInterval(() => {
      if (this.pollStartTimestamp) {
        let timeLeft = this.pollTimer - Math.floor((Date.now() - this.pollStartTimestamp) / 1000);
        if (timeLeft <= 0) {
          this.pollStartTimestamp = null;
        }
        this.countdownString = this.formatTime(timeLeft);
      } else {
        this.countdownString = "";
      }
    }, 500);


    //get channel from query string
    let channel = this.$route.query.channel;
    let allowMods = this.$route.query.mods;
    let background = this.$route.query.background;
    console.log(channel);
    console.log(allowMods);
    console.log(background);

    let tmi = require("tmi.js");
    let client = new tmi.Client({
      options: {debug: true},
      connection: {
        reconnect: true,
        secure: true,
      },
      channels: [channel],
    });
    client.connect();
    client.on("message", (channel, tags, message) => {
      let isAllowedToAdd = this.isAllowedToAdd(tags, allowMods)
      let isAllowedToVote = this.isAllowedToVote(tags, allowMods)
      this.runCommand(message, isAllowedToAdd, isAllowedToVote);
    });
  },
  computed: {
    pollOptions() {
      let totalVotes = 0;
      let optionVotes = new Array(this.options.length).fill(0);
      for (let name in this.votes) {
        let option = this.votes[name];
        optionVotes[option]++;
        totalVotes++;
      }
      let result = [];
      for (let i = 0; i < this.options.length; i++) {
        let option = this.options[i];
        let percentage = totalVotes > 0 ? Math.round((optionVotes[i] / totalVotes) * 100) : 0;
        result.push({name: option.name, percentage: percentage});
      }
      console.log(this.options)
      console.log(this.votes)
      console.log(result)
      return result;
    }
  },

}
</script>

<style scoped>
.poll-container {
  background-color: #1c1c1c;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.poll-title {
  font-size: 24px;
  font-weight: bold;
  color: #00bfff;
  margin-bottom: 16px;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.option-number {
  font-size: 18px;
  font-weight: bold;
  color: #00bfff;
  margin-right: 10px;
}

.option-name {
  font-size: 18px;
  color: #fff;
  flex: 1;
}

.percentage-bar {
  width: 100%;
  height: 10px;
  background-color: #2a2a2a;
  border-radius: 5px;
  margin-top: 6px;
  overflow: hidden;
}

.percentage-fill {
  height: 100%;
  background-color: #00bfff;
}

strong {
  color: #00bfff;
}

.info-section {
  margin-top: 20px;
  color: #fff;
}

.input-section {
  /* Your custom styles for the input section */
}

.input-field {
  /* Your custom styles for input fields */
}

.add-button {
  /* Your custom styles for buttons */
}
</style>


<!--// features-->

<!--// !poll bits off-->
<!--// !poll bits 100-->

<!--// !poll add <option>-->
<!--// !poll remove <number>  (mods)-->

<!--// !poll open all / mods / subs / vips / followers <role>-->
<!--// !poll close all / mods / subs / vips / followers <role>-->

<!--// !poll start <time>-->
<!--// !vote <option>-->

<!--// !poll end-->
<!--// !poll resolve on-->








