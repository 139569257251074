<template>
  <div class="profile-header">
    <div class="profile-pictures">
      <!-- Profile Picture 1 -->
      <img v-if="profilePicture1 !== '' && profilePicture2 === ''"
           :src=profilePicture1
           alt="Profile Picture 1"
           class="twitch-picture"/>
      <div v-if="profilePicture2 !== ''"
           class="gartic-profile-picture-holder">
        <div class="gartic-profile-picture-border"></div>
        <img :src=profilePicture2
             alt="Profile Picture 2"
             class="gartic-profile-picture"/>
      </div>
      <img v-if="profilePicture1 === '' && profilePicture2 === ''"
           :src=profilePicture3
           alt="Profile Picture 1"
           class="circle-less-picture"/>
      <div class="profile-details">
        <!-- Username -->
        <div class="detail-item detail-name">
          <span>{{ username }}</span>
        </div>
        <!-- Followers, Following Since, Fanart Images (in the same row) -->
        <div v-if="pageImages.length > 0"
             class="detail-item horizontal-details">
          <div class="horizontal-detail">
            <label>Total Drawings: </label>
            <span>{{ pageImages.length }}</span>
          </div>
        </div>
      </div>
      <div v-if="search" class="column search-bar">
        <input placeholder="Search..." type="text" @input="queryChanged($event.target.value)">
      </div>
      <div v-if="showToggle" class="toggle-header-social">
        <!--          class based on toggleState for color-->
        Show NSFW
        <button :class="toggleState ? 'toggle-on' : 'toggle-off'"
                type="submit" @click="toggleCallback()">{{ toggleState ? 'Yes' : 'No' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SocialHeader',
  props: {
    circle: {},
    queryChanged: {},
    search: {},
    pageImages: {},
    profilePicture1: {},
    profilePicture2: {},
    profilePicture3: {},
    username: {},
    showToggle: {},
    toggleCallback: {},
    toggleState: {},
  }
}
</script>
<style scoped>

/* Profile header */
.profile-header {
  display: flex;
  align-items: center;
}

/* Profile pictures */
.profile-pictures {
  display: flex;
  gap: 20px;
}

.gartic-profile-picture-holder {
  left: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}

.gartic-profile-picture {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  object-fit: contain;
  margin-inline-start: 1px;
}


.gartic-profile-picture-border {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 98px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}


.twitch-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}

.circle-less-picture {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #fff;
}

/* Short description */
.profile-details {

  flex: 1;
  width: 100px;
  height: 100px;
  display: flex;
  font-size: 10px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 20px;
}

/* Tabs */

/* Tab content */

/* Text color for tab content */
.tab-content p {
  color: #333;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

/* About Me (Short description) */

/* Followers, Following Since, Fanart Images (in the same row) */
.horizontal-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.horizontal-detail {
  text-align: left;
  margin-right: 10px; /* Adjust spacing between items */
}

/* List of tags */
.detail-item ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.detail-item ul li {
  margin-bottom: 5px;
}

.detail-name {
  font-size: 16px;
}

.search-bar input[type="text"] {
  border: 2px solid #000; /* Gray border */
  width: 50%;
  padding: 10px;
  font-size: 16px; /* Adjust the font size as needed */
  border-radius: 5px;
  margin-right: 10px; /* Add some spacing between input and button */
}

/* CSS for the search button */
.search-bar button[type="submit"] {
  background-color: #0099FF; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
}

/* Hover effect for the search button */
.search-bar button[type="submit"]:hover {
  background-color: #0056b3; /* Adjust the hover background color */
}


.search-bar {
  position: absolute;
  top: 20%;
  bottom: 30%;
  padding: 10px;
  width: 100%;
  /* Add your search bar styles here */
}


@media (max-width: 768px) {
  .detail-item {
    font-size: 15px;
    width: auto;
  }

  .horizontal-details {
    width: 4500px;
  }

  .detail-name {
    margin-top: 60%;
    font-size: 20px;
    font-weight: 900;
  }

  .search-bar input[type="text"] {
    border: 2px solid #000; /* Gray border */
    width: 50%;
    padding: 10px;
    font-size: 16px; /* Adjust the font size as needed */
    border-radius: 5px;
    margin-top: 5%;
    margin-right: 10px; /* Add some spacing between input and button */
    margin-inline-start: 10%;
  }
}

.toggle-header-social {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.toggle-header-social button {
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  width: 50px;
  margin-inline-start: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.toggle-header-social button:hover {
  background-color: #2980b9;
}

.toggle-on {
  background-color: #2ecc71;
}

.toggle-off {
  background-color: #e74c3c;
}

/* Add more specific styles as needed */
</style>
