<template>
  <div class="gardle-background">
    <div class="gardle-date">
      Past Games:
      <VueDatePicker v-model="date"
                     :maxDate="new Date()"
                     :minDate="new Date('2024-02-01')"
                     @update:model-value="handleDate"
      ></VueDatePicker>
    </div>
    <h1 class="gartle-title">Gartle {{ test }}</h1>
    <h2 v-if="currentRound <= 5" class="gartle-rounds"> Round {{ currentRound }} of 5</h2>
    <h2 v-if="currentRound <= 5" class="gartle-rounds"> attempts: {{ currentAttempt }} of 5</h2>
    <h2 class="gartle-rounds"> score: {{ score }}</h2>
    <div class="gartle-main">
      <div v-if="currentRound <= 5" class="gartle-filler">

      </div>
      <div v-if="currentRound <= 5" class="gart-images">
        <img v-if="currentRound <= 5" :src=currentImageUrl alt="Game Image" class="gart-image">
        <div v-if="currentRound <= 5" class="gartle-image-row">
          <img v-for="(index) in [1,2,3,4,5]" :key="index" :class="currentAttempt < index ? 'blur' : ''"
               :src="getImageFromData(currentRound - 1, index - 1)"
               @click="viewOtherImage(index - 1)"
          >
        </div>
      </div>
      <div class="gartle-filler">
        Results
        <div v-for="(result,index) in scoreBreakdown" :key="result.name" class="gartle-rounds-result">
          Round {{ index + 1 }}:
          <span v-if="currentRound === index + 1">Current Round ( {{ 6 - currentAttempt }})</span>
          <span v-if="currentRound < index + 1"></span>
          <span v-if="currentRound > index + 1"
                :style="{ color: result.score === 5 ? 'green' : result.score === 0 ? 'red' : 'yellow' }">  {{
              result.name
            }} ({{ result.score }})
          </span>
        </div>
      </div>
    </div>
    <input v-if="currentRound <= 5" v-model="query" class="gartle-input"
           placeholder="Name The Gartist" type="text"
           v-on:keyup.enter="submitResult">
    <div v-for="artist in searchResults" :key="artist.id" @click="submitResultName(artist)">
      {{ artist }}

    </div>
  </div>
</template>

<script>
import imageSourceList from "@/assets/GarticDownloads/gartic.json";

export default {
  name: "GartleView",
  data() {
    return {
      overrideUrl: '',
      date: new Date(),
      scoreBreakdown: [],
      data: [],
      score: 0,
      test: '',
      query: '',
      currentRound: 1,
      currentAttempt: 1,
      namesList: new Set(),
      nameSizeMap: new Map(),
      namesSizeSFWMap: new Map(),
      imageFiles: imageSourceList,
      allImages: [],
    }
  },
  methods: {
    getImageFromData(i, j) {
      try {
        if (this.data.length <= i || !this.data[i].images || this.data[i].images.length <= j) {
          return ''
        }
      } catch (e) {
        return ''
      }
      return this.data[i].images[j]
    },
    viewOtherImage(index) {
      if (this.currentAttempt > index) {
        this.overrideUrl = this.data[this.currentRound - 1].images[index]
      }
    },
    handleDate(date) {
      this.init(date)
    },
    init(date) {

      console.log('init')
      this.prepareData(date)
      const tempScoreBreakdown = localStorage.getItem(this.today(date) + "gartle")
      console.log('tempScoreBreakdown', tempScoreBreakdown)
      if (tempScoreBreakdown) {
        this.scoreBreakdown = JSON.parse(tempScoreBreakdown)
        this.currentRound = this.scoreBreakdown.findIndex((round) => round.score === -1) + 1;
        if (this.currentRound === 0) {
          this.currentRound = 6;
        }
        console.log('currentRound', this.currentRound)
        if (this.currentRound <= 5) {
          this.currentAttempt = this.scoreBreakdown[this.currentRound - 1].attempt + 1;
        }
        console.log('currentAttempt', this.currentAttempt)
      } else {
        this.scoreBreakdown = []
        this.data.forEach((round) => {
          this.scoreBreakdown.push({name: round.name, score: -1, attempt: 0})
        })
        this.currentRound = 1;
        this.currentAttempt = 1;
        this.score = 0;
      }
      this.query = '';
    },

    prepareData(date) {
      const candidates = [];
      for (const test1 of [20, 30, 40, 50, 60]) {
        const subCandidates = []
        for (const name of this.namesList) {
          if (this.nameSizeMap.get(name) > test1 && this.namesSizeSFWMap.get(name) > 5) {
            subCandidates.push(name)
          }
        }
        candidates.push(this.seedShuffleArray(Array.from(subCandidates), this.today(date)))
      }


      let firstFive = []
      candidates.forEach((candidate) => {
        let chosen = candidate[0];
        firstFive.push(chosen)
        for (let candidate1 of candidates) {
          candidate1 = candidate1.filter((name) => name !== chosen)
        }
      })
      // reverse order
      firstFive = firstFive.reverse();



      this.data = firstFive.map((name) => {
        let images = this.allImages.filter((image) => image.name === name)
            .filter((image) => !(!image.moderated || (image.tags && image.tags.includes('nsfw'))))
            .map((image) => image.url);
        images = this.seedShuffleArray(images, this.today(date));
        images = images.slice(0, 5);
        return {name, images}
      })


    },
    submitResultName(name) {
      this.overrideUrl = '';
      const currentCorrectName = this.data[this.currentRound - 1].name.toLowerCase();
      this.scoreBreakdown[this.currentRound - 1].attempt = this.currentAttempt;
      if (name.toLowerCase() === currentCorrectName) {
        this.score = this.score + 6 - this.currentAttempt;
        this.scoreBreakdown[this.currentRound - 1].score = 6 - this.currentAttempt;
        this.currentRound++;
        this.currentAttempt = 1;
      } else {
        this.currentAttempt++;
        if (this.currentAttempt > 5) {
          this.scoreBreakdown[this.currentRound - 1].score = 0;
          this.scoreBreakdown[this.currentRound - 1].attempt = 5;
          this.currentRound++;
          this.currentAttempt = 1;
        }
      }
      this.query = '';
      const key = this.today(this.date) + "gartle"
      const value = JSON.stringify(this.scoreBreakdown);
      console.log(key, value)
      localStorage.setItem(key, value)

    },
    submitResult() {
      this.submitResultName(this.searchResults[0])
    },
    getGarticImages() {
      const allImages = [];
      const imageList = this.imageFiles;
      for (const imageFile of imageList) {
        const require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        allImages.push(...require1)
      }
      const names = new Set();
      const namesSizeMap = new Map();
      const namesSizeSFWMap = new Map();
      for (const image of allImages) {
        names.add(image.name)
        if (!(!image.moderated || (image.tags && image.tags.includes('nsfw')))) {
          if (namesSizeSFWMap.has(image.name)) {
            namesSizeSFWMap.set(image.name, namesSizeSFWMap.get(image.name) + 1)
          } else {
            namesSizeSFWMap.set(image.name, 1)
          }
        }
        if (namesSizeMap.has(image.name)) {
          namesSizeMap.set(image.name, namesSizeMap.get(image.name) + 1)
        } else {
          namesSizeMap.set(image.name, 1)
        }
      }
      this.nameSizeMap = namesSizeMap;
      this.namesSizeSFWMap = namesSizeSFWMap;
      this.namesList = names;
      this.allImages = allImages;

    },
    hashString(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
      }
      return hash;
    },


    seedShuffleArray(array, seed) {
      seed = this.hashString(seed);


      const shuffledArray = [...array];
      let currentIndex = array.length;
      let randomIndex, temporaryValue;

      // Use a custom random function with the seed
      const customRandom = () => {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
      };

      while (currentIndex !== 0) {
        // Pick a remaining element
        randomIndex = Math.floor(customRandom() * currentIndex);
        currentIndex--;

        // Swap it with the current element
        temporaryValue = shuffledArray[currentIndex];
        shuffledArray[currentIndex] = shuffledArray[randomIndex];
        shuffledArray[randomIndex] = temporaryValue;
      }
      return shuffledArray;
    },
    today(date) {

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return year + '-' + month + '-' + day;
      // return '2020-12-12'

    },
    smartContain(fullString, subString) {
      fullString = fullString.toLowerCase();
      subString = subString.toLowerCase();
      //   do all the constter in subString appear in fullString in order?
      let subIndex = 0;
      for (const constter of fullString) {
        if (constter === subString[subIndex]) {
          subIndex++;
        }
        if (subIndex === subString.length) {
          return true;
        }
      }
      return false;


    }
  },
  computed: {
    currentImageUrl() {
      if (this.overrideUrl !== '') return this.overrideUrl;
      if (this.currentRound > 5) return '';
      if (this.currentAttempt > 5) return '';
      if (!this.data[this.currentRound - 1]) return '';
      return this.data[this.currentRound - 1].images[this.currentAttempt - 1]
    },
    searchResults() {
      //   find all names that contain the query (case insensitive)
      // blank query returns nothing
      if (this.query === '') return [];
      const results = [];
      for (const name of this.namesList) {
        if (this.smartContain(name, this.query)) {
          results.push(name)
        }
      }

      const splitResults = []

      for (const result of results) {
        splitResults.push(result)
      }
      return splitResults.slice(0, 3)
    }
  },
  mounted() {

    this.date = new Date();
    this.getGarticImages()
    this.init(this.date)

  }
}


</script>

<style scoped>

.gardle-background {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(200deg, rgba(92, 30, 166, 1) 0%, rgba(200, 67, 94, 1) 100%);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  font-family: 'Inter', sans-serif;
  color: white;
  align-items: center;
}


.gart-images {

}

.gart-image {
  border: 7px solid black;
  border-radius: 10px;
}


.gartle-filler {
  width: 27vw;
  height: 100%;
}


.gartle-title {
  margin-bottom: 0;
  font-size: 60px;
  font-family: 'Inter', sans-serif;
  color: white;
}

.gartle-rounds {
  margin-top: 0;
  font-size: 30px;
  font-family: 'Inter', sans-serif;
  color: white;
}

.gartle-input {
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: black;
}

.gartle-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


}

.gartle-rounds-result {
  display: flex;
  flex-direction: row;
  align-items: start;
  font-size: 30px;
}

.gardle-date {
  position: absolute;
  top: 0;
  left: 0;
  width: 15vw;
}

.gartle-image-row {
  display: flex;
  flex-direction: row;
  width: 35vw;
  border-radius: 10px;
}

.gartle-image-row img {
  width: 20%;
  margin: 10px;
}

.blur {
  filter: blur(50px);
}
</style>
