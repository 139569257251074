<template>
  <div class="gallery-screen-saver text">
    <div class="img-holder" id="dvd-image-holder"
         :style="{  transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg)` }">
      <img class="dvd-image" id="dvd-image" :src="imageSrc"
           crossorigin="anonymous"
           alt="image"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
// loco https://i.imgur.com/qFy8cV8.png

// brb https://i.imgur.com/yDED2Y1.png

export default {
  name: "DVDLogoView",
  data() {
    return {
      logLine: "",
      imageSrc: "https://i.imgur.com/pIshH8T.png",
      position: {x: 0, y: 0},
      speed: 3, // in pixels per second
      direction: {x: 1, y: 1}, // either 1 or -1 for x and y
      rotation: 0,
      imageData: null
    }
  },
  methods: {

    colorizeImage() {
      const img = document.getElementById("dvd-image");
      const randomPastel = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        const avg = (r + g + b) / 3;
        const scale = 255 / avg;
        const newR = Math.min(Math.floor(r * scale), 255);
        const newG = Math.min(Math.floor(g * scale), 255);
        const newB = Math.min(Math.floor(b * scale), 255);
        return "#" + newR.toString(16) + newG.toString(16) + newB.toString(16);
      };
      const hexColor = randomPastel()
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      //resize so it's contained in teh image and drawImage

      canvas.width = img.clientWidth;
      canvas.height = img.clientHeight;
      ctx.drawImage(img, 0, 0, img.clientWidth, img.clientHeight);

      const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imgData.data;
      console.log(hexColor)
      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] > 10) { // Check if alpha is less than 255
          data[i] = parseInt(hexColor.substring(1, 3), 16);
          data[i + 1] = parseInt(hexColor.substring(3, 5), 16);
          data[i + 2] = parseInt(hexColor.substring(5), 16);
        }
      }
      ctx.putImageData(imgData, 0, 0);
      img.src = canvas.toDataURL();
    },
    startAnimation() {
      setInterval(() => {
        this.updatePosition();
      }, 16);
    },
    updatePosition() {

      //get image size by id
      const img = document.getElementById("dvd-image");
      const dvdWidth = img.clientWidth;
      const dvdHeight = img.clientHeight;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const width = screenW - dvdWidth;
      const height = screenH - dvdHeight;
      const {x, y} = this.position;
      const {speed, direction} = this;

      const newX = x + speed * direction.x;
      const newY = y + speed * direction.y;


      if (newX < 0) {
        this.direction.x = 1;
        this.colorizeImage()
      } else if (newX > width) {
        this.direction.x = -1;
        this.colorizeImage()
      } else if (newY < 0) {
        this.direction.y = 1;
        this.colorizeImage()
      } else if (newY > height) {
        this.direction.y = -1;
        this.colorizeImage()
      }

      this.position.x = newX;
      this.position.y = newY;
    }
  },
  mounted() {
    document.title = "DVD Logo";
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('image')) {
      this.imageSrc = urlParams.get('image');
    }
    if (urlParams.has('height')) {
      const height = urlParams.get('height');
      const img = document.getElementById("dvd-image");
      img.style.height = `${height}vh`;
    } else {
      const img = document.getElementById("dvd-image");
      img.style.height = `15vh`;
    }


    this.position.x = Math.floor(Math.random() * 1020);
    this.position.y = Math.floor(Math.random() * 200);
    this.direction.x = Math.floor(Math.random() * 2) === 0 ? 1 : -1;
    this.direction.y = Math.floor(Math.random() * 2) === 0 ? 1 : -1;
    this.startAnimation();
  }
}
</script>

<style scoped>
.gallery-screen-saver {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}


.img-holder {

  position: absolute;
  object-fit: cover;
}

.img-holder img {
  height: 15vh;
  max-width: 10vw;
  max-height: 10vh;
  object-fit: contain;
}

.text {
  font-size: 2em;
  font-weight: bold;
  color: white;
  text-shadow: 0 0 10px black;
}

.img-holder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

</style>
