<template>
  <div class="home-container">
    <h1 id="header"

    >sillyv.art/gallery</h1>
    <img alt="cover image" class="cover-image" src="@/views/social/image_union.png">


    <img id="silly-best-images" :alt="sillyBest"
         :src="sillyBestCurrentUrl" class="gallery-highlight">
    <p id="silly-best-text">SillyV</p>

    <img id="today-highlights-images" :alt="todayHighlightsCurrentUrl"
         :src="todayHighlightsCurrentUrl" class="gallery-highlight">
    <p id="today-highlights-text">{{ this.todayHighlightsCurrentName }}</p>

    <img id="latest-streams-images" :alt="latestStreamsCurrentUrl"
         :src="latestStreamsCurrentUrl" class="gallery-highlight">
    <p id="latest-streams-text">{{ this.latestStreamsCurrentName }}</p>

    <img id="gartic-avatar-images" :alt="garticAvatarsCurrentUrl"
         :src="garticAvatarsCurrentUrl" class="gartic-avatar-highlight">
    <p id="gartic-avatar-text">{{ this.garticAvatarsCurrentName }}</p>
    <img id="layout-avatars-images" :alt="layoutAvatarsCurrentUrl"
         :src="layoutAvatarsCurrentUrl" class="gartic-avatar-highlight">
    <p id="layout-avatars-text">{{ this.layoutAvatarsCurrentName }}</p>


    <img id="badge" alt="cover image" src="@/views/social/badge.png">
    <img id="gem" alt="cover image" src="@/views/social/gem.png">
  </div>
</template>

<!--suppress JSUnresolvedFunction -->
<script>
import AOS from "aos";
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import layoutSourceList from "@/assets/webImageSourceList.json";
import {logAnalyticsEvent} from "@/firebase/init";

export default {
  name: "SocialHomeView",
  data() {
    // noinspection SpellCheckingInspection
    return {
      currentMonth: this.getCurrentMonth(),
      layoutFiles: layoutSourceList,
      expanded: [],
      latestStreamsIndex: 0,
      allStreams: [],
      latestStreams: [],
      sillyBestCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      sillyBestIndex: 0,
      sillyBest: [],
      latestStreamsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      latestStreamsCurrentName: "",
      todayHighlightsIndex: 0,
      todayHighlights: [],
      todayHighlightsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      todayHighlightsCurrentName: "",
      garticAvatarsIndex: 0,
      garticAvatars: [],
      garticAvatarsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      garticAvatarsCurrentName: "",
      layoutAvatarsIndex: 0,
      layoutAvatars: [],
      layoutAvatarsCurrentUrl: "https://media.discordapp.net/attachments/985315170609922048/1173241846714077224/accidantey-avatar.png",
      layoutAvatarsCurrentName: "",
      artistsToImagesMap: {},
      currentMonthArtistsToImagesMap: {},
      datesToImagesMap: {},
      allImages: [],
      imageFiles: imageSourceList,
    };
  },
  methods: {
    defineBestOfSilly() {
      let bestImages = this.allImages
          .filter((item) => 'name' in item && item.name.toLowerCase() === "sillyv")
          .filter((item) => item.moderated)
          .filter((item) => item.tags && item.tags.includes('best'))
          .filter((item) => !item.tags.includes('nsfw'))
      bestImages = this.shuffleArrayWithSeed(bestImages)
      if (bestImages.length === 0) {
        return
      }
      this.sillyBest = bestImages
      this.sillyBestCurrentUrl = [this.sillyBest[0].url]
      setInterval(() => {
        this.sillyBestIndex += 1
        if (this.sillyBestIndex >= this.sillyBest.length) {
          this.sillyBestIndex = 0
        }
        this.sillyBestCurrentUrl = [this.sillyBest[this.sillyBestIndex].url]
      }, 15000)


    },

    //   shuffle bestImages, and use today as a random seed
    getCurrentMonth() {
      let date = new Date()
      console.log(date)
      return date.toLocaleString('default', {month: 'long'})

    },
    startCurrentYear() {
      //    dont use currentDateYYYYMMDDInGMT2 method,
      //   just return the current calendar year, at 4 digits
      const date = new Date();
      console.log(date)
      console.log(date.getFullYear())
      return date.getFullYear();

    },
    startCurrentMonth() {
      //    dont use currentDateYYYYMMDDInGMT2 method,
      //   just return the current calendar month, as a three letter string
      const date = new Date();
      return date.toLocaleString('default', {month: 'short'})
    },
    currentDateYYYYMMDDInGMT2() {
      const date = new Date();
      date.setUTCHours(date.getUTCHours() + 2);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    },
    getGarticImages() {

      AOS.init()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }
      this.allImages.forEach((item) => {
        // don't add sillyv images
        if (item.name === 'sillyv') {
          return
        }
        // if no name, return
        if (!item.name) {
          return
        }
        if (this.artistsToImagesMap[item.name]) {
          this.artistsToImagesMap[item.name].push(item)
        } else {
          this.artistsToImagesMap[item.name] = [item]
        }
      });


      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // January is 0, so we add 1

      const filteredItems = this.allImages
          .filter(item => item.date)
          .filter(item => name in item && item.name.toLowerCase() !== "sillyv")
          .filter(item => {
            const [year, month] = item.date.split('-').map(Number);
            return year === currentYear && month === currentMonth;
          });

      const nameCount = {};
      filteredItems.forEach(item => {
        if (nameCount[item.name]) {
          nameCount[item.name]++;
        } else {
          nameCount[item.name] = 1;
        }
      });
      const nameCountArray = Object.entries(nameCount).map(([name, count]) => ({name, count}));
      nameCountArray.sort((a, b) => b.count - a.count);
      const topNames = nameCountArray.slice(0, 10);
      topNames.forEach(item => {
        console.log(`${item.name}: ${item.count}`);
      });
      this.currentMonthDrawers = topNames.map((item) => {
        return {name: item.name, count: item.count}
      })
    },
    selectTab(tab) {
      logAnalyticsEvent('Gallery Tab Changed', {tab: tab, type: 'galleryHome'})
      this.activeTab = tab;
      console.log(this.activeTab)
    },
    defineLatestStreams: function () {
      this.allImages
          .filter((item) => !item.tags || !item.tags.includes('nsfw'))
          .forEach((item) => {
            // don't add sillyv images

            // if no name, return
            if (!item.date) {
              return
            }
            if (this.datesToImagesMap[item.date]) {
              this.datesToImagesMap[item.date].push(item)
            } else {
              this.datesToImagesMap[item.date] = [item]
            }
            //   get all date keys.

          });
      let dateKeys = Object.keys(this.datesToImagesMap)

      dateKeys.sort((a, b) => {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateB - dateA
      })


      //   get 3 latest dates
      let latestDates = dateKeys.slice(0, 3)
      this.latestStreams = []
      for (let date of latestDates) {
        this.latestStreams.push(...this.datesToImagesMap[date])
      }
      this.latestStreams.sort(() => Math.random() - 0.5);

      this.latestStreamsCurrentUrl = [this.latestStreams[0].url]
      this.latestStreamsCurrentName = this.latestStreams[0].name.split(' ')[0]

      //make latestStreamIndex a random number between 0 and latest Streams.length
      //   do an operation once every 60 seconds
      setInterval(() => {
        this.latestStreamsIndex += 1
        if (this.latestStreamsIndex >= this.latestStreams.length) {
          this.latestStreamsIndex = 0
        }
        this.latestStreamsCurrentUrl = [this.latestStreams[this.latestStreamsIndex].url]
        this.latestStreamsCurrentName = this.latestStreams[this.latestStreamsIndex].name.split(' ')[0]
      }, 9000)
    },
    shuffleArrayWithSeed(array) {
      let shuffledArr = [...array];
      for (let i = shuffledArr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
      }
      return shuffledArr;

    },
    getGarticAvatar() {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText);
          json = Object.keys(json)
              .map((key) => {
                return {name: key, url: json[key]}
              })
          context.garticAvatars = json
          context.garticAvatars.sort(() => Math.random() - 0.5);
          // remove any gartic avatar that has the name 'amarisskye'
          context.garticAvatars = context.garticAvatars.filter((item) => item.name.toLowerCase() !== 'amarisskye')
          context.garticAvatarsCurrentUrl = [context.garticAvatars[0].url]
          context.garticAvatarsCurrentName = context.garticAvatars[0].name.split(' ')[0]
          setInterval(() => {
            context.garticAvatarsIndex += 1
            if (context.garticAvatarsIndex >= context.garticAvatars.length) {
              context.garticAvatarsIndex = 0
            }
            context.garticAvatarsCurrentUrl = [context.garticAvatars[context.garticAvatarsIndex].url]
            context.garticAvatarsCurrentName = context.garticAvatars[context.garticAvatarsIndex].name.split(' ')[0]
          }, 13000)

        }
      };
      request.send();
    },
    getLayoutAvatars() {

      let keys = Object.keys(this.layoutFiles)
      let arrays = keys.map((key) => {
        return this.layoutFiles[key].map((item) => {
          return {name: key, url: item.url}
        })
      })

      this.layoutAvatars = [].concat.apply([], arrays)
      // shuffle layout avatars
      this.layoutAvatars.sort(() => Math.random() - 0.5);
      this.layoutAvatarsCurrentUrl = [this.layoutAvatars[1].url]
      let name = this.layoutAvatars[1].name.split(' ')[0];
      let avatar_name = this.layoutAvatars[1].url.split('/').pop().split('.')[0];
      this.layoutAvatarsCurrentName = name + " - " + avatar_name
      setInterval(() => {
        this.layoutAvatarsIndex += 1
        if (this.layoutAvatarsIndex >= this.layoutAvatars.length) {
          this.layoutAvatarsIndex = 0
        }
        this.layoutAvatarsCurrentUrl = [this.layoutAvatars[this.layoutAvatarsIndex].url]

        let avatar_name = this.layoutAvatars[this.layoutAvatarsIndex].url.split('/').pop().split('.')[0];
        let name = this.layoutAvatars[this.layoutAvatarsIndex].name.split(' ')[0]
        this.layoutAvatarsCurrentName = name + " - " + avatar_name
      }, 17000)
    },
    defineDailyHighlights() {

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Note: Month is zero-based
      const startOfMonth = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

      // Calculate the start date of the previous calendar month
      const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;
      const startOfPreviousMonth = `${previousYear}-${previousMonth.toString().padStart(2, '0')}`;

      let bestImages = this.allImages
          .filter((item) => item.moderated)
          .filter((item) => item.tags && item.tags.includes('best'))
          .filter((item) => !item.tags.includes('nsfw'))
          .filter((item) => item.date && !(item.date.startsWith(startOfMonth) || item.date.startsWith(startOfPreviousMonth)))
      // filter images so that it contains only one entry per item.name
      let seen = new Set();
      bestImages = bestImages.filter(el => {
        const duplicate = seen.has(el.name);
        seen.add(el.name);
        return !duplicate;
      });

      bestImages = this.shuffleArrayWithSeed(bestImages, this.currentDateYYYYMMDDInGMT2())

      //   shuffle bestImages, and use today as a random seed


      this.todayHighlights = bestImages.slice(0, 3)
      this.todayHighlightsCurrentUrl = [this.todayHighlights[0].url]
      this.todayHighlightsCurrentName = this.todayHighlights[0].name.split(' ')[0]
      setInterval(() => {
        this.todayHighlightsIndex += 1
        if (this.todayHighlightsIndex >= this.todayHighlights.length) {
          this.todayHighlightsIndex = 0
        }
        this.todayHighlightsCurrentUrl = [this.todayHighlights[this.todayHighlightsIndex].url]
        this.todayHighlightsCurrentName = this.todayHighlights[this.todayHighlightsIndex].name.split(' ')[0]
      }, 11000)

    }
  }
  ,
  mounted() {

    document.title = "Gartic Gallery"
    this.getGarticImages()
    this.getGarticAvatar()
    this.getLayoutAvatars()
    this.defineLatestStreams();
    this.defineDailyHighlights()
    this.defineBestOfSilly()

  }

}
</script>

<style scoped>


.search-bar input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Adjust the font size as needed */
  border: none;
  border-radius: 5px;
  margin-right: 10px; /* Add some spacing between input and button */
}

/* CSS for the search button */
.search-bar button[type="submit"] {
  background-color: #0099FF; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
}

/* Hover effect for the search button */
.search-bar button[type="submit"]:hover {
  background-color: #0056b3; /* Adjust the hover background color */
}


/* CSS for the top 10 list container */

/* Header styling */
.top-10-list h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* List item styling */
.top-10-list ul {
  list-style-type: none;
  padding: 0;
}

.top-10-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

* {
  font-family: 'Inter', sans-serif;
}

.image-card img {
  width: 100%;
  height: auto;
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.image-content img {
  max-width: 100%;
  height: auto;
}

.home-container {
  display: flex;
  flex-direction: column;
}

.gallery-highlight {

  object-fit: cover;
  border: 1px solid #444;
  width: 90%;
  aspect-ratio: 758/424;
}

.gallery-highlight:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.gartic-avatar-highlight {
  object-fit: contain;
  aspect-ratio: 758/424;
  width: 900px;

}

.gartic-avatar-highlight:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.extension-icon img {
  width: 64px; /* Adjust the width as needed */
  height: 64px; /* Adjust the height as needed */
}

.artist-card img {
  max-width: 100%;
  aspect-ratio: 241/134;
  height: auto;
  border-radius: 8px;
}

.alphabet-navigation ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

.alphabet-navigation li {
  cursor: pointer;
  font-size: 18px;
  color: #333; /* Color for alphabet letters */
}


@media (max-width: 768px) {

  .home-container {
    width: 100vw;
    overflow: hidden;
  }

  .gallery-highlight {

    object-fit: cover;
    border: 1px solid #444;
    width: 90%;
    aspect-ratio: 758/424;
  }

  h3 {
    font-size: 14px;
  }

  .alphabet-navigation ul {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    padding: 0;
  }

  .alphabet-navigation li {
    flex: 1;
    /* Add styles for the <li> elements */
  }


}


.just-some select {
  border: none;
  font-size: 16px;
}


h1 {
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
}

/*center horizontally within parent*/
#header {
  color: #bdaae9;
  position: absolute;
  font-size: 75px;
  top: 1.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#latest-streams-images {
  position: absolute;
  bottom: 5%;
  left: 3.7%;
  aspect-ratio: 758/424;
  width: 400px;
}

#latest-streams-text {
  position: absolute;
  bottom: -2%;
  left: 3.7%;
  width: 400px;
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
  font-size: 30px;
  color: #bdaae9;
}

#layout-avatars-images {
  position: absolute;
  bottom: 7.5%;
  right: -1%;
  width: 537px;
  object-fit: contain;
}

#gartic-avatar-images {
  position: absolute;
  top: 11.7%;
  aspect-ratio: 305/262;
  right: 8%;
  width: 380px;
}

#today-highlights-images {
  position: absolute;
  top: 44%;
  left: 3.7%;
  aspect-ratio: 758/424;
  width: 400px;
}

#today-highlights-text {
  position: absolute;
  bottom: 26%;
  left: 3.7%;
  width: 400px;
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
  font-size: 30px;
  color: #bdaae9;
}

#silly-best-images {
  position: absolute;
  top: 16%;
  left: 3.7%;
  aspect-ratio: 758/424;
  width: 400px;
}

#silly-best-text {
  position: absolute;
  top: 36%;
  left: 3.7%;
  width: 400px;
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
  font-size: 30px;
  color: #bdaae9;
}


#layout-avatars-text {
  position: absolute;
  bottom: -2%;
  right: 6%;
  width: 400px;
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
  font-size: 30px;
  color: #bdaae9;
}

#gartic-avatar-text {
  position: absolute;
  top: 45%;
  right: 6%;
  width: 400px;
  font-family: Inter, serif;
  /*text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;*/
  text-shadow: #2F2A39FF 4px 4px 4px, #2F2A39FF -4px -4px 4px, #2F2A39FF 4px -4px 4px, #2F2A39FF -4px 4px 4px;
  font-size: 30px;
  color: #bdaae9;
}

#badge {
  position: absolute;
  bottom: 0;
  left: 39%;
  width: 130px;
  height: 130px;
}

#gem {
  position: absolute;
  top: 61%;
  left: 39%;
  width: 130px;
}

.gallery-highlight {
  border-radius: 18px;
}
</style>
