<template>
  <div>
    <div class="image-container"
         v-if="isImageVisible" :class="{ 'slide-in': isImageVisible , 'slide-out': isImageHiding }">
      <img :src="currentImage.url" alt="Your Image" class="image"/>
      <div class="gradient"></div>
      <h4 class="bottom-text">{{ currentImage.name }}</h4>

    </div>
  </div>
</template>


<!--{"badge-info":{"subscriber":"25"},"badges":{"broadcaster":"1","subscriber":"3018","glhf-pledge":"1"},"color":"#8A2BE2","custom-reward-id":"442f9c33-b016-4a40-95c5-5d10fee181df","display-name":"SillyV","emotes":null,"first-msg":false,"flags":null,"id":"51948e0d-765c-48d4-ad21-e0c28c84d563","mod":false,"returning-chatter":false,"room-id":"57813188","subscriber":true,"tmi-sent-ts":"1697463185802","turbo":false,"user-id":"57813188","user-type":null,"emotes-raw":null,"badge-info-raw":"subscriber/25","badges-raw":"broadcaster/1,subscriber/3018,glhf-pledge/1","username":"sillyv","message-type":"chat"}-->
<script>
import imageSourceList from "@/assets/GarticDownloads/gartic.json";

export default {
  name: "PreviewView",
  data() {
    return {
      isImageVisible: false,
      isImageHiding: false,
      queue: [],
      imageFiles: imageSourceList,
      allImages: [],
      currentImage: '',
      currentName: '',
      log: ''
    };
  },
  mounted() {
    this.getGarticImages()
    //   listen to twitch chat
    let tmi = require('tmi.js');
    let client = new tmi.Client({
      connection: {
        secure: true,
        reconnect: true
      },
      channels: ['sillyv']
    });
    client.connect();
    client.on('message', (channel, tags, message) => {
      if (tags['custom-reward-id'] === '442f9c33-b016-4a40-95c5-5d10fee181df') {
        this.getRandomImage(tags.username, message)
      }
    })
  },
  methods: {
    addToQueue(img) {
      this.queue.push(img);
      this.showImage()
    },
    showImage() {
      console.log('showing image')
      if (this.isImageVisible) return; // If image is already visible, do nothing
      this.currentImage = this.queue[0]
      console.log(this.currentImage)
      this.isImageVisible = true;
      setTimeout(() => {
        this.hideImage();
      }, 10000);
    },
    hideImage() {
      this.isImageVisible = false;
      // Remove the first item from the queue
      this.queue.shift();
      if (this.queue.length > 0) {
        // If the queue is not empty, show the next image
        // get the first item from the queue
        setTimeout(() => {
          this.showImage();
        }, 2000)

      }
    },
    getRandomImage(name, msg) {

      if (msg.toLowerCase() === 'random') {
        this.addToQueue(this.allImages[Math.floor(Math.random() * this.allImages.length)])
        return
      }
      let imagesToTakeIntoAccount = this.allImages.filter((item) => item.name.toLowerCase() === msg.toLowerCase())

      if (imagesToTakeIntoAccount.length === 0) {
        imagesToTakeIntoAccount = this.allImages.filter((item) => item.name.toLowerCase().includes(msg.toLowerCase()))
      }

      if (imagesToTakeIntoAccount.length === 0) {
        imagesToTakeIntoAccount = this.allImages.filter((item) => item.name.toLowerCase() === name.toLowerCase())
      }

      if (imagesToTakeIntoAccount.length === 0) {
        imagesToTakeIntoAccount = this.allImages
      }

      let bestImages = imagesToTakeIntoAccount.filter((item) => item.tags && item.tags.includes('best'))

      imagesToTakeIntoAccount.push(...bestImages)
      imagesToTakeIntoAccount.push(...bestImages)
      imagesToTakeIntoAccount.push(...bestImages)


      let randomImage = imagesToTakeIntoAccount[Math.floor(Math.random() * imagesToTakeIntoAccount.length)];

      this.addToQueue(randomImage)
    }
    ,
    getGarticImages() {
      // noinspection JSUnresolvedFunction
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }

      // eslint-disable-next-line no-undef
      const {compare} = Intl.Collator('en-US');
      // noinspection SpellCheckingInspection
      const aliases = {
        silly: "sillyv",
        maryagneshiiitop: "maryagneshilltop",
        defnotpetri: "petrigor",
      };
      this.allImages.forEach((item) => {
        const alias = aliases[item.name];
        if (alias) {
          item.name = alias
        }
      });

      this.allImages = this.allImages.filter((item) => item.moderated)
      let tagsToExclude = ['slices', 'style', 'outfit', 'complement', 'delete', 'comic', 'nsfw']

      this.allImages = this.allImages.filter((item) => {
        if ('tags' in item) {
          for (let tag of tagsToExclude) {
            if (item.tags.includes(tag)) {
              return false
            }
          }
        }
        return true
      })
      this.allImages
          .sort((a, b) => compare(a.name, b.name))
      this.allImages = this.allImages.filter((item) => 'url' in item)
    }
  }
}
</script>

<style scoped>
.image {
  position: absolute;
  top: 0;
  left: 0;
}

.image-container {
  display: block;
  width: 758px;
  height: 424px;
  position: relative;
  object-fit: contain;
}

.slide-in {
  animation: slideIn 3s, slideOut 2s 8s;
}


@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 00) 50%);
}

.bottom-text {
  color: white;
  height: auto;
  width: 100%;
  text-align: start;
  margin-inline-start: 20px;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
  position: absolute;
  font-size: 50px;
  bottom: 0;
  left: 0;
}
</style>
