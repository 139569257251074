<template>
  <div class="social-page">
    <SocialHeader :page-images="pageImages" :profile-picture1="profilePicture1" :profile-picture2="profilePicture2"
                  :show-toggle="true" :toggle-callback="toggleCallback" :toggle-state="toggleState" :username="username"

    />
    <!--suppress JSValidateTypes -->
    <SocialTabs :active-tab="activeTab" :layout-images="layoutImages" :oc-images="ocImages" :page-images="pageImages"
                :select-tab="selectTab" :tab-names="tabs"
                :tag-images="tagImages"/>
    <div v-if="page !== 'gartic' && page !== 'layout'" class="tab-content">
      <div v-if="activeTab === 'GARTIC'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="loadedPageImages" :showNSFW="toggleState"/>
      </div>
      <div v-if="activeTab === 'FANART'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="loadedTagImages" :showNSFW="toggleState"
        />
      </div>
      <div v-if="activeTab === 'LAYOUT'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="layoutImages"/>
      </div>
      <div v-if="activeTab === 'OC'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="ocImages"/>
      </div>
    </div>
    <div v-if="page === 'gartic' || page === 'layout'" class="tab-content">
      <div v-if="activeTab === 'LAYOUT AVATARS'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="layoutImages"/>
      </div>
      <div v-if="activeTab === 'GARTIC AVATARS'">
        <SocialImageGrid :imageClicked="imageClicked" :page-images="garticAvatars"/>
      </div>
    </div>
    <!--    absolute positioning fullscreen div for image-->
    <div v-if="fullScreenImage !== ''" class="fullscreen-image-container">
      <div class="fullscreen-image-button-container">
        <div class="full-screen-x-button webdings" @click="imageCleared">r</div>
        <div class="full-screen-x-button" @click="copyUrlToClipboard">
          <img alt="Copy to Clipboard" class="copy-to-clipboard-icon" src="./chain.png"/>
        </div>
      </div>
      <div class="fullscreen-image-border">
        <img :src="fullScreenImage" alt="Image" class="fullscreen-image"/>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import layoutSourceList from "@/assets/webImageSourceList.json";
import ocDirectorySourceList from "@/assets/ocDirectory.json";
import SocialHeader from "@/views/social/SocialHeader.vue";
import SocialTabs from "@/views/social/SocialTabs.vue";
import SocialImageGrid from "@/views/social/SocialImageGrid.vue";

import {logAnalyticsEvent} from "@/firebase/init";

// question: how do i subscribe to changes in the current url in vue3
// detailed answer: you can use the watch method to watch for changes in the $route object
// here is an example:
// watch: {

// }


// todo download button
// todo share buttons,  facebook, twitter, instagram, copy to clipboard


// todo reverse default sort order
// todo add sorting buttons.
// todo add click functionality to sort buttons
//

export default {
  components: {SocialImageGrid, SocialTabs, SocialHeader},
  watch: {
    '$route'() {
      // noinspection JSUnresolvedVariable
      let imageId = this.$route.params.imageId;
      if (!imageId) {
        this.fullScreenImage = ''
      }
    }
  },
  data() {
    return {
      fullScreenImage: '',
      page: '',
      imageFiles: imageSourceList,
      layoutFiles: layoutSourceList,
      ocFiles: ocDirectorySourceList,
      pageImages: [],
      tagImages: [],
      allImages: [],
      ocImages: [],
      garticAvatars: [],
      loadedPageImages: [],
      loadedTagImages: [],
      layoutImages: [],
      followers: 4000,
      followingSince: '2021-06-01',
      fanartImages: 100,
      username: '',
      profilePicture1: '',
      profilePicture2: '',
      shortDescription: 'Twitch Profile About me text',
      activeTab: 'gartic', // Default active tab
      toggleState: false,
      tab3Images: [
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/300',
        'https://picsum.photos/200/300',
        // Add more image URLs for Tab 3 as needed
      ],
    };
  },
  methods: {
    toggleCallback() {
      this.toggleState = !this.toggleState
    },
    handleBackButton(event) {
      event.preventDefault()

      if (this.fullScreenImage !== '') {
        this.fullScreenImage = ''
      } else {
        history.pushState(null, null, window.location.href);
      }
    },
    downloadImage() {
      logAnalyticsEvent('Gallery Image Downloaded', {image: this.fullScreenImage, type: 'gallery'})
      try {
        let link = document.createElement('a');
        link.href = this.fullScreenImage;
        link.target = '_blank'; // Open link in a new tab
        link.rel = 'noopener noreferrer'; // Security measure for opening in a new tab
        document.body.appendChild(link);

        // Use a HEAD request to check if Content-Disposition header is set
        fetch(this.fullScreenImage, {method: 'HEAD'})
            .then(response => {
              if (response.headers.get('Content-Disposition')) {
                // If Content-Disposition header is present, use it for download
                link.download = '';
              } else {
                // Otherwise, use the image filename for download
                link.download = this.fullScreenImage.split('/').pop();
              }

              // Initiating the download
              link.click();
              document.body.removeChild(link);
            })
            .catch(error => {
              console.error('Error during image download:', error);
              // Handle the error or provide user feedback as needed
            });
      } catch (error) {
        console.error('Error during image download:', error);
        // Handle the error or provide user feedback as needed
      }
    },
    copyUrlToClipboard() {
      logAnalyticsEvent('Gallery Image URL Copied', {image: this.fullScreenImage, type: 'gallery'})
      let url = window.location.href;
      navigator.clipboard.writeText(url)
    },
    imageCleared() {
      this.fullScreenImage = ''
      window.history.pushState({}, '', '/gallery/' + this.page)
    },
    imageClicked(image) {
      logAnalyticsEvent('Gallery Image Clicked', {image: image, type: 'gallery'})
      this.fullScreenImage = image
      window.history.pushState({}, '', '/gallery/' + this.page + '/' + image.split('/').pop())
    },
    checkScroll() {
      const scrollY = window.scrollY; // Current vertical scroll position
      const windowHeight = window.innerHeight; // Viewport height
      const documentHeight = document.documentElement.scrollHeight; // Total document height

      console.log("scrollY: " + scrollY)
      console.log("windowHeight: " + windowHeight)
      console.log("documentHeight: " + documentHeight)
      console.log("scrollY + windowHeight: " + (scrollY + windowHeight))


      // Check if you have reached the bottom
      if (scrollY + windowHeight >= documentHeight - 100) {
        //   check which tab is active


        if (this.activeTab === 'GARTIC') {
          this.loadedPageImages = this.pageImages.slice(0, this.loadedPageImages.length + 20)
        } else if (this.activeTab === 'FANART') {
          this.loadedTagImages = this.tagImages.slice(0, this.loadedTagImages.length + 20)
        }
      }
    },
    getGarticImages(page) {
      // noinspection JSUnresolvedFunction
      AOS.init()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }

      // eslint-disable-next-line no-undef
      const {compare} = Intl.Collator('en-US');
      // noinspection SpellCheckingInspection
      const aliases = {
        silly: "sillyv",
        maryagneshiiitop: "maryagneshilltop",
        defnotpetri: "petrigor",
      };
      this.allImages.forEach((item) => {
        const alias = aliases[item.name];
        if (alias) {
          item.name = alias
        }
      });
      this.allImages
          .sort((a, b) => compare(a.name, b.name))
      // eslint-disable-next-line
      this.allImages = this.allImages.filter((item) => item.hasOwnProperty('url'))
      ;
      this.pageImages = this.allImages
          .filter((item) => item.name.toLowerCase() === page.toLowerCase())
          .reverse()

      this.loadedPageImages = this.pageImages.slice(0, 20)

      this.tagImages = this.allImages
          // eslint-disable-next-line
          .filter((item) => item.hasOwnProperty('tags'))
          .filter((item) => item.tags.includes(page.toLowerCase()))
          .filter((item) => item.name.toLowerCase() !== page.toLowerCase())
          .reverse()
      this.loadedTagImages = this.tagImages.slice(0, 20)
    },
    getTwitchPfp(page) {
      let url = "https://decapi.me/twitch/avatar/" + page
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.profilePicture1 = request.responseText
        }
      };
      request.send();
    },
    getGarticAvatar(page) {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText)
          // eslint-disable-next-line no-prototype-builtins
          if (page === 'layout' || page === 'gartic') {
            context.garticAvatars = Object.values(json)
          }
          // eslint-disable-next-line
          if (json.hasOwnProperty(page))
            context.profilePicture2 = json[page]
        }
      };
      request.send();
    },
    selectTab(tab) {
      console.log(tab)
      logAnalyticsEvent('Gallery Tab Changed', {tab: tab, type: 'gallery'})
      this.activeTab = tab;
    },
    getLayoutAvatars(page) {

      if (this.page === 'layout' || this.page === 'gartic') {
        let keys = Object.keys(this.layoutFiles)
        let arrays = keys.map((key) => {
          return this.layoutFiles[key].map((item) => {
            return {name: key, url: item.url, "moderated": true}
          })
        })
        this.layoutImages = [].concat.apply([], arrays).map((item) => item.url)
        // eslint-disable-next-line
      } else if (this.layoutFiles.hasOwnProperty(page)) {
        this.layoutImages = this.layoutFiles[page].map((item) => item.url)
      } else {
        this.layoutImages = []
      }
    },
    getOcReferences(page) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.ocFiles.hasOwnProperty(page)) {
        this.ocImages = this.ocFiles[page]
      } else {
        this.ocImages = []
      }
    }
  },
  created() {
    console.log("created")
    window.addEventListener('scroll', this.checkScroll);
  },
  unmounted() {
    // Remove the scroll event listener when the component is destroyed to prevent memory leaks
    window.removeEventListener('scroll', this.checkScroll);
  },
  beforeUnmount() {
    // Remove the event listener when the component is about to be unmounted
    window.removeEventListener('handleBeforeUnload', this.handleBackButton);
  },
  mounted() {

    document.title = "Gallery"
    window.addEventListener('handleBeforeUnload', this.handleBackButton);
    let names_to_exclude = ['noreisa', 'itskafedean']
    if (names_to_exclude.includes(this.$route.params.page.toLowerCase())) {
      return
    }
    // noinspection JSUnresolvedVariable
    const page = this.$route.params.page.toLowerCase();
    // noinspection JSUnresolvedVariable


    this.page = page
    logAnalyticsEvent('Gallery Opened', {page: page});
    this.username = page
    this.getTwitchPfp(page)
    this.getGarticAvatar(page)
    this.getGarticImages(page)
    this.getLayoutAvatars(page)
    this.getOcReferences(page)

    // if image is within pageImages, tagImages, layoutImages, or ocImages, set it as the fullscreen image
    // to be within the list,  the filename attribute needs to equal the imageId,  but all the spaces are replaced with a plus sign

    let imageId = this.$route.params.imageId;
    if (imageId) {
      imageId = imageId.toLowerCase();
      let imageIdWithSpaces = imageId.replace(/\+/g, ' ')
      if (this.pageImages.map((item) => item.filename).includes(imageIdWithSpaces)) {
        this.fullScreenImage = this.pageImages.filter((item) => item.filename === imageIdWithSpaces)[0].url
      } else if (this.tagImages.map((item) => item.filename).includes(imageIdWithSpaces)) {
        this.fullScreenImage = this.tagImages.filter((item) => item.filename === imageIdWithSpaces)[0].url
      } else if (this.layoutImages.map((item) => item.filename).includes(imageIdWithSpaces)) {
        this.fullScreenImage = this.layoutImages.filter((item) => item.filename === imageIdWithSpaces)[0].url
      } else if (this.ocImages.map((item) => item.filename).includes(imageIdWithSpaces)) {
        this.fullScreenImage = this.ocImages.filter((item) => item.filename === imageIdWithSpaces)[0].url
      }
    }
    if (this.page === 'layout') {
      this.activeTab = 'LAYOUT AVATARS'
    } else if (this.page === 'gartic') {
      this.activeTab = 'GARTIC AVATARS'
    } else if (this.pageImages.length > 0) {
      this.activeTab = 'GARTIC'
    } else if (this.tagImages.length > 0) {
      this.activeTab = 'FANART'
    } else if (this.layoutImages.length > 0) {
      this.activeTab = 'LAYOUT'
    } else if (this.ocImages.length > 0) {
      this.activeTab = 'OC'
    } else {
      this.activeTab = 'GARTIC'
    }
  },
  computed: {


    tabs() {
      if (this.page === 'layout' || this.page === 'gartic') {
        return ['LAYOUT AVATARS', 'GARTIC AVATARS']
      }
      let tabs = []
      //   ['GARTIC', 'FANART', 'LAYOUT', 'OC']
      if (this.pageImages.length > 0) {
        tabs.push('GARTIC')
      }
      if (this.tagImages.length > 0) {
        tabs.push('FANART')
      }
      if (this.layoutImages.length > 0) {
        tabs.push('LAYOUT')
      }
      if (this.ocImages.length > 0) {
        tabs.push('OC')
      }

      return tabs


    }
  }

};
</script>


<style scoped>

.social-page {
  background-color: #fff;
  padding: 20px;
  position: absolute;
  right: 20%;
  left: 20%;
  width: 60vw;
}


/* Tab content */
.tab-content {
  margin-top: 20px;
}

/* Text color for tab content */
.tab-content p {
  color: #333;
}

/* List of tags */
.detail-item ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.detail-item ul li {
  margin-bottom: 5px;
}


/* Add more specific styles as needed */
.fullscreen-image {
  border: 15px solid #ffffffcc;
  border-radius: 30px;
  position: absolute;
  top: 5vh;
  left: 5vw;
  right: 5vw;
  bottom: 5vh;
  max-width: 85vw;
  width: 85vw;
  max-height: 90vh;
  object-fit: contain;
}

.fullscreen-image-container {
  background-color: #00000090;
  border: 1px solid black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}


@media (max-width: 768px) {

  .social-page {
    background-color: #fff;
    padding: 20px;
    position: absolute;
    right: 10%;
    left: 0;
    overflow: hidden;
    width: 90vw;
  }

  social-tabs {
    width: 35vw;
  }

}

.fullscreen-image-button-container {
  position: absolute;
  top: 0;
  right: 2%;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.full-screen-x-button {
  font-size: 30px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.full-screen-x-button img {
  width: 30px;
  height: 30px;
}

.webdings {
  font-family: "Webdings",serif;
}

</style>
