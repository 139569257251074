<template>
  {{ imageList.length }}
  <div class="image-grid">
    <div v-for="(group, groupName) in groupedImages" :key="groupName" class="group-container">
      <h2>{{ groupName }}</h2>
      <div class="image-row">
        <div class="card" v-for="image in group" :key="image.url">
          <img :src="image.url" class="card-img-top" @click="handleImageClick(image)" alt="image">
          <div class="card-body">
            <div class="tags">
              <span class="tag float-right tag-time" v-if="image.filename.split('.')[0].split(' ')[2] && image.filename.split('.')[0].split(' ')[2].split('_')[0] <= 15">
                {{
                  image.filename.split('.')[0].split(' ')[2].split('_',2).join(":")
                }}              </span>
              <span class="tag float-right tag-time-2" v-if="image.filename.split('.')[0].split(' ')[2] && image.filename.split('.')[0].split(' ')[2].split('_')[0] > 15">
                {{
                  image.filename.split('.')[0].split(' ')[2].split('_',2).join(":")
                }}              </span>
              <span v-for="tag in image.tags" :key="tag" class="tag float-right"
                    @click="handleImageTagClick(image, tag)">
              {{ tag }}
            </span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  props: ['imageList', 'excludedTags', 'selectedTags'],
  methods: {
    handleImageClick(image) {
      this.$emit('imageClick', image)
    },
    handleImageTagClick(image, tag) {
      this.$emit('imageTagClick', image, tag)
    }
  },
  mounted() {
    this.selectedImageList = [...this.imageList]
  },
  computed: {
    groupedImages() {
      const groups = {}
      for (const image of this.imageList) {
        if (image.moderated) {
          continue
        }
        const groupName = image.name
        if (groups[groupName]) {
          groups[groupName].push(image)
        } else {
          groups[groupName] = [image]
        }
      }

      return groups
    }
  }

  ,
  watch: {
    imageList(newList) {
      if (this.previousImageList !== null && this.previousImageList !== newList) {
        window.scrollTo(0, 0)
      }
      this.previousImageList = [...newList]
    }
  }
};
</script>
<style>

/*image grid should have 3 columns*/
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}


.group-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* horizontal offset, vertical offset, blur radius, color */
  transition: box-shadow 0.3s ease; /* add a smooth transition */
  margin-bottom: 1rem;
  margin-right: 1rem;

}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* increase shadow size on hover */
}


.card-img-top {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-body {
  padding: 0.5rem;
}

.tags {
  text-align: right;
}

.tag {
  display: inline-block;
  margin: 4px;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #ddd;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tag:hover {
  background-color: #999;
  color: #fff;
}

.image-row {
  display: grid;
  font-size: 0;
  grid-template-columns: 25% 25% 25% 25%;

}

.tag-time {
  background-color: #c0ffee;
}

.tag-time-2 {
  background-color: #decaff;
}
</style>
