<template>
  <div class="sliders">
    <h2>Configure Emote Grid</h2>
    <label for="rows">Rows: {{ rows }}</label>
    <input id="rows" v-model="rows" max="15" min="1" type="range" @input="onInnerChange"/>
    <label for="columns">Columns: {{ columns }}</label>
    <input id="columns" v-model="columns" max="15" min="1" type="range" @input="onInnerChange"/>
    <label for="squareAspectRatio">Square Aspect Ratio:</label>
    <div class="checkbox-wrapper-8">
      <input id="cb3-8" v-model="squareAspectRatio" class="tgl tgl-skewed" type="checkbox" @change="onSliderChange"
             @input="onInnerChange"/>
      <label class="tgl-btn" data-tg-off="OFF" data-tg-on="ON" for="cb3-8"></label>
    </div>

    <label v-if="!squareAspectRatio" for="outerVerticalMargin">Outer Vertical Margin: <span>{{
        outerVerticalMargin
      }}</span> pixels</label>
    <label v-if="squareAspectRatio" for="outerVerticalMargin">Outer Margin: <span>{{ outerVerticalMargin }}</span>
      pixels</label>
    <input id="outerVerticalMargin" v-model="outerVerticalMargin" max="300" min="0" type="range"
           @change="onSliderChange" @input="onInnerChange"
    />
    <label v-if="!squareAspectRatio" for="outerHorizontalMargin">Outer Horizontal Margin:
      <span>{{ outerHorizontalMargin }}</span> pixels</label>
    <input v-if="!squareAspectRatio" id="outerHorizontalMargin" v-model="outerHorizontalMargin" max="300" min="0"
           type="range"
           @input="onInnerChange"/>

    <label v-if="!squareAspectRatio" for="innerVerticalMargin">Inner Vertical Margin: <span>{{
        innerVerticalMargin
      }}</span> pixels</label>
    <label v-if="squareAspectRatio" for="innerVerticalMargin">Inner Margin: <span>{{ innerVerticalMargin }}</span>
      pixels</label>
    <input id="innerVerticalMargin" v-model="innerVerticalMargin" max="300" min="0" type="range"
           @change="onSliderChange"
           @input="onInnerChange"
    />
    <label v-if="!squareAspectRatio" for="innerHorizontalMargin">Inner Horizontal Margin:
      <span>{{ innerHorizontalMargin }}</span> pixels</label>
    <input v-if="!squareAspectRatio" id="innerHorizontalMargin" v-model="innerHorizontalMargin" max="300" min="0"
           type="range"
           @input="onInnerChange"/>

    <label v-if="!squareAspectRatio" for="horizontalSizeCell">Horizontal Size Cell: {{ horizontalSizeCell }}
      pixels</label>
    <label v-if="squareAspectRatio" for="horizontalSizeCell">Cell Size: {{ horizontalSizeCell }} pixels</label>
    <input id="horizontalSizeCell" v-model="horizontalSizeCell" max="700" min="10" type="range"
           @input="onInnerChange"/>
    <label v-if="!squareAspectRatio" for="verticalSizeCell">Vertical Size Cell: {{ verticalSizeCell }} pixels</label>
    <input v-if="!squareAspectRatio" id="verticalSizeCell" v-model="verticalSizeCell" max="700" min="10"
           type="range" @input="onInnerChange"/>
    <div style="display: flex; flex-direction: row; justify-content: space-evenly; width: 100%">
      <div class="swatch">
        <input id="color" v-model="marginColor" name="color" type="color" @input="onInnerChange">
        <div class="info">
          <h1>Margin</h1>
        </div>
      </div>
      <div class="swatch">
        <input id="color" v-model="cellColor" name="color" type="color" @input="onInnerChange">
        <div class="info">
          <h1>Cell</h1>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmoteTemplateSliders',
  props: {
    currentTemplate: {},
    changeValue: {}
  },
  data() {
    return {
      rows: this.currentTemplate.rows,
      columns: this.currentTemplate.columns,
      squareAspectRatio: this.currentTemplate.squareAspectRatio,
      outerVerticalMargin: this.currentTemplate.outerVerticalMargin,
      innerVerticalMargin: this.currentTemplate.innerVerticalMargin,
      outerHorizontalMargin: this.currentTemplate.outerHorizontalMargin,
      innerHorizontalMargin: this.currentTemplate.innerHorizontalMargin,
      horizontalSizeCell: this.currentTemplate.horizontalSizeCell,
      verticalSizeCell: this.currentTemplate.verticalSizeCell,
      marginColor: this.currentTemplate.marginColor,
      cellColor: this.currentTemplate.cellColor,
    }
  },
  methods: {
    onSliderChange() {
      if (this.squareAspectRatio) {
        this.outerHorizontalMargin = this.outerVerticalMargin
        this.innerHorizontalMargin = this.innerVerticalMargin
        this.verticalSizeCell = this.horizontalSizeCell
        this.onInnerChange()
      }
    },
    onInnerChange() {
      this.changeValue({
        rows: this.rows,
        columns: this.columns,
        squareAspectRatio: this.squareAspectRatio,
        outerVerticalMargin: this.outerVerticalMargin,
        innerVerticalMargin: this.innerVerticalMargin,
        outerHorizontalMargin: this.outerHorizontalMargin,
        innerHorizontalMargin: this.innerHorizontalMargin,
        horizontalSizeCell: this.horizontalSizeCell,
        verticalSizeCell: this.verticalSizeCell,
        marginColor: this.marginColor,
        cellColor: this.cellColor
      })
    }
  }
}
</script>
<style scoped>

* {
  font-family: Inter;
}


.sliders {
  text-align: center;
  margin-inline-start: 48px;
  background: whitesmoke;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.sliders h2 {
  width: 100%;
}

.sliders label {
  margin-left: 20px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.sliders span {
  font-weight: 900;
}


label[for=range] {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 49px;
  padding-top: 6px;
  width: 350px;
  padding-left: 13px;
  overflow: hidden;
  padding-bottom: 10px;
}

label[for=range]:after {
  content: "";
  position: absolute;
  bottom: 11px;
  left: 69px;
  height: 9px;
  width: 278px;
  box-shadow: 0px 3px 10px -3px rgba(0, 0, 0, 0.51);
}

input[type=range] {
  -webkit-appearance: none;
  background-color: transparent;
  width: 300px;
  height: 38px;
  padding-top: 10px;
  overflow: hidden;
  margin: 0;
  margin-left: 20px;
  transform-style: preserve-3d;
  perspective: 300;
  transform-origin: 50% 50% 300px;
  perspective-origin: 50% -121%;
}

input[type=range]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;
  width: 30px;
  height: 18px;
  box-shadow: 1px 5px 10px -1px rgba(0, 0, 0, 0.2),
  -25px 0 0 10px rgba(90, 184, 6, 0.5),
  -75px 0 0 10px rgba(90, 184, 6, 0.5),
  -125px 0 0 10px rgba(90, 184, 6, 0.5),
  -175px 0 0 10px rgba(90, 184, 6, 0.5),
  -225px 0 0 10px rgba(90, 184, 6, 0.5),
  -275px 0 0 10px rgba(90, 184, 6, 0.5),
  -325px 0 0 10px rgba(90, 184, 6, 0.5);
  z-index: 2;
}

input[type="range"]::-webkit-slider-thumb:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: -285px;
  top: -28px;
  width: 300px;
  height: 140px;
  background: rgba(90, 184, 6, 0.5);
  transform: rotateX(90deg);
  transform-origin: 0 0px 0;
  transform: rotateX(90deg) translateY(-140px) translateZ(-18px);
}

input[type="range"]::-webkit-slider-thumb:before {
  content: "< >";
  font-family: 'Inter', cursive;
  position: absolute;
  background: #eaebe5;
  background: -moz-linear-gradient(top, #eaebe5 0%, #dcdedd 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eaebe5), color-stop(100%, #dcdedd));
  background: -webkit-linear-gradient(top, #eaebe5 0%, #dcdedd 100%);
  background: -o-linear-gradient(top, #eaebe5 0%, #dcdedd 100%);
  background: -ms-linear-gradient(top, #eaebe5 0%, #dcdedd 100%);
  background: linear-gradient(to bottom, #eaebe5 0%, #dcdedd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaebe5', endColorstr='#dcdedd', GradientType=0);
  top: -2px;
  left: 0px;
  border-radius: 2px;
  color: #5a5a5a;
  text-shadow: 0 1px 0 white;
  height: 22px;
  width: 32px;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-sizing: border-box;
  text-align: center;
  line-height: 19px;
  font-size: 17px;
}

input[type="range"]::-webkit-slider-runnable-track:before {
  content: "";
  position: absolute;
  height: 38px;
  width: 283px;
  background: #e8e8e8;
  background: -moz-linear-gradient(top, #dfdfdf 0%, #d8d8d8 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dfdfdf), color-stop(100%, #d8d8d8));
  background: -webkit-linear-gradient(top, #dfdfdf 0%, #d8d8d8 100%);
  background: -o-linear-gradient(top, #dfdfdf 0%, #d8d8d8 100%);
  background: -ms-linear-gradient(top, #dfdfdf 0%, #d8d8d8 100%);
  background: linear-gradient(to bottom, #dfdfdf 0%, #d8d8d8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#d8d8d8', GradientType=0);
  bottom: 0;
  left: 0;
}

input[type="range"]::-webkit-slider-runnable-track:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 270px;
  background: rgb(247, 247, 247);
  top: 0;
  right: 26px;
  transform: skew(62deg);
}

input[type=range]:before {
  content: "";
  position: absolute;
  background: rgb(190, 190, 190);
  box-shadow: 0 1px 0 rgb(235, 235, 235);
  width: 283px;
  left: 0;
  height: 1px;
  top: 29px;
  z-index: 1;
}

input[type=range]:after {
  content: "";
  position: absolute;
  background: rgb(190, 190, 190);
  width: 7px;
  left: 3px;
  border-radius: 50%;
  height: 6px;
  top: 26px;
  z-index: 1;
  box-shadow: 30px 0 0 rgb(190, 190, 190),
  60px 0 0 rgb(190, 190, 190),
  90px 0 0 rgb(190, 190, 190),
  120px 0 0 rgb(190, 190, 190),
  150px 0 0 rgb(190, 190, 190),
  180px 0 0 rgb(190, 190, 190),
  210px 0 0 rgb(190, 190, 190),
  240px 0 0 rgb(190, 190, 190),
  270px 0 0 rgb(190, 190, 190),
  60px 1px 0 rgb(235, 235, 235),
  90px 1px 0 rgb(235, 235, 235),
  120px 1px 0 rgb(255, 255, 255),
  150px 1px 0 rgb(235, 235, 235),
  180px 1px 0 rgb(235, 235, 235),
  210px 1px 0 rgb(235, 235, 235),
  240px 1px 0 rgb(235, 235, 235),
  270px 1px 0 rgb(235, 235, 235);
}


.checkbox-wrapper-8 .tgl {
  display: none;
}

.checkbox-wrapper-8 .tgl,
.checkbox-wrapper-8 .tgl:after,
.checkbox-wrapper-8 .tgl:before,
.checkbox-wrapper-8 .tgl *,
.checkbox-wrapper-8 .tgl *:after,
.checkbox-wrapper-8 .tgl *:before,
.checkbox-wrapper-8 .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper-8 .tgl::-moz-selection,
.checkbox-wrapper-8 .tgl:after::-moz-selection,
.checkbox-wrapper-8 .tgl:before::-moz-selection,
.checkbox-wrapper-8 .tgl *::-moz-selection,
.checkbox-wrapper-8 .tgl *:after::-moz-selection,
.checkbox-wrapper-8 .tgl *:before::-moz-selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-8 .tgl::selection,
.checkbox-wrapper-8 .tgl:after::selection,
.checkbox-wrapper-8 .tgl:before::selection,
.checkbox-wrapper-8 .tgl *::selection,
.checkbox-wrapper-8 .tgl *:after::selection,
.checkbox-wrapper-8 .tgl *:before::selection,
.checkbox-wrapper-8 .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after,
.checkbox-wrapper-8 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper-8 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  font-family: Inter;
  background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after,
.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active {
  background: #888;
}

.checkbox-wrapper-8 .tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn {
  background: rgba(90, 184, 6, 0.5);
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper-8 .tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

.checkbox-wrapper-8 {
  margin-bottom: 10px;
}

.swatch {
  margin-top: 20px;
  margin-bottom: 20px;
  background: white;
  display: flex;
  flex-direction: column;
}

.swatch .info {
  padding: 1em;
}

.swatch h1 {
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 5em;
  padding: 0;
  width: 10em;
}

*:focus {
  border-radius: 0;
  outline: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

</style>
