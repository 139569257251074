<template>
  <div class="divider"/>
  <div class="profile-tabs">
    <div v-for="(tabName, index) in tabNames" :key="index" class="tab" @click="selectTab(tabName)"
         :class=" {'active': activeTab === tabName }">
      {{ tabName }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SocialTabs',
  props: {
    tabNames: [],
    activeTab: {},
    selectTab: {},
  }
}
</script>
<style scoped>

/* Profile header */

/* Profile pictures */


/* Short description */

/* Tabs */
.profile-tabs {
  justify-content: center;
  display: flex;
  gap: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  color: #5e5e5e;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab.active {
  color: #000000;
  /*  border only on top of width 2px*/
  border-top: 2px solid #333;
  background-color: #fff;
}

/* Tab content */

/* Text color for tab content */
.tab-content p {
  color: #333;
}

/* About Me (Short description) */

/* Followers, Following Since, Fanart Images (in the same row) */

/* List of tags */
.detail-item ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.detail-item ul li {
  margin-bottom: 5px;
}

.divider {
  margin-top: 5%;
  width: 100%;
  height: 1px;
  background-color: #4b4b4b;
}

@media (max-width: 768px) {
  .profile-tabs {
    font-size: 12px;
    gap: 0;
    margin-inline-end: 20px;
  }

  .divider {
    width: 95%;
  }
}


</style>
