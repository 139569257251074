<template>
  <div style="width: 100px; height: 100px; background-color: white;"></div>
</template>

<script>
import soundFile from '@/assets/champagne.mp3';

export default {
  name: 'TwitchChatListener',
  mounted() {
    document.title = "Twitch Chat Listener - Audio Alerts";
    const sound = new Audio(soundFile);
    let channelQueryParam = this.$route.query.channel;
    let usernames = this.$route.query.usernames.toLowerCase().split(',');
    let tmi = require('tmi.js');
    let client = new tmi.Client({
      connection: {
        secure: true,
        reconnect: true
      },
      channels: [channelQueryParam]
    });
    client.connect();
    client.on('message', (channel, tags) => {
          let username = tags.username
          if (usernames.includes(username.toLowerCase())) {
            sound.volume = 1;
            sound.play();
          }
        }
    );
  }
}

</script>

<style scoped>
/* Add your custom styles here */
</style>
