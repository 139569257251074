<template>
  <div class="gallery-container">
    <div class="sticky-buttons">
      <GalleryHeader :selectedDate="selectedDate" :dates="dates" @date-selected="onDateSelected"/>
      <button @click="downloadSelectedImagesAsJson">Download Selected Images as JSON</button>
      <StickyTags :dates="dates" v-model="selectedDate" :tags="uniqueTags" :selectedTags="selectedTags"
                  @update:selectedTags="selectedTags = $event" @tagClick="handleTagClick" @tagChanged="tagsChanged"


      />

    </div>


    <ImageGrid :imageList="selectedImageList" :excluded-tags="excludeKeys" :selected-tags="selectedTags"
               @imageClick="handleImageClick" @imageTagClick="handleImageTagClick"/>

  </div>
</template>

<script>
import StickyTags from '@/views/gallery/StickyTags.vue'
import GalleryHeader from '@/views/gallery/GalleryHeader.vue'
import ImageGrid from '@/views/gallery/ImageGrid.vue'


export default {
  name: 'SortedView',
  computed: {
    computedSelectedTags() {
      return this.selectedTags;
    }
  },

  components: {
    StickyTags,
    GalleryHeader,
    ImageGrid,
  },
  data() {
    return {
      excludeKeys: ['date', 'deleteHash', 'filename', 'url', 'name', 'tags'],
      dates: [],
      selectedDate: '',
      imageList: [],
      modalVisible: false,
      modalImage: '',
      modalAlt: '',
      uniqueTags: [],
      selectedImageList: [],
      selectedTags: [],
      knownTags: []
    }
  },
  mounted() {
    document.title = "Gartic Gallery - Tag Sorter";
    this.loadDirectory()
    //   listen to keydown
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    //   remove keydown listener
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    tagsChanged(tags) {
      this.knownTags = tags;
    },
    handleKeyDown(event) {
      //   check if event key is a letter
      if (event.key.match(/[a-z]/i)) {

        // find all knownTags taht start with the same letter

        let tags = this.knownTags.filter(tag => tag.toLowerCase().startsWith(event.key.toLowerCase()))
        // if tags is empty. do nothing and return
        if (tags.length === 0) {
          return
        }

        console.log(event.key)
        // if more than 1 tag is selected, remove all selected tags
        if (this.selectedTags.length === 1) {
          //   if selected tag starts with a different letter,  remove all tags
          if (!this.selectedTags[0].toLowerCase().startsWith(event.key.toLowerCase())) {
            this.selectedTags = []
          } else {
            //   find the tag in tags that is after the current selected, or the first one if the current selected is the last one
            const index = tags.findIndex(tag => tag === this.selectedTags[0])
            const nextIndex = index === tags.length - 1 ? 0 : index + 1
            this.selectedTags = [tags[nextIndex]]
          }
        }


        if (this.selectedTags.length > 1) {
          this.selectedTags = []
        }
        if (this.selectedTags.length === 0) {
          const tag = this.knownTags.find(tag => tag.toLowerCase().startsWith(event.key.toLowerCase()))
          if (tag) {
            this.selectedTags.push(tag)
          }
        }
      }
    },
    downloadSelectedImagesAsJson() {
      const selectedImageListWithEmptyObject = [...this.selectedImageList, {}];
      let copy = JSON.parse(JSON.stringify(selectedImageListWithEmptyObject));
      copy.forEach(obj => {
        // check if object has keys
        if (Object.keys(obj).length > 0) {
          obj.moderated = true;
        }
      });
      const jsonData = JSON.stringify(copy);
      const fileName = `${this.selectedDate}.json`;

      const blob = new Blob([jsonData], {type: 'application/json'});
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;

      link.click();

      // Clean up the URL object after the download is complete
      URL.revokeObjectURL(url);
    },
    isSelected(tag) {
      return this.selectedTags.includes(tag)
    },
    handleTagClick(tag) {
      if (this.isSelected(tag)) {
        const index = this.selectedTags.indexOf(tag)
        if (index > -1) {
          this.selectedTags.splice(index, 1)
        }
      } else {
        this.selectedTags.push(tag)
      }
    },
    handleImageClick(image) {

      // Add the selected tags to the image object
      const hasAllSelectedTags = this.selectedTags.every(tag => image.tags.includes(tag));
      const updatedTags = hasAllSelectedTags ? image.tags.filter(tag => !this.selectedTags.includes(tag)) : [...new Set([...image.tags, ...this.selectedTags])];
      const updatedImage = {...image, tags: updatedTags};
      // Find the index of the updated image in the selectedImageList
      const index = this.selectedImageList.findIndex(img => img.url === updatedImage.url)
      // If the updated image is not already in the selectedImageList, add it
      if (index === -1) {
        this.selectedImageList.push(updatedImage)
      } else { // Otherwise, replace the existing image with the updated version
        this.selectedImageList.splice(index, 1, updatedImage)
      }
    },


    handleImageTagClick(image, tag) {
      const updatedTags = image.tags.filter(t => t !== tag);
      const updatedImage = {...image, tags: updatedTags};
      const index = this.selectedImageList.findIndex(img => img.url === updatedImage.url)
      if (index === -1) {
        this.selectedImageList.push(updatedImage)
      } else {
        this.selectedImageList.splice(index, 1, updatedImage)
      }
    },


    loadDirectory() {
      const directory = require('@/assets/GarticDownloads/gartic.json')
      const validFiles = []
      for (const date of directory) {
        const file = require(`@/assets/GarticDownloads/${date}.json`)
        let allModerated = true
        if (window.location.hostname === "localhost") {
          // check if any of the images have moderated set to false
          allModerated = !file.some(item => !item.moderated)
          if (!allModerated) {
            validFiles.push(date)
          }
        } else {
          validFiles.push(date)
        }
      }
      this.dates = validFiles.sort((a, b) => b.localeCompare(a)) // Sort filenames in reverse alphabetical order
      this.selectedDate = this.dates[0]
    },
    loadImageList() {
      this.imageList = require(`@/assets/GarticDownloads/${this.selectedDate}.json`)
      const allKeys = [...new Set(this.imageList.flatMap(item => item.tags).filter(tag => tag !== undefined))];
      this.uniqueTags = allKeys.filter(key => !this.excludeKeys.includes(key));
      this.imageList
          .filter(obj => Object.keys(obj).length !== 0)
          .forEach(image => {
            if (image.tags) {
              image.tags = image.tags.filter(key => !this.excludeKeys.includes(key));
            } else {
              image.tags = []
            }
          });
      this.selectedImageList = this.imageList.filter(obj => Object.keys(obj).length !== 0).filter(image => image.name)
      this.selectedImageList.sort((a, b) => a.name.localeCompare(b.name))
    },
    onDateSelected(date) {
      this.selectedDate = date
    },
    openModal(src) {
      this.modalVisible = true
      this.modalImage = src
      this.modalAlt = 'Image'
    },
    closeModal() {
      this.modalVisible = false
      this.modalImage = ''
      this.modalAlt = ''
    }
  },
  watch: {
    selectedDate() {
      this.loadImageList()
    }
  }
}
</script>


<style>
.gallery-container {
  max-width: 800px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

.modal img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  cursor: pointer;
}

.sticky-buttons {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-direction: column;
}

</style>
