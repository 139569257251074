<template>
  <div :id="'expandable-button' + title"
       :class="['option-holder']" @click=itemClicked>
    <p class="expandable-button-title">{{ titleUsername }}</p>
    <div v-if="state === 'expanded'">
      <!--      add input and ok button-->
      <input class="expandable-button-input"
             v-model="twitchUsername"
             type="text" placeholder="Twitch Username">
      <br>
      <button class="expandable-button-button">OK</button>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpandableButton",
  props: [
    'title', 'options', 'expandedCallback', 'itemClickedCallback'
  ],
  data() {
    return {
      state: 'collapsed',
      selected: '',
      twitchUsername: ''

    }
  },
  mounted() {
    this.twitchUsername = localStorage.getItem('twitchUsername')
    this.itemClickedCallback(this.title, this.twitchUsername)
  },
  methods: {
    collapse() {
      console.log('collapse')
      this.state = 'collapsed'
    },
    itemClicked(event) {
      if (event.target.tagName !== 'INPUT') {
        if (event.target.tagName === 'BUTTON') {
          this.itemClickedCallback(this.title, this.selected)
          // save twitchUsername to localStorage
          localStorage.setItem('twitchUsername', this.twitchUsername)
          this.state = 'collapsed'
        } else if (this.state === 'expanded') {
          this.state = 'collapsed'
        } else {
          this.state = 'expanded'
        }
      }
    },
    itemSelected(title, option) {
      console.log('picked option', option)
      this.selected = option
      this.itemClickedCallback(title, option)
    }
  },
  computed: {
    titleUsername() {
      if (this.state !== 'collapsed') {
        return "Twitch Integration"
      } else {
        if (this.twitchUsername === null || this.twitchUsername === '') {
          return "Twitch Integration"
        } else {
          return this.twitchUsername
        }
      }
    }
  }
}
</script>

<style scoped>


.option-holder {
  border-radius: 54px;
  border: 3px solid rgb(118, 104, 163);
  background: linear-gradient(180deg, #7D6ED9 0%, #B0A3FF 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 242px;
  height: fit-content;
  flex-shrink: 0;
  display: flex; /* Center-align content horizontally and vertically */
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center-align horizontally */
  justify-content: center; /* Center-align vertically */
}


.option-holder:hover {
  background: linear-gradient(180deg, #7D6ED9 0%, #715fde 100%);
  cursor: pointer;
}

.expandable-button-title {
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expandable-button-option {
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.expandable-button-option:hover {
  font-weight: bold;
}

.enabled {
  background: linear-gradient(180deg, #D2D2D2 0%, #9E9E9E 100%);
}

.enabled:hover {
  background: linear-gradient(180deg, #D2D2D2 0%, #9E9E9E 100%);
}

.expandable-button-input {
  width: 207px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #8072DB;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align horizontally */
  color: #FFF;
  text-align: center;
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.expandable-button-input::placeholder {
  color: white;
  opacity: .5;
}

.expandable-button-button {
  display: flex;
  width: 90%;
  margin-inline-start: 5%;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align text horizontally */
  flex-shrink: 0;
  text-align: center;
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  background: transparent;
  font-weight: 400;
  line-height: normal;
  border: 0 solid #000; /* Add a border for visibility, customize as needed */
  color: #fff; /* Set text color to a suitable color */
  padding: 10px 20px; /* Adjust padding as needed */
  cursor: pointer;

  color: #FFF;
  text-align: center;
  font-family: Jura;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

</style>
