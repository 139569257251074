<template>
  <div class="base-container">
    <label class="goal-label" for="goal">DIFFICULTY CLASS</label>
    <input class="goal-input" type="number" id="goal" v-model="goalResult"/>
    <div class="adv-button-holder">
      <button v-bind:class="(selectedAdvantage === 1)?'adv-button-selected':'adv-button'" @click="advantageClick">
        Advantage
      </button>
      <button v-bind:class="(selectedAdvantage === -1)?'adv-button-selected':'adv-button'" @click="disadvantageClick">
        Disadvantage
      </button>
    </div>
    <div class="modifiers-holder row">
      <odds-item class="modifiers-item" item-type="input" title="Modifiers"/>
      <odds-item class="modifiers-item" item-type="die" v-for="(dieRow, index) in diceRows" :key="index"
                 :title="('d' + dieRow.size)" @click="removeDie(index)"

      />
    </div>
    <button class="add-die-button" v-if="!showAdditionalDice" @click="showAdditionalDiceToggle">Add Die Row</button>
    <label class="die-label"> {{ odds }} </label>
    <div class="dice-holder row" v-if="showAdditionalDice">
      <odds-item class="modifiers-item" item-type="die"
                 v-for="size in [4,6,8,12,16,20]" :key="size" @click="addDieRow(size)"
                 :title="('d' + size)"

      />
    </div>
  </div>
</template>


<script>
import OddsItem from "@/views/odds/oddsItem.vue";

export default {
  name: "oddsComponent",
  components: {OddsItem},
  data() {
    return {
      goalResult: 10,
      selectedAdvantage: 0,
      modifiers: 0,
      diceRows: [],
      showAdditionalDice: false
    };
  },
  computed: {
    odds() {
      return this.calculateDie(this.selectedAdvantage, this.goalResult, this.diceRows, this.modifiers)
    }
  },
  methods: {
    removeDie(index) {
      this.diceRows.splice(index, 1)
    },
    toggleModifierSign() {
      this.modifiers *= -1;
    },
    advantageClick() {
      if (this.selectedAdvantage === 1) {
        this.selectedAdvantage = 0
      } else {
        this.selectedAdvantage = 1
      }
    },
    disadvantageClick() {
      if (this.selectedAdvantage === -1) {
        this.selectedAdvantage = 0
      } else {
        this.selectedAdvantage = -1
      }
    },
    showAdditionalDiceToggle() {
      if (this.diceRows.length < 8)
        this.showAdditionalDice = true
    },
    addDieRow(size) {
      if (this.diceRows.length < 8)
        this.diceRows.push({size: size, isNegative: false})
      this.showAdditionalDice = false
    },
    removeDieRow(index) {
      this.diceRows.splice(index, 1);
    },
    toggleDieSign(dieRow) {
      dieRow.isNegative = !dieRow.isNegative;
    },
    calculateAdvantage: function (modifier, goal, dice) {
      let totalHits = 0
      let die = 20
      for (let i = 1; i <= die; i++) {
        for (let j = 1; j <= die; j++) {
          let firstScore = i;
          let secondScore = j
          if (firstScore + modifier >= goal || secondScore + modifier >= goal) {
            totalHits += this.getTotalOutcomes(dice)
          } else {
            let biggestScore = Math.max(firstScore, secondScore)
            if (dice.length >= 1) {
              totalHits += this.recursiveCalculateDie(goal, dice, biggestScore, modifier, [biggestScore])
            }
          }
        }
      }
      let total = this.getTotalOutcomes(dice) * 20 * 20;
      return (Math.round(totalHits / total * 100) + "%")
    }, calculateNormal: function (modifier, goal, dice) {
      let totalHits = 0
      let die = 20
      for (let i = 1; i <= die; i++) {
        let currentScore = i;
        if (currentScore + modifier >= goal) {
          totalHits += this.getTotalOutcomes(dice)
        } else {
          if (dice.length >= 1) {
            totalHits += this.recursiveCalculateDie(goal, dice, currentScore, modifier, [i])
          }
        }
      }
      let total = this.getTotalOutcomes(dice) * 20;
      return Math.round(totalHits / total * 100) + "%"
    }, calculateDisadvantage: function (modifier, goal, dice) {
      let totalHits = 0
      let die = 20
      for (let i = 1; i <= die; i++) {
        for (let j = 1; j <= die; j++) {
          let firstScore = i;
          let secondScore = j
          if (firstScore + modifier >= goal && secondScore + modifier >= goal) {
            totalHits += this.getTotalOutcomes(dice)
          } else {
            let smallestScore = Math.min(firstScore, secondScore)
            if (dice.length >= 1) {
              totalHits += this.recursiveCalculateDie(goal, dice, smallestScore, modifier, [smallestScore])
            }
          }
        }
      }
      let total = this.getTotalOutcomes(dice) * 20 * 20;
      return Math.round(totalHits / total * 100) + "%"
    }, calculateDie(advantage, goal, dice, modifier) {
      if (advantage === 1) {
        return this.calculateAdvantage(modifier, goal, dice);
      } else if (advantage === 0) {
        return this.calculateNormal(modifier, goal, dice);
      } else {
        return this.calculateDisadvantage(modifier, goal, dice);
      }

    },
    recursiveCalculateDie(goal, dice, scoreSoFar, modifier, currentDieOption) {

      let totalHits = 0
      let die = parseInt(dice[0].size)
      let otherDice = dice.slice(1);
      for (let i = 1; i <= die; i++) {
        let diceSoFar = JSON.parse(JSON.stringify(currentDieOption))
        diceSoFar.push(i)

        let currentScore = scoreSoFar + i;
        if (currentScore + modifier >= goal) {
          totalHits += this.getTotalOutcomes(otherDice)
        } else {
          if (otherDice.length >= 1) {


            totalHits += this.recursiveCalculateDie(goal, otherDice, currentScore, modifier, JSON.parse(JSON.stringify(diceSoFar)))
          }
        }
      }
      return totalHits
    },
    getTotalOutcomes(dice) {
      if (dice.length === 0) {
        return 1
      } else {
        let totalOutcomes = parseInt(dice[0].size)
        for (let die of dice.slice(1)) {
          totalOutcomes = totalOutcomes * parseInt(die.size)
        }
        return totalOutcomes
      }
    }
  }

}
</script>

<style scoped>

.base-container {
  font-family: 'Roboto Slab', serif;
  /*noinspection CssUnknownTarget*/
  background-image: url("../../../src/assets/rolls.png");
  background-size: 100%;
  background-repeat: no-repeat;
  aspect-ratio: 473 / 972;
  text-align: center;
  background-color: black;
  color: white;
  align-items: start;
}


.base-container * {
  visibility: hidden;
}

.dice-holder {
  background-color: black;
  overflow-x: auto;
  position: absolute;
  top: 90%;
  visibility: visible;
  white-space: nowrap;
  width: 100%;
  height: auto;
}

.modifiers-holder {
  overflow-x: auto;
  position: absolute;
  top: 58%;
  visibility: visible;
  white-space: nowrap;
  width: 100%;
  height: auto;
}

.modifiers-item {
  position: relative;
  visibility: visible;
  display: inline-block;
  margin-inline-start: 1%;
  min-height: min-content;
  width: 100px;
  aspect-ratio: 148/213;
}

.die-label {
  position: absolute;
  top: 32%;
  color: #ffffff90;
  font-weight: 900;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  visibility: visible;
  width: 30%;
  font-size: 100%;
}

.goal-label {
  position: absolute;
  top: 9%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  visibility: visible;
  width: 30%;
  font-size: 80%;
}

.goal-input {
  visibility: visible;
  background: transparent;
  border: none;
  color: white;
  font-family: 'Roboto Slab', serif;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 14.5%;
  font-size: 200%;
  position: absolute;

}

.adv-button-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 42%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  visibility: visible;
  width: 60%;

}

.adv-button {
  visibility: visible;
  background-color: #00000030;
  font-size: 80%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #895d28 0%, #967950 50%, #895d28 100%) 1;
  color: #967950;
  cursor: pointer;
}


.adv-button-selected {
  visibility: visible;
  background-color: #00000030;
  font-size: 80%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #895d28 0%, #967950 50%, #895d28 100%) 1;
  color: white;
  cursor: pointer;
}


::-webkit-scrollbar {
  height: 20px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
  height: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  background: rgba(39, 17, 94, 0.65);
  border-radius: 100px;
  width: 20px;
  max-width: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(107, 72, 194, 0.65);
}

.add-die-button {
  top: 93.5%;
  position: absolute;
  visibility: visible;
  background-color: #00000000;
  font-size: 80%;
  box-shadow: none;
  margin-left: auto;
  margin-right: auto;
  left: 50px;
  right: 0;

  text-align: center;
  border-width: 4px;
  border-style: solid;
  border-color: #00000000;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}
</style>
