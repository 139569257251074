<template>
  <div class="main-screen">
    <div class="game-content">
      <div class="left-column">
        <div class="side-column-button-holder">
          <img alt="home" class="circle_button" src="./home.svg" @click="goHome">
          <div class="side-column-button" @click="pauseUnpause">
            <p>{{ remainingTime }}</p>
          </div>
          <div class="side-column-button" @click="pauseUnpause">
            <p>{{ remainingTurns }}</p>
          </div>
        </div>
        <div class="column-content">
          <div class="mini-title">
            <h1 class="mini-title-text">Leaderboard</h1>
          </div>
          <div class="listContainer">
            <div class="inner-list-container">
              <div v-for="(item,i) in score" v-bind:key="item" class="listItem">
                <p class="listItemText">#{{ i + 1 }}</p>
                <p class="listItemText">{{ item.name }}</p>
                <p class="listItemText">{{ item.score }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-column">
        <div class="chat-title"><h1 class="chat-title-text">Gartist Guesser</h1></div>
        <div v-if="state==='singleImage' || state === 'showAnswer'" class="singleImage">
          <div class="some-margin"></div>

          <div class="guessImage">
            <img :class="'img-border '+ (mainImageBlur ? 'blur' : '')" alt="currentImage" v-bind:src="currentImage.url">
            <img alt="blur" class="blur-button" src="./blur.svg" @click="toggleBlur">
          </div>
          <div v-if="state === 'singleImage'"
               v-bind:class="{
                      'guessControls2': answers === '2 Options',
                      'guessControls4': answers === '4 Options',
                      'guessControls6': answers === '6 Options'}">
            <div v-for="(guessItem,i) in guessItems" v-bind:key="guessItem" class="guess-item"
                 @click="submitGuess(twitchUsername, guessItem)">
              <p class="guess-text"> {{ i + 1 }}. {{ guessItem }}</p>
            </div>
          </div>
          <div v-if="state === 'showAnswer'" class="guessAnswer">
            <div v-if="!currentImage.avatarUrl" class="circleImage">
              <img :src="currentImage.twitchAvatarUrl" alt="twitchAvatar" class="twitchImage">
            </div>
            <div v-if="currentImage.avatarUrl" class="circleImageGartic">
              <img :src="currentImage.avatarUrl" alt="garticAvatar" class="garticImage">
            </div>
            <div class="info">
              <h2>{{ currentImage.name }}</h2>
              <p>Drawing from {{ currentDrawingAge }} ago</p>
              <p>Joined: {{ firstDrawingCurrentArtist }} Ago</p>
              <p>Total drawn: {{ totalImagesByCurrentArtist }} images</p>
            </div>
          </div>
        </div>
        <div v-if="state==='multiImage'" class="multiImage">
          <h1>{{ this.currentImage.name }}</h1>
          <div class="image-to-guess-grid">
            <div v-for="(image, i) in imagesToGuess" v-bind:key="image"
                 class="img-to_guess-border">
              <img alt="image" class="img-to_guess" v-bind:class="{ 'blur': imagesToBlur.includes(i) || paused }"

                   v-bind:src="image.url">
              <div class="guess-gradient" @click="submitGuess(twitchUsername, image.name)">

              </div>
              <div class="guess-number">{{ i + 1 }}</div>
              <img alt="blur" class="blur-button" src="./blur.svg" @click="toggleBlurMultiple(i)">


            </div>

          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="side-column-button-holder-2">
          <img alt="skip" class="circle_button" src="./skip.svg" @click="skipImage">
          <div class="side-column-button" @click="goToEnd">
            <p>End Game</p>
          </div>
        </div>
        <div class="column-content">
          <div class="mini-title">
            <h1 class="mini-title-text">Guesses</h1>
          </div>
          <div class="listContainer">
            <div class="inner-list-container">
              <div v-for="item in displayedGuesses"
                   v-bind:key="item"
                   class="listItem"
                   v-bind:class="{ 'correct-bg': item.correct && state === 'showAnswer' }">
                <p class="listItemText">#{{ item.position }}</p>
                <p class="listItemText">{{ item.player }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import imageSourceList from "@/assets/GarticDownloads/gartic.json";


export default {

  data() {
    return {
      currentPlayers: [],
      countdownPlayed: false,
      imagesToBlur: [],
      roundLengthMillis: 30000,
      lastActionTimestamp: 0,
      mainImageBlur: false,
      rounds: [],
      state: "",
      currentScore: [
        {
          position: 1,
          name: 'VixiPixi',
          score: 37
        }
      ],
      currentGuesses: [],
      guessItems: ['aa', 'bb', 'cc', 'dd', 'ee', 'ff'],
      imageFiles: imageSourceList,
      showImage: 'OFF',
      selectedDifficulty: 'easy',
      selectedGuessTime: '30s',
      twitchUsername: 'You',
      remainingTime: 30,
      userGuess: '',
      allImages: [],
      applicableImages: [],
      applicableArtists: [],
      imagesToGuess: [],
      currentImage: {},
      currentImageIndex: 0,
      nsfw: "OFF",
      answers: "2 Options",
      guess: "Artist",
      artists: "Stream Regulars",
      totalRounds: -1,
      imagesSoFar: 0,
      timer: "30s",
      garticAvatars: [],
      paused: false,
      intervalId: 0
    };
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
  mounted() {
    document.title = "Gartist Guesser";

    let gameId = localStorage.getItem('gameId')
    let gameIdFromRoute = this.$route.query.gameId
    if (gameIdFromRoute !== gameId) {
      return
    }
    this.intervalId = setInterval(() => {

          //IF CURRENTgUESSES IS EMPTY, FABRICATE SOME FAKES
          if (this.currentGuesses.length === 0) {
            for (let i = 0; i < 5; i++) {
              let randomScore = Math.floor(Math.random() * 10)
              //filter out to only have answers with names that are 10 characters or less
              let filter = this.applicableArtists.filter(x => x.length <= 10 && x.length > 0);
              let randomWrongAnswer = filter[Math.floor(Math.random() * filter.length)];
              this.currentGuesses.push({
                score: randomScore,
                player: randomWrongAnswer,
                guess: randomWrongAnswer,
                correct: false,
                responseTime: 0,
                position: i + 1
              })
            }
          }

          if (this.paused) {
            this.lastActionTimestamp = this.lastActionTimestamp + 300
            this.remainingTime = "Paused"
            return
          }
          if (this.state === 'singleImage' || this.state === 'multiImage') {
            let millis = new Date().getTime() - this.lastActionTimestamp
            this.remainingTime = Math.round((this.roundLengthMillis - millis) / 1000) + 1
            if (millis > this.roundLengthMillis) {
              if (this.timer !== "Host Decision") {
                this.populateCorrectAnswer()
              }
            }
          } else if (this.state === 'showAnswer') {
            let displayTime = Math.min(this.roundLengthMillis / 3, 10000)
            let millis = new Date().getTime() - this.lastActionTimestamp
            this.remainingTime = Math.round((displayTime - millis) / 1000) + 1
            if (millis > displayTime) {
              this.imagesSoFar++
              this.currentGuesses = []
              if (this.imagesSoFar === this.totalRounds) {
                this.state = 'gameOver'
                this.goToEnd()
              } else {
                this.countdownPlayed = false
                if (this.timer !== "Host Decision") {
                  if (this.guess === "Artist") {
                    this.populateSingleImage()
                  } else {
                    this.populateMultiImage()
                  }
                }
              }
            }
          }

          if (this.timer === "Host Decision") {
            // infinity symbol
            this.remainingTime = "∞"
          } else if (this.remainingTime === 3 && !this.countdownPlayed && this.state !== 'showAnswer') {
            this.countdownPlayed = true
            let audio = new Audio(require('@/assets/sounds/timer.mp3'));
            audio.play();
          }
        }
        ,
        300
    )


    this.twitchUsername = localStorage.getItem('twitchUsername')
    let tmi = require('tmi.js');
    let client = new tmi.Client({
      connection: {
        secure: true,
        reconnect: true
      },
      channels: [this.twitchUsername]
    });
    client.on('message', (channel, tags, message) => {
      let potentialGuess = message.toLowerCase().trim()
      const name = tags['display-name']
      if (potentialGuess.length === 1
          && !isNaN(potentialGuess)
          && potentialGuess > 0
          && potentialGuess <= this.guessItems.length
      ) {
        let guessItem = this.guessItems[potentialGuess - 1]
        if (this.state !== 'showAnswer') {
          this.submitGuess(name, guessItem)
        }
      }
    })
    client.connect();
    // log query url
    this.artists = localStorage.getItem('Artists')
    let duration = localStorage.getItem('Duration')
    console.log('duration', duration)
    switch (duration) {
      case "Infinite":
        this.totalRounds = -1
        break;
      case "5 Turns":
        this.totalRounds = 5
        break;
      case "10 Turns":
        this.totalRounds = 10
        break;
      case "20 Turns":
        this.totalRounds = 20
        break;
    }
    this.timer = localStorage.getItem('Timer')
    this.answers = localStorage.getItem('Answers')
    this.nsfw = localStorage.getItem('NSFW')
    this.guess = localStorage.getItem('Guess')
    this.getGarticImages()
    this.roundLengthMillis = 60000;
    switch (this.timer) {
      case "5s":
        this.roundLengthMillis = 5000;
        break;
      case "30s":
        this.roundLengthMillis = 30000;
        break;
      case "1m":
        this.roundLengthMillis = 60000;
        break;
    }
    if (this.guess === "Artist") {
      this.populateSingleImage()
    } else {
      this.populateMultiImage()
    }
    this.getGarticAvatar()

  },
  created() {
    import('./ChatGame.css')
  }
  ,
  computed: {
    remainingTurns() {
      if (this.totalRounds === -1) {
        return "∞"
      } else
        return (this.imagesSoFar + 1) + "/" + this.totalRounds
    }
    ,
    displayedGuesses() {
      let guesses = this.currentGuesses;
      if (this.state === 'showAnswer') {
        guesses.sort((a, b) => b.correct - a.correct)
      }
      return guesses
    }
    ,
    score() {
      let scores = []
      for (let round of this.rounds) {
        let correctGuesses = round.guesses.filter(x => x.correct)
        //   sort by time
        correctGuesses.sort((a, b) => a.responseTime - b.responseTime)

        for (let i = 0; i < correctGuesses.length; i++) {
          let guess = correctGuesses[i]
          let scoreToAdd = guess.score
          if (scores.some(x => x.name === guess.player)) {
            let score = scores.find(x => x.name === guess.player)
            score.score += scoreToAdd
          } else {
            scores.push({
              name: guess.player,
              score: scoreToAdd
            })
          }
        }
      }
      // go over all scores. and round them toa single decimal
      for (let score of scores) {
        score.score = +score.score.toFixed(1)
      }
      scores.sort((a, b) => b.score - a.score)
      return scores
    }
    ,
    currentDrawingAge() {
      return this.formatDate(this.currentImage.date)
    }
    ,
    firstDrawingCurrentArtist() {
      let artist = this.currentImage.name
      let imagesByArtist = this.allImages.filter(image => image.name === artist);
      imagesByArtist.sort((a, b) => new Date(a.date) - new Date(b.date));
      return this.formatDate(imagesByArtist[0].date)
    }
    ,
    totalImagesByCurrentArtist() {
      let artist = this.currentImage.name
      let imagesByArtist = this.allImages.filter(image => image.name === artist);
      return imagesByArtist.length
    }
  }
  ,
  methods: {
    pauseUnpause() {
      this.paused = !this.paused
      if (this.state !== 'showAnswer') {
        this.mainImageBlur = this.paused
      }

    }
    ,
    getImageFromArtist(randomArtist) {

      let imagesByArtist = this.applicableImages.filter(image => image.name === randomArtist);
      let imageWeights = [];
      for (let image of imagesByArtist) {
        let weight = 1;
        if (image.tags) {
          if (image.tags.includes('best')) {
            weight = 10;
          }
        }
        imageWeights.push(weight);
      }
      let totalWeight = imageWeights.reduce((a, b) => a + b, 0);
      let randomNum = Math.random() * totalWeight;
      let weightSum = 0;
      let randomImageIndex = 0;
      for (let i = 0; i < imageWeights.length; i++) {
        weightSum += imageWeights[i];
        weightSum = +weightSum.toFixed(2);
        if (randomNum <= weightSum) {
          randomImageIndex = i;
          break;
        }
      }
      return imagesByArtist[randomImageIndex];
    }
    ,
    goToEnd() {
      let audio = new Audio(require('@/assets/sounds/success.mp3'))
      audio.play()
      localStorage.setItem('rounds', JSON.stringify(this.rounds))
      this.$router.push({name: 'endgame', query: {gameId: localStorage.getItem('gameId')}})
    }
    ,
    goHome() {
      this.$router.go(-1)
    }
    ,
    toggleBlur() {
      this.mainImageBlur = !this.mainImageBlur
    }
    ,
    toggleBlurMultiple(i) {
      if (this.imagesToBlur.includes(i)) {
        this.imagesToBlur = this.imagesToBlur.filter(x => x !== i)
      } else {
        this.imagesToBlur.push(i)
      }
    }
    ,
    skipImage() {

      if (this.state === 'singleImage' || this.state === 'multiImage') {
        this.populateCorrectAnswer()
      } else if (this.state === 'showAnswer') {
        this.imagesSoFar++
        this.currentGuesses = []
        if (this.imagesSoFar === this.totalRounds) {
          this.state = 'gameOver'
          this.goToEnd()
        } else {
          if (this.guess === "Artist") {
            this.populateSingleImage()
          } else {
            this.populateMultiImage()
          }
        }
      }


    }
    ,
    addPlayerImagesToCollection(player) {
      if (this.applicableArtists.includes(player)) {
        return
      }
      console.log(player)
      let imagesByArtist = this.allImages.filter(image => 'name' in image && image.name.toLowerCase() === player.toLowerCase());
      imagesByArtist = imagesByArtist.filter(image => this.imageFiler(image));
      console.log('imagesByArtist', imagesByArtist)
      this.applicableImages.push(...imagesByArtist);
      this.applicableArtists.push(player.toLowerCase());
      console.log('applicableArtists', this.applicableArtists)
    }
    ,
    imageFiler(image) {

      let tagsToExclude = ['slices', 'style', 'outfit', 'complement', 'delete', 'comic']
      let imageHasBadTags = image.tags && image.tags.some(x => tagsToExclude.includes(x))
      let followsNSFWRules
      if (this.nsfw === 'Off') {
        followsNSFWRules = image.moderated && (!image.tags || !image.tags.includes('nsfw'))
      } else if (this.nsfw === 'On') {
        followsNSFWRules = true
      } else {
        followsNSFWRules = image.moderated && image.tags && image.tags.includes('nsfw')
      }

      return !imageHasBadTags && followsNSFWRules;

    }
    ,
    submitGuess(player, guess) {

      if (!this.currentPlayers.includes(player)) {
        this.addPlayerImagesToCollection(player);

      }

      if (this.currentGuesses.some(x => x.player === player)) {
        return
      }
      let positionInArray = this.currentGuesses.length + 1
      let guessIsCorrect = guess === this.currentImage.name
      // score is relative to how long it took the guess, starting 5% of the timer in.
      let responseTime = new Date().getTime() - this.lastActionTimestamp
      let score = 0
      let grace_period = 0.1 * this.roundLengthMillis
      let remaining_time = Math.min(this.roundLengthMillis - grace_period, this.roundLengthMillis - responseTime)
      let max_score = 10

      score = (max_score * (remaining_time / (this.roundLengthMillis - grace_period)))
      if (!guessIsCorrect) {
        score = 0
      }
      this.currentGuesses.push({
        score: score,
        player: player,
        guess: guess,
        correct: guess === this.currentImage.name,
        responseTime: new Date().getTime() - this.lastActionTimestamp,
        position: positionInArray
      })
      let audio = new Audio(require('@/assets/sounds/addGuess.mp3'));
      audio.play();
    }
    ,
    getGarticAvatar() {
      let url = "https://gist.githubusercontent.com/SillyV/24ffd9981415f12b93ba0f186beb4ec9/raw"
      let request = new XMLHttpRequest();
      request.open('GET', url, true);
      let context = this
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          let json = JSON.parse(request.responseText);
          json = Object.keys(json)
              .map((key) => {
                return {name: key, url: json[key]}
              })
          context.garticAvatars = json
        }
      };
      request.send();
    }
    ,
    formatDate(date) {
      const inputDateObj = new Date(date)
      const currentDate = new Date();
      const yearsDiff = currentDate.getFullYear() - inputDateObj.getFullYear();
      const monthsDiff = currentDate.getMonth() - inputDateObj.getMonth();
      const daysDiff = currentDate.getDate() - inputDateObj.getDate();
      let result = "";
      if (yearsDiff > 0) {
        result += yearsDiff + (yearsDiff === 1 ? " year" : " years");
      }
      if (monthsDiff > 0) {
        result += (result ? ", " : "") + monthsDiff + (monthsDiff === 1 ? " month" : " months");
      }
      if (daysDiff > 0) {
        result += (result ? ", " : "") + daysDiff + (daysDiff === 1 ? " day" : " days");
      }
      return result
    }
    ,
    populateSingleImage() {
      // play sound
      let audio = new Audio(require('@/assets/sounds/click.mp3'));
      audio.play();

      this.state = 'singleImage'
      // let randomArtist = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];
      // find artist with the name mary in their name
      let randomArtist = this.applicableArtists.find(x => x.toLowerCase().includes('mary'))
      let imagesByArtist = this.applicableImages.filter(image => image.name === randomArtist);
      let imageWeights = [];
      for (let image of imagesByArtist) {
        let weight = 1;
        if (image.tags) {
          if (image.tags.includes('best')) {
            weight = 10;
          }
        }
        imageWeights.push(weight);
      }
      let totalWeight = imageWeights.reduce((a, b) => a + b, 0);
      let randomNum = Math.random() * totalWeight;
      let weightSum = 0;
      let randomImageIndex = 0;
      for (let i = 0; i < imageWeights.length; i++) {
        weightSum += imageWeights[i];
        weightSum = +weightSum.toFixed(2);
        if (randomNum <= weightSum) {
          randomImageIndex = i;
          break;
        }
      }
      this.currentImage = imagesByArtist[randomImageIndex];
      this.lastActionTimestamp = new Date().getTime();
      let context = this
      let request = new XMLHttpRequest();
      request.open('GET', 'https://decapi.me/twitch/avatar/' + randomArtist, true);
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.currentImage.twitchAvatarUrl = request.responseText
        }
      };
      request.send();


      let tags = this.currentImage.tags;
      let artistGuesses = [];
      artistGuesses.push(randomArtist);
      if (tags) {
        for (let tag of tags) {
          if (this.applicableArtists.includes(tag) && tag !== randomArtist) {
            artistGuesses.push(tag)
          }
        }
      }
      for (let i = 0; i < 5; i++) {
        let randomWrongAnswer = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];
        while (randomWrongAnswer === randomArtist || artistGuesses.includes(randomWrongAnswer)) {
          randomWrongAnswer = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];
        }
        artistGuesses.push(randomWrongAnswer)
      }

      let answers = 0
      if (this.answers === "2 Options") {
        answers = 2
      } else if (this.answers === "4 Options") {
        answers = 4
      } else if (this.answers === "6 Options") {
        answers = 6
      }

      artistGuesses = artistGuesses.slice(0, answers);
      let guessItems = [];
      guessItems.push(...artistGuesses);
      guessItems.sort(() => Math.random() - 0.5);
      this.guessItems = guessItems;

    }
    ,
    populateMultiImage() {
      let audio = new Audio(require('@/assets/sounds/click.mp3'));
      audio.play();

      this.state = 'multiImage'
      let randomArtist = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];

      this.currentImage = this.getImageFromArtist(randomArtist);

      this.lastActionTimestamp = new Date().getTime();


      let context = this
      let request = new XMLHttpRequest();
      request.open('GET', 'https://decapi.me/twitch/avatar/' + randomArtist, true);
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.currentImage.twitchAvatarUrl = request.responseText
        }
      };
      request.send();

      let tags = this.currentImage.tags;
      let artistGuesses = [];
      artistGuesses.push(randomArtist);
      if (tags) {
        for (let tag of tags) {
          if (this.applicableArtists.includes(tag) && tag !== randomArtist) {
            artistGuesses.push(tag)
          }
        }
      }
      for (let i = 0; i < 5; i++) {
        let randomWrongAnswer = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];
        while (randomWrongAnswer === randomArtist || artistGuesses.includes(randomWrongAnswer)) {
          randomWrongAnswer = this.applicableArtists[Math.floor(Math.random() * this.applicableArtists.length)];
        }
        artistGuesses.push(randomWrongAnswer)
      }

      let answers = 0
      if (this.answers === "2 Options") {
        answers = 2
      } else if (this.answers === "4 Options") {
        answers = 4
      } else if (this.answers === "6 Options") {
        answers = 6
      }

      artistGuesses = artistGuesses.slice(0, answers);
      let guessItems = [];
      guessItems.push(...artistGuesses);
      guessItems.sort(() => Math.random() - 0.5);
      this.guessItems = guessItems;


      this.imagesToGuess = []
      for (let guessItem of this.guessItems) {

        if (guessItem !== randomArtist) {
          let image = this.getImageFromArtist(guessItem)
          this.imagesToGuess.push(image)
        } else {
          this.imagesToGuess.push(this.currentImage)
        }

      }
      console.log(this.imagesToGuess)


    }
    ,
    populateCorrectAnswer() {
      this.lastActionTimestamp = new Date().getTime();
      try {
        this.currentImage.avatarUrl = this.garticAvatars.find(x => x.name.toLowerCase() === this.currentImage.name.toLowerCase()).url
      } catch (e) {
        console.log(e)
      }
      this.state = 'showAnswer'
      this.rounds.push({
        correctAnswer: this.currentImage.name,
        guesses: this.currentGuesses
      })
    }
    ,
    getGarticImages() {

      // get all images from the database
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allImages.push(...require1)
      }

      // filter out the bad images.
      this.applicableImages = this.allImages.filter(image => {
            return this.imageFiler(image)
          }
      );


      // get limited number of artists from timeframe:
      let timestampSixMonthsAgo = new Date().getTime() - 15778476000;
      let imagesFromLastSixMonths = this.allImages.filter(image => new Date(image.date).getTime() > timestampSixMonthsAgo);
      let setOfNamesFromLastSixMonths = new Map();
      for (let image of imagesFromLastSixMonths) {
        if (image.name) {
          if (setOfNamesFromLastSixMonths.has(image.name)) {
            setOfNamesFromLastSixMonths.set(image.name, setOfNamesFromLastSixMonths.get(image.name) + 1)
          } else {
            setOfNamesFromLastSixMonths.set(image.name, 1)
          }
        }
      }
      const artistsToIncludeMapping = {
        "Stream Regulars": 50,
        "Community Members": 100,
        "Occasional Visitors": 200,
        "Everyone": this.allImages.length
      };
      let artistsToInclude = artistsToIncludeMapping[this.artists];

      const applicableImageNames = new Set(this.applicableImages.map(image => image.name));

      let keys = Array.from(setOfNamesFromLastSixMonths.keys())
      let artistNames = keys
          .filter(artistName => {
                return applicableImageNames.has(artistName)
              }
          )

      console.log('ss', setOfNamesFromLastSixMonths)
      const artists = artistNames
          .map(artistName => ({name: artistName, value: setOfNamesFromLastSixMonths.get(artistName)}));
      artists.sort((a, b) => b.value - a.value);
      console.log(artists)
      const sortedArtists = artists.slice(0, artistsToInclude).map(artist => artist.name);


      let specialCaseArtists = [
        'blembily', 'jayumomoo', 'nickysimss', 'sorryiwasasleep', 'syrpentina', 'sneakyjaybird',
        'skullwalkerx_art', 'zoppyam', 'artwomble', 'itsnear2', 'azuritte_', 'gevoart', 'corporatemommy',
        'francesca__m', 'dioso69', 'ragepaintgames', 'amazing_ayyy', 'cerbross', 'opalreflections', 'seraphim8core',
        'electronic_alien2', 'zeniarose', 'tokumei0215', 'lefishtank', 'cchestie11', 'thegildedbastet'
      ]
      for (let specialCaseArtist of specialCaseArtists) {
        if (applicableImageNames.has(specialCaseArtist) && !sortedArtists.includes(specialCaseArtist)) {
          sortedArtists.push(specialCaseArtist)
        }
      }

      // assign to fields
      this.applicableImages = this.applicableImages.filter(image => sortedArtists.includes(image.name))
      this.applicableArtists = sortedArtists
      console.log(sortedArtists)
    }
  }
  ,
}

</script>


<style scoped>

img {
  object-fit: contain;
}

@font-face {
  font-family: 'LondrinaSolid';
  src: url('@/assets/fonts/LondrinaSolid-Regular.ttf') format('truetype'); /* Adjust the path accordingly */
  font-weight: normal;
  font-style: normal;
}

.game-content {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.left-column {
  align-items: end;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.center-column {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1.6;
  height: 100%;
  width: 100%;
}

.right-column {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  height: 100%;
  width: 100%;
}

.guessImage {
  position: relative;
  margin-bottom: 20px;
  aspect-ratio: 754/370;
  width: 80%;
}

.guessControls2 {
  display: grid;
  grid-template-columns:  1fr 1fr;
  flex: 1;
  margin-inline-start: 2%;
  margin-inline-end: 2%;
  aspect-ratio: 754/200;
  width: 95%;
}

.guessControls4 {
  display: grid;
  grid-template-columns:  1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /*column height*/
  margin-inline-start: 2%;
  margin-inline-end: 2%;
  aspect-ratio: 754/200;
  width: 95%;
  height: 20%;
}

.guessControls4 .guess-item {
  margin: 3px;
  aspect-ratio: 190/13 !important;
}

.guessControls6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-inline-start: 2%;
  align-items: start;
  margin-inline-end: 2%;
  aspect-ratio: 754/200;
  width: 95%;
  grid-row-gap: 10px;

}

/*745 - 424 = 321*/

.guessAnswer {
  display: flex;
  flex-direction: row;
  margin-inline-start: 2%;
  margin-inline-end: 2%;
  aspect-ratio: 754/200;
  width: 76%;
  border-radius: 17px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(180deg, #5748B4 0%, #AD76D8 100%);
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.img-to_guess-border {
  margin-top: 10px;
  position: relative;
  aspect-ratio: 605/339;
  flex-shrink: 0;
  width: 95%;
  border-radius: 26px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
}


.img-to_guess-border .blur-button {
  opacity: 0;
  border-radius: 100%;
  background-color: #00000050;
  margin-top: 10px;
  margin-inline-start: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.img-to_guess-border:hover .blur-button {
  opacity: .5;
}

.img-to_guess-border .blur-button:hover {
  opacity: 1 !important;
}

.img-to_guess {
  border-radius: 26px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.blur {
  filter: blur(50px);
}

/*noinspection CssUnusedSymbol*/
.img-border {
  margin-top: 50px;
  aspect-ratio: 605/339;
  flex-shrink: 0;
  width: 95%;
  border-radius: 26px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.guess-item {
  margin: 10px;
  aspect-ratio: 190/63;
  flex-shrink: 0;
  border-radius: 14px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(180deg, #7D6ED9 0%, #B0A3FF 100%);
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.guess-text {
  padding-top: 5%;
  padding-bottom: 5%;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.circleImage {
  border-radius: 122px;
  background: lightgray 50% / cover no-repeat;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  /*  cover*/
  width: 122px;
  height: 122px;
  margin: 5%;
  flex-shrink: 0;
}

.circleImageGartic {
  border: 0 solid rgba(255, 255, 255, 0.50);
  width: 122px;
  height: 141px;
  margin: 5%;
  flex-shrink: 0;
}


.garticImage {
  width: 122px;
  height: 141px;
  object-fit: cover;
}

.twitchImage {
  border-radius: 122px;
  width: 122px;
  height: 122px;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  height: 70%;
  align-items: start;
  flex-shrink: 0;
  margin: 5%;
}

.info h2 {
  margin: 1px;
  color: #FFF;
  font-family: Jura, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info p {
  margin: 0;
  color: #FFF;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.multiImage {
  overflow: hidden;
  height: 100%;
}

.multiImage h1 {
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.image-to-guess-grid {
  padding-inline-start: 50px;
  padding-inline-end: 50px;
  display: grid;
  grid-template-columns: .8fr .8fr;
  grid-template-rows: 1fr 1fr 1fr;
}

/*noinspection CssUnusedSymbol*/
.button-holder {
  display: flex;
  position: fixed;
  right: 16.3%;
  top: 5%;
  width: 181px;
  height: 7%;

}

.circle_button {
  position: relative;
  width: 24%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  align-content: center;
}


.side-column-button-holder {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
  width: 181px;

  margin-inline-end: 3%;
}

.side-column-button-holder-2 {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  align-items: center;
  width: 181px;
  margin-inline-start: 2%;
}

.side-column-button {
  background: linear-gradient(180deg, #9D6ED9 0%, #B0A3FF 100%);
  width: 100%;
  margin-bottom: 4%;
  margin-inline-start: 5%;
  color: white;
  border-radius: 25px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.guessImage:hover .blur-button {
  opacity: .4;
}

.blur-button {
  opacity: 0;
  height: 20%;
  aspect-ratio: 1;
  border-radius: 100%;
  background-color: #00000050;
  margin-top: 70px;
  margin-inline-start: 40px;
  position: absolute;
  top: 0;
  left: 0;
}

.blur-button:hover {
  opacity: 1 !important;
}

.correct-bg {
  background: linear-gradient(180deg, #48ff96 0%, #0b5623 100%);
}

/*gradient from top to bottom with a middle color*/
.guess-gradient {
  display: flex;
  flex-direction: column;
  align-content: end;
  align-items: start;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 26px;
  height: 100%;
  background: linear-gradient(180deg, #ffffff00 0%, #ffffff00 70%, #00000077 100%);
}

.guess-number {
  position: absolute;
  bottom: 5%;
  font-size: 30px;
  font-family: Jura, serif;
  color: white;
  font-weight: 600;
  left: 5%;
}

.some-margin {
  height: 10px;
}

.singleImage {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  align-content: center;
  height: 100%;
}

.inner-list-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-inline-end: -35px;
}
</style>
