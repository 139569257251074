<template>
  <main id="home">
    <div v-if="spinning">
      <LuckyWheel
          ref="luckyWheel"
          :forceWinner="this.winnerValue"
          :items="this.particiChart"></LuckyWheel>
    </div>
    <div v-if="this.allImages.length > 2" class="row parent1">
      <div class="full-list-wrapper">
        <div class="button-group">
          <input id="winner" v-model="winnerValue">
          <button id="toggleTokens" :style="{'background-color': showTokens ? 'green' : 'red'}"
                  class="button button-secondary"
                  @click="toggleTokens"
          >{{ showTokens ? "VISIBLE TOKENS" : "HIDDEN TOKENS" }}
          </button>
          <button id="accept" class="button button-primary" @click="acceptOC">Accept</button>
          <button id="spin" class="button button-secondary" @click="spin">Spin</button>
          <button id="incrementAll" class="button button-secondary" @click="add10">Add 10</button>
          <button class="button button-secondary" @click="addAllUsersToPool">Show All</button>
          <button class="button button-secondary" @click="clearWheel">Clear</button>
          <div class="form-group">
            <label class="form-label" for="new-name">Name:</label>
            <input id="new-name" class="form-input" type="text">
          </div>
          <div class="form-group">
            <label class="form-label" for="new-url">URL:</label>
            <input id="new-url" class="form-input" type="text">
          </div>
          <button class="button button-secondary" @click="addNewPerson()">Add</button>
          <div class="form-group">
            <label class="form-label" for="filter">Filter:</label>
            <input id="filter" class="form-input" type="text" @keyup="queryChanged">
          </div>
        </div>
        <div class="full-list-container">
          <br>
          <div class="card-container">
            <div v-for="avatar in filteredImages" v-bind:key="avatar.name" class="card">
              <div class="card-image">
                <img :src=avatar.url alt="{{avatar.name}}">
              </div>
              <div class="card-details">
                <div class="card-name">{{ avatar.name }}</div>
                <div class="card-input">
                  <label for="list-input{{avatar.name}}">Image URL:</label>
                  <input :id='"list-input" +  avatar.name' :value="avatar.url">
                  <button class="card-button" @click="inputChanged(avatar)">Change URL</button>
                </div>
                <div class="card-input">
                  <label for='"list-input" +  avatar.name'>Weight:</label>
                  <input :id='"list-weight" +  avatar.name' :value="avatar.weight" class="list-input" step="1"
                         type="number">
                  <button class="card-button" @click="weightChanged(avatar)">Change Weight</button>
                </div>
                <div class="card-buttons">
                  <button class="card-button card-button-delete" @click="deleteAvatar(avatar)">Delete</button>
                  <button class="card-button card-button-add" @click="addUserToPool(avatar)">!me</button>
                  <button class="card-button card-button-remove" @click="removeUserFromPool(avatar)">!removeme
                  </button>
                  <button class="card-button card-button-remove" @click="increment(avatar)">!increment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :style="{'background-image': 'url(' + require('./backdrop.png') + ')'}"
           class="active-wrapper"
      >
        <div class="active-container">
          <div class="label-container">
            <img :src="require('./title.png')" alt=""/>
          </div>
          <div class="grid-container">
            <div v-for="avatar in avatars" v-bind:key="avatar" class="grid-item">
              <OCSatCard :avatar="avatar"
                         :calculate-participation-for-display="calculateParticipationForDisplay(avatar.weight)"
                         :calculate-score-display="calculateScoreDisplay(avatar.wins, avatar.weight + 1)"
                         :calculate-wins-modifier-for-display="calculateWinsModifierForDisplay(avatar.wins)"
                         :last-win-time="lastWinTime(avatar.wins)"/>

            </div>
            <div v-for="index in 40" :key="index" class="empty-scrollable-space"></div>

          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<!--suppress JSUnusedLocalSymbols -->
<script>
import AOS from "aos";
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import ocSourceList from "@/assets/ocDirectory.json";

import tmi from "tmi.js";
import LuckyWheel from "@/views/oc/LuckyWheel.vue";
import OCSatCard from "@/views/oc/OCSatCard.vue";

const PREFIX = "[SillyV]"; // replace with your own prefix
// eslint-disable-next-line
function log(...args) {
  console.log(PREFIX, ...args);
}

export default {
  components: {
    OCSatCard,
    LuckyWheel,
  },
  data() {
    return {
      lastLog: Date.now(),
      spinning: false,
      fakeCommand: '',
      fakeName: '',
      totalInSpin: [],
      count: 0,
      endCount: 0,
      current: '',
      currentWinner: '',
      lastMessage: 'current OCs',
      showNsfwState: false,
      sorting: 'name',
      query: '',
      ocFiles: ocSourceList,
      imageFiles: imageSourceList,
      allImages: [],
      allItems: [],
      openCategories: [],
      participants: [],
      chosenParticipants: [],
      showTokens: true,
      winnerValue: ""
    }
  },
  created() {
    import('@/views/slices/slices.css')
  },
  mounted() {

    document.title = "OC Saturday"
    this.getGarticImages()
    const client = new tmi.Client({
      channels: ['sillyv']
    })
    client.connect()
    client.on('message', (channel, tags, message) => {


          if (this.spinning) {
            return
          }
          const name = tags['display-name']
          // chec if filteredImages contains name
          if (this.filteredImages.some((avatar) => avatar.name.toLowerCase() === name.toLowerCase())) {
            this.handleMessage(name, message, tags)
          }
        }
    )
    AOS.init()
  },
  methods: {
    datedLog(msg) {

      let currentTime = Date.now();
      let x = currentTime - this.lastLog
      this.lastLog = currentTime
      console.log('dated log: ' + x + ' milliseconds since last log, ' + msg)
    },
    getGarticImages() {
      this.lastLog = Date.now()
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allItems.push(...require1)
      }
      this.datedLog("finished loading images")

      const extractFileInfo = (filename) => {
        try {
          const regex = /^(\S+) (\d{4}-\d{1,2}-\d{1,2}) (\d{1,2}_\d{1,2}_\d{1,2}_\d{1,4})\.png$/;
          let matchResult = filename.match(regex);
          if (!matchResult) {
            return null;
          }

          // eslint-disable-next-line
          let [_, filenameName, filenameDate, filenameHour] = matchResult;
          let isSaturday = new Date(filenameDate).getDay() === 6;
          let isSunday = new Date(filenameDate).getDay() === 0;
          filenameHour = parseInt(filenameHour.split('_')[0]);
          return {filenameDate, filenameHour, isSaturday, isSunday};
        } catch (e) {
          console.error('Error in extractFileInfo:', e.message);
          return null;
        }
      };
      const startDate = new Date("2022-12-10");


      this.allItems = this.allItems
          .filter((item) => {
            // Combine first, second, and third filter conditions
            if (
                item.filename &&
                item.filename.split('.').length === 2 &&
                item.filename.split(' ').length === 3
            ) {

              const fileInfo = extractFileInfo(item.filename);
              return fileInfo &&
                  (fileInfo.isSaturday && fileInfo.filenameHour >= 16 || fileInfo.isSunday && fileInfo.filenameHour <= 9) &&
                  new Date(fileInfo.filenameDate) >= startDate;
            }
            return false;
          });

      this.datedLog("finished filtering images")
      let weekendMap = new Map();

      for (let item of this.allItems) {
        // Extract date information from filename
        let filenameParts = item.filename.split(" ");
        if (filenameParts.length < 2) continue;

        let filenameDate = filenameParts[1];

        // Convert date string to a Date object directly
        let dateKey = new Date(filenameDate);

        // Adjust the date for Sunday
        if (dateKey.getDay() === 0) {
          dateKey.setDate(dateKey.getDate() - 1);
        }

        // Convert Date object to string in 'YYYY-MM-DD' format
        let dateKeyAsString = dateKey.toISOString().slice(0, 10);

        if (weekendMap.has(dateKeyAsString)) {
          weekendMap.get(dateKeyAsString).push(item)
        } else {
          weekendMap.set(dateKeyAsString, [item])
        }
      }

      this.datedLog("finished mapping images")
      let nameCountTagMap = new Map()
      let nameToWinDatesMap = new Map()

      let tagsToExclude = ['silly', 'nsfw', 'best', 'complement', 'oc']
      this.datedLog("starting processing" + JSON.stringify(weekendMap))
      for (let [weekendKey, weekendItems] of weekendMap) {
        let tagMapCount = new Map()

        for (let item of weekendItems) {
          if (item.tags) {
            for (let tag of item.tags) {
              if (!tagsToExclude.includes(tag)) {
                tagMapCount.set(tag, (tagMapCount.get(tag) || 0) + 1);
              }
            }
          }
        }
        for (let [tag, tagCount] of tagMapCount) {
          if (tagCount < 6) {
            tagMapCount.delete(tag)
          }
        }

        for (let item of weekendItems) {
          if (item.tags) {
            for (let tag of item.tags) {
              let isTagInTagMapCount = tagMapCount.has(tag)
              if (isTagInTagMapCount) {
                let name = item.name
                if (nameCountTagMap.has(name)) {
                  nameCountTagMap.set(name, nameCountTagMap.get(name) + 1)
                } else {
                  nameCountTagMap.set(name, 1)
                }
                break
              }
            }
          }
        }

        console.log("tagMapCount: " + JSON.stringify(tagMapCount))
        // eslint-disable-next-line
        for (let [tag, count] of tagMapCount) {
          if (nameCountTagMap.has(tag)) {
            nameCountTagMap.set(tag, 0)
          }
          if (nameToWinDatesMap.has(tag)) {
            nameToWinDatesMap.get(tag).push(weekendKey)
          } else {
            nameToWinDatesMap.set(tag, [weekendKey])
          }
        }
      }
      this.datedLog("finished processing")


      let result = {}

      for (let [key, value] of nameCountTagMap) {
        if (value > 0) {
          let key1 = key.toLowerCase();
          result[key1] = {
            name: key1,
            weight: value,
            url: ["no url"]
          }
        }
      }

      // eslint-disable-next-line
      for (let [name, value] of nameToWinDatesMap) {
        let name2 = name.toLowerCase();
        if (name2 in result) {
          result[name2].wins = nameToWinDatesMap.get(name2)
        } else {
          result[name2] = {
            name: name2,
            weight: 0,
            wins: nameToWinDatesMap.get(name2),
            url: ["no url"]
          }
        }
      }

      for (let name of Object.keys(this.ocFiles)) {
        let name1 = name.toLowerCase();
        if (name1 in result) {

          result[name1].url = this.ocFiles[name1][0]
        } else {
          result[name1] = {
            name: name1,
            weight: 0,
            url: this.ocFiles[name1][0]
          }
        }
      }
      this.allImages = Object.values(result)
      this.datedLog("finished processing")

    },

    toggleTokens() {
      this.showTokens = !this.showTokens
    },
    incrementAll() {
      //open an dialog with an input field for a list of comma delimited names
      //increment the weight of each name in the list by 1
      let result = prompt("Enter a comma delimited list of names to increment", "");
      if (result !== null) {
        let names = result.split(",")
        let allNames = this.getSavedNames()
        for (let name of allNames) {
          if (names.includes(name.name)) {
            name.weight++
          }
        }
        //find all items in names who aren't in allNames
        let newNames = names.filter(name => !allNames.some(allName => allName.name === name))
        for (let newNAme of newNames) {
          allNames.push({name: newNAme, weight: 0, url: ["no url"]})
        }
        this.saveNames(allNames)
        this.allImages = this.getSavedNames()
        for (let allName of this.allNames) {
          this.handleCommand("!me", allName.name.toLowerCase())
        }
      }
    },
    calculateWinsModifierForDisplay(wins) {
      if (!wins || wins.length === 0) {
        return "Previous Wins Modifier: 0 (x2)"
      }
      return "Previous Wins Modifier: " + wins.length + " (-" + (wins.length) + ")"
    },
    calculateScoreDisplay(wins, weight) {
      return "Score: " + Math.max(this.calculateScoreNumber(wins, weight), 1)
    },
    calculateScoreNumber(wins, weight) {
      //  double weight if no wins,  otherwise weight minus wins
      if (!wins || wins.length === 0) {
        return weight * 2
      }
      return weight - wins.length
    },
    calculateParticipationForDisplay(weight) {
      let participationBonus = weight + 1
      if (participationBonus > 0 && this.showTokens) {
        return "Participation Tokens: " + participationBonus
      } else {
        return ""
      }
    },
    lastWinTime(wins) {
      //returns a x days ago string based on the date of the latest win
      if (!wins || wins.length === 0) {
        return ""
      }
      let lastWin = wins[wins.length - 1]
      let lastWinDate = new Date(lastWin)
      let today = new Date()
      let diffTime = Math.abs(today - lastWinDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return "Last Win: " + diffDays + " days ago"
    },
    queryChanged(state) {
      this.query = state.target.value.toLowerCase().trim();
    },
    getSavedNames() {

      return this.allImages;

    },
    addNewPerson() {
      let input = document.getElementById("new-name");
      let input2 = document.getElementById("new-url");
      let names = this.getSavedNames()
      let found = false
      for (let name of names) {
        if (name.name === input.value) {
          name.url = input2.value
          found = true
          break
        }
      }
      if (!found) {
        names.push({
          name: input.value.toLowerCase(),
          url: [input2.value],
          weight: -1
        })
      }
      this.saveNames(names)
      this.allImages = this.getSavedNames()
    },
    inputChanged(avatar) {
      let input = document.getElementById("list-input" + avatar.name);

      let names = this.getSavedNames()
      for (let name of names) {
        if (name.name === avatar.name) {
          name.url = input.value
          break
        }
      }
      this.saveNames(names)
      this.allImages = this.getSavedNames()
    },
    addUserToPool(avatar) {
      this.handleCommand("!me", avatar.name.toLowerCase())
    },
    removeUserFromPool(avatar) {
      this.name = avatar.name
      if (!this.participants.includes(name.toLowerCase())) {
        this.participants = this.participants.filter((name) => name !== avatar.name);
      }
    },
    increment(avatar) {
      let names = this.getSavedNames()
      for (let name of names) {
        if (name.name === avatar.name) {
          name.weight = name.weight + 1
          break
        }
      }
      this.saveNames(names)
      this.allImages = this.getSavedNames()
      this.handleCommand("!me", avatar.name.toLowerCase())
      this.query = ''
    },

    deleteAvatar(avatar) {
      let names = this.getSavedNames()
      let newNames = []
      for (let name of names) {
        if (name.name !== avatar.name) {
          newNames.push(name)
        }
      }
      this.saveNames(newNames)
      this.allImages = this.getSavedNames()
    },
    weightChanged(avatar) {

      let input = document.getElementById("list-weight" + avatar.name);
      let names = this.getSavedNames()
      for (let name of names) {
        if (name.name === avatar.name) {
          name.weight = parseInt(input.value)
          break
        }
      }
      this.saveNames(names)
      this.allImages = this.getSavedNames()
    },
    add10() {
      //   same as addAllUsersToPool but only adds 10 random users
      let names = this.allImages;
      let randomNames = []
      for (let i = 0; i < 10; i++) {
        let randomName = names[Math.floor(Math.random() * names.length)];
        randomNames.push(randomName.name)
      }
      for (let name of randomNames) {
        if (name) {
          this.handleCommand("!me", name)
        }
      }

    },
    addAllUsersToPool() {
      let names = this.allImages;
      for (let name of names) {
        if (name) {
          this.handleCommand("!me", name.name)
        }
      }
    },
    saveNames: function (names) {
      localStorage.setItem('names', JSON.stringify(names))
    },
    acceptOC() {
      this.totalInSpin = []
      let names = this.allImages;
      for (let name of names) {
        if (this.participants.includes(name.name) && name.name !== this.currentWinner) {
          // name.weight = parseInt(name.weight) + 1
        } else if (name.name === this.currentWinner) {
          name.weight = -1
          // eslint-disable-next-line no-prototype-builtins
          if (name.hasOwnProperty("wins")) {
            name.wins.push(new Date().toISOString().slice(0, 10));
          } else {
            name.wins = [new Date().toISOString().slice(0, 10)];
          }
        }
      }
      this.current = ""
      this.currentWinner = ""
      localStorage.setItem('names', JSON.stringify(names))
    },
    handleCommand: function (message, name) {
      if (message.toLowerCase() === "!me") {
        this.addToWheel(name)
      }
    },
    privilegeUser: function (tags) {
      try {
        return tags.badges.broadcaster === '1'
      } catch (e) {
        return false
      }
    },
    addToWheel(name) {
      // check if filteredImages has an item with name like name
      if (!this.filteredImages.some((avatar) => avatar.name.toLowerCase() === name.toLowerCase())) {
        return
      }
      if (!this.participants.includes(name.toLowerCase())) {
        this.participants.push(name.toLowerCase())
      }
    },
    clearWheel() {
      this.participants = []
      this.current = null
      this.currentWinner = null
    },
    spin() {
      this.spinning = !this.spinning
      this.chosenParticipants = this.participants
    },

    handleMessage(name, message, tags) {
      let privilegedUser = this.privilegeUser(tags);
      this.handleCommand(message, name, privilegedUser);
    },
    computed: {
      nameUrl() {
        return new URL(location.href).searchParams.get('name')
      },
    },
  }
  ,
  computed: {
    particiChart() {
      return this.chosenParticipants.map(name => {
        let person = this.allImages.filter(allName => name.toLowerCase() === (allName.name.toLowerCase()))[0];
        let score = this.calculateScoreNumber(person.wins, person.weight)
        if (score < 1) {
          score = 1
        }
        return {
          "label": name,
          "weight": score
        }
      })
    },
    filteredImages() {
      if (!this.allImages) {
        return []
      }
      console.log("filteredImages", this.allImages)
      let filter = this.allImages
          .filter(value => {
            if (this.query === '') {
              return true
            } else {
              return value.name.toLowerCase().includes(this.query.toLowerCase())
            }
          });
      filter.forEach((value) => {
        try {
          value.score = this.calculateScoreNumber(value.wins, value.weight)
        } catch (e) {
          value.score = 1
        }
      })
      log("filteredImages", filter)
      return filter.sort((b, a) => a.score - b.score)
    }
    ,
    avatars() {
      let newAvatars = [];

// If allImages is empty, return an empty array
      if (!this.allImages) {
        return [];
      }

// Create a map for quick lookups based on participant names
      const participantMap = new Map(this.allImages.map((avatar) => [avatar.name, avatar]));

// Iterate through participants array and find matching avatars
      for (let participant of this.participants) {
        // Find the avatar in the map and add it to the newAvatars array
        let matchingAvatar = participantMap.get(participant);
        if (matchingAvatar) {
          newAvatars.push(matchingAvatar);
        }
      }

// Return the array of avatars in the order of participants
      return newAvatars;
    }
  }
  ,
}
</script>
<style scoped>


.parent1 {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.full-list-container::-webkit-scrollbar {
  display: none;
}

.full-list-container {
  height: 65%;
  overflow: scroll;
  scrollbar-width: none;
  width: 100%;
}

.active-container {
  background: none;
  height: fit-content;
  width: 98%;
  margin-top: 30px;
}


.list-input {
  width: 40%;
  height: 30px;
  margin-top: 0;
}

.full-list-wrapper {
  width: 30%;
  height: 100vh; /* set a fixed height */
  overflow-y: hidden; /* enable vertical scrolling */
}

.active-wrapper {
  width: 70%;
  height: 100vh; /* set a fixed height */
  background-repeat: no-repeat;
  background-position: 25% 25%;
  background-size: 70%;

}


.button {
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-primary {
  color: #fff;
  background-color: #007bff;
}

.button-primary:hover {
  background-color: #0062cc;
}

.button-secondary {
  color: #333;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
}

.button-secondary:hover {
  background-color: #e6e6e6;

}

.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.form-input {
  display: block;
  width: 90%;
  padding: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  outline: none;
  border-color: #007bff;
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}


.card {
  display: flex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  max-width: 100%;
  max-height: 100%;
}

.card-details {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-name {
  font-weight: bold;
  font-size: 24px;
  color: #333;
}

.card-input {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.card-input label {
  font-weight: bold;
  font-size: 16px;
  color: #666;
}

.card-input input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  color: #333;
}

.card-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.card-button-delete {
  background-color: #f44336;
}

.card-button-add {
  background-color: #2196F3;
}

.card-button-remove {
  background-color: #ff9800;
}


.grid-container {
  height: 52vh;
  overflow: scroll;
  display: grid;
  width: 96%;
  margin-top: 30px;
  margin-inline-start: 20px;
  scroll-padding-bottom: 200px;
  overflow-x: hidden;
  padding-right: 17px;
  box-sizing: content-box;
  scroll-snap-align: end;
  grid-template-columns: repeat(auto-fill, minmax(197px, 1fr));
  gap: 20px;
}

.grid-item {
  width: 197px;
  border-radius: 23px;
  border: 4px solid #000;
  background: linear-gradient(180deg, #B69DE8 0%, #D1C2F0 36.98%, #B9A3E7 100%);
  box-shadow: 0 4px 55.7px 0 rgba(0, 0, 0, 0.72);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 386/572;
}

.grid-image img {
  max-width: 100%;
  aspect-ratio: 356/241;
  object-fit: cover;
  object-position: top;
}


.winner-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.winner-text h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.winner-text p {
  font-size: 20px;
  color: #666;
}


#home {
  background: linear-gradient(180deg, #642A92 0%, #2E062A 55.57%, #020521 100%);
  height: 97vh;
}

#home {
  overflow: hidden;
}


.label-container {
  border-radius: 41px;
  border: 4px solid #D4B3F4;
  background: linear-gradient(180deg, #8971B8 0%, #A18CCA 38.02%, #624A92 100%);
  box-shadow: 0 4px 57px 31px rgba(0, 0, 0, 0.33);
  width: 95%;
}

.label-container img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}


.empty-scrollable-space {
  height: 100px;
  width: 100%;
}


</style>
