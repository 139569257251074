<template>
  <div class="history">
    <button v-for="(his) in history.reverse()" :key="his" class="button-55" @click="onClick(his)">
      {{ getGridSize(his) }}
      <div
          :style='"background-color: "+his.cellColor+"; border: 4px solid " + his.marginColor '
          class="sample">
        {{ getCellSize(his) }}px
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: 'EmoteTemplateDownloadHistory',
  props: {changeValue: {}},
  data() {
    return {
      history: []
    }
  },
  methods: {
    onClick(his) {
      this.changeValue(his)
    },

    getGridSize(his) {
      return his.rows + " X " + his.columns
    },
    getCellSize(his) {
      let size;
      if (his.horizontalSizeCell === his.verticalSizeCell) {
        size = his.horizontalSizeCell;
      } else {
        size = his.horizontalSizeCell + "x" + his.verticalSizeCell;
      }
      return size
    }
  },
  mounted() {
    this.history = JSON.parse(localStorage.getItem('downloadedTemplates'));
    console.log(this.history.length)
  }
}
</script>
<style scoped>

.history {
  text-align: center;
  margin-inline-start: 48px;
  background: whitesmoke;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

* {
  font-family: Inter;
}


.sliders {
  text-align: center;
  margin-inline-start: 48px;
  background: whitesmoke;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.button-55 {
  width: 100%;
  text-align: start;
  padding-inline-start: 40px;
  margin-bottom: 10px;
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}


.sample {
  width: 150px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
