import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import CollageView from "@/views/collage/CollageView";
import OCView from "@/views/oc/OCView";
import SocialView from "@/views/social/SocialView";
import TimerView from "@/views/timer/TimerView";
import TopTenView from "@/views/topten/TopTenView.vue";
import SnitchView from "@/views/snitch/SnitchView.vue";
import SubTimerView from "@/views/timer/SubTimerView.vue";
import SorterView from "@/views/gallery/SorterView.vue";
import FMKView from "@/views/fmk/FMKView.vue";
import RaidView from "@/views/raid/RaidView.vue";
import DVDLogoView from "@/views/dvd/DVDLogoView.vue";
import DVDPfpView from "@/views/dvd/DVDPfpView.vue";
import BoopView from "@/views/boop/BoopView.vue";

import ChatSoundAlert from "@/views/sound/ChatSoundAlert.vue";
import oddsView from "@/views/odds/oddsView.vue";
import PollView from "@/views/poll/PollView.vue";
import PongView from "@/views/pong/PongView.vue";
import SocialHomeView from "@/views/social/SocialHomeView.vue";
import ChatGameMenuView from "@/views/games/chat/ChatGameMenuView.vue";
import ChatGameView from "@/views/games/chat/ChatGameView.vue";
import EndGameScoreView from "@/views/games/chat/EndGameScoreView.vue";
import StoreView from "@/views/store/StoreView.vue";
import ReferenceView from "@/views/reference/ReferenceView.vue";
import OcSaturdayView from "@/views/ocsaturday/ocSaturdayView.vue";
import PreviewView from "@/views/social/PreviewView.vue";
import SlicesLoginView from "@/views/slices/SlicesLoginView.vue";
import SlicesHomeView from "@/views/slices/SlicesHomeView.vue";
import SlicesChooseView from "@/views/slices/SlicesChooseView.vue";
import SlicesDownloadView from "@/views/slices/SlicesDownloadView.vue";
import SlicesDownloadConfirmationView from "@/views/slices/SlicesDownloadConfirmationView.vue";
import SlicesUploadPromptView from "@/views/slices/SlicesUploadPromptView.vue";
import RecapView from "@/views/recap/RecapView.vue";
import SocialViewDate from "@/views/social/SocialViewDate.vue";
import SocialHomeViewUI from "@/views/social/SocialHomeViewUI.vue";
import SocialHomeViewRaid from "@/views/social/SocialHomeViewRaid.vue";
import GartleView from "@/views/gartle/GartleView.vue";
import BingoView from "@/views/bingo/BingoView.vue";
import EmoteView from "@/views/emote/EmoteView.vue";
import DartsView from "@/views/darts/DartsView.vue";
import FMKHSView from "@/views/fmk/FMKHSView.vue";
import ClockView from "@/views/clock/ClockView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/collage',
        name: 'collage',
        component: CollageView
    },
    {
        path: '/oc',
        name: 'oc',
        component: OCView
    },
    {
        path: '/social/:page/:imageId',
        name: 'socialImage',
        component: SocialView
    },
    {
        path: '/streams/:date/:imageId',
        name: 'streamsImage',
        component: SocialViewDate
    },
    {
        path: '/streams/:date',
        name: 'streams',
        component: SocialViewDate
    },
    {
        path: '/social/:page',
        name: 'socialImage',
        component: SocialView
    },
    {
        path: '/social',
        name: 'socialHome',
        component: SocialHomeView
    },
    {
        path: '/socialUI',
        name: 'socialHomeUI',
        component: SocialHomeViewUI
    },
    {
        path: '/socialRaiders',
        name: 'socialHomeRaiders',
        component: SocialHomeViewRaid
    },
    {
        path: '/gallery/:page/:imageId',
        name: 'galleryImage',
        component: SocialView
    },
    {
        path: '/gallery/:page',
        name: 'galleryImage',
        component: SocialView
    },
    {
        path: '/gallery',
        name: 'galleryHome',
        component: SocialHomeView
    },
    {
        path: '/subtimer',
        name: 'subtimer',
        component: SubTimerView
    },
    {
        path: '/timer',
        name: 'timer',
        component: TimerView
    },
    {
        path: '/topten',
        name: 'topten',
        component: TopTenView
    },
    {
        path: '/snitch',
        name: 'snitch',
        component: SnitchView
    },
    {
        path: '/sorter',
        name: 'sorter',
        component: SorterView
    }, {
        path: '/pong',
        name: 'pong',
        component: PongView
    },
    {
        path: '/fmkhs',
        name: 'fmkhs',
        component: FMKHSView
    },
    {
        path: '/fmk',
        name: 'fmk',
        component: FMKView
    },
    {
        path: `/dvd/logo`,
        name: 'DVDLogo',
        component: DVDLogoView
    },
    {
        path: `/dvd/pfp`,
        name: 'DVDPfp',
        component: DVDPfpView
    },
    {
        path: '/raid',
        name: 'raid',
        component: RaidView
    },
    {
        path: '/boop',
        name: 'boop',
        component: BoopView
    },
    {
        path: '/chatAlert',
        name: 'chatAlert',
        component: ChatSoundAlert
    },
    {
        path: '/poll',
        name: 'poll',
        component: PollView
    },
    {
        path: '/bg3odds',
        name: 'odds',
        component: oddsView
    },
    {
        path: '/guesser',
        name: 'chatgamemenu',
        component: ChatGameMenuView
    },
    {
        path: '/game',
        name: 'game',
        component: ChatGameView
    },
    {
        path: '/endgame',
        name: 'endgame',
        component: EndGameScoreView
    },
    {
        path: '/store',
        name: 'store',
        component: StoreView
    },
    {
        path: '/reference',
        name: 'reference',
        component: ReferenceView
    },
    {
        path: '/saturday',
        name: 'saturday',
        component: OcSaturdayView
    },
    {
        path: '/preview',
        name: 'preview',
        component: PreviewView
    },
    {
        path: '/slices',
        name: 'slices',
        component: SlicesLoginView
    },
    {
        path: '/slices/home',
        name: 'slicesHome',
        component: SlicesHomeView
    },
    {
        path: '/slices/choose',
        name: 'slicesChoose',
        component: SlicesChooseView
    },
    {
        path: '/slices/download',
        name: 'slicesDownload',
        component: SlicesDownloadView
    },
    {
        path: '/slices/confirmation',
        name: 'slicesConfirmation',
        component: SlicesDownloadConfirmationView
    },
    {
        path: '/slices/upload',
        name: 'slicesPromptUpload',
        component: SlicesUploadPromptView
    },
    {
        path: '/recap/:page',
        name: 'recap',
        component: RecapView
    },
    {
        path: '/gartle',
        name: 'Gardle',
        component: GartleView
    },
    {
        path: '/bingo',
        name: 'Bingo',
        component: BingoView
    },
    {
        path: '/emote',
        name: 'Emote',
        component: EmoteView
    },
    {
        path: '/darts',
        name: 'Darts',
        component: DartsView
    },
    {
        path: '/clock',
        name: 'clock',
        component: ClockView
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
