<template>
  <div class="example">
    <cropper
        v-if="image"
        :src="image"
        class="cropper"
        :resize-image="{
            adjustStencil: false
        }"
        :stencil-size="defaultSize"
        :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 752/424,
          }"
        image-restriction="none"
    />
    <div class="controls">
      <input type="file" @change="onFileChange" accept="image/*" class="input-file">
      <div class="input-holder">
        <input type="text" @change="onFileChange" accept="image/*" class="input-file" v-model="url"
        >
        <button @click="submitUrl">Submit URL</button>
      </div>
      <h4 v-if="url && url !== image">Not all image sources work, due to restrictions by the hosts of the images. download the image and use the local image function if a url doesn't work</h4>
      <button class="toggle-button" @click="toggleDarkLight">Toggle Dark Light Grid</button>

    </div>
    <img :src="gridImage" class="grid-overlay-image" alt="Grid Overlay"
         v-if="image">

  </div>
</template>

<!--suppress JSUnusedLocalSymbols -->
<script>
import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "vueImageCropperExample",
  components: {
    Cropper
  },
  props: {},
  data() {
    return {
      url: "",
      darkImage: "https://artist-tools-for-gartic-phone.s3.eu-north-1.amazonaws.com/grid.png",
      lightImage: "https://artist-tools-for-gartic-phone.s3.eu-north-1.amazonaws.com/white-grid.png",
      gridImage: "https://artist-tools-for-gartic-phone.s3.eu-north-1.amazonaws.com/grid.png",
      image: null
    };
  },
  methods: {
    submitUrl() {
      this.image = this.url

    },
    toggleDarkLight() {
      if (this.gridImage === this.darkImage) {
        this.gridImage = this.lightImage
      } else {
        this.gridImage = this.darkImage
      }
    },
    defaultSize({imageSize, visibleArea}) {
      console.log(imageSize)
      console.log(visibleArea)
      return {
        width: 757,
        height: 424,
      };
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;

        const reader = new FileReader();

        reader.onload = (e) => {
          // Set the result of the FileReader as the image source
          this.image = e.target.result;
          console.log(this.image)
        };

        // Read the file as a Data URL
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>

<style>

.button input {
  display: none;
}

.cropper {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.example {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #decaff;
}

.grid-overlay-image {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-file {
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: auto;
  background-color: #c0ffee;

}

.input-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.input-holder button {
  width: 40%;
}

.input-holder input {
  width: 50%;
}
</style>
