<template>
  <div class="SlicesButtonOuter" :style="{ height: (buttonHeight) + 'px' }">
    <div class="SlicesButtonMid" :style="{ height: (buttonHeight-8) + 'px' }">
      <div class="SlicesButtonCenter" :style="{ height: (buttonHeight-10) + 'px' , backgroundColor: color}">
        <h1>{{ text }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlicesButton",
  props: {
    text: String,
    buttonHeight: Number,
    color: String
  },
  created() {
    import('./slices.css')
  }
}
</script>

<style scoped>

.SlicesButtonOuter {
  width: 275px;
  border: 2px solid #fff;
  filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.50));
  font-family: Irish Grover, serif;
}

.SlicesButtonMid {
  height: 92px;
  border: 4px solid #000;
}

.SlicesButtonCenter {
  height: 90px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 2px solid #fff;
  user-select: none;
}

.SlicesButtonOuter:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 1));
}

h1 {
  width: 100%;
  font-size: 20px;
}


</style>
