<template>
  <div class="page">
    <div class="logo">
      <img alt="Logo" src="@/assets/slices_logo.svg"/>
    </div>
    <div class="slices-login-next-event-label">
      <p>Next event in
        <span class="custom-number-style">{{ daysUntilEvent }}</span>
        days</p>
    </div>
    <SlicesButton
        buttonHeight=100
        class="login-button"
        color="#807EE0"
        hoverColor="#6441a5"
        text="Login with Twitch"
        @click="loginWithTwitch"/>
    <SlicesAdsView/>
  </div>

</template>

<script>
import SlicesButton from "@/views/slices/SlicesButton.vue";

export default {
  components: {SlicesButton},
  data() {
    return {
      daysUntilEvent: 7, // Replace with the actual number of days
    };
  },
  mounted() {
    // Add your Twitch login logic here
    /*curl -X GET 'https://id.twitch.tv/oauth2/validate' \
-H 'Authorization: OAuth <access token to validate goes here>'
*/
    let token = localStorage.getItem('twitchToken');
    if (token) {
      let url = `https://id.twitch.tv/oauth2/validate`
      let headers = {
        'Authorization': `OAuth ${token}`
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!');
        }
      }).then(() => {
        window.location.href = 'http://localhost:8080/slices/home';
      }).catch(error => {
        console.error(error);
      })
    }
  },
  methods: {
    loginWithTwitch() {
      // https://id.twitch.tv/oauth2/authorize?[parameters]
      let client_id = "mun73iujdvytp6ezl88wkl75d5cotp";
      let redirect_uri = "http://localhost:8080/slices/home";
      let response_type = "token";
      //   generate a uuid for state
      let state = this.randomUUID()
      localStorage.setItem("twitchLoginState", state)
      let scope = "user:read:email"
      window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}&scope=${scope}`;
    },
    randomUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  },
  created() {
    import('./slices.css')
  }
};
</script>

<style scoped>
.page {

  background-color: #c0ffee;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 85px;
}

.logo img {
  width: 676px;
  height: 196px;
  flex-shrink: 0;
}

.content {
  flex: 1;
  background-color: #decaff;
  text-align: center;
}

button {
  background-color: #6441a5;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.ads {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  background-color: red;
  width: 160px;
  height: 600px;
}

/* Add more styles for the vertical banner ad here */
.slices-login-next-event-label {
  display: flex;
  width: 601px;
  height: 78px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Irish Grover;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;

}

.login-button {
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
