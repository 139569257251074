import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import 'aos/dist/aos.css'
import domtoimage from "dom-to-image";
import VueSocialSharing from 'vue-social-sharing'
import vueMasonry from "vue-masonry/index";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserSecret} from '@fortawesome/free-solid-svg-icons'
import CustomScrollBar from 'custom-vue-scrollbar'
import 'custom-vue-scrollbar/dist/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

library.add(faUserSecret)


createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component(CustomScrollBar.name, CustomScrollBar)
    .component('VueDatePicker', VueDatePicker)
    .use(router)
    .use(VueSocialSharing)
    .use(domtoimage)
    .use(vueMasonry)
    .mount('#app')

