<template>
  <div class="image-grid">
    <!-- Use v-for to loop through Tab 1 image URLs -->
    <div v-for="(image, index) in imagesToShow" :key="index" class="grid-item" style="position: relative;">
      <img :src="image.url" alt="Image" class="tab-image"
           :class="{  'blurry':!showNSFW && !checkHasSet(image.url) && (!image.moderated || ((image.tags && image.tags.includes('nsfw'))))  }"
           @click="imageClicked(image.url)"
      />
      <div v-if="!showNSFW && !image.moderated && !checkHasSet(image.url)" class="overlay-text unmoderated"
           @click="clickCensors(image.url)">
        Unmoderated
      </div>
      <div v-else-if="!showNSFW && image.tags && image.tags.includes('nsfw') && !checkHasSet(image.url)" class="overlay-text nsfw"
           @click="clickCensors(image.url)"
      > NSFW
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'SocialImageGrid',
  data: () => ({
    clickedCensorsHashSet: {}
  }),
  props: {
    pageImages: {},
    imageClicked: {},
    showNSFW: {}

  },
  methods: {
    checkHasSet(imageUrl) {
      //   check if imageUrl is in clickedCensorsHashSet
      return this.clickedCensorsHashSet[imageUrl]
    },
    // Handle censors click
    clickCensors(imageUrl) {
      //   add imageUrl to clickedCensorsHashSet
      this.clickedCensorsHashSet[imageUrl] = true
    }
  },
  computed: {
    // Return the first 9 images
    imagesToShow() {
      //   iterate pageImages, if they are strings, keep as is, otherwise map to item.url
      return this.pageImages.map(item => {
            if (typeof item === 'string') {
              return {url: item, moderated: true}
            } else {
              return item
            }
          }
      )
    }
  }

}
</script>
<style scoped>


/* Tab content */

/* Text color for tab content */
.tab-content p {
  color: #333;
}

/* List of tags */
.detail-item ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.detail-item ul li {
  margin-bottom: 5px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;
}

.image-grid .grid-item {
  width: 100%;
  margin-right: .5rem;
  aspect-ratio: 758/424;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
  flex-basis: calc(33.33% - .6rem);
  margin-bottom: .7rem;
  padding-bottom: 0;
  background-color: transparent;
  overflow: hidden;
}


.tab-image {
  aspect-ratio: 75/42;
  object-fit: contain;

}

.tab-image:hover {
  opacity: 0.7
}

/* Style for NSFW images */
.overlay-text.nsfw {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(107, 104, 104, 0.7);
  color: #A44D4DFF; /* Adjust text color for Unmoderated */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  border-radius: 4px;
}

/* Style for Unmoderated images */
.overlay-text.unmoderated {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(107, 104, 104, 0.7);
  color: rgb(241, 213, 160); /* Adjust text color for Unmoderated */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  border-radius: 4px;
}


/* Style for the blurry image */
.blurry {
  filter: blur(10px); /* Less intense blur for Unmoderated */
}


@media (max-width: 768px) {
  .image-grid {
    flex-direction: column;
  }

  .image-grid .grid-item {
    flex-basis: 100%;
  }
}

/* Add more specific styles as needed */
</style>
