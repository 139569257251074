<template>
  <div class="main-menu-screen-end">
    <div class="chat-title"><h1 class="chat-title-text">Gartist Guesser</h1></div>

    <div class="end-game-main-column">
      <div class="winners-podium">
        <div class="winner-holder winner-holder-silver" v-if="scores.length > 1">
          <img :src="imageMap.get(1)"
               class="circle-winner-image" alt="silver-profile-picture">
          <div class="name-plate silver-name-plate">
            {{ scores[1].name }}<br>
            {{ scores[1].score }}
          </div>
        </div>
        <div class="winner-holder winner-holder-gold" v-if="scores.length > 0">
          <img :src="imageMap.get(0)"
               class="circle-winner-image" alt="gold-profile-picture">
          <div class="name-plate gold-name-plate">
            {{ scores[0].name }}<br>
            {{ scores[0].score }}
          </div>
        </div>
        <div class="winner-holder winner-holder-bronze" v-if="scores.length > 2">
          <img :src="imageMap.get(2)"
               class="circle-winner-image" alt="bronze-profile-picture">
          <div class="name-plate bronze-name-plate">
            {{ scores[2].name }}<br>
            {{ scores[2].score }}
          </div>
        </div>
      </div>
      <div class="losers " v-if="scores.length > 3">
        <custom-scrollbar class="losers-inside  losers-inside-inside">
          <div class="loser" v-for="(loser,i) in scores.slice(3)" v-bind:key="loser">
            <div class="loser-number"># {{ i + 4 }}</div>
            <div class="loser-name">{{ loser.name }}</div>
            <div class="loser-number"> {{ loser.score }}</div>
          </div>
        </custom-scrollbar>
      </div>

      <div class="end_buttons_holder">
        <button class="new-game" @click="startGame">New Game</button>
        <img src="./home.svg" class="home_end_circle_button" @click="goHome" alt="home">

      </div>
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      imageMap: {},
      winners: [
        {
          username: 'user1',
          score: 100,
          img: 'https://gartic-gallery.s3.eu-north-1.amazonaws.com/2023-05-16/paythephi/paythephi+2023-5-16+1_50_18_22.png'
        },
        {
          username: 'user1',
          score: 100,
          img: 'https://gartic-gallery.s3.eu-north-1.amazonaws.com/2023-05-16/paythephi/paythephi+2023-5-16+1_50_18_22.png'
        },
        {
          username: 'user1',
          score: 100,
          img: 'https://gartic-gallery.s3.eu-north-1.amazonaws.com/2023-05-16/paythephi/paythephi+2023-5-16+1_50_18_22.png'
        }
      ],
      losers: [
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100},
        {name: "Dean", points: 100}
      ],
      scores: []


    }
  },
  created() {
    import('./ChatGame.css')

  },
  mounted() {
    document.title = "Gartist Guesser - Results";
    let gameId = localStorage.getItem('gameId')
    let gameIdFromRoute = this.$route.query.gameId
    if (gameIdFromRoute !== gameId) {
      return
    }

    //   get rounds from localStorage
    this.rounds = JSON.parse(localStorage.getItem('rounds'))
    let scores = []
    for (let round of this.rounds) {
      let correctGuesses = round.guesses.filter(x => x.correct)
      //   sort by time
      correctGuesses.sort((a, b) => a.responseTime - b.responseTime)

      for (let i = 0; i < correctGuesses.length; i++) {
        let guess = correctGuesses[i]
        let scoreToAdd = guess.score
        console.log('adding score to player', guess.player, scoreToAdd)
        if (scores.some(x => x.name === guess.player)) {
          let score = scores.find(x => x.name === guess.player)
          score.score += scoreToAdd
        } else {
          scores.push({
            name: guess.player,
            score: scoreToAdd
          })
        }
      }
    }
    for (let score of scores) {
      score.score = +score.score.toFixed(1)
    }
    this.scores = scores.sort((a, b) => b.score - a.score)

    this.imageMap = new Map()
    let context = this
    //   iterate the three 1st scores or less
    for (let i = 0; i < Math.min(3, scores.length); i++) {
      let score = scores[i]
      let request = new XMLHttpRequest();
      request.open('GET', 'https://decapi.me/twitch/avatar/' + score.name, true);
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          context.imageMap.set(i, request.responseText)
        }
      };
      request.send();

    }
  }
  ,
  methods: {
    startGame() {
      // navigate 1 page back
      // noinspection JSUnresolvedFunction

      this.$router.go(-1)
    }
    ,
    goHome() {
      // noinspection JSUnresolvedFunction
      this.$router.go(-2)
    }
  }
}
</script>


<style>
.main-menu-screen-end {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: start;
  justify-content: start;
  background-color: #432C61;
}

.end-game-main-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.winners-podium {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 100px;
  width: 80%;
  height: 250px;
}

.winner-holder {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
}

.winner-holder-gold {
  justify-content: start;
}

.winner-holder-silver {
  justify-content: center;
}

.winner-holder-bronze {
  justify-content: end;
}

.circle-winner-image {
  width: 122px;
  height: 122px;
  flex-shrink: 0;
  margin-bottom: 10px;
  border-radius: 122px;
  border: 3px solid rgba(255, 255, 255, 0.50);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.name-plate {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 14px;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25);
  width: 191px;
  height: 63px;
  flex-shrink: 0;
}

.gold-name-plate {
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(180deg, #7A470F 0%, #B67A39 10.42%, #DCB374 18.23%, #B0702E 36.46%, #C99757 56.25%, #AA6725 74.48%, #BA8B60 90.1%, #6B3502 100%);

}

.bronze-name-plate {
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(180deg, #492907 0%, #8E591F 10.42%, #AD7E38 18.23%, #975512 36.46%, #966221 56.25%, #86490C 74.48%, #985F2A 90.1%, #412104 100%);

}

.silver-name-plate {
  border: 3px solid rgba(255, 255, 255, 0.50);
  background: linear-gradient(180deg, #898989 0%, #C2C2C2 10.42%, #767676 23.44%, #5F5F5F 34.9%, #747474 56.25%, #6D6D6D 74.48%, #7D7D7D 83.33%, #323232 100%);
}

.losers {
  padding-top: 10px;
  margin-top: 40px;
  width: 76%;
  height: 155px;
  flex-shrink: 0;
  border-radius: 17px;
  background: linear-gradient(180deg, #9D6ED9 0%, #B0A3FF 100%);
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.25) inset;
  padding-inline-end: 1%;
}

.losers-inside {
  padding-top: 20px;
  height: 135px;
  width: 99%;
  flex-shrink: 0;
  border-radius: 17px;
}

.losers-inside-inside {
  overflow: auto;
}

.new-game {
  margin-top: 20px;
  font-family: Jura, serif;
  font-size: 30px;
  bottom: 240px;
  border-radius: 57px;
  background: linear-gradient(180deg, #EFBE90 0%, #C072D3 58.85%, #A57BFF 100%);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 280px;
  height: 63px;
  flex-shrink: 0;

}


.loser {
  color: #FFF;
  font-family: Jura, serif;
  font-size: 20px;
  font-style: normal;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loser-number {
  width: 60px;

  margin-inline-end: 5px;
  margin-inline-start: 30px;
}

.loser-name {
  flex-grow: 9;
  margin-inline-start: 15px;
  text-align: start;
}

.home_end_circle_button {
  position: relative;
  height: 100%;
  margin-inline-start: 4%;
  margin-top: 4%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  align-content: center;

}

.end_buttons_holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 20px;
}
</style>
