<!--suppress HtmlRequiredAltAttribute -->
<template>
  <div class="gallery-screen-saver text">
    <img :src="currentProfileImage" class="raider">
    <img v-if="currentGames.length > 0" :src="currentGames[0].box_art_url" class="current-game">
    <img
        :style="{ transform: `translate(${defenderPosition.x}px, ${defenderPosition.y}px)`, height: `${defenderHeight}px`, objectFit: 'cover', objectPosition: 'top' }"
        class="defender" src="./shield.png"/>


    >
    <img v-for="currentEmote in currentEmotes" :id="`emote-` + currentEmote.key" :key="currentEmote.key"
         :src="currentEmote.url" :style="{ transform: `translate(${currentEmote.x}px, ${currentEmote.y}px)` }"
         class="emote"/>

  </div>
</template>

<script>
export default {
  name: "RaidView",
  data() {
    return {
      currentUsername: null,
      currentProfileImage: null,
      currentEmotes: [],
      currentGames: [],
      defenderPosition: {x: 0, y: 0},
      defenderSpeed: 0,
      defenderGravity: 0.5,
      defenderHeight: 450,
      log: ""
    };
  },
  methods: {
    addEmote(emote) {
      this.currentEmotes.push(emote);
    },
    startAnimation() {
      setInterval(() => {
        this.updatePosition();
      }, 16);
    },
    updatePosition() {
      // 66% of 1920
      const bounceArea = 1050;
      const destroyThreshold = 0;

      for (let currentEmote of this.currentEmotes) {
        currentEmote.x += currentEmote.speedX;
        currentEmote.y += currentEmote.speedY;
        if (currentEmote.x > bounceArea) {
          currentEmote.speedX = -(Math.floor(Math.random() * 10) + 10);
          currentEmote.speedY = -(Math.floor(Math.random() * 20) + 20);
        }
        // if horizontal speed is negative, apply gravity on speedY
        if (currentEmote.speedX < 0) {
          currentEmote.speedY += 1;
        }


        // if emote is offscreen (to the left, change its x,y and speedX to default values)
        if (currentEmote.x < -destroyThreshold) {
          currentEmote.x = Math.floor(Math.random() * 384);
          currentEmote.y = Math.floor(Math.random() * 216) + 432;
          currentEmote.speedX = Math.floor(Math.random() * 10) + 10;
          currentEmote.speedY = 0;
        }

      }

      this.defenderPosition.y += this.defenderSpeed;
      this.defenderSpeed += this.defenderGravity;
      if (Math.abs(this.defenderSpeed) > 3) {
        this.defenderGravity = -this.defenderGravity
      }
      this.defenderHeight = 425 - this.defenderPosition.y


    },
    getEmotes: function (broadcasterId, clientId, oauth, raidSize) {
      const url = "https://api.twitch.tv/helix/chat/emotes?broadcaster_id=" + broadcasterId;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Client-Id", clientId);
      xhr.setRequestHeader("Authorization", "Bearer " + oauth);
      let t = this
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          const resp = JSON.parse(xhr.responseText);
          const numberOfEmotesToAdd = Math.min(Math.max(raidSize, resp.data.length), 25);
          for (let i = 0; i < numberOfEmotesToAdd; i++) {
            let moduloEmote = resp.data[i % resp.data.length]
            // y = the central 20% of 1080, randomly
            // x = the first 20% of 1920, randomly
            let y = Math.floor(Math.random() * 216) + 432;
            let x = Math.floor(Math.random() * 684);

            t.addEmote({
              url: moduloEmote.images.url_1x,
              speedY: 0,
              speedX: Math.floor(Math.random() * 10) + 10,
              type: "png",
              y: y,
              x: x
            })
          }
        }
      }

      xhr.send();
    },
    getLastGame: function (currentGame, clientId, oauth) {
      let t = this
      let distinctListOfGameIds = new Set();
      distinctListOfGameIds.add(currentGame)
      // get games data by ids
      const gameUrl = "https://api.twitch.tv/helix/games?id=" + Array.from(distinctListOfGameIds).join("&id=");
      const gameXhr = new XMLHttpRequest();
      gameXhr.open("GET", gameUrl, true);
      gameXhr.setRequestHeader("Client-Id", clientId);
      gameXhr.setRequestHeader("Authorization", "Bearer " + oauth);
      gameXhr.onreadystatechange = function () {
        if (gameXhr.readyState === 4) {
          // JSON.parse does not evaluate the attacker's scripts.
          const gameResp = JSON.parse(gameXhr.responseText);
          t.currentGames = gameResp.data
          //set width and height of games
          for (let game of t.currentGames) {
            game.box_art_url = game.box_art_url.replace("{width}x{height}", "200x300");
          }
        }
      }
      gameXhr.send();
    }
  },
  mounted() {
    // do an action in 1 second

    setTimeout(() => {
      // play local mp3 file named raid.mp3

      // raidmp3
      const audio = new Audio(require('@/assets/raid.mp3'));
      audio.addEventListener("canplaythrough", () => {
        console.log("playing")
        audio.play();
      });

      // wait for audio to finish playing
      audio.addEventListener("ended",  () => {
        const audio2 = new Audio(require('@/assets/raid_music.mp3'));
        audio2.addEventListener("canplaythrough", () => {
          audio2.play();
        });
      });
    }, 1000)


    document.title = "Raid";
    const currentUrl = window.location.href;
    const params = new URLSearchParams(currentUrl.split("?")[1]);
    const clientId = params.get("clientId");
    const broadcasterId = params.get("broadcasterId");
    const oauth = params.get("oauth");
    const raidSize = params.get("raidsize");
    const currentGame = params.get("currentgame");
    this.getEmotes(broadcasterId, clientId, oauth, raidSize);
    this.getLastGame(currentGame, clientId, oauth);
    const url = "https://api.twitch.tv/helix/users?id=" + broadcasterId;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Client-Id", clientId);
    xhr.setRequestHeader("Authorization", "Bearer " + oauth);
    let t = this
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        // JSON.parse does not evaluate the attacker's scripts.
        const resp = JSON.parse(xhr.responseText);
        t.currentUsername = resp.data[0].display_name
        t.currentProfileImage = resp.data[0].profile_image_url.replace("{width}x{height}", "300x300")
      }
    }
    xhr.send();
    this.startAnimation();
  }
}
</script>

<style scoped>

.raider {
  position: absolute;
  border-radius: 20px;
  top: 33.5vh;
  border: 5px solid #2d2a37;
  left: 20vw;
  width: 22vw;
  height: 22vw;
}

.defender {
  top: 33.5vh;
  z-index: -4;
  position: absolute;
  right: 20vw;
  width: 22vw;
  height: 22vw;
}

.gallery-screen-saver {
  position: absolute;
  top: 0;
  background-color: #2c3e5023;
  left: 0;
  width: 1920px;
  height: 1080px;
  display: flex;
  overflow: hidden;
}

.emote {
  z-index: -3;
  position: absolute;
  width: 100px;
  height: 100px;
}

/*centered to the center of the screen*/


.current-game {
  border: 5px solid #2d2a37;
  position: absolute;
  top: 30%;
  left: 12%;
  width: 150px;
  z-index: -1;
  aspect-ratio: 2/3;
  transform: rotate(-45deg);
}


</style>
