<template>

  <div>
    <h1>FMKHS {{ this.allCards.length }}</h1>
    <!--    a row with three images-->
    <div class="row" style="display: flex; flex-direction: row">
      <div v-for="card in currentCards" :key="card.id">
        <img :src="'https://art.hearthstonejson.com/v1/render/latest/enUS/256x/' + card.id + '.png'" alt="card"
             @click="clickedCard = card">
      </div>
    </div>
    <button @click="getThreeRandomCards">Get Three Random Cards</button>
    <h1 v-if="clickedCard">{{ clickedCard }}</h1>
    <h1 v-if="loading"
        style="color: red">LOADING</h1>
    <div v-for="type in typeValues" :key="type">
      <h2>{{ type }}</h2>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="attack in attackValues" :key="attack">
        <h2>{{ attack }}</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="health in healthValues" :key="health">
        <h2>{{ health }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="cost in costValues" :key="cost">
        <h2>{{ cost }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="faction in factionValues" :key="faction">
        <h2>{{ faction }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="rarity in rarityValues" :key="rarity">
        <h2>{{ rarity }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="set in setValues" :key="set">
        <h2>{{ set }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="cardClass in cardClassValues" :key="cardClass">
        <h2>{{ cardClass }},</h2>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <div v-for="race in raceValues" :key="race">
        <h2> {{ race }},</h2>
      </div>
    </div>

  </div>

</template>

<script>

const acceptableTypes = ["MINION"]
const excludedSets = ["LETTUCE"]
export default {
  name: "FMKHSView.vue",
  data() {
    return {
      loading: false,
      allCards: [],
      typeValues: [],
      currentCards: [],
      attackValues: [],
      healthValues: [],
      costValues: [],
      factionValues: [],
      rarityValues: [],
      setValues: [],
      cardClassValues: [],
      raceValues: [],
      clickedCard: null
    }
  },
  methods: {
    getThreeRandomCards() {
      let threeCards = []
      for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * this.allCards.length)
        threeCards.push(this.allCards[randomIndex])
      }
      this.currentCards = threeCards
    }
  },
  mounted() {
    const url = "https://api.hearthstonejson.com/v1/latest/all/cards.json"
    this.loading = true
    const that = this
    fetch(url)
        .then(response => response.json())
        // .then(response => console.log(response))
        .then(response => that.allCards = response)
        .catch(error => console.error(error))
        .then(() => {
          //   filter out the cards that are not acceptable
          let filteredCards = []
          for (let card of that.allCards) {
            if (acceptableTypes.includes(card.type) && !excludedSets.includes(card.set)

            ) {
              filteredCards.push(card)
            }
          }
          that.allCards = filteredCards
        })
        .then(() => console.log(that.allCards))

        .then(() => {


          for (let allCard of this.allCards) {
            if (!that.typeValues.includes(allCard.type)) {
              that.typeValues.push(allCard.type)
            }
            if (!that.attackValues.includes(allCard.attack)) {
              that.attackValues.push(allCard.attack)
            }
            if (!that.healthValues.includes(allCard.health)) {
              that.healthValues.push(allCard.health)
            }
            if (!that.costValues.includes(allCard.cost)) {
              that.costValues.push(allCard.cost)
            }
            if (!that.factionValues.includes(allCard.faction)) {
              that.factionValues.push(allCard.faction)
            }
            if (!that.rarityValues.includes(allCard.rarity)) {
              that.rarityValues.push(allCard.rarity)
            }
            if (!that.setValues.includes(allCard.set)) {
              that.setValues.push(allCard.set)
            }
            if (!that.cardClassValues.includes(allCard.cardClass)) {
              that.cardClassValues.push(allCard.cardClass)
            }
            if (!that.raceValues.includes(allCard.race)) {
              that.raceValues.push(allCard.race)
            }
            //   sort attack values
            that.attackValues.sort((a, b) => a - b)
            that.healthValues.sort((a, b) => a - b)
            that.costValues.sort((a, b) => a - b)

          }

        })
        .finally(() => that.loading = false)


  }
}
</script>

<style scoped>

</style>
