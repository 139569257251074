<template>
  <div class="slices-header">
    <div class="slices-header-logo">
      <img src="@/assets/slices_logo.svg" alt="Logo"/>
    </div>
    <div class="slices-header-name-holder">
      <h1 class="slices-header-name">{{ displayName }}</h1></div>
    <div class="profile-image-slices-holder">
      <div class="profile-image-outer-border"><img class="profile-image-slices" :src="profileImageUrl" alt="Logo"/>
      </div>
      <div class="dropdown-menu">
        <ul>
          <li @click="logoutFromTwitch">logout</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SlicesHeaderView',
  data() {
    return {
      displayName: '',
      profileImageUrl: ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.validateToken()
    }, 4000)
    this.displayName = localStorage.getItem('twitchDisplayName');
    this.profileImageUrl = localStorage.getItem('twitchProfileImageUrl');
  },
  created() {
    import('./slices.css')
  },
  methods: {
    logoutFromTwitch() {
      localStorage.removeItem('twitchToken');
      localStorage.removeItem('twitchLoginState');
      let client_id = "mun73iujdvytp6ezl88wkl75d5cotp";
      let token = localStorage.getItem('twitchToken');
      let url = `https://id.twitch.tv/oauth2/revoke?client_id=${client_id}&token=${token}`
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }).then(response => {
        console.log(response)
        window.location.href = 'http://localhost:8080/slices';
      })
    },
    getTwitchInfo() {
      //   https://api.twitch.tv/helix/users
      let url = `https://api.twitch.tv/helix/users`
      let token = localStorage.getItem('twitchToken');
      let headers = {
        'Authorization': `Bearer ${token}`,
        'Client-Id': 'mun73iujdvytp6ezl88wkl75d5cotp'
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!');
        }
      }).then(response => {
        console.log(response);
        localStorage.setItem('twitchUser', JSON.stringify(response));
        this.displayName = response.data[0].display_name;
        this.profileImageUrl = response.data[0].profile_image_url;
        localStorage.setItem('twitchDisplayName', this.displayName);
        localStorage.setItem('twitchProfileImageUrl', this.profileImageUrl);
      }).catch(error => {
        console.error(error);
      });
    },
    validateToken() {
      let token = localStorage.getItem('twitchToken');
      let url = `https://id.twitch.tv/oauth2/validate`
      let headers = {
        'Authorization': `OAuth ${token}`
      }
      fetch(url, {
        method: 'GET',
        headers: headers
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!');
        }
      }).then(response => {
        console.log(response);
        localStorage.setItem('twitchUser', JSON.stringify(response));
        this.getTwitchInfo()
      }).catch(error => {
        console.error(error);
        //   navigate to login
        window.location.href = 'http://localhost:8080/slices';
      });
    },
  },
}
</script>
<style scoped>

.header {
  justify-content: space-evenly;
  display: flex;
  background-color: #decaff;
  width: 100%;
  flex: 1;
}

.content img {
  max-width: 200px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  animation: slideDown 0.3s ease-in-out;
}

.profile-image-slices-holder:hover .dropdown-menu {
  display: block;
}


.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 8px;
}

/* Dropdown trigger style */
.dropdown-trigger {
  cursor: pointer;
}

/* Animation to slide down */
@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Add more styles for the vertical banner ad here */
.profile-image-slices {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  border: 4px solid #fff;
}

.profile-image-outer-border {
  margin-top: 22px;
  width: 134px;
  height: 134px;
  border-radius: 50%;
  border: 4px solid #000;
  overflow: hidden;
}

.slices-header-logo {
  width: 300px;
  height: 90px;
}

.slices-header-name {
  color: #000;
  width: 100%;
  text-align: start;
  font-family: Irish Grover;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slices-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  flex: 1;
}

.slices-header-name-holder {
  width: 50%;
  display: flex;
}
</style>
