<template>
  <div>
    <div
    >
      Latest: {{ latest }}


      <div v-for="first in thisWeek" v-bind:key="first">
aaa        {{ first }}
      </div>

    </div>
    <div v-for="first in firsts" v-bind:key="first.timestamp">
      <!--      {{ first.name }}-->
    </div>


  </div>
</template>

<script>
import text from 'raw-loader!@/assets/topten/mockData.txt'

Array.prototype.minBy = function (pluck) {
  return this.reduce((min, x) => min && pluck(min) <= pluck(x) ? min : x, null)
}

Array.prototype.maxBy = function (pluck) {
  return this.reduce((max, x) => max && pluck(max) >= pluck(x) ? max : x, null)
}

export default {
  name: "TopTen",
  data() {
    return {
      firsts: []
    }
  },
  computed: {
    latest() {
      let latest = this.firsts[0]
      let latestName = ''

      for (let i = 0; i < this.firsts.length; i++) {
        if (this.firsts[i].timestamp > latest.timestamp) {
          latest = this.firsts[i]
          latestName = this.firsts[i].name
        }

      }
      return latestName


    },
    thisWeek() {
      let thisWeekFirsts = []
      let pastWeek = Date.now() - 8.64e+8
      this.firsts.forEach(item => {
        if (item.timestamp > pastWeek) {
          thisWeekFirsts.push(item)
        }
      });

      let map1 = new Map();


      for (let thisWeekFirst of thisWeekFirsts) {
        let times = map1.get(thisWeekFirst.name);
        if (!times) {
          map1.set(thisWeekFirst.name, 1)
        } else {
          map1.set(thisWeekFirst.name, 1 + times)
        }
      }

      console.log(map1)
      let strings = Object.keys(map1).sort((a, b) => {
        return map1[b] - map1[a];
      });
      console.log(strings)
      return strings

    },
    // thisMonth() {
    //
    // },
    // thisYear() {
    //
    // }
  },
  mounted() {
    document.title = "Top Ten"
    // const media = topTenList
    // console.log(media) // => [ "foo", "bar" ]
    let x = text.toString().split('\n', 730);

    for (let i = 0; i < x.length; i++) {
      let line = x[i]
      this.firsts.push({name: line.split(",")[0], timestamp: line.split(",")[1]})
    }


  }

}
</script>

<style scoped>

</style>


<!--import helloText from './hello.txt';  // OR: const helloText = require('./hello.txt')

export default {
  //...
  methods: {
    getPoemList () {
      console.log({ helloText });
    }
  }
}
-->
