<template>
  <div class="slice-image-button">
    <h1>{{ title }}</h1>
    <h1 :style="divStyle(subTitle)">{{ subTitle }}</h1>
    <div class="SlicesImageOuter">
      <div class="SlicesImageMid">
        <div class="SlicesImageCenter">
          <img :src="url">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SlicesPictureButton',
  props: {
    title: {},
    subTitle: {},
    number: {},
    total: {},
    url: {}
  },
  created() {
    import('./slices.css')
  },
  methods: {
    divStyle(text) {
      if (text === "-1") {
        return {opacity: 0}
      } else {
        return {}
      }
    }
  }
}
</script>
<style scoped>

.SlicesImageCenter img {
  width: 100%;
  height: 100%;
  max-width: 356px;
  max-height: 206px;
  background-color: red;
  object-fit: cover;
}

.SlicesImageOuter {
  border: 2px solid #fff;
  background-color: coral;
  filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.50));
  font-family: Irish Grover, serif;
}

.SlicesImageOuter:hover {
  cursor: pointer;
  filter: drop-shadow(0px 4px 21px rgba(0, 0, 0, 1));
}

.SlicesImageMid {
  border: 4px solid #000;
}

.SlicesImageCenter {
  border: 2px solid #fff;
  user-select: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

h1 {
  color: #000;
  text-align: center;
  font-family: Irish Grover, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 0.4;
}

/* Add more styles for the vertical banner ad here */
.slice-image-button {

}
</style>
