<template>
  <div>

    <div class="upper-name-list">
      <div
          v-for="(name, index) in allNames"
          :key="index"
          @click="selectUpperName(name)"
          :class="{ selected: name === selectedUpperName }">
        {{ name }}
      </div>
    </div>
    <div class="date-list">
      <div
          v-for="(date, index) in displayedDates"
          :key="index"
          @click="selectDate(date)"
          :class="{ selected: date === selectedDate }"
      >
        {{ date }}
      </div>
    </div>

    <div class="name-list">
      <div v-for="(name, index) in names" :key="index">
        <div class="name-item">
          {{ name }}
        </div>
        <div class="image-grid">
          <div class="image-item" v-for="image in showNames.get(name)" :key="image.url">
            <img :src="image.url" alt="Image">
            {{ image.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageSourceList from "@/assets/GarticDownloads/gartic.json";
import ocSourceList from "@/assets/ocDirectory.json";

export default {
  name: 'OCSaturdayView',
  data() {
    return {
      dates: ['Date 1', 'Date 2', 'Date 3'], // Replace with your date data
      selectedDate: null,
      selectedName: null,
      imageFiles: imageSourceList,
      ocFiles: ocSourceList,
      allImages: null,
      allItems: [],
      showNames: new Map(),
      selectedUpperName: null,

    };
  },
  computed: {
    allNames() {
      if (!this.allImages) {
        return [];
      }
      //   iterate all dates in allImages, and get all names
      let allNames = new Set()
      // noinspection JSUnusedLocalSymbols
      // eslint-disable-next-line
      for (let [key, value] of this.allImages) {
        // noinspection JSUnusedLocalSymbols
        // eslint-disable-next-line no-unused-vars
        for (let [name, items] of value) {
          allNames.add(name)
        }
      }
      console.log('allnames', allNames)
      let from = Array.from(allNames);
      // sort
      from.sort((a, b) => {
        return a.localeCompare(b)
      })
      return from
    },

    displayedDates() {
      if (this.selectedUpperName === null) {
        return this.dates
      } else {
        //   iterate all dates,  find all dates that have selectedUpperName as a key.
        //   return those dates
        let dates = []
        // noinspection JSUnusedLocalSymbols
        // eslint-disable-next-line
        for (let [key, value] of this.allImages) {
          if (value.has(this.selectedUpperName)) {
            dates.push(key)
          }
        }
        return dates
      }


    },
    names() {
      console.log(this.selectedDate)
      if (!this.selectedDate) {
        return [];
      } else {

        if (this.selectedUpperName) {
          return [this.selectedUpperName];
        } else {
          return Array.from(this.allImages.get(this.selectedDate).keys());
        }


      }
    },
    images(name) {
      if (!this.selectedName) {
        return [];
      }
      let newVar = this.allImages.get(this.selectedDate);
      console.log(newVar)
      return newVar.get(name)
    }
  },
  mounted() {
    this.getGarticImages()

  },
  methods: {
    selectUpperName(name) {
      if (this.selectedUpperName === name) {
        this.selectedUpperName = null;
      } else {
        this.selectedUpperName = name;
      }
      this.selectedDate = null;
      this.showNames = new Map();
    },
    getGarticImages() {
      let imageList = this.imageFiles;
      for (let imageFile of imageList) {
        let require1 = require('@/assets/GarticDownloads/' + imageFile + '.json');
        this.allItems.push(...require1)
      }
      this.allItems = this.allItems
          .filter((item) => {
            return item.filename &&
                (item.filename.match(/\./g) || []).length === 1 &&
                (item.filename.match(/ /g) || []).length === 2
          })
          .filter((item) => {
            try {
              let filenameTime = item.filename.split(".")[0].split(" ")[2]
              let filenameHour = parseInt(filenameTime.split("_")[0])
              let filenameDate = item.filename.split(".")[0].split(" ")[1]
              // filenameDate is yyyy-mm-dd.  get weekday out of it
              let filenameWeekDay = new Date(filenameDate).toLocaleDateString('en-US', {weekday: 'long'})
              let isSaturday = filenameWeekDay === "Saturday"
              let isSunday = filenameWeekDay === "Sunday"
              return isSaturday && filenameHour >= 16 || isSunday && filenameHour <= 9
            } catch (e) {
              return false
            }
          })
          .filter((item) => {
            let filenameDate = item.filename.split(".")[0].split(" ")[1]
            let startDate = new Date("2022-12-10")
            return new Date(filenameDate) >= startDate
          })

      let weekendMap = new Map()
      for (let item of this.allItems) {
        let filenameDate = item.filename.split(".")[0].split(" ")[1]
        // filenameDate is yyyy-mm-dd.  get weekday out of it
        let filenameWeekDay = new Date(filenameDate).toLocaleDateString('en-US', {weekday: 'long'})
        let isSunday = filenameWeekDay === "Sunday"
        let dateKey = new Date(filenameDate)
        if (isSunday) {
          dateKey.setDate(dateKey.getDate() - 1)
        }
        let dateKeyAsString = dateKey.toISOString().slice(0, 10)
        if (weekendMap.has(dateKeyAsString)) {
          weekendMap.get(dateKeyAsString).push(item)
        } else {
          weekendMap.set(dateKeyAsString, [item])
        }
      }
      let tagsToExclude = ['silly', 'nsfw', 'best', 'complement', 'oc']
      let weekendMapWithItems = new Map()
      for (let [key, items] of weekendMap) {
        let tagMapCount = new Map()
        for (let item of items) {
          if (item.tags) {
            for (let tag of item.tags) {
              if (tagsToExclude.includes(tag)) {
                continue
              }
              if (tagMapCount.has(tag)) {
                let items = tagMapCount.get(tag);
                items.push(item)
                tagMapCount.set(tag, items)
              } else {
                tagMapCount.set(tag, [item])
              }
            }
          }
        }
        for (let [tag, count] of tagMapCount) {
          if (count < 6) {
            console.log("deleting", tag, count)
            tagMapCount.delete(tag)
          } else {
            weekendMapWithItems.set(key, tagMapCount)
          }
        }
      }

      // iterate weekendMapWithItems, in each date, iterate names, and remove names that don't have 6 items
      // noinspection JSUnusedLocalSymbols
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of weekendMapWithItems) {
        for (let [name, items] of value) {
          if (items.length < 6) {
            value.delete(name)
          }
        }
      }

      console.log(weekendMapWithItems)
      this.dates = Array.from(weekendMapWithItems.keys())
      this.allImages = weekendMapWithItems

    },
    selectDate(date) {
      this.selectedDate = date;
      this.showNames = this.allImages.get(date)


    },
    showImages(name) {
      this.selectedName = name;
    },
  },
};
</script>

<style scoped>
.date-list {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 10px;
  overflow-x: auto;
  flex-wrap: wrap; /* Add flex-wrap property */
}

.upper-name-list {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 10px;
  overflow-x: auto;
  flex-wrap: wrap; /* Add flex-wrap property */
}


.upper-name-list > div {
  cursor: pointer;
  color: #fff;
  padding: 10px 10px;
  margin: 0 10px;
  font-size: 13px;
  border-radius: 5px;
}

.upper-name-list > .selected {
  background-color: #ff5722;
}


.date-list > div {
  cursor: pointer;
  color: #fff;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 18px;
  border-radius: 5px;
}

.date-list > .selected {
  background-color: #ff5722;
}

.name-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.name-item {
  cursor: pointer;
  text-align: center;
  background-color: #ff5722;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.image-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Add more styles for images within the image grid */
</style>
