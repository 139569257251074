<template>
  <main id="home" class="home-main">
    <div class="rotate">
      <TransitionGroup name="list">
        <div v-for="section in actualSections" :key="section.title" class="section_header">
          <div v-if="section.type === 'Section'" class="section_title" @click="toggleSection(section.title)">
            {{ section.title }}
          </div>
          <div v-if="section.type === 'subSection'" class="sub_section_header">
            {{ section.title }}
          </div>
          <div v-if="section.type === 'firstSubSection'" class="first_sub_section_header">
            {{ section.title }}
          </div>
        </div>
      </TransitionGroup>
    </div>
    <img alt="base_image"
         class="base_image"
         src="https://gartic-gallery.s3.eu-north-1.amazonaws.com/2024-03-27/sillyv/sillyv+2024-3-27+21_43_36_142.png">


    <div class="bottom-header">
      <p>
        sillyvcreative@gmail.com<br> discord: @silly.v
      </p>
    </div>
    <div class="slide">
      Slide into my DMs
    </div>
  </main>
</template>


<!-- Use preprocessors via the lang attribute! e.g. <style lang="scss"> -->

<script>
import AOS from "aos";

export default {
  data() {
    return {
      openSection: "Extensions",
      sections: [
        {
          title: "About Me",
          type: "Section",
        },
        {
          title: "Extensions",
          type: "Section",
        },
        {
          title: "Streamer Tools for Gartic Phone",
          type: "firstSubSection",
          parent: "Extensions"
        },
        {
          title: "Artist Tools for Gartic Phone",
          type: "subSection",
          parent: "Extensions"
        },
        {
          title: "Custom Avatars for Gartic Phone",
          type: "subSection",
          parent: "Extensions"
        }, {
          title: "Custom Games for Gartic Phone",
          type: "subSection",
          parent: "Extensions"
        },
        {
          title: "Streaming Tools",
          type: "Section",
        },
        {
          title: "Layout Avatars",
          type: "firstSubSection",
          parent: "Streaming Tools"
        },
        {
          title: "Boop",
          type: "subSection",
          parent: "Streaming Tools"
        },
        {title: "Socials", type: "Section"},
        {title: "Twitch", type: "firstSubSection", parent: "Socials"},
        {title: "Twitter", type: "subSection", parent: "Socials"},
        {title: "Instagram", type: "subSection", parent: "Socials"},
        {title: "Discord", type: "subSection", parent: "Socials"},
        {title: "YouTube", type: "subSection", parent: "Socials"},
        {title: "TikTok", type: "subSection", parent: "Socials"},
        {title: "Facebook", type: "subSection", parent: "Socials"},
        {title: "Snapchat", type: "subSection", parent: "Socials"},
        {title: "LinkedIn", type: "subSection", parent: "Socials"},
        {title: "Pinterest", type: "subSection", parent: "Socials"},
        {title: "Reddit", type: "subSection", parent: "Socials"},
        {title: "Tumblr", type: "subSection", parent: "Socials"},
        {title: "OnlyFans", type: "subSection", parent: "Socials"},
        {title: "Patreon", type: "subSection", parent: "Socials"},
        {title: "Ko-fi", type: "subSection", parent: "Socials"},

      ]
    }
  },
  methods: {
    toggleSection(section) {
      if (this.openSection === section) {
        this.openSection = null
      } else {
        this.openSection = section
      }
      console.log(this.openSection)
    }
  },
  mounted() {
    document.title = "SillyV"
    AOS.init()
  },
  computed: {
    actualSections: function () {
      console.log(this.openSection)
      return this.sections.filter(section => {
        console.log(section)
        console.log("Section Type: " + section.type)
        console.log("Open Section: " + this.openSection)
        console.log("Section Type: " + section.type === "Section" || section.type === this.openSection)
        return section.type === "Section" || section.parent === this.openSection
      })

    },
  },
}
</script>

<style scoped>
* {
  font-family: Inter, sans-serif;
  margin: 0;
  padding: 0;
}


.base_image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  clip-path: polygon(0 90%, 0 50%, 40% 0, 90% 0);

}

.section_header {
  text-align: start;
  rotate: -45deg;

}

.sub_section_header {
  padding-inline-start: 6vh;
  background-color: #fadea7;
  width: 50vh;
  margin-top: 2vh;
  clip-path: polygon(0 0, 0 100%, 90% 100%, 100% 50%, 90% 0);
}

.first_sub_section_header {
  padding-inline-start: 6vh;
  background-color: #fadea7;
  width: 50vh;
  margin-top: 4vh;
  clip-path: polygon(0 0, 0 100%, 90% 100%, 100% 50%, 90% 0);
}

.section_title {
  clip-path: polygon(0 0, 0 100%, 80% 100%, 100% 50%, 80% 0);
  width: 40vh;
  margin-inline-start: -2vh;
  padding: 3vh;
  font-size: 3vh;
  background-color: #fadea7;
  padding-inline-start: 10vh;
  margin-top: 6vh;


}

.rotate {
  width: 40vh;
  height: 100vh;
  rotate: 45deg;
  margin-inline-start: 40vh;
  margin-top: -10vh;
}

.bottom-header {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  bottom: 0;
  width: 50vh;
  right: 0;
  padding: 2vh;
  text-align: center;
  background-color: #9876DD;
  font-size: 2vh;
  clip-path: polygon(0 100%, 50% 100%, 100% 80%, 100% 0);
}

.bottom-header p {
  margin-top: 20vh;
  text-align: start;
  margin-inline-start: 20vh;
  color: black;
  text-decoration: none;
}

.slide {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 4vh;
  height: 36vh;
  width: auto;
  rotate: -45deg;
}

.home-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}


</style>

