<!-- GridConfiguration.vue -->

<template>
  <div class="emote-parent">
    <div class="ezmote-header">
      <div class="ezmote-logo">
        EzEMOTE
      </div>
      <div class="ezmote-tabs">
        <div class="ezmote-tab">Template</div>
        <div class="ezmote-tab">Chat Preview</div>
        <div class="ezmote-tab">Share</div>
      </div>
    </div>
    <div class="preview">
      <div :style="gridStyle">
        <div class="gridMidHolder">
          <div v-for="i in parseInt(currentTemplate.rows)" :key="i" :style="rowStyle(i)">
            <div v-for="j in parseInt(currentTemplate.columns)" :key="j" :style="cellStyle(i,j)"></div>
          </div>
        </div>
        <img v-if="uploadedImage" :src="uploadedImage" alt="Uploaded Emote Sheet"
             style="position: absolute; top: 0; left: 0;"/>
        <div class="gridMidHolder">
          <div v-for="i in parseInt(currentTemplate.rows)" :key="i" :style="rowStyle(i)">
            <div v-for="j in parseInt(currentTemplate.columns)" :key="j" :style="cellStyleSelected(i,j)"
                 @click="toggleSelected(i,j)"
            ></div>
          </div>
        </div>
      </div>

    </div>
    <Sliders v-if="!showHistory" :change-value="changeValue" :current-template="currentTemplate"/>
    <EmoteTemplateDownloadHistory v-if="showHistory" :change-value="changeValue"/>
    <EmoteTemplateDownloads/>
    <div v-if="error" class="emote-error">File Download Failed, <br>File seems to be too large</div>
    <div class="ezmote-buttons">
      <div class="buttons">
        <button v-if="!showHistory" class="button-55" @click="showHistory = true">Load Template</button>
        <button v-if="showHistory" class="button-55" @click="showHistory = false">Edit Template</button>
        <button class="button-55" @click="downloadGrid">Download and Save Template</button>
        <button class="button-55" @click="uploadEmoteSheet">Upload Emote Sheet</button>
        <button v-if="uploadedImage" class="button-55" @click="downloadEmotes">Download Emotes</button>
      </div>
      <div class="buttons">
      </div>
    </div>
  </div>
</template>

<script>

import Sliders from "@/views/emote/EmoteTemplateSliders.vue";
import EmoteTemplateDownloadHistory from "@/views/emote/EmoteTemplateDownloadHistory.vue";
import EmoteTemplateDownloads from "@/views/emote/EmoteTemplateDownloads.vue";

export default {
  components: {EmoteTemplateDownloadHistory, Sliders, EmoteTemplateDownloads},
  data() {
    return {
      selectedEmotes: [],
      showHistory: false,
      newEmote: null,
      uploadedImage: null,
      error: false,
      currentTemplate: {
        rows: 8,
        columns: 4,
        squareAspectRatio: true,
        outerVerticalMargin: 300,
        innerVerticalMargin: 165,
        outerHorizontalMargin: 300,
        innerHorizontalMargin: 165,
        horizontalSizeCell: 700,
        verticalSizeCell: 700,
        marginColor: '#777777',
        cellColor: '#939393',
      }
    };
  },
  methods: {
    toggleSelected(i, j) {
      let index = this.selectedEmotes.indexOf(i + '-' + j)
      if (index === -1) {
        this.selectedEmotes.push(i + '-' + j)
      } else {
        this.selectedEmotes = this.selectedEmotes.filter((item) => item !== i + '-' + j)
      }
    },
    saveTemplate() {
      console.log('save')
      let storedArray = JSON.parse(localStorage.getItem('downloadedTemplates')) || [];
      let obj = this.currentTemplate;
      console.log(obj)
      const index = storedArray.findIndex(existingObj => JSON.stringify(existingObj) === JSON.stringify(obj));
      console.log(index)
      if (index === -1) {
        storedArray.push(obj)
      } else {
        storedArray = storedArray.filter(existingObj => JSON.stringify(existingObj) !== JSON.stringify(obj) && existingObj)
        storedArray.push(obj)
      }
      localStorage.setItem('downloadedTemplates', JSON.stringify(storedArray));
      console.log(storedArray)
    },
    changeValue(object) {
      this.currentTemplate = object
      this.onSliderChange()
    },
    onSliderChange() {
      if (this.currentTemplate.squareAspectRatio) {
        this.currentTemplate.outerHorizontalMargin = this.currentTemplate.outerVerticalMargin
        this.currentTemplate.innerHorizontalMargin = this.currentTemplate.innerVerticalMargin
        this.currentTemplate.verticalSizeCell = this.currentTemplate.horizontalSizeCell
      }
    },
    downloadEmotes() {
      // wait for 2 seconds after each emote.


      // Generate and store individual emote URLs
      for (let i = 2; i < this.currentTemplate.rows; i++) {
        for (let j = 2; j < this.currentTemplate.columns; j++) {


          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          // Set canvas dimensions
          canvas.width = this.hsc;
          canvas.height = this.actualHeight;
          this.drawSingleEmoteOnCanvas(ctx, canvas, i, j);
        }
      }
    }
    ,
    drawSingleEmoteOnCanvas(ctx, canvas, rowIndex, columnIndex) {


      // Calculate the coordinates on the image for cropping
      const x = 0; // Start cropping from the left edge of the image
      const y = 0; // Start cropping from the top edge of the image
      const imgWidth = this.hsc; // Width of the cropped region on the image
      const imgHeight = this.actualHeight; // Height of the cropped region on the image

      // Calculate the position on the canvas where the cropped image will be drawn
      const imgX = this.ohm + columnIndex * this.hsc + ((columnIndex) * this.ihm)
      const imgY = rowIndex * this.actualHeight + this.ovm + ((rowIndex) * this.ivm);

      console.log(rowIndex, columnIndex, x, y, this.hsc, this.actualHeight, imgX, imgY);

      // Draw the cropped emote onto the canvas
      const img = new Image();
      img.src = this.uploadedImage;

      img.onload = () => {
        // Draw the cropped image onto the canvas using the calculated coordinates
        ctx.drawImage(img, imgX, imgY, imgWidth, imgHeight, x, y, this.hsc, this.actualHeight);

        // Create a link for downloading the emote
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `emote_${rowIndex + 1}_${columnIndex + 1}.png`;
        link.click();
      };

    },
    uploadEmoteSheet() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      // Trigger the file input dialog
      input.click();
      // Handle the selected file
      input.addEventListener('change', (event) => {
        this.handleImageUpload(event);
      });
    },
    handleImageUpload(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        this.getImageResolution(input.files[0])
            .then(({width, height}) => {
              // You can now use width and height in your logic
              console.log('Width:', width);
              console.log('Height:', height);
              this.uploadedImage = URL.createObjectURL(input.files[0]);
            })
            .catch(error => {
              console.error('Error getting image resolution:', error);
              alert('Error getting image resolution. Please try again.');
            });
      }
    },
    getImageResolution(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            resolve({width: img.width, height: img.height});
          };
          img.src = e.target.result;
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      })
    },
    downloadGrid() {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const canvasWidth = this.calculateCanvasWidth();
        const canvasHeight = this.calculateCanvasHeight();
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        this.drawBacgroundColorOnCanvas(ctx, this.currentTemplate.marginColor);
        this.drawGridOnCanvas(ctx);
        canvas.toBlob((blob) => {
          this.error = true
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'grid.png';
          link.click();
          URL.revokeObjectURL(link.href);
          this.error = false
          this.saveTemplate()
        }, 'image/png');

      } catch (ex) {
        this.error = true
      }
    }
    ,
    calculateCanvasWidth() {
      return (this.currentTemplate.columns * this.hsc) + ((this.currentTemplate.columns - 1) * this.ihm) + this.ohm * 2;
    },

    calculateCanvasHeight() {
      return (this.currentTemplate.rows * this.actualHeight) + ((this.currentTemplate.rows - 1) * this.ivm) + this.ovm * 2;
    },

    drawBacgroundColorOnCanvas(ctx, color) {
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, this.calculateCanvasWidth(), this.calculateCanvasHeight());
    },
    drawGridOnCanvas(ctx) {
      ctx.imageSmoothingEnabled = false;

      const cellWidth = this.hsc + this.ihm;
      const cellHeight = this.actualHeight + this.ivm;
      for (let i = 0; i < this.currentTemplate.rows; i++) {
        for (let j = 0; j < this.currentTemplate.columns; j++) {
          const x = j * cellWidth + this.ohm;
          const y = i * cellHeight + this.ovm;
          ctx.fillStyle = this.currentTemplate.cellColor;
          ctx.fillRect(x, y, this.hsc, this.actualHeight);
        }
      }
    },
    rowStyle(index) {
      let width = (this.currentTemplate.columns * this.hsc) + ((this.currentTemplate.columns - 1) * this.ihm);
      let topMargin = index === 1 ? this.ovm : this.ivm;
      let bottomMargin = index === this.currentTemplate.rows ? this.ovm : 0;
      let leftMargin = this.ohm;
      let rightMargin = this.ohm;
      return {
        width: `${width}px`,
        margin: `${topMargin}px ${rightMargin}px ${bottomMargin}px ${leftMargin}px`,
        display: 'flex',
        flexDirection: 'row',
      }
    },
    cellStyle(x, y) {

      let marginLeft = y === 1 ? 0 : this.ihm;
      return {
        marginLeft: marginLeft + 'px',
        height: this.actualHeight + 'px',
        width: this.hsc + 'px',
        backgroundColor: this.currentTemplate.cellColor
      };
    },
    cellStyleSelected(x, y) {
      let marginLeft = y === 1 ? 0 : this.ihm;
      let border;
      if (this.selectedEmotes.includes(x + '-' + y)) {
        border = 'inset 0px 0px 0px 20px #0f0';
      } else {
        border = '';
      }
      return {
        marginLeft: marginLeft + 'px',
        height: this.actualHeight + 'px',
        width: this.hsc + 'px',
        webkitBoxShadow: border,
        mozBoxShadow: border,
        boxShadow: border,
        backgroundColor: this.currentTemplate.cellColor + "50"

      };
    }

  },
  computed: {
    ovm() {
      return parseInt(this.currentTemplate.outerVerticalMargin)
    },
    ivm() {
      return parseInt(this.currentTemplate.innerVerticalMargin)
    },
    ohm() {
      if (this.currentTemplate.squareAspectRatio) {
        return this.ovm
      } else {
        return parseInt(this.currentTemplate.outerHorizontalMargin)
      }
    },
    ihm() {
      if (this.currentTemplate.squareAspectRatio) {
        return this.ivm
      } else {
        return parseInt(this.currentTemplate.innerHorizontalMargin)
      }
    },
    hsc() {
      return parseInt(this.currentTemplate.horizontalSizeCell)
    },
    vsc() {
      return parseInt(this.currentTemplate.verticalSizeCell)
    },

    estimatedFileSize() {
      const width = this.calculateCanvasWidth();
      const height = this.calculateCanvasHeight();
      const bitsPerPixel = 32; // Assuming 32 bits per pixel for RGBA (8 bits per channel)
      const dpi = 96; // Dots per inch
      const compressionRatio = .69; // A rough estimate for PNG compression

      const estimatedFileSizeBytes = (width * height * bitsPerPixel * dpi ** 2) / (8 * 10 ** 6) * compressionRatio;
      const estimatedFileSizeKB = estimatedFileSizeBytes / 1024;
      const estimatedFileSizeMB = estimatedFileSizeKB / 1024;


      return estimatedFileSizeMB.toFixed(2);

    },
    actualHeight() {
      if (this.currentTemplate.squareAspectRatio) {
        return this.hsc;
      } else {
        return this.vsc;
      }
    },
    gridStyle() {
      let width = (this.currentTemplate.columns * this.hsc) + ((this.currentTemplate.columns - 1) * this.ihm) + this.ohm * 2;
      let height = (this.currentTemplate.rows * this.actualHeight) + ((this.currentTemplate.rows - 1) * this.ivm) + this.ovm * 2;

      // get window width in px
      // /*  left: 420px;
      //  top: 150px;

      let windowWidth = window.innerWidth - 420;
      let horizontalScale = windowWidth / width
      let windowHeight = window.innerHeight - 300;
      let verticalScale = windowHeight / height
      let scale = Math.min(horizontalScale, verticalScale, 1);
      console.log(scale)


      return {
        display: 'flex',
        flexDirection: 'column',
        margin: '0',
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: this.currentTemplate.marginColor,
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left',

      };
    },
  },
};
</script>

<style scoped>

* {
  font-family: Inter;
}

.buttons {
  display: flex;
  flex-direction: row;
}


.emote-parent {
  background-image: url("@/assets/emote-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #c0ffee;
  overflow: hidden;
}

.ezmote-logo {
  font-family: Impact;
  font-size: 40px;
  margin-inline-start: 48px;
  height: 44px;
}

.sliders h2 {
  width: 100%;
}

.sliders label {
  margin-left: 20px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.sliders span {
  font-weight: 900;
}


.ezmote-header {
  width: 100vw;
  height: 76px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.swatch h1 {
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 5em;
  padding: 0;
  width: 10em;
}

*:focus {
  border-radius: 0;
  outline: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 0;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

.button-55 {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.ezmote-buttons {
  position: absolute;
  top: 76px;
  text-align: center;
  margin-inline-start: 420px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 20px;
}

.preview {
  position: absolute;
  left: 420px;
  top: 150px;
}

.ezmote-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #a6d47d;
  height: 44px;
  border-radius: 40px 0 0 40px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 44px;
}

.ezmote-tab {
  margin: 10px;
}

.ezmote-tab:hover {
  opacity: .5;
}

.emote-error {
  color: red;
  top: 40px;
  position: absolute;
  left: 420px;
}

.gridMidHolder {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
