<template>
  <div class="page">
    <SlicesHeaderView/>
    <div class="choose-content">

      <div class="selection">
        <img src="https://3.datraff.com/bdsm-maniacs/bondagepics/2/2737.jpg" alt="Logo"/>
        <h1>Choose your slice</h1>
        <img src="https://titis.org/pics/uploads/posts/2022-09/1662889510_10-titis-org-p-hot-bdsm-pics-erotika-14.jpg"
             alt="Logo"/>

      </div>

      <div class="confirm">
        <h1> SELECT </h1>
      </div>


    </div>
    <SlicesAdsView/>
  </div>

</template>

<script>
import SlicesHeaderView from "@/views/slices/SlicesHeaderView.vue";

export default {
  components: {SlicesHeaderView},
  data() {
    return {
      daysUntilEvent: 7, // Replace with the actual number of days
    };
  },
  methods: {
    loginWithTwitch() {
      // Add your Twitch login logic here
    },
  },
};
</script>

<style scoped>
.page {
  background-color: #c0ffee;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

.header {
  justify-content: space-evenly;
  display: flex;
  background-color: #decaff;
  width: 100%;
  flex: 1;
}

.content {
}

.content img {
  max-width: 200px;
}

.ads {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  background-color: red;
  width: 160px;
  height: 600px;
}

/* Add more styles for the vertical banner ad here */
.selection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.confirm {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.choose-content {
  justify-content: space-evenly;
  padding-inline-end: 100px;
  padding-inline-start: 100px;
  background-color: #DEFACE;
  width: 100%;
  flex: 3;
  display: flex;
  flex-direction: column;
}

.choose-content img {
  max-width: 200px;
}

.selection {

}
</style>
