<template>
  <div class="gallery-header">
    <label for="date-select">Select a date:</label>
    <select id="date-select" :value="selectedDate" @input="onDateSelected">
      <option v-for="date in dates" :value="date" :key="date">{{ date }}</option>
    </select>
    <span  class="weekend-indicator">{{ dates.length }} Files left </span>
    <span v-if="isWeekend" class="weekend-indicator">OC</span>
  </div>
</template>

<script>
export default {
  name: 'GalleryHeader',
  props: {
    dates: {
      type: Array,
      required: true
    },
    selectedDate: {
      type: String,
      required: true
    }
  },
  computed: {
    isWeekend() {
      const selectedDateObj = new Date(this.selectedDate);
      const dayOfWeek = selectedDateObj.getDay();
      return dayOfWeek === 6 || dayOfWeek === 0; // Saturday or Sunday
    }
  },
  methods: {
    onDateSelected(event) {
      this.$emit('date-selected', event.target.value)
    }
  }
}
</script>


<style scoped>
.weekend-indicator {
  margin-left: 10px;
  padding-left: 5px;
  border-left: 1px solid #ccc;
}
</style>
