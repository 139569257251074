<template>
  <div class="bingo-view">
    <div class="side-column">
      <div v-for="player in sidePlayers" :key="player.id" class="player-bingo"
           @click="previewPlayer(player)">
        <h3>{{ player.name }}</h3>
        <h3>Card #{{ player.seed }}</h3>
        <h3 v-if="claimedBingo(player.name)">{{ claimedBingo(player.name) }}</h3>
      </div>
    </div>
    <div class="bingo-title">Ross Mode Bingo!</div>
    <div v-if="this.selectedPlayer" class="bingo-title">Player: {{ this.selectedPlayer }}</div>
    <div v-if="!this.selectedPlayer && this.host" class="bingo-title">Card: {{ this.cardId }}</div>
    <div v-if="!host" class="bingo-input-holder">
      <input v-model="cardId" class="input_field" placeholder="Enter Your Bingo Code" type="text"
      >
      <button class="get_cards_button" @click="getCards"
      >Get Bingo Cards
      </button>
    </div>
    <button v-if="!host" class="host_button" @click="setHost"></button>
    <div v-if="host && selectedCategory !== ''" class="bingo-card-holder">
      <div class="bingo-card">
        <div v-for="(card, index) in cards" :key="index" class="bingo-card-item"
             @click="toggleMarkCard(card)">
          <label :for="card.name">{{ card }}</label>
          <p v-if="markedCards.includes(card)" class="marked">X</p>
        </div>
      </div>
    </div>
    <h1 v-if="host && selectedCategory === ''">Please Select Current Category</h1>

    <div class="all-cards">
      <div v-for="(card, index) in allCategories" :key="index" class="all-card-item" @click="toggleMarkCategory(card)">
        <label>Category: {{ card }}</label><label v-if="selectedCategory">(click to change)</label>
      </div>
      <div v-for="(card, index) in allCards" :key="index" class="all-card-item" @click="toggleMarkCard(card)">
        <label :class="{markedAll: markedCards.includes(card)}">{{ card }}</label>
      </div>
    </div>

    <button v-if="host" @click="reset">Reset</button>
  </div>
</template>

<script>

import cardsData from "@/assets/cards.json";

export default {
  name: "BingoView",
  data() {
    return {
      cardCollections: cardsData,
      selectedCategory: "",
      cardId: "",
      host: null,
      markedCards: [
        "free use"
      ],
      playersBingoCards: [],
      selectedPlayer: "",
      bingoClaimsMap: new Map(),
      client: null,
    }
  },
  methods: {
    toggleMarkCategory(category) {
      if (this.selectedCategory === category) {
        this.selectedCategory = "";
      } else {
        this.selectedCategory = category;
      }
    },
    reset() {
      this.markedCards = [
        "free use"
      ];
      this.host = null;
      this.cardId = "";
      this.playersBingoCards = [];
    },
    getCards() {
      if (this.cardId === "" || this.cardId === null) {
        return;
      }
      this.playersBingoCards = [
        {name: "You", seed: this.cardId.toLowerCase().trim()},
      ];
      this.host = true
      this.selectedPlayer = "You"
    },
    setHost() {
      this.host = "SillyV";
      const tmi = require('tmi.js');
      this.client = new tmi.Client({
        channels: [this.host],
      });
      this.client.connect();
      this.client.on('message', (channel, tags, message) => {
        this.handleMessage(message, tags)
      });
      let randomSeed = Math.floor(Math.random() * 1000000);
      this.playersBingoCards = [
        {name: this.host, seed: randomSeed},
      ];
      this.selectedPlayer = this.host;

    },
    previewPlayer(player) {
      // remove this.selectedPlayer from the list bingoClaims
      this.bingoClaimsMap.delete(this.selectedPlayer);
      this.selectedPlayer = player.name;
    },
    claimedBingo(playerName) {
      if (!this.bingoClaimsMap.has(playerName)) {
        return false;
      }
      // readable hour of day (with seconds)
      return new Date(this.bingoClaimsMap.get(playerName)).toLocaleTimeString();
    },
    handleMessage(message, tags) {
      if (message === "!bingo") {
        const timestamp = new Date().getTime();
        this.bingoClaimsMap.set(tags.username, timestamp);
      } else if (message === "!me") {
        let randomSeed = Math.floor(Math.random() * 1000000);
        if (!this.playersBingoCards.some((player) => player.name === tags.username)) {
          this.playersBingoCards.push({name: tags.username, seed: randomSeed});
        }
      }
    },
    shuffleCards(selectedPlayer) {
      let cardId = 0;
      this.playersBingoCards.forEach((player) => {
        if (player.name === selectedPlayer) {
          cardId = player.seed;
        }
      });

      let cardsToShuffle = this.allCards;
      // remove duplicates by using a set
      cardsToShuffle = [...new Set(cardsToShuffle)];
      // find selected player, and get their seed
      let seededShuffle = this.seededShuffle(cardId, cardsToShuffle);
      // insert the free use in the middle
      seededShuffle.splice(12, 0, "free use");
      // limit the list to 25 items
      seededShuffle = seededShuffle.slice(0, 25);
      return seededShuffle
    },
    generateCard() {
      let newCardId = Math.floor(Math.random() * 1000000);
      this.playersBingoCards.forEach((player) => {
        if (player.name === this.selectedPlayer) {
          player.seed = newCardId;
        }
      });
    },
    isBingo(markedCards, cardOrder) {
      const numbers = markedCards.map((card) => cardOrder.indexOf(card));
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
          if (!numbers.includes(i * 5 + j)) {
            break;
          }
          if (j === 4) {
            return true;
          }
        }
      }
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
          if (!numbers.includes(j * 5 + i)) {
            break;
          }
          if (j === 4) {
            return true;
          }
        }
      }

      // check the diagonals
      for (let i = 0; i < 5; i++) {
        if (!numbers.includes(i * 5 + i)) {
          break;
        }
        if (i === 4) {
          return true;
        }
      }
      for (let i = 0; i < 5; i++) {
        if (!numbers.includes(i * 5 + 4 - i)) {
          break;
        }
        if (i === 4) {
          return true;
        }
      }
      return false;
    },

    toggleMarkCard(index) {
      if (index === "free use") {
        return;
      }
      if (this.markedCards.includes(index)) {
        this.markedCards = this.markedCards.filter((card) => card !== index);
      } else {
        this.markedCards.push(index);
      }
    },
    seededShuffle(seed, array) {
      let currentIndex = array.length, temporaryValue, randomIndex;

      // Use a seeded random number generator
      function seededRandom() {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
      }

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(seededRandom() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
  },
  mounted() {
    let cardParam = this.$route.query.card;
    if (cardParam) {
      this.playersBingoCards = [
        {name: "You", seed: this.cardId},
      ];
      this.host = true
    }
  },
  computed: {
    allCategories() {
      if (this.selectedCategory === "") {
        return this.cardCollections.map((collection) => collection.name);
      } else {
        return [this.selectedCategory];
      }
    },
    allCards() {
      if (this.selectedCategory === "") {
        return [];
      } else {
        return this.cardCollections.find((collection) => collection.name === this.selectedCategory).cards;
      }
    },
    sidePlayers() {
      let allPlayers = this.playersBingoCards;
      //   main sort by timestamp in BingoClaimMap,  secondary sort alphabetically!

      console.log(this.bingoClaimsMap)


      allPlayers.sort((a, b) => {
        if (this.bingoClaimsMap.get(a.name) != null && this.bingoClaimsMap.get(b.name) != null) {
          return this.bingoClaimsMap.get(a.name) - this.bingoClaimsMap.get(b.name);
        } else if (this.bingoClaimsMap.get(a.name) != null) {
          return -1;
        } else if (this.bingoClaimsMap.get(b.name) != null) {
          return 1;
        } else {
          //   keep SillyV first, but otherwise sort alphabetically
          if (a.name === "SillyV") {
            return -1;
          } else if (b.name === "SillyV") {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        }
      });

      console.log(allPlayers)
      return allPlayers;

    },
    markBingo() {
      return this.isBingo(this.markedCards, this.cards);
    },
    cards() {

      return this.shuffleCards(this.selectedPlayer);
    }
  }

}
</script>

<style scoped>
.bingo-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust height as needed */
  gap: 10px; /* Adjust the gap as needed */
}

.bingo-card-item label {
  text-transform: uppercase;
  font-size: 1rem;
  font-family: Inter, sans-serif;
  align-items: center;


}

.bingo-card-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  aspect-ratio: 1;
  width: 10vh; /* Set a fixed width for each card item */
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
}

.bingo-card-holder {
  display: flex;
  justify-content: center;
  align-items: center;

}


.marked {
  position: absolute;
  color: #ff0000;
  font-size: 5rem;
  font-family: Inter, sans-serif;
  width: 10vh;
  height: 10vh;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.side-column {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

}

.player-bingo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.player-bingo h3 {
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0;
  text-align: start;
  padding: 0;
  margin-inline-start: 30px;
}


.all-cards {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
}

.all-card-item {
  border: 1px solid #000;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Inter, sans-serif;
}

.markedAll {
  color: #ff0000;
}

.bingo-title {
  width: 100%;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 2rem;
}

.bingo-input-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

}

/*center of screen*/
.host_button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  left: 0;
  transform: translate(0, -50%);
  margin: 20px;
  padding: 10px;
  font-size: 1.5rem;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

.get_cards_button {
  padding: 10px;
  font-size: 1.5rem;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

.input_field {
  padding: 10px;
  font-size: 1.5rem;
  font-family: Inter, sans-serif;
  text-transform: uppercase;
  border: 1px solid #000;
}
</style>
